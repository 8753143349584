if(!window.console){
    window.console = {
        log:function(){}
    };
}

var _error_msg=0;
var _decimalplaces;
var _display_decimalplaces;
var _isBookingAllowed=true;
var _noGaps=true;
var _totalBookingAmount=0.00;
var _bookingError='';
var _noset=false;
var _row_total=[];
var _row_strike_total=[];
var _first_load=0;
var _quick_load=0;
var default_var=0;
var resize_done=-1;
var lfolder='';

(function($) {
    
        // Start - Krunal Kaklotar - 08-04-2019 - Get service details
        $.ajax({
        url:  window.location.protocol + "//"+ window.location.hostname+ '/booking/sevicedata.php',
        type: "POST",
        dataType:'JSON',
        data: {
                action:'book_rooms',
        },
        error: function(request,error,message){
                console.log( "AJAX - token error() >> "+message);
        },
        success: function(result){
                console.log('result');
                console.log(result);
            }
        });
        // END - Krunal Kaklotar - 08-04-2019 - Get service details 
    
    $.fn.eZBookingPlugin = function(customOptions) {  //Add the function
        
        var options = $.extend({}, defaultOptions, customOptions);
		
		//FB code issue checking for solving issue of: 
		//methods._checkfbaccess(options);
		
        _decimalplaces=options.decimalplaces;
        _display_decimalplaces=options.display_decimalplaces;
        methods._init(options);
        
        if(options.loadgrid==true)
            methods._loadGrid(options);
        if(options.modifysearch==true)
            methods._initModifyGrid(options);        
    };
    
     var methods = {
        test:'123',
        _init:function(opt){
            if(opt.init)
            {                
                //Widget Header
                if(opt.siteroot==true)//Widget Calling
                {                    
                    //opt.ResUILimitRatePlanPackage=0;
                    methods._loadHeader(opt);
                    methods._loadExternalCssJs(opt);
                    methods._loadJsDefault(opt);
                }
                        
                //if (typeof(top.frames['editframe'])!='undefined' ) {
                //    opt.ResUILimitRatePlanPackage=0;
                //}
                //Purpose - Cut off time && opt.CalMinDate=='0'
                if(opt.siteroot==true)//Chinmay Gandhi - 1.0.52.57 - Set infinit day
                {
                    var param='action=getDefaultDays&HotelId='+opt.HotelId+'&DefaultDays='+opt.CalMinDate;
                    
                    var cutofftime_days=opt.CalMinDate;
                    jQuery.ajax({
                        type: "POST",
                        url: opt.serverurl+'service.php',
                        async:false,
                        data: param, 
                        success: function(response) {
                            cutofftime_days=response.trim();
                        }
                    });
                    
                    if (cutofftime_days!='' && cutofftime_days!=undefined && cutofftime_days>=0)
                    {
                    opt.CalMinDate=cutofftime_days;
                    }
                }
        
                console.log("init cal ...................");
                getUrl=opt.serverurl+'bx-'+opt.HotelId+'';
                var _passchkDate,_passchkoutDate,_selPromo,_SetNight;
                var _setAdult,_setChild;
                
                 if(opt.chkindate!='null')
                    _passchkDate=opt.chkindate;
                 
                 if((opt.chkoutdate_modify!=null && opt.chkoutdate_modify!=''))//for billing page change dates
                 {
                    _passchkoutDate=opt.chkoutdate_modify;
                 }
				 else if(opt.chkoutdate!='null' && opt.ShowDepart==true)//dept date
                   _passchkoutDate=opt.chkoutdate;
                
                if (opt.nights_billing!=null && opt.nights_billing!='') {//for billing page change dates
                        _SetNight=opt.nights_billing;
                }
                else if(opt.ShowNights==true && opt.SelectedNights!=0 && opt.chkoutdate_modify!=null)
                    _SetNight=opt.SelectedNights;
                    
                 if(opt.SelectedPromo!=null && opt.SelectedPromo!='' && ( $("input[id$='promotioncode']").val() == undefined || $("input[id$='promotioncode']").val() == '' ))//Priyanka Maurya - 03 Sep 2019 - Purpose : Regarding Promotion code - RES-2201
                    _selPromo=opt.SelectedPromo;	
                if(opt.CalLanguage!='')
                    _setCalLanguage=opt.CalLanguage;
                
                /*adult & child - start*/
                //opt.RunOnMaximumOccupanySettings=0;
               
                if(opt.SelectedAdults!=0 && opt.SelectedAdults!='')
                    _setAdult=opt.SelectedAdults;
				else if(opt.defaultselectedadult!=0 && opt.defaultselectedadult!='')
                    _setAdult=opt.defaultselectedadult;
                
                if(opt.RunOnMaximumOccupanySettings==0)
                {
                    if(opt.SelectedChild!='')
                        _setChild=opt.SelectedChild;
                    else if(opt.defaultselectedchild!='')
                        _setChild=opt.defaultselectedchild;
                }
                else
                {
                    _setChild=0;
                }
                /*adult & child - end*/
                
                $.ajax({
                        url: getUrl,
                        type: "POST",
                        dataType: "html",
                        data: {
                                calendarDateFormat: opt.CalDtFormat,
                                calendarDefaultDays: opt.CalMinDate,
                                HotelId: opt.HotelId,
                                isLogin: opt.isLogin,
                                eZ_chkin: _passchkDate, 
                                eZ_chkout: _passchkoutDate,//dept date
                                eZ_Nights: _SetNight,
                                eZ_adult: _setAdult,
                                eZ_child: _setChild,
                                promotionalcode: _selPromo,
                                selectedLang:opt.selectedLang,
                                CalLanguage:_setCalLanguage,
                                LayoutTheme:opt.LayoutTheme                                
                                },
                    error: function(e){
                    	console.log( "AJAX - error() _init "+ e);
                    },
                    beforeSend: function(){
                        try
						{							
							if(parent.document.domain!=opt.serverhost && !parent.editframe)
							{
								methods._iescriptload(opt);
								console.log( "AJAX - _iescriptload() load - if" );
							}
						}
						catch(e)
						{
							methods._iescriptload(opt);	
							console.log( "AJAX - _iescriptload() load - else "+e);
						}
						//Cross SIte browser checking for IE - FB & Iframe page - End
						
                        
                    },
                    complete: function(){
                        
                        if(opt.siteroot==true)//Widget Calling
						{
							methods._setThemes(opt);
							methods._loadbbevents(); //Pinal
						}
                        //if(opt.ShowCurrencyCountryWise == 0)
                        //{
                        //    console.log("get country name");
                        //    methods._getCountryName(opt);//Chinmay Gandhi - Set currency list vise
                        //}
                        if(opt.siteroot!=true) //Pinal - do not call when from widget
                        methods._loadOtherJs(opt);						
						methods._getImages(opt);							
						methods._initDatePicker(opt);
                        methods._loadLoadingBar(); //Pinal - needed for widget code
						methods._loadAcr(opt);
						//methods._loadPromotion(opt);                     	
						
                        //methods._loadFilterBar(opt);
                        //methods._ShowQuickNotes(opt); //Pinal
                        //Prashant Patel - 29/09/2021 - Purpose : maxnight restriction while redirect from booking widget [RES-2881] --START
                        if(opt.SelectedNights > 90)
                        {
                            jQuery('.confirm').click(function(){
                                methods._loadGrid(opt);
                            });
                        }
                        else
                        {
                            methods._loadGrid(opt);
                        }
                        //Prashant Patel - 29/09/2021 - Purpose : maxnight restriction while redirect from booking widget [RES-2881] --END
                        methods._loadPromotion(opt); //Pinal - shifted from above to here.
                        methods._loadFilterBar(opt); //Pinal - shifted from above to here.
                        if(opt.ShowCurrencyCountryWise == 0) //Pinal - 6 April 2018 - Purpose : Booking Engine Optimization , shifted from above to here.
                        {
                            console.log("get country name");
                            methods._getCountryName(opt);//Chinmay Gandhi - Set currency list vise
                        }
                        
                        $("#book").click(function()
                        {
							if(opt.ShowNights==false && opt.ShowDepart==true)//dept date
							{
								var startDate=$("#eZ_chkin").val();
								var endDate = $("#eZ_chkout").val();

								startDate = $.datepicker.parseDate(opt.CalDtFormat, startDate);
								endDate = $.datepicker.parseDate(opt.CalDtFormat, endDate);

								var difference = (endDate - startDate) / (86400000);
								if(difference > 90)//Prashant Patel - 17/09/2021 - Purpose : change condition as per maxnights [RES-2881]
								{
									 var message = js_stripslashes(langjs[0]["SWAL_morenightsnotallowed"]);                                     
                                     var title_message = js_stripslashes(langjs[0]["lblAccessRestricted"]); 
                                     swal({
                                            title: title_message,
                                            text: message,
                                            type: 'warning',
                                            animation:false	
                                        },function(){              //Prashant Patel - 17/09/2021 - Purpose: Add callback function [RES-2881]
                                            var date = startDate;
                                            date.setDate(date.getDate() + 90);
                                            jQuery("#eZ_chkout").datepicker('setDate',date);
                                            jQuery("#eZ_Nights").val(90);
                                        });                                     
									 hideCheckavalabilityLoadingBar();									 
									 return false;
								}
								
								if (difference < 0) {									
									 var message = js_stripslashes(langjs[0]["L_ChkGreater"]);
                                     var title_message = js_stripslashes(langjs[0]["lblErrordate"]); 
                                     swal({
                                            title: title_message,
                                            text: message,
                                            type: 'error',
                                            animation:false	
                                        });  
									 hideCheckavalabilityLoadingBar();
                                     return false;
								}
							}	
							else
							{
								if(parseInt(jQuery("Select[id$='eZ_Nights']").val()) >  90)//Prashant Patel - 17/09/2021 - Purpose : change condition as per maxnights [RES-2881]
								{
								 var message = js_stripslashes(langjs[0]["SWAL_morenightsnotallowed"]);
								 var title_message = js_stripslashes(langjs[0]["lblAccessRestricted"]); 
                                     swal({
                                            title: title_message,
                                            text: message,
                                            type: 'warning',
                                            animation:false	
                                        },function(){              //Prashant Patel - 17/09/2021 - Purpose: Add callback function [RES-2881]
                                            jQuery('#eZ_Nights option[value="90"]').prop('selected',true).change();
                                        });      
								 hideCheckavalabilityLoadingBar();
                                 return false;
								}
							}
                            methods._checksession(opt,'book');//Issue : Once page load & cookie clear , as soon as click on 'check availability' button system shows error "opps wrong page url messages." transfer both below method to checksession method
                           // methods._loadGrid(opt);
                           // methods._InsertCheckAvail(opt); //Pinal - Booking Engine analytics                           
                        });
						
                        $("#promotioncode").keyup(function(event){
                            if(event.keyCode == 13){
                                jQuery("#trackbtnclicked").val(1);
                                methods._loadGrid(opt);
                            }
                        });
                        $("#promotioncode3").keyup(function(event){
                            if(event.keyCode == 13){
                                jQuery("#trackbtnclicked").val(1);
                                methods._loadGrid(opt);
                            }
                        });	
                    },
                    success: function( data )
                    {                        
                        $("<div id='temp' style='display:block;'></div>").appendTo("div#eZee_BookingHeader");
                        $("#temp").html(data);
                        $('#eZee_BookingHeader').html($("#box").html());
                        $("#temp").remove();
                        
                    }					
                });
                opt.init=false;
            }
            opt.init;
        },
        _checksession_test:function(opt,pageclicked){
          try{            
                getUrl=opt.serverurl+'service.php?HotelId='+opt.HotelId+'&chksession=1';
                var message_title = js_stripslashes(langjs[0]["lblHangon"]); 
                var message = js_stripslashes(langjs[0]["lblSessionExpired"]);
                var message_btn = js_stripslashes(langjs[0]["L_Pleaserefreshpage"]);
                               
                $.ajax({
                        url: getUrl,
                        type: "POST",
                        dataType: "html",
                        data: {                                
                                HotelId: opt.HotelId,                                
                                selectedLang:opt.selectedLang,                                
                                LayoutTheme:opt.LayoutTheme                                
                                },
                    error: function(e){
                    	console.log( "AJAX - error() _init "+ e);
                    },                   
                    complete: function(){                       
                
                    },
                    success: function( data ){                        
                        if(jQuery.trim(data)!='') {
                                        swal({
                                        title: message_title,
                                        text: message,
                                        type: "warning",
                                        confirmButtonText: message_btn,
                                        showCancelButton: false,
                                        //cancelButtonText:"No",
                                        confirmButtonColor: "#DD6B55",
                                        animation:false				
                                        },
                                        function(){
                                            if (opt.LocalFolderSessionChk!='') {                                               
                                                window.location.href=opt.serverurl+'book-rooms-'+opt.LocalFolderSessionChk;
                                                //return false;
                                            }
                                            else
                                            {
                                                lfolder=1;
                                                //t=1;                                              
                                                //window.location.href=opt.serverurl+'book-rooms-'+opt.lfolder;
                                                //return false;
                                            }                                            
                                        }
                                    );                    
                                    hideLoadingBar();
                                    return false;
                        }
                        else
                        {                            
                            
                        }
                    }
                 });
                if (lfolder=='') {
                    return true;
                }
                else
                    return false;
          }
          catch(err){
                hideLoadingBar();
                hideCheckavalabilityLoadingBar();
				console.log("Error - _checksession_test - "+err.message);
          }
        },
        _checksession:function(opt,pageclicked){
          try{
                getUrl=opt.serverurl+'service.php?HotelId='+opt.HotelId+'&chksession=1';
                var message_title = js_stripslashes(langjs[0]["lblHangon"]); 
                var message = js_stripslashes(langjs[0]["lblSessionExpired"]);
                var message_btn = js_stripslashes(langjs[0]["L_Pleaserefreshpage"]);
                
                if (opt.selectedLang!='-zh-CN-Chinese' && opt.selectedLang!='-zh-TW-ChineseTraditional') {
                    var lang=opt.selectedLang.split("-");
                 }
                  else
                 {
                     var lang2=opt.selectedLang.split("-");
                     var lang=Array();
                     lang[2]=lang2[3];
                     lang[1]=lang2[1]+"-"+lang2[2];
                 }
                 
                 $.ajax({
                        url: getUrl,
                        type: "POST",
                        dataType: "html",
                        data: {                                
                                HotelId: opt.HotelId,
                                //lang_code:lang[1],
                                //langtext:lang[2],
                                selectedLang:opt.selectedLang,                                
                                LayoutTheme:opt.LayoutTheme,
                                lang_code:lang[1],
                                langtext:lang[2]
                                },
                    error: function(e){
                    	console.log( "AJAX - error() _init "+ e);
                    },                   
                    complete: function(){                       
                
                    },
                    success: function( data ){
                        if(jQuery.trim(data)!='') {
                                        swal({
                                        title: message_title,
                                        text: message,
                                        type: "warning",
                                        confirmButtonText: message_btn,
                                        showCancelButton: false,
                                        //cancelButtonText:"No",
                                        confirmButtonColor: "#DD6B55",
                                        animation:false				
                                        },
                                        function(){
                                            if (opt.LocalFolderSessionChk!='') {
                                                window.location.href=opt.serverurl+'book-rooms-'+opt.LocalFolderSessionChk;
                                            }
                                            else
                                            {                                               
                                                window.location.href=opt.serverurl+'book-rooms-'+opt.lfolder;
                                                //return false;
                                            }                                            
                                        }
                                    );                    
                                    hideLoadingBar();
                                    return false;
                        }
                        else
                        {
                            if (pageclicked=='book') {
                                methods._loadGrid(opt);
                                methods._InsertCheckAvail(opt); //Pinal - Booking Engine analytics
                            }
                            if (pageclicked=='promo') {
                                methods._loadGrid(opt); 
                            }
                        }
                    }
                 });
          }
          catch(err){
                hideLoadingBar();
                hideCheckavalabilityLoadingBar();
				console.log("Error - _checksession - "+err.message);
          }
        },
        _setOtherThemes:function(opt){
            try {
                
                 $(".vres_bhk_pos .full-bar-wrapper .input-daterange label p").css('color','#ffffff');
                 $(".vres_promo-btn").css('color','#ffffff'); 
                
            } catch(e) {
                hideLoadingBar();
                hideCheckavalabilityLoadingBar();
				console.log("Error - _setOtherThemes - "+err.message);
            }
        },
        _initModifyGrid:function(opt){
            
        },
        _loadExternalCssJs:function(opt){
          try{
                //var _cssName=opt.serverurl+"/templates/resui/css/";		
                //var _jsName=opt.serverurl+"/templates/resui/js/";		
				//$.getScript(_jsName+"datepicker_lang/jquery.ui.datepicker-"+opt.CalLanguage+".js");
				//$.getScript(_jsName+"_resbgnext.js");
                
                //load font
                $("<link/>", {
                    rel: "stylesheet",
                    type: "text/css",
                    href: "https://fonts.googleapis.com/css?family=Source+Sans+Pro"
                 }).appendTo("head");                 
          }
          catch(err){
                hideLoadingBar();
                hideCheckavalabilityLoadingBar();
				console.log("Error - _loadExternalCssJs - "+err.message);
          }
        },
        _loadHeader:function(opt){
            try{
                 console.log("load header");
                getUrl=opt.serverurl+'resheader-'+opt.HotelId+'';                 
                 $.ajax({
                        url: getUrl,
                        type: "POST",
                        dataType: "html",
                        data: {                                
                                HotelId: opt.HotelId,
                                isLogin: opt.isLogin,
                                selectedLang:opt.selectedLang,                                
                                LayoutTheme:opt.LayoutTheme                                
                    },
                    error: function(e){
                    	console.log( "AJAX - error() _init "+ e);
                    },
                    beforeSend: function(){
                        try
						{							
							if(parent.document.domain!=opt.serverhost && !parent.editframe)
							{
								methods._iescriptload(opt);
								console.log( "AJAX - _iescriptload() load - if" );
							}
						}
						catch(e)
						{
							methods._iescriptload(opt);	
							console.log( "AJAX - _iescriptload() load - else "+e);
						}
						//Cross SIte browser checking for IE - FB & Iframe page - End
					},
                    complete: function(){                       
						if(opt.siteroot==true)//Widget Calling
						{
							methods._setThemes(opt);
							methods._loadheaderevents(opt);
							//$.getScript(opt.serverurl+"/templates/resui/js/main.js"); //Pinal - load main.js for widget.
                            $.getScript(opt.serverurl+"/templates/resui/js/main.min.js"); //Pinal - 8 March 2018 - Purpose :  Booking Engine Optimization
						}
                    },
                    success: function( data ){                        
                        $("<div id='tempheader' style='display:block;'></div>").appendTo("div#eZee_BookingTop");
                        $("#tempheader").html(data);
                        $('#eZee_BookingTop').html($("#resheader").html());
                        $("#tempheader").remove();                        
                    }
                 });
            }
            catch(err){
                hideLoadingBar();
                hideCheckavalabilityLoadingBar();
				console.log("Error - _loadOtherJs - "+err.message);
            }
        },
        _loadOtherJs:function(opt){
          try{
                methods._loadheaderevents(opt); //Pinal - shifted some header events to function.
		
		// Currency and Language dropdown
                //$('.cur-btn').on('click', function(e){        
                //    //console.log("call" + $(this).parent().hasClass('open') );
                //    if($(this).parent().hasClass('open')){
                //        $(this).parent().removeClass('open');    
                //    }else{
                //        $('.vres-cur-label').removeClass('open');
                //        $(this).parent().addClass('open')
                //    }
                //});
                
                // Header Popup
                //$('.vres-pop-down .pop-down-btn').on('click', function(){
                //    if($(this).parent().hasClass('open')){
                //        $(this).parent().removeClass('open');  
                //    }else{
                //        $('.vres-pop-down').removeClass('open');
                //        $(this).parent().addClass('open');     
                //    }
                //});
                
                 //Back to Top
                //if ($('.vres-back-top').length) {
                //    var scrollTrigger = 100, // px
                //        backToTop = function () {
                //            var scrollTop = $(window).scrollTop();
                //            if (scrollTop > scrollTrigger) {
                //                $('.vres-back-top').addClass('vshow');
                //            } else {
                //                $('.vres-back-top').removeClass('vshow');
                //            }
                //        };
                //    backToTop();
                //    $(window).on('scroll', function () {
                //        backToTop();
                //    });
                //    $('.vres-back-top').on('click', function (e) {
                //        e.preventDefault();
                //        $('html,body').animate({
                //            scrollTop: 0
                //        }, 500);
                //    });
                //}
                
                // $('.offer-btn').on('click', function(){
                //    $('.vres-specialoffer').toggleClass('vres-offerposition').css({'transition':'all ease .3s'});
                //    $('#ofr-btn').toggleClass('offr_bg');
                //    $(this).children('img').toggleClass('animate_ofr-img');
                //    
                //    if(opt.siteroot==true)//widget calling
                //    {
                //        if (jQuery("div#vres-specialoffer").hasClass('vres-offerposition')) {                        
                //           methods._specialOffersCss(opt);                       
                //        }
                //        else
                //        {                        
                //            $("#ofr-btn").css('background','');
                //        }
                //    }
                //});    
    
                $('.vres-lang-btn').on('click', function(e){
                   // e.stopPropagation();
                    $(this).toggleClass('vres-bgli');
                    $('.vres-lang-btn').find('.vres-dropdown').toggle();
                });
                
		methods._loadbbevents(); //Shifted help box event to function - Pinal
                //$('.help_btn').on('click', function(e){
                //    e.stopPropagation();
                //    $(this).parents('[data-type="help_section"]').find('.vres-helpdetail').toggle();
                //});
    
    
                // close header dropdowns on outside click
               // $(document).mouseup(function (e){
               //     var vrescontainer = $(".vres-cur-label");
               //     //console.log(e.target);
               //     if (!vrescontainer.is(e.target)
               //         && vrescontainer.has(e.target).length === 0)
               //     {
               //         $('.vres-cur-label').removeClass('open');
               //     }
               // });
               // 
               //  $("ul#CHCurrency li").on('click', function(e){			
               //     if($(this).attr('value')!='')
               //     {					
               //         if(typeof(resgrid[0][0])!='undefined')
               //         {
               //             $('.vres-cur-label').removeClass('open');
               //             methods._getCurrencyValue(opt,resgrid[0][0]['MultiplyCurrCode'],$(this).attr('value'),resgrid[0][0].decimal);
               //         }
               //     }
               //});
                 
                //Language              
                //jQuery("ul#lang_cmb > li span").on('click', function(e){
                //    var $clicked = jQuery(e.target);
                //    //console.log($clicked);
                //    if(!$clicked.parents().hasClass("langselected"))
                //    {
                //        classlang=$clicked.parents('li').attr('id');                        
                //        var lang_arr=classlang.split("_");
                //        
                //        caption_lang=$clicked.attr('id');                       
                //        var caption_arr=caption_lang.split("|");
                //        
                //        if(lang_arr[1]=='ru')
                //            var lang_caption='Russian';
                //        else
                //            var lang_caption=caption_arr[1];
                //            //console.log("select " + lang_arr[1] + "  : " + lang_caption );
                //        change_lang(lang_arr[1],lang_caption);
                //    }
                //});
		
		jQuery("ul#lang_cmb > li").on('click', function(e){
		    
			var $clicked=jQuery(this);
			
			if(!$clicked.hasClass("langselected"))
			{
			    classlang=$clicked.attr('id');                        
			    var lang_arr=classlang.split("_");
			    
			    caption_lang=$clicked.find("span").attr('id');                       
			    var caption_arr=caption_lang.split("|");
			    
			    if(lang_arr[1]=='ru')
			      var lang_caption='Russian';
			    else
			      var lang_caption=caption_arr[1];
			    //console.log("select " + lang_arr[1] + "  : " + lang_caption );
			    change_lang(lang_arr[1],lang_caption);
			}
		});
		
             }
            catch(err){
                hideLoadingBar();
                hideCheckavalabilityLoadingBar();
				console.log("Error - _loadOtherJs - "+err.message);
		    } 
        },
        _loadFilterBar:function(opt){
            try{                
                 getUrl=opt.serverurl+'showfilter-'+opt.HotelId+'';
                 
                 //Flora - for chienese case it was not work
                if (opt.selectedLang!='-zh-CN-Chinese' && opt.selectedLang!='-zh-TW-ChineseTraditional') {
                    var lang=opt.selectedLang.split("-");
                 }
                  else
                 {
                    var lang2=opt.selectedLang.split("-");
                    var lang=Array();
                    lang[2]=lang2[3];
                    lang[1]=lang2[1]+"-"+lang2[2];
                 }
                 //Flora - for chienese case it was not work
                //var lang=opt.selectedLang.split("-");//flora - - for chienese case it was not work - OLD
                 
                 $.ajax({
                        url: getUrl,
                        type: "POST",
                        dataType: "html",
                        data: {                              
                                HotelId: opt.HotelId,
                                lang_code:lang[1],
                                langtext:lang[2],                              
                                LayoutTheme:opt.LayoutTheme,
                                ResUIshowfilter : opt.ResUIshowfilter, //Pinal - 21 March 2018 - Purpose : Booking Engine Optimization - START
                                ResUIshowfilterPreference : opt.ResUIshowfilterPreference,
                                ResUIFilterRoomType : opt.ResUIFilterRoomType,
                                ResUIFilterSearchMore : opt.ResUIFilterSearchMore, //END
                    },
                    error: function(e){
                    	console.log( "AJAX - error() _init "+ e);
                    },
                    beforeSend: function(){
                    
                    },
                    complete: function(){                       						
						 methods._loadFilterOptions(opt);
                         //methods._loadDefaultJsInit(opt); //Priyanka Maurya - 15 Sep 2020 - Purpose : Sometime Fileter Menus Click Function is not called - RES-2599//Kishan Tailor 8 Oct 2020 purpose:reverte code as its showing wrong rate RES-2627
                        
                        $('.showhide_filter_pnl').text('');
                        $('.showhide_filter_pnl').on('click', function(){
                            $(this).toggleClass('opn-tab');
                        });

                        if(opt.WebShowRatesAvgNightORWholeStay) {                        
                            //price slider 
                            var min_value=0;
                            var max_value=0;
                            if (opt.ResUIMinimumPrice>0) {
                              min_value=parseInt(opt.ResUIMinimumPrice)
                            }
                            if (opt.ResUIMaximumPrice>0) {
                              max_value=parseInt(opt.ResUIMaximumPrice)
                            }
                           
                            $( "#slider-range" ).slider({
                                range: true,
                                min: min_value,
                                max: max_value,
                                values: [ min_value, max_value ],
                                slide: function( event, ui ) {                               
                                    if(typeof(resgrid)!='undefined') {
                                    var curr_lbl_pre=curr_lbl_post='';
                                    if(resgrid[0][0].curr_prefix==1) {
                                       curr_lbl_pre=resgrid[0][0].currency+' ';
                                    }
                                    else
                                       curr_lbl_post=resgrid[0][0].currency+' ';
                                    }
                                   
                        $( "#price_slider" ).val( curr_lbl_pre + ui.values[ 0 ] + curr_lbl_post + " - "+curr_lbl_pre+ ui.values[ 1 ]+ curr_lbl_post );
                        $("input#price_slider_val").val(ui.values[ 0 ]+"|"+ui.values[ 1 ]);
                        // console.log("slider 2 " +  ui.values[ 0 ] );
                         
                                }
                            });
                        }
                        
                        if(opt.ResUIFilterNonRefundableValue==1) {
                            jQuery("#nonrefundable").prop("checked",true);
                        }
                        
                         jQuery("#process_filter").on('click',function(){
                               /*XSS and CSRF changes - Chinmay - START*/
                               //Prashant Patel - 27/09/2021 - Purpose :  Add restriction for maxnights while apply filter [RES-2881] ---START
                                startDate = $.datepicker.parseDate(opt.CalDtFormat, $("#eZ_chkin").val());
                                endDate = $.datepicker.parseDate(opt.CalDtFormat, $("#eZ_chkout").val());
                                selectednights = jQuery("Select[id$='eZ_Nights']").val();
                                if(opt.ShowNights==false && opt.ShowDepart==true)
                                {
                                    var difference = (endDate - startDate) / (86400000);
                                }
                                else
                                {
                                    var difference = selectednights;
                                }
                                if(difference > 90)
                                {
                                    jQuery('#book').trigger('click');
                                    return false;
                                }
                                //Prashant Patel - 27/09/2021 - Purpose :  Add restriction for maxnights while apply filter [RES-2881] ---END
                                var config_token=jQuery("input#restoken").val();
                                if(typeof(config_token)=='undefined') {
                                   var config_token='';
                                }
                                jQuery.ajax({
                                      type: "POST",
                                      url: serverurl+'service.php',			  
                                      data: 'action=checktoken&HotelId='+opt.HotelId+'&config_token='+config_token,
                                      success: function(response) {
                                          
                                       },//success
                                       complete: function(response){
                                        if (jQuery.trim(response.responseText)==1 || config_token=='')
                                        {						
                                        /*XSS and CSRF changes - Chinmay - END*/
                                            methods._checksession(opt,'promo');//Check session - florra
                                        //methods._loadGrid(opt);
                                        /*XSS and CSRF changes - Chinmay - START*/			
                                        }
                                        else
                                        {
                                            swal({
                                                    title: 'Invalid Request',
                                                    text: "Invalid Request",
                                                    type: "warning",
                                                    showCancelButton: false,
                                                    //cancelButtonText:"No",
                                                    confirmButtonColor: "#DD6B55",
                                                    animation:false			
                                                    },
                                                    function(){
                                                        //methods._loadGrid(opt);
                                                    }
                                                );
                                        }
                                    }
                                });//Main
                            /*XSS and CSRF changes - Chinmay - END*/	
                         });
                         
                        jQuery(".gr-cnt").on('click',function(){                            
                            jQuery(this).toggleClass('selected');
                        });
                              
                         jQuery("#reset_filter,#reset_filter_all").on('click',function(){
                            jQuery("input[name='room_amenity[]']").each(function() {                           
                                this.checked=false;
                            });
                            
                            jQuery("input[name='room_highlight_text[]']").each(function() {                           
                                this.checked=false;                                   
                            });
                            
                            jQuery("input#nonrefundable").removeAttr("checked","");
                            jQuery("input#nonrefundable").prop("checked",false);
                            
                            jQuery("input#excludenotavailable").removeAttr("checked","");
                            jQuery("input#excludenotavailable").prop("checked",false);
                            
                            jQuery("input#all_room").filter("[value=1]").removeAttr("checked","");
                            jQuery("input#all_pack").filter("[value=2]").removeAttr("checked","");
                            jQuery("input#all_default").filter("[value=0]").prop('checked', true);
                            
                            jQuery("select#roomtypeunkid").val('');
                            opt.opt_roomrateunkid = ""; //Priyanka Maurya - Coming From Website Rate Plan Binding Widget that time Reset Filter is not working in Booking Engine - RES-2088
                            //Price filter - start
                            var min_value=0;
                            var max_value=0;
                            jQuery("#ResUIMinimumPrice").text(opt.ResUIMinimumPrice);
                            jQuery("#ResUIMaximumPrice").text(opt.ResUIMaximumPrice);
                           
                            if(opt.ResUIMinimumPrice>0) {
                              min_value=parseInt(opt.ResUIMinimumPrice)
                            }
                            if (opt.ResUIMaximumPrice>0) {
                              max_value=parseInt(opt.ResUIMaximumPrice)
                            }
                            
                            var slider = $("#slider-range").slider({
                                range: true,
                                values: [min_value, max_value],
                                min: min_value,
                                max: max_value
                            });
                         
                            var curr_lbl_pre=curr_lbl_post='';
                            if(resgrid[0][0].curr_prefix==1) {
                               curr_lbl_pre=resgrid[0][0].currency+' ';
                            }
                            else
                                curr_lbl_post=resgrid[0][0].currency+' ';
                            
                            $( "#price_slider" ).val( curr_lbl_pre + min_value + curr_lbl_post +
                                " - "+ curr_lbl_pre + max_value + curr_lbl_post);
                            jQuery("input#price_slider_val").val(min_value+"|"+max_value);
                            //Price filter - end
                            
                            jQuery(".gr-cnt").each(function(){
                            if(jQuery(this).hasClass('selected')==true)
                               jQuery(this).removeClass('selected');
                            });
                            methods._checksession(opt,'promo');//Check session - florra
                            //methods._loadGrid(opt);
                         });
                         
                          jQuery(".showhide_filter_pnl").on('click',function(){
                           jQuery(this).parent().next().toggle();
                         });
                          
                         //Reset Individual option
                         jQuery(".reset_room_row").on('click',function(){
                           jQuery("select#roomtypeunkid").val('');
                         });
                         
                        jQuery(".reset_display_row").on('click',function(){
                            jQuery("input#all_room").filter("[value=1]").removeAttr("checked","");
                            jQuery("input#all_pack").filter("[value=2]").removeAttr("checked","");
                            jQuery("input#all_default").filter("[value=0]").prop('checked', true);                            
                        });
                         
                        jQuery(".reset_ameniti_row").on('click',function(){
                           jQuery("input[name='room_amenity[]']").each(function() {                           
                                this.checked=false;                                   
                            });
                        });
                        jQuery(".reset_searchmore_row").on('click',function(){
                            jQuery("input[name='room_highlight_text[]']").each(function() {                           
                                this.checked=false;                                   
                            });
                        });
                        jQuery(".reset_price_row").on('click',function(){
                            var min_value=0;
                            var max_value=0;
                            jQuery("#ResUIMinimumPrice").text(opt.ResUIMinimumPrice);
                            jQuery("#ResUIMaximumPrice").text(opt.ResUIMaximumPrice);
                           
                            if(opt.ResUIMinimumPrice>0) {
                              min_value=parseInt(opt.ResUIMinimumPrice)
                            }
                            if (opt.ResUIMaximumPrice>0) {
                              max_value=parseInt(opt.ResUIMaximumPrice)
                            }
                            
                            var slider = $("#slider-range").slider({
                                range: true,
                                values: [min_value, max_value],
                                min: min_value,
                                max: max_value
                            });
                         
                            var curr_lbl_pre=curr_lbl_post='';
                            if(resgrid[0][0].curr_prefix==1) {
                               curr_lbl_pre=resgrid[0][0].currency+' ';
                            }
                            else
                                curr_lbl_post=resgrid[0][0].currency+' ';
                            
                            $( "#price_slider" ).val( curr_lbl_pre + min_value + curr_lbl_post +
                                " - "+ curr_lbl_pre + max_value + curr_lbl_post);
                            jQuery("input#price_slider_val").val(min_value+"|"+max_value);
                            //Price filter - end
                         });
                         jQuery(".reset_guestrating_row").on('click',function(){
                            jQuery(".gr-cnt").each(function(){
                              if(jQuery(this).hasClass('selected')==true)
                                 jQuery(this).removeClass('selected');
                              });
                         });
                         jQuery(".reset_nonrefundable_row").on('click',function(){
                            jQuery("input#nonrefundable").removeAttr("checked","");
                            jQuery("input#nonrefundable").prop("checked",false);
                            
                            jQuery("input#excludenotavailable").removeAttr("checked","");
                            jQuery("input#excludenotavailable").prop("checked",false);
                         });
                    
                   //creating issue in promo panel hide , if remove filter option create problem
                   if(opt.siteroot==false)
                    {
                        if($(window).width() > 992){
                            $('.filter-div').css('top',$('.filter-btn1').offset().top + 45);
                            $('.filter-div').css('left',$('.filter-btn1').offset().left);
                            $('.filter-div').hide();
                        }
                    }
                    //filter script
                    },
                    success: function( data ){                        
                        $("<div id='temp2' style='display:block;'></div>").appendTo("div#eZee_BookingFilter");
                        $("#temp2").html(data);
                        $('#eZee_BookingFilter').html($("#filter_data").html());
                        $("#temp2").remove();
                    }					
                });                
            }            
            catch(err){
                hideLoadingBar();
                hideCheckavalabilityLoadingBar();
				console.log("Error - _loadFilterBar - "+err.message);
		    } 
        },
        _loadFilterOptions:function(opt){
          try
            {
            // if(typeof(resgrid[0])!='undefined' && resgrid[0]!=null && typeof(resgrid)!='undefined') 
            {                 
                if(opt.ResUIshowfilter==true)
                {
                    jQuery("#filter_bar").show();
                    jQuery("#smallscreenfilter_bar").show();
                    
                    //if(!('sessionStorage' in window))
                    //{
                    //     jQuery(".filter-help").show();
                    //}
                    //else
                    {
                        //Filter help
                        var keyname_filter="res_FilterOption_"+opt.HotelId;
			
			var name = keyname_filter + "=";
			var ca = document.cookie.split(';');
			var cookie_filter='';
			for(var i = 0; i <ca.length; i++) {
			    var c = ca[i];
			    while (c.charAt(0)==' ') {
				c = c.substring(1);
			    }
			    if (c.indexOf(name) == 0) {			    
				cookie_filter=c.substring(name.length,c.length)
			    }
			}		    
			
                        //if(sessionStorage.getItem(keyname_filter)!=null)	
                        //{                               
                        //    var filter_visibility=sessionStorage.getItem(keyname_filter);
                        //    if (filter_visibility=="false") {
                        //        jQuery(".filter-help").show();
                        //    }
                        //}
                        //else
            //Priyanka Maurya - 31 July 2018 - START
            //Purpose - Hide the filter Popup - BENotificationStop
			/* if (cookie_filter=='true')
            {
                jQuery(".filter-help").hide();
            }
			else
			{
			    jQuery(".filter-help").show();
			} */
            // Priyanka Maurya - END
                    }
                }
                else
                {
                    jQuery("#filter_bar").hide();
                    jQuery("#smallscreenfilter_bar").hide();
                    //Priyanka Maurya - 31 July 2018 - START
                    //Purpose - Hide the filter Popup - BENotificationStop
                    //jQuery(".filter-help").hide();
                    // Priyanka Maurya - END
                }
                
                if(opt.ResUIshowfilter==true) 
                {
                    //Rate Plan , packages & all bar
		    if (opt.ResUIshowfilterPlan==true) {
                        jQuery("#filter_option").show();
                        
                        if (opt.selectedviewoptions==0) {//all 
                            jQuery("input[id$='all_default']").filter("[value='"+opt.selectedviewoptions+"']").attr("checked","checked");
                        }
                        if (opt.selectedviewoptions==1) {//Room only                            
                            jQuery("input[id$='all_room']").filter("[value='"+opt.selectedviewoptions+"']").attr("checked","checked");
                        }
                        if (opt.selectedviewoptions==2) {//Pakcage
                            jQuery("input[id$='all_pack']").filter("[value='"+opt.selectedviewoptions+"']").attr("checked","checked");
                        }
                    }
                    else
                    {
                        jQuery("input[id$='all_default']").filter("[value='0']").removeAttr("checked");
                        jQuery("input[id$='all_room']").filter("[value='1']").removeAttr("checked");
                        jQuery("input[id$='all_pack']").filter("[value='2']").removeAttr("checked");
                        
                        jQuery("input[id$='all_default']").filter("[value=0]").attr("checked","checked");
                        jQuery("#filter_option").hide();
                    }
                    
                    //Price Bar
                    if(opt.ResUIshowfilterPrice==true && opt.ResUIMinimumPrice!='' && opt.ResUIMaximumPrice!=''
                      // && opt.WebShowRatesAvgNightORWholeStay!=3
                       )
                    {                        
                        if(typeof(resgrid)!='undefined' && resgrid[0][0].currency!='' ) {
                            var curr_lbl_pre=curr_lbl_post='';
                            if(resgrid[0][0].curr_prefix==1) {
                               curr_lbl_pre=resgrid[0][0].currency+' ';
                            }
                            else
                                curr_lbl_post=resgrid[0][0].currency+' ';
                         
                         if($( "#slider-range" ).length > 0 )
                         {
                         $( "#price_slider" ).val( curr_lbl_pre + $( "#slider-range" ).slider( "values", 0 ) + curr_lbl_post +
                                " - "+ curr_lbl_pre + $( "#slider-range" ).slider( "values", 1 ) + curr_lbl_post);
                         
                          $("input#price_slider_val").val($( "#slider-range" ).slider( "values", 0 )+"|"+$( "#slider-range" ).slider( "values", 1) );
                         }
                           // console.log("slider 1 " +  $( "#slider-range" ).slider( "values", 0 ) );
                        }
                        // console.log("slider change " +  $("input#price_slider_val").val() );
                    }
                    else
                        jQuery("#filter_price").hide();
                        
                    //Amenities Bar
                    if(opt.ResUIshowfilterPreference==true)
                    {
                        jQuery("#filter_amenities").show();                  
                    }
                    else
                        jQuery("#filter_amenities").hide();
                     
                    //Search Bar                   
                    if(opt.ResUIFilterSearchMore==true)
                    {
                        jQuery("#filter_highlight").show();                  
                    }
                    else
                        jQuery("#filter_highlight").hide();
                     
                         
                    //Apply button
                    if (opt.ResUIshowfilter==true && (opt.ResUIshowfilterPlan==true || opt.ResUIshowfilterPrice==true || opt.ResUIshowfilterPreference==true))
                        jQuery("#filter_apply").show();
                    else
                    {
                        jQuery("#filter_apply").hide();
                        jQuery("#filter_bar").hide();
                        jQuery("#smallscreenfilter_bar").hide();
                       
                    }
                    
                    //Guest Rating
                    if(opt.ResUIshowGuestRating==1) {
                        jQuery("#filter_gr").show();                        
                    }
                    else
                        jQuery("#filter_gr").hide();
                    //Guest Rating
                    
                    //Exclude Non refundable rate plans
                    if(opt.ResUIFilterNonRefundable==1 || opt.ResUIFilterExcludeNotAvailable==1)
                    {
                        jQuery("#filter_nonrefundable").show();
                    }
                    else
                    {
                        jQuery("#filter_nonrefundable").hide();
                    }
                    
                    if(opt.ResUIFilterNonRefundable==1)
                    {                      
                      jQuery("#filter_nonrefundable_div").show();                      
                    }
                    else
                    {                      
                      jQuery("#filter_nonrefundable_div").hide();
                    }
                    
                    if(opt.ResUIFilterExcludeNotAvailable==1)
                    {                     
                      jQuery("#filter_excludenotavailable_div").show();                      
                    }
                    else
                    {                     
                      jQuery("#filter_excludenotavailable_div").hide();
                    }  
                    
                    //Room Type combo
                    if(opt.ResUIFilterRoomType==true)
                    {
                       jQuery("#filter_roomspecific").show();   
                    }
                    else
                       jQuery("#filter_roomspecific").hide();
                    
                    //Priyanka Maurya - 31 July 2018 - START
                    //Purpose - Hide the filter Popup - BENotificationStop
                    /* jQuery(".gotit").on('click',function(){
                        //if(!('sessionStorage' in window))
                        //{
                        //     jQuery(".filter-help").hide();
                        //}
                        //else
                        {
                            var keyname_filter="res_FilterOption_"+opt.HotelId;
                            //sessionStorage.setItem(keyname_filter,true);
                            document.cookie = keyname_filter+"=true";
                            jQuery(".filter-help").hide();
                        }                        
                    });*/
                    //Priyanka Maurya - 31 July 2018 - END
                }
            }//resgrid 
            }
            catch(err){
                hideLoadingBar();
                hideCheckavalabilityLoadingBar();
				console.log("Error - _loadFilterOptions - "+err.message);
		    }    
        },
        _iescriptload:function(opt){	
		 if ( window.XDomainRequest ) {
				 jQuery.ajaxTransport(function( s ) {
			  if ( s.crossDomain && s.async ) {
				  
				if ( s.timeout ) {
				  s.xdrTimeout = s.timeout;
				  delete s.timeout;
				}
				var xdr;
				return {
				  send: function( _, complete ) {
					function callback( status, statusText, responses, responseHeaders ) {
					  xdr.onload = xdr.onerror = xdr.ontimeout = jQuery.noop;
					  xdr = undefined;
					  complete( status, statusText, responses, responseHeaders );
					}
					
					xdr = new window.XDomainRequest();
					xdr.onload = function() {						
					    callback( 200, "OK", { text: xdr.responseText }, "Content-Type: " + xdr.contentType );
					};
					xdr.onerror = function() {									    
						callback( 404, "Not Found" );
					};
					xdr.onprogress = function() {}; 
					if ( s.xdrTimeout ) {
					  xdr.ontimeout = function() {
						callback( 0, "timeout" );
					  };
					  xdr.timeout = s.xdrTimeout;
					}
					
					xdr.open( s.type, s.url, true );
					xdr.send( ( s.hasContent && s.data ) || null );
				  },
				  abort: function() {
					if ( xdr ) {
					  xdr.onerror = jQuery.noop();
					  xdr.abort();
					}
				  }
				};
			  }
			});
		  }
			
		},
         _loadJsDefault:function(opt){
			try
			{
				var _jsName=opt.serverurl+"templates/js/";		
				$.getScript(_jsName+"datepicker_lang/jquery.ui.datepicker-"+opt.CalLanguage+".js");
				//$.getScript(_jsName+"_resbgnext.js");                
			}
		    catch(err){
                hideLoadingBar();
                hideCheckavalabilityLoadingBar();
				console.log("Error - _loadJsDefault - "+err.message);
		    }	
        },       
        _loadAcr:function(opt){
            try{                
                if(opt.ShowNights==true)
                {                    
                    $("#nightblock").show();
                    methods._fillStaticCombo(1,opt.Nonights,"eZ_Nights");
                    
                      if (opt.nights_billing!=null && opt.nights_billing!='')
                      {
                         //for billing page change dates
                         $('select#eZ_Nights option').prop('selected', false).filter('[value="'+opt.nights_billing+'"]').prop('selected', true);
                         $('input#eZ_Nights2').val(opt.nights_billing);                
                      }
                    else if(opt.SelectedNights!=0 && opt.SelectedNights!='')
                    {                        
                    $('select#eZ_Nights option').prop('selected', false).filter('[value="'+opt.SelectedNights+'"]').prop('selected', true);
                    $('input#eZ_Nights2').val(opt.SelectedNights);
                    }
                    else if(opt.SelectedNights==0 && opt.SelectedNights!='')
                    {                         
                        opt.SelectedNights=1;
                        $('select#eZ_Nights option').prop('selected', false).filter('[value="'+opt.SelectedNights+'"]').prop('selected', true);
                        $('input#eZ_Nights2').val(opt.SelectedNights);
                    }
                    else if(opt.ShowSelectedNights==true && opt.DefaultSelectedNights!=0 && opt.DefaultSelectedNights!=''){                        
                        $('select#eZ_Nights option').prop('selected', false).filter('[value="'+opt.DefaultSelectedNights+'"]').prop('selected', true);
                        $('input#eZ_Nights2').val(opt.DefaultSelectedNights);
                    }
                    else if(opt.ShowSelectedNights==true && opt.DefaultSelectedNights==0 && opt.DefaultSelectedNights!=''){
                        opt.DefaultSelectedNights=1;
                        $('select#eZ_Nights option').prop('selected', false).filter('[value="'+opt.DefaultSelectedNights+'"]').prop('selected', true);
                        $('input#eZ_Nights2').val(opt.DefaultSelectedNights);
                    }
                    else
                    {
                        $('select#eZ_Nights option').prop('selected', false).filter('[value="1"]').prop('selected', true);
                        $('input#eZ_Nights2').val(1);
                    }
                }
                else
                    $("#nightblock").hide();
                            
                if(opt.ShowNights==false && opt.ShowDepart==true)//dept date
                {				
                    $("#nightblock").hide();					
                    $("#checkout_blk").show();
                    $("#gridcolumn").val($("input[id$='eZ_Nights']").val());
                }
                else
                {
                    $("#checkout_blk").hide();                   
                    $("#gridcolumn").val($("input#eZ_Nights2").val());
                }
                
                $("select#eZ_Nights").change(function() {
                   // alert("call");
                    var selvalue=1;
                    if ($("select[id$='eZ_Nights']").val()=='' || $("select[id$='eZ_Nights']").val()==0) {
                         if (opt.SelectedNights!=0) 
                                var selvalue=(opt.SelectedNights);
                         else if(opt.ShowSelectedNights==true && opt.DefaultSelectedNights!=0)
                            var selvalue=(opt.DefaultSelectedNights);
                       // alert("if " + selvalue);
                    }
                    else
                    {
                         var selvalue=($("select[id$='eZ_Nights']").val());
                         //alert("else " + selvalue);
                    }
                     
                    $("input#eZ_Nights2").val(selvalue);
                    
                    //Flora - Fixed Bug
                    //Creating problem in Iphone 7 once select nights from 2 -> 1 -> 2
                    $('select#eZ_Nights option').prop('selected', false).filter('[value="'+selvalue+'"]').prop('selected', true);
                    //$('select#eZ_Nights option').removeAttr('selected');                    //old
                    //$("select[id$='eZ_Nights'] option[value='"+selvalue+"']").attr("selected", "selected");//old                   
                    //Flora - Fixed Bug
                    
                    $("select[id$='eZ_Nights']").val(selvalue);
                    
                    jQuery("#trackbtnclicked").val(1);
                    jQuery("#trackbook_btnclicked").val(0);
                });                
                
                $("[id^=eZ_Nights2]").focus(function() {
                    jQuery(this).select();
                });
                
                $("[id^=eZ_Nights]").focus(function() {
                    jQuery(this).select();
                });
                
                $("[id^=eZ_Nights2]").change(function() {                     
                    var selvalue=1;
                    if ($(this).val()=='' || $(this).val()==0) {
                        if (opt.SelectedNights!=0) {
                            var selvalue=(opt.SelectedNights);
                        }
                        else if(opt.ShowSelectedNights==true && opt.DefaultSelectedNights!=0)
                            var selvalue=(opt.DefaultSelectedNights);                         
                    }
                    else
                    {
                         var selvalue=$(this).val();//($("select[id$='eZ_Nights']").val());
                    }
                    $("input#eZ_Nights2").val(selvalue);
                   
                    if($("select[id$='eZ_Nights']").find('option[value=' + selvalue + ']').length==0)
                           $("select[id$='eZ_Nights']").append('<option value='+selvalue+'>'+selvalue+'</option>');
                    
                    //Flora - Fixed Bug
                    //Creating problem in Iphone 7 once select nights from 2 -> 1 -> 2
                    $('select#eZ_Nights option').prop('selected', false).filter('[value="'+selvalue+'"]').prop('selected', true);
                    
                    //$('select#eZ_Nights option').removeAttr('selected');
                    //$("select[id$='eZ_Nights'] option[value='"+selvalue+"']").attr("selected", "selected");
                    //$("select[id$='eZ_Nights']").val(selvalue);
                    
                    //Flora - Fixed Bug
                    
                    jQuery("#trackbtnclicked").val(1);
                    jQuery("#trackbook_btnclicked").val(0);
                });
                
                
                /*for tracking button clicked*/
                $("select[id$='eZ_adult']").change(function() {
                    jQuery("#trackbtnclicked").val(1);
                    jQuery("#trackbook_btnclicked").val(0);
                });
                $("select[id$='eZ_child']").change(function() {
                    jQuery("#trackbtnclicked").val(1);
                    jQuery("#trackbook_btnclicked").val(0);
                });
                
                /*adult & child - start*/
                
                if(opt.acr==true)
                {
                    //$("#acr").css('display','block');
                    $("#acr").removeClass('reshide');
                    $("#acr_hidden").addClass('reshide');
                   // $("#acr_hidden").css('display','none');
                    
                    methods._fillStaticCombo(1,opt.defaultadult,"eZ_adult");                    
                    
                    if(opt.SelectedAdults!=0 && opt.SelectedAdults!='')
                    {                       
                        $("select[id$='eZ_adult'] option[value="+opt.SelectedAdults+"]").attr("selected", "selected");
                        $("select[id$='eZ_adult']").val(opt.SelectedAdults);
                    }   
                    else if(opt.defaultselectedadult!=0 && opt.defaultselectedadult!='')
                    {                         
                       $("select[id$='eZ_adult'] option[value="+opt.defaultselectedadult+"]").attr("selected", "selected")
                       $("select[id$='eZ_adult']").val(opt.defaultselectedadult);;
                    }
                }
                else
                {
                   // $("#acr").css('display','none');
                    $("#acr").addClass('reshide');
                    $("#acr_hidden").removeClass('reshide');
                    
                    if(opt.SelectedAdults!=0 && opt.SelectedAdults!='')
                        $("input[id$='eZ_adult']").val(opt.SelectedAdults);	
                    //else if(opt.defaultselectedadult!=0 && opt.defaultselectedadult!='')
                      //  $("input[id$='eZ_adult']").val(opt.defaultselectedadult);	
                }
                
                //child
                if (opt.RunOnMaximumOccupanySettings==0)
                {
                    if((opt.showchild_bb=="-1" && opt.showchild==true) || opt.showchild_bb==true) 
                    {                    
                        //$("#showchildblk").css('display','block');                    
                       // $("#showchild_hidden").css('display','none');
                        
                        $("#showchild_hidden").addClass('reshide');
                        $("#showchildblk").removeClass('reshide');
                    
                        methods._fillStaticCombo(0,opt.defaultchild,"eZ_child");			
                        
                        if(opt.SelectedChild!='' && opt.SelectedChild!=0)
                        {
                            $("select[id$='eZ_child'] option[value="+opt.SelectedChild+"]").attr("selected", "selected");
                            $("select[id$='eZ_child']").val(opt.SelectedChild);
                        }
                        else if(opt.defaultselectedchild!='' && opt.defaultselectedchild!=0)
                        {
                            $("select[id$='eZ_child'] option[value="+opt.defaultselectedchild+"]").attr("selected", "selected")
                            $("select[id$='eZ_child']").val(opt.defaultselectedchild);
                        }
                        else
                        {
                            $("select[id$='eZ_child']").val(0);
                            $("select[id$='eZ_child'] option[value=0]").attr("selected", "selected");                        
                        }
                    }
                    else if((opt.showchild_bb=="-1" && opt.showchild==false) || (opt.showchild_bb==false)) 
                    {
                       // $("#showchildblk").css('display','none');
                       // $("#showchild_hidden").css('display','block');
                         $("#showchildblk").addClass('reshide');
                         $("#showchild_hidden").removeClass('reshide');
                    
                    }
                }
                else
                {
                    //$("#showchild_hidden").css('display','block');                     
                    $("#showchild_hidden").removeClass('reshide');
                    $("#showchildblk").addClass('reshide');
                    //$("input[id$='eZ_child']").val(0);
                }
                /*adult & child - end*/            
            }
             catch(err){
                hideLoadingBar();
                hideCheckavalabilityLoadingBar();
				console.log("Error - _loadAcr - "+err.message);
		    }    
        },
        _loadPromotion:function(opt){
            if(opt.ShowPromotion==true)
                $("#promotion,#smallscreenpromotion").show();
            else
                $("#promotion,#smallscreenpromotion").hide();
		
            $('.vres_promo-btn').on('click', function(e){
                e.stopPropagation();
                $('.promo-popUp').css('top', $(this).offset().top + 45);
                $('.promo-popUp').css('left', $(this).offset().left - 160);
                $('.promo-popUp').toggle();
                $('.filt_wrap_bg').addClass('filt_wrap_bg_open');
                /* --- promo box position --- */
                $('.promo-popUp').css('left', $('.vres_promo-btn').offset().left - 100);//sanket
            });
             
            $('.vres-probtn').on('click', function(e){
            e.stopPropagation();
                $('.vres-apply').toggle();
            });
            
            var windowWidth = $(window).width();
            $(window).on('resize', function(){
                if($(window).width() !== windowWidth){
                    $('.promo-popUp').hide();
                    $('.filt_wrap_bg').removeClass('filt_wrap_bg_open');
                }
            });
            
        },
         _fillStaticCombo:function(iLimitStart,iLimitEnd,controlName,selectedValue){
            console.log("_fillStaticCombo : "+iLimitStart+"");
            var comboArr = [];
            for(ilmt=iLimitStart;ilmt<=iLimitEnd;ilmt++)
                comboArr.push('<option value="'+ ilmt +'">'+ ilmt  +'</option>');
              
            jQuery("select[id$='"+controlName+"']").html(comboArr.join(''));
            if(selectedValue!='')
            {
                jQuery("select[id$='"+controlName+"']").val(selectedValue);
            }
        },
        _storeAuthDetails:function(opt){
			try
			{
				$.get(opt.serverurl+'service.php?HotelId='+opt.HotelId+'&_xrty=true', function(data) {
				});
			}
			catch(e)
			{
				console.log("Error in _storeAuthDetails"+ e);	
			}
		},
        _setFrenchNotation:function(opt){//flora
            try{
                //Flora - 1.0.51.56 - 6th August 2017 - START
                //Purpose - Store Selected Langugae in session storage for French notetion rates display
                if(('sessionStorage' in window))
                {
                    var keyname_frenchhotel="res_FrenchHotel_"+opt.HotelId;
                    var frenchhotel_hotelcode="res_HotelCode";
                    sessionStorage.setItem(frenchhotel_hotelcode,opt.HotelId);                       
                    if(sessionStorage.getItem(keyname_frenchhotel)!=null)	
                    {                               
                        var keyname_frenchhotel_val=sessionStorage.getItem(keyname_frenchhotel);                       
                        if (keyname_frenchhotel_val!=opt.selectedLang) {
                            sessionStorage.setItem(keyname_frenchhotel,opt.selectedLang);
                        }
                    }
                    else
                    {                        
                        sessionStorage.setItem(keyname_frenchhotel,opt.selectedLang);
                    }
                }
                //Flora - 1.0.51.56 - 6th August 2017 - END
            }
            catch(e)
			{
				console.log("Error in _setFrenchNotation"+ e);	
			}
        },
        _loadGrid:function(opt){
			try
			{
                console.log("loadgrid.....");
                
                methods._setFrenchNotation(opt);//flora
                jQuery("#trackbtnclicked").val(0);                
                jQuery("#promo_apply_pnl").hide();
				jQuery("#applypromo_text").text("");
                jQuery(".vres_promo-btn").removeClass("promo_applied");
                jQuery("body").removeClass("adjfooter");//Chinmay Gandhi - In mobile book button not work properly.
                
                getUrl=opt.serverurl+'rmdetails';
                //Nights checking
                if(opt.ShowNights==false && opt.ShowDepart==true)//dept date
                {
                     var no_nights=$("input[id$='eZ_Nights']").val();
                }
                else
                    var no_nights=1;//if nights not enable on BE page then multiple room type booking not working
                
                if(opt.ShowNights==true)
                {                    
                    $("select[id$='eZ_Nights'] option[value="+$("select#eZ_Nights").val()+"]").attr("selected", "selected");                     
                    no_nights=$("select[id$='eZ_Nights']").val();                    
                }
                
                var layoutView=2;//OTA View
                
                if(opt.acr==true){                    
                    var adult=$("select[id$='eZ_adult']").val();               
                }
                else
                    var adult=$("input[id$='eZ_adult']").val();
			  
                if(opt.RunOnMaximumOccupanySettings==0)
                {
                    if((opt.showchild_bb=="-1" && opt.showchild==true) || opt.showchild_bb==true)
                    {		    
                        var child=$("select[id$='eZ_child']").val();
                    }
                    else
                        var child=$("input[id$='eZ_child']").val();
                }
                else
                {
                    var child=0;
                }
               // var adult=1;
               // var child=0;
                var _selPromo='';
                if(opt.SelectedPromo!=null && opt.SelectedPromo!='' && ( $("input[id$='promotioncode']").val() == undefined || $("input[id$='promotioncode']").val() == '' )) //Priyanka Maurya - 03 Sep 2019 - Purpose : Regarding Promotion code - RES-2201
                    _selPromo=opt.SelectedPromo;
                
                if(_selPromo=='')
                    _selPromo=$("input[id$='promotioncode']").val();
                
                var showmoreless_opt='';
                if(opt.siteroot==true || (typeof(top.frames['editframe'])!='undefined' ))//Widget Calling
                {
                    showmoreless_opt=0;
                }

                var norooms=1;
                $("input[id$='promotioncode2']").val($("input[id$='promotioncode']").val());
                $.ajax({
                url: getUrl,
                type: "POST",
                data: {
                    checkin: $("input[id$='eZ_chkin']").val(),
                    gridcolumn: $("input[id$='gridcolumn']").val(),
                    adults: adult,
                    child: child,
                    nonights:no_nights,
                    ShowSelectedNights:opt.ShowSelectedNights,
                    DefaultSelectedNights:opt.DefaultSelectedNights,
                    calendarDateFormat: $("input[id$='CalDtFormat']").val(),
                    rooms: norooms,//$("select[id$='eZ_room']").val()
                    promotion: _selPromo,
                    ArrvalDt:$("input[id$='ArrvalDt']").val(),
                    HotelId: opt.HotelId,
                    isLogin: opt.isLogin,
                    selectedLang:opt.selectedLang,
                    modifysearch:opt.modifysearch,
                    promotioncode:_selPromo,//enable to pass value of promo to another page
					layoutView:layoutView,
					ShowMinNightsMatchedRatePlan:opt.ShowMinNightsMatchedRatePlan,
					LayoutTheme:opt.LayoutTheme,
					w_showadult:opt.acr, //Pinal
					w_showchild_bb:opt.showchild_bb,  //Pinal
                    ShowMoreLessOpt:showmoreless_opt,
                    w_showchild:opt.showchild,  //flora , widget issue
                    ischeckavailabilityclicked: $("#ischeckavailabilityclicked").val()
                },
                dataType: "html",
                error: function(request,error,message){
                	console.log( "AJAX - error() >> "+message);
                },
                beforeSend: function(){
                    //var pro=$("input[id$='promotioncode']").val();
                    
                    showCheckavalabilityLoadingBar();
                     //Delete / remove object of calulated & strike values                    
                     /*Delete Object values - use for calculation*/
                     if (typeof(_row_total)!='undefined') {
                        for (var key in _row_total){
                            _row_total.splice(key);
                            }
                     }
                     if (typeof(_row_strike_total)!='undefined') {
                        for (var key_strike in _row_strike_total){
                            _row_strike_total.splice(key_strike);
                            }
                     }                     
		        },
                complete: function(){                    
                    
                    //Pinal - 22 May 2018 - START
                    //Purpose : GDPR
                    var keyname="cookieconsent_"+opt.HotelId;
                    var name = keyname + "=";
                    var ca = document.cookie.split(';');
                    var cookie_value='';
                    for(var i = 0; i <ca.length; i++) {
                        var c = ca[i];
                        while (c.charAt(0)==' ') {
                            c = c.substring(1);
                        }
                        if (c.indexOf(name) == 0) {			    
                            cookie_value=c.substring(name.length,c.length)
                        }
                    }
                    console.log("cookie_value : "+cookie_value);
                    if(cookie_value!='true')
                    {
                        jQuery("#cookieconsentmsg").show();
                    }
                    
                    jQuery("#cookieconsent").on('click',function(){
                        jQuery("#cookieconsentmsg").hide();
                        
                        var expiryDate = new Date();
                        expiryDate.setDate(expiryDate.getDate() + 365);
                        document.cookie = 'cookieconsent_'+opt.HotelId+"="+"true;expires="+expiryDate.toUTCString();
                    });
                    //Pinal - 22 May 2018 - END
                    
                     //Rooms , Packages & all
                     jQuery("[id$='main_otarow']").each(function(){
                            jQuery(this).show();
                            jQuery(this).find('.vres-cnt-card').find(".card-list").each(function(){
                                jQuery(this).show();
                            });
                        });
                    
					if(opt.siteroot==true)//widget calling
                   		methods._setThemes(opt);
                    
                    //Reset filter for the case of the message while filter option hide
                    if(opt.ResUIshowfilter==true)
                    {
                        if(jQuery("#filter-wrapper_div").is(':hidden')==true)
                        {
                           //jQuery("#reset_filter").click();//create issue on load grid loads multiple times while no inventory at first load
                        }
                    }
                    
                    jQuery("#summary_head").show();
                    jQuery("#filter-wrapper_div").show();
                  //console.log("before calling of _loadDefaultJsInit");
                    methods._loadDefaultJsInit(opt);
                    //hideLoadingBar();
                    
                    methods._loadFilterOptions(opt);
                    
                    $('.tipso-tooltip').tipso();
                    $('.tipso-tooltip-roomlist').tipso({width: '',maxWidth: 300,TextAlign:'left',tooltipHover: true,});
                    $('.tipso-tooltip-icon').tipso({width:'',maxWidth:100,useTitle:false});
                    $('.tipso-tooltip-help').tipso(); //Chandrakant - 1.0.53.61 - 15 February 2018 - ADARequirement
                    /*Shifted from loaddefaultjs function to here - currency not come some time - creating issue on book button doesn't work */
                    //Filter option code .offset error was come
                    //filter script
                    try{
                            if($(window).width()>1040){
                                setTimeout(function(){
                                    var booktop = $('.vres-bookdiv').offset().top;
                          
                                    $(window).on('scroll', function(){
                                        var bookheight = $('.vres-bookdiv').height(),
                                            sumth = bookheight + booktop - $('.booking-section').height(),
                                            bokdiv = bookheight - $('.booking-section').height();
                                        if($(window).scrollTop() > booktop){
                                            $('.booking-section').addClass('book-fixed');
                                        } else {
                                            $('.booking-section').removeClass('book-fixed');
                                        }
                                        if($(window).scrollTop() > sumth){
                                            $('.booking-section').addClass('book-abso');
                                            $('.booking-section').css({'top': bokdiv});
                                        } else {
                                            $('.booking-section').removeClass('book-abso')
                                            $('.booking-section').css({'top': '0px'});
                                        }
                                    });
                                }, 4000);
                            }
                        }
                        catch(e)
                        {
                            console.log("Error in loadbooking summary"+ e);
                        }
                         /*Shifted from loaddefaultjs function to here - currency not come some time - creating issue on book button doesn't work */
                        //Chinmay gandhi - 1.0.53.61 - Currency setting
                        {
                            methods._showdefaultCurrency(opt);
                        }
                          if(typeof(resgrid)!='undefined' && typeof(resgrid[0])!='undefined' && resgrid[0]!=null)
                          {                    
                               jQuery("#pnl_avg_blk").on('click',function(){
                                   $('[id*=average_per_night_blk]').show();
                                   $('[id*=price_for_whole_stay_blk]').hide();
                                   jQuery(this).removeClass('active').addClass('active');
                                  // jQuery("#process_filter").click();
                                   //kishan Tailor 3rd August 2018 purpose:Triptease Price Widget START
                                  if(opt.TripteasehotelKey!='' && opt.TripteasehotelAPIkey !='' && $("#pnl_tot_blk").is(":visible"))
                                  {
                                    //kishan Tailor 26 Nov 2020 purpose:Per Room per night price sending to tiptease RES-2648 START
                                    if(opt.ShowNights==true)
                                    {
                                        var totnoofnights=$("select#eZ_Nights").val();
                                    }
                                    else
                                    {
                                        var totnoofnights=$("input#eZ_Nights").val();
                                    }
                                    jQuery(".price-fighter-widget").attr("data-pf-direct-price",parseFloat(jQuery(".price-fighter-widget").attr("data-pf-direct-price")) / totnoofnights );
                                    //kishan Tailor 26 Nov 2020 purpose:Per Room per night price sending to tiptease RES-2648 END
                                    Paperboy.PriceCheck.reset();
                                  }
                                 //kishan Tailor 3rd August 2018 purpose:Triptease Price Widget END
                               });
                    
                                jQuery("#pnl_tot_blk").on('click',function(){
                                    console.log("==========whole stay price calll========");
                                    $('[id*=average_per_night_blk]').hide();
                                    $('[id*=price_for_whole_stay_blk]').show();
                                    jQuery(this).removeClass('active').addClass('active');
                                    //kishan Tailor 3rd August 2018 purpose:Triptease Price Widget START
                                    if(opt.TripteasehotelKey!='' && opt.TripteasehotelAPIkey !='' && $("#pnl_avg_blk").is(":visible"))
                                    {    
                                        //kishan Tailor 26 Nov 2020 purpose:Per Room per night price sending to tiptease RES-2648 START
                                        if(opt.ShowNights==true)
                                        {
                                            var totnoofnights=$("select#eZ_Nights").val();
                                        }
                                        else
                                        {
                                            var totnoofnights=$("input#eZ_Nights").val();
                                        }
                                        jQuery(".price-fighter-widget").attr("data-pf-direct-price",parseFloat(jQuery(".price-fighter-widget").attr("data-pf-direct-price")) * totnoofnights );
                                        //kishan Tailor 26 Nov 2020 purpose:Per Room per night price sending to tiptease RES-2648 END
                                        Paperboy.PriceCheck.reset();
                                    }
                                   //kishan Tailor 3rd August 2018 purpose:Triptease Price Widget
                                   // jQuery("#process_filter").click();
                                });
                    
                    
                                jQuery("#reset_filter_all2").on('click',function(){
                                     jQuery("#reset_filter_all").click();       
                                });
                
                                if (opt.WebShowRatesAvgNightORWholeStay==3)
                                {//both
                                     //console.log("ff" + " : " + opt.WebDefaultRatesAvgNightORWholeStay + " : " + opt.WebShowRatesAvgNightORWholeStay);
                                     if(opt.WebDefaultRatesAvgNightORWholeStay==1)//avg rate
                                     {
                                        jQuery("#pnl_avg_blk").removeClass('active').addClass('active');
                                        jQuery("#pnl_tot_blk").removeClass('active');
                                     }
                                     else if(opt.WebDefaultRatesAvgNightORWholeStay==2)//price for stay
                                     {
                                       jQuery("#pnl_tot_blk").removeClass('active').addClass('active');
                                       jQuery("#pnl_avg_blk").removeClass('active');
                                     }
                                     else
                                     {
                                        jQuery("#pnl_avg_blk").removeClass('active').addClass('active');
                                        jQuery("#pnl_tot_blk").removeClass('active');
                                     }
                                }
                                else
                                {
                                    if(opt.WebShowRatesAvgNightORWholeStay==1)//avg rate
                                    {
                                        jQuery("#pnl_avg_blk").removeClass('active').addClass('active');
                                        jQuery("#pnl_tot_blk").removeClass('active');
                                    }
                                    else if(opt.WebShowRatesAvgNightORWholeStay==2)//price for stay
                                    {
                                        jQuery("#pnl_tot_blk").removeClass('active').addClass('active');
                                        jQuery("#pnl_avg_blk").removeClass('active');
                                    }
                                }                   
                  
                                $('.filter-div').hide();
                                $('.filt_wrap_bg').removeClass('filt_wrap_bg_open');
                       
                                //Room Preferences
                                var amenity_id=[];
                                var amenity_id_list='';
                                jQuery("input[name='room_amenity[]']").each(function() {                           
                                    if(this.checked==true)
                                       amenity_id.push($(this).val());                              
                                 });
                                if (amenity_id.length!=0) {
                                    amenity_id_list = amenity_id;                                                     
                                }
                        
                                //Search More
                                var searchmore_id=[];
                                var searchmore_list='';
                                jQuery("input[name='room_highlight_text[]']").each(function() {                           
                                    if(this.checked==true)
                                    {
                                       var str_text=$(this).val();
                                       searchmore_id.push(str_text);                              
                                    }
                                 });
                                if (searchmore_id.length!=0) {
                                    searchmore_list = searchmore_id;                                                     
                                }

                                //Guest Rating
                                var guest_rating=new Array();
                                if (opt.ResUIshowGuestRating==1) {
                                     jQuery(".gr-cnt.selected").each(function(){
                                        if(jQuery(this).text()!='')
                                            guest_rating.push(parseFloat(jQuery(this).text()));
                                        });
                                }
                                
                var display_view=$('input[name=pack]:checked').val();
                /*this will create issue while filter plan is no show nothing set to display_view*/
                if (display_view==undefined) {
                    display_view=0;
                }

       
                           
                            if(display_view==0 || display_view==1 || display_view==2)
                            {
                                var row_cnt=0;
                                var total_div_len=jQuery("[id$='main_otarow']").length;
                                var main_div_hide=0;
                                
                                var filterapply_roomtype=0;
                                var filterapply_price=0;
                                var filterapply_display=0;
                                var main_otarow_cnt=0;
                                window.savebtnarr = []; // Chandrakant - Save for later
                                window.allbtnarr = []; // Chandrakant - Save for later
                                
                                jQuery("[id$='main_otarow']").each(function(){
                                    row_cnt=jQuery(this).find('.vres-cnt-card').find(".card-list").length;
				    
                                    var na_cnt=0;
                                    var hidereadmore=0;
                                    var setreasmore=0;
                                    var hidereadmore_cls=0;
                                    var main_obj_id=jQuery(this);
                                    
                                    var show_on_limit=-1;                                   
                                    if (opt.ResUILimitRatePlanPackage==1 && opt.ResUINoOfRatePlanPackage>=1) {
                                        show_on_limit=1;
                                    }
                                    
                                    jQuery(this).find('.vres-cnt-card').find(".card-list").each(function(){
                                        var rowinx=jQuery(this).attr('id').split("_");
					
                                        var obj_id=jQuery(this);
                                    
                                    //hide show more & less - mobile view
                                    methods._ShowMoreLessMobileViewOnload(obj_id,opt);
                                     //hide show more & less - mobile view
                                    
                                    //Exlude Not available - flora
                                    var exclude_notavailable=0;
                                    if(jQuery(this).find(".vrmprice > p#not_available").hasClass('rm_sold')==true
                                       && jQuery("#excludenotavailable").is(':checked')==true
                                       )
                                    {
                                        exclude_notavailable++;
                                    }
                                    //Exlude Not available - flora
                                    //console.log(resgrid[0][rowinx[1]]['display_name']+ " :  exclude_notavailable " + exclude_notavailable);
                            
                                var non_refundable_count=0;                                    
                                if(jQuery("#nonrefundable").is(':checked')==true &&
                                resgrid[0][rowinx[1]].is_prepaid_noncancel_nonrefundabel==1)
                                 {
                                     non_refundable_count++;
                                 }
                                    
                                       //Guest Rating - start
                                       var rating_cnt=0;
                                       if (guest_rating.length>0) {
                                            var rating='';
                                            if(jQuery(this).find('.guestrating > span.grrate').text()!='') {
                                                rating=parseFloat(jQuery(this).find('.guestrating > span.grrate').text());
                                            }
                                             
                                            var g_rating='';
                                            if (rating!='') {
                                                var g_rating=parseFloat(rating.toFixed(0));
                                            }
                                            //Guest Rating - end
                                         
                                            if(jQuery.inArray(g_rating, guest_rating) != -1 && g_rating!='') {
                                               //console.log(resgrid[0][rowinx[1]].display_name + "matched..."); 
                                               rating_cnt++;
                                            }
                                        }
                                        
                                        //Search more Filter wise search - start                                        
                                        if(searchmore_list!='' && typeof(resgrid[0][rowinx[1]].specialhighlightinclusion)!='undefined')
                                        {
                                          var str_text=resgrid[0][rowinx[1]].specialhighlightinclusion;
                                          var find = ' , ';
                                          var re = new RegExp(find, 'g');
                                          var cnt_caption_found=0;
                                          if(str_text!='' && str_text!=null && typeof(str_text)!='undefined') {
                                            room_highlight_list = str_text.replace(re, ',');
                                            var list_captions=room_highlight_list.split(",");                                          
                                            for(gb=0;gb<searchmore_list.length;gb++)
                                            {                                           
                                              if(jQuery.inArray(searchmore_list[gb],list_captions)!=-1) {//found- dont hide
                                                  cnt_caption_found++;
                                              }                                            
                                            }    
                                          }                                                                               
                                        }
                                        //Search more Filter wise search - start
                                        
                                        //Amenities Filter wise search - start
                                        if(amenity_id_list!='' && typeof(resgrid[0][rowinx[1]].amenities)!='undefined')
                                        {
                                          var room_ame_list=resgrid[0][rowinx[1]].amenities;
                                          var list_amenities=room_ame_list.split(",");                                        
                                          var cnt_amenity_found=0;
                                          for(gb=0;gb<amenity_id_list.length;gb++)
                                          {
                                            if(jQuery.inArray(amenity_id_list[gb],list_amenities)!=-1) {//found- dont hide
                                                cnt_amenity_found++;
                                            }
                                          }
                                        }
                                        //Amenities Filter wise search - end
                                        
                                        //Room type wise
                
                        var rmtype_cnt_found=0;
                        if (opt.ResUIFilterRoomType==true && jQuery("select#roomtypeunkid").val()!=''
                             && typeof(jQuery("select#roomtypeunkid").val())!='undefined'
                             && typeof(resgrid[0][rowinx[1]].roomtypeunkid)!='undefined'
                             && resgrid[0][rowinx[1]].roomtypeunkid!=jQuery("select#roomtypeunkid").val())
                             {                                               
                                rmtype_cnt_found++;
                             }
                        else if(opt.roomtypeunkid!=''
                            && typeof(resgrid[0][rowinx[1]].roomtypeunkid)!='undefined'
                            && resgrid[0][rowinx[1]].roomtypeunkid!=opt.roomtypeunkid)
                            {
                               rmtype_cnt_found++;                                               
                            }
					    else if(opt.specialunkid!='' && opt.specialunkid!=undefined
					    && (typeof(resgrid[0][rowinx[1]].specialunkid)=='undefined'
					    || (typeof(resgrid[0][rowinx[1]].specialunkid)!='undefined'
					    && resgrid[0][rowinx[1]].specialunkid!=opt.specialunkid))) //Pinal - 1.0.50.55 - 5 August 2016 - Purpose - Display only package when package is selected from website
					    {
					       rmtype_cnt_found++;                                               
					    }
                        /*flora - mapping with rate plan id wise along with associated packages - flora - RES-1145*/
                        else if(opt.opt_roomrateunkid!='' && opt.opt_roomrateunkid!=undefined
					    && (typeof(resgrid[0][rowinx[1]].roomrateunkid)=='undefined'
					    || (typeof(resgrid[0][rowinx[1]].roomrateunkid)!='undefined'
					    && resgrid[0][rowinx[1]].roomrateunkid!=opt.opt_roomrateunkid))) 
					    {
					       rmtype_cnt_found++;                                        
					    }
                
                        /*flora - mapping with rate plan id wise along with associated packages - flora*/
                        
                        //Chandrakant - 1.0.50.55 - 29 Sep 2016 - START
                        //Purpose : Save for Later
                        if((opt.sflroomrateunkid!='' && typeof(opt.sflroomrateunkid)!='undefined' && opt.sflroomrateunkid!=null) ||
                           (opt.sflspecialunkid!='' && typeof(opt.sflspecialunkid)!='undefined' && opt.sflspecialunkid!=null)
                           )
                        {
                            var sfl_roomid=opt.sflroomrateunkid;
                            var sflroomid=sfl_roomid.split(",");
                            
                            var sfl_splid=opt.sflspecialunkid;                            
                            var sflsplid=sfl_splid.split(",");
                            var len_s=sflroomid.length;
                            
                            var sflroomrateunkid_cnt_found=0;
                            var sflspecialunkid_cnt_found=0;
                            
                            var sfl_roomid_arr=new Array();
                            var sfl_splid_arr=new Array();
                            
                            for(var rmid=0;rmid<len_s;rmid++)
                            {
                                if(rmid==0) {
                                    var found = false;
                                    var rplfound = false;
                                }
                                
                                //console.log('Total Rate Plan : '+len_s);
                                //console.log('Rate Plan : '+resgrid[0][rowinx[1]].roomrateunkid+' : '+sflroomid[rmid]);
                                //console.log('Package : '+resgrid[0][rowinx[1]].specialunkid+' : '+sflsplid[rmid]);
                                
                                //Rate Plan hide condition - start
                                
                                if(typeof(resgrid[0][rowinx[1]].roomrateunkid)!='undefined'
                                   && sflroomid[rmid]!=resgrid[0][rowinx[1]].roomrateunkid
                                   && sflsplid[rmid]=='-1'
                                   && found!=true)
                                {
                                    //console.log('R IF');
                                    if(jQuery.inArray(resgrid[0][rowinx[1]].roomrateunkid,sfl_roomid_arr)=='-1')
                                    {
                                        sflroomrateunkid_cnt_found++;
                                        sfl_roomid_arr.push(resgrid[0][rowinx[1]].roomrateunkid);
                                    }
                                }
                                else if(typeof(resgrid[0][rowinx[1]].specialunkid)!='undefined'
                                        && sflroomid[rmid]==resgrid[0][rowinx[1]].roomrateunkid && sflsplid[rmid]!='-1' 
                                        && rplfound!=true)
                                {
                                    //console.log('R ELSEIF');
                                    sflroomrateunkid_cnt_found++;
                                    sfl_roomid_arr.push(resgrid[0][rowinx[1]].roomrateunkid);
                                }
                                else if(typeof(resgrid[0][rowinx[1]].specialunkid)=='undefined'
                                        && sflroomid[rmid]==resgrid[0][rowinx[1]].roomrateunkid && sflsplid[rmid]!='-1' 
                                        && rplfound!=true)
                                {
                                    //console.log('R ELSEIF2');
                                    sflroomrateunkid_cnt_found++;
                                    sfl_roomid_arr.push(resgrid[0][rowinx[1]].roomrateunkid);
                                }
                                else
                                {
                                    //console.log('R ELSE');
                                    if(sflroomid[rmid]==resgrid[0][rowinx[1]].roomrateunkid && len_s>1)
                                    {
                                       // console.log('R IF1');
                                        found = true;
                                    }
                                        
                                    if(typeof(resgrid[0][rowinx[1]].roomrateunkid)!='undefined'
                                       && sflroomid[rmid]==resgrid[0][rowinx[1]].roomrateunkid
                                       && jQuery.inArray(resgrid[0][rowinx[1]].roomrateunkid,sfl_roomid_arr)!='-1'
                                       && sflsplid[rmid]==-1)
                                    {
                                       // console.log('R IF2');
                                        var sfl_roomid_arr = jQuery.grep(sfl_roomid_arr, function(value) {
                                            return value != resgrid[0][rowinx[1]].roomrateunkid;
                                        });
                                    }
                                }
                                //Rate Plan hide condition - end
                                
                                //Specials hide condition - start
                                if ((typeof(resgrid[0][rowinx[1]].specialunkid)!='undefined'
                                          && sflsplid[rmid]!=resgrid[0][rowinx[1]].specialunkid
                                          && sflroomid[rmid]!=resgrid[0][rowinx[1]].roomrateunkid)
                                          && rplfound!=true)
                                {
                                    //console.log('S IF');
                                    if(jQuery.inArray(resgrid[0][rowinx[1]].specialunkid,sfl_splid_arr)=='-1')
                                    {
                                        //console.log('S IF2');
                                        sflspecialunkid_cnt_found++;
                                        sfl_splid_arr.push(resgrid[0][rowinx[1]].specialunkid);    
                                    }
                                }
                                else if ((typeof(resgrid[0][rowinx[1]].specialunkid)!='undefined'
                                          && sflsplid[rmid]!=resgrid[0][rowinx[1]].specialunkid
                                          && sflroomid[rmid]==resgrid[0][rowinx[1]].roomrateunkid)
                                          && rplfound!=true)
                                {
                                    //console.log('S ELSEIF');
                                    if(jQuery.inArray(resgrid[0][rowinx[1]].specialunkid,sfl_splid_arr)=='-1')
                                    {
                                        //console.log('S IF');
                                        sflspecialunkid_cnt_found++;
                                        sfl_splid_arr.push(resgrid[0][rowinx[1]].specialunkid);    
                                    }
                                }
                                else
                                {
                                    //console.log('S ELSE');
                                    if(sflsplid[rmid]==resgrid[0][rowinx[1]].specialunkid)
                                    {
                                        //console.log('S IF1');
                                        rplfound = true;
                                    }
                                        
                                    if(jQuery.inArray(resgrid[0][rowinx[1]].roomrateunkid,sfl_roomid_arr)=='-1'
                                       && sflroomid[rmid]!=resgrid[0][rowinx[1]].roomrateunkid
                                       && sflsplid[rmid]!='-1' && found!=true)
                                    {
                                        //console.log('S IF2');
                                        sflroomrateunkid_cnt_found++;
                                        sfl_roomid_arr.push(resgrid[0][rowinx[1]].roomrateunkid);
                                    }
                                    
                                    if(typeof(resgrid[0][rowinx[1]].specialunkid)!='undefined' 
                                       && sflsplid[rmid]==resgrid[0][rowinx[1]].specialunkid
                                       && jQuery.inArray(resgrid[0][rowinx[1]].specialunkid,sfl_splid_arr)!='-1')
                                    {
                                        //console.log('S IF3');
                                        var sfl_splid_arr = jQuery.grep(sfl_splid_arr, function(value) {
                                            return value != resgrid[0][rowinx[1]].specialunkid;
                                        });
                                    }
                                }
                                //Specials hide condition - end
                                
                                //console.log('Rate Plan Found : '+found);
                                //console.log('Package Found : '+rplfound);
                            }
                        }
                        
                        allbtnarr.push(rowinx[1]);
                        if(typeof(resgrid[0][rowinx[1]].roomrateunkid)!='undefined' && sflroomrateunkid_cnt_found>0
                            && (sfl_roomid_arr.length!=0 && jQuery.inArray(resgrid[0][rowinx[1]].roomrateunkid,sfl_roomid_arr)!='-1')
                            && (typeof(resgrid[0][rowinx[1]].specialunkid)=='undefined'))
                        {
                            savebtnarr.push(rowinx[1]);
                           // console.log("hide this :  " + resgrid[0][rowinx[1]].display_name);
                        }
                        
                        if(typeof(resgrid[0][rowinx[1]].specialunkid)!='undefined' && sflspecialunkid_cnt_found>0
                           && (sfl_splid_arr.length!=0 && jQuery.inArray(resgrid[0][rowinx[1]].specialunkid,sfl_splid_arr)!='-1'))
                        {
                            savebtnarr.push(rowinx[1]);
                            //console.log("special hide this :  " + resgrid[0][rowinx[1]].display_name);
                            //console.log("special hide this :  " + resgrid[0][rowinx[1]].specialunkid);
                        }
                        //console.log('---------------------------------');
                        //Chandrakant - 1.0.50.55 - 29 Sep 2016 - END
                        
                        //Price wise show rate plans - start
                        var compare_amt='';                                        
                        var price_start='';
                        var price_end='';
                                        
            //console.log("price " + $("input#price_slider_val").val()+ " : " +opt.ResUIMinimumPrice + " : " +opt.ResUIMaximumPrice);
            //console.log("curr val " + resgrid[0][0]['MultiplyCurrCode1']);
                    if(resgrid[0][0]['MultiplyCurrCode1']!='')
                    {
                      opt.ResUIshowfilterPrice=false;
                      jQuery("#filter_price").hide();
                    }
                    
                    //console.log("price =======================================");
                    
                    if($.trim($("input#price_slider_val").val())!=''
                       //&& opt.WebShowRatesAvgNightORWholeStay!=3
                       && opt.ResUIshowfilterPrice==true
                       && opt.ResUIMinimumPrice!='' && opt.ResUIMaximumPrice!=''
                       ) {
                            //console.log("ggg"+opt.ResUIshowfilterPrice);
                            var price_values=$("input#price_slider_val").val();
                            console.log(p_values);
                            var p_values=price_values.split("|");
                            if(p_values[0]!='' && p_values[1]!=''
                               //p_values[0]!=0 //flora - commented , it was creating issue for price searching 1.0.52.57
                               && (opt.ResUIMaximumPrice!=p_values[1])
                               )
                            {
                                price_start=p_values[0];
                                price_end=p_values[1];
                                if(jQuery("#pnl_tot_blk").hasClass('active')==true)
                                {
                                    //var amt_whole_stay=(jQuery(this).find("#rmamt_"+rowinx[1]).text());
                                    var amt_whole_stay=jQuery(this).find("#rmamt").text();
                                    var frenchhotel_hotelcode="res_HotelCode";
                                    if(sessionStorage.getItem(frenchhotel_hotelcode)!=null)	
                                    { 
                                        var h_code_val=sessionStorage.getItem("res_HotelCode");
                                        var fr_val=sessionStorage.getItem("res_FrenchHotel_"+h_code_val);
                                        //console.log("fr_val : "+ fr_val);
                                        if(fr_val!=null && fr_val=='-fr-French')	
                                        {
                                            amt_whole_stay = amt_whole_stay.replace(/ /g , "");//for french case -flora
                                            amt_whole_stay = amt_whole_stay.replace(/,/g , ".");
                                        }
                                        else
                                        {
                                           amt_whole_stay = amt_whole_stay.replace(/,/g , "");
                                        }
                                    }
                                    else
                                    {
                                         amt_whole_stay = amt_whole_stay.replace(/,/g , "");
                                    }
                                    if(opt.ShowNights==true)
                                        var n_night=parseInt($("select#eZ_Nights").val());
                                    else
                                        var n_night=parseInt($("input#eZ_Nights").val());
                                    if(parseFloat(amt_whole_stay)>0) {
                                        compare_amt=parseFloat(amt_whole_stay)/parseInt(n_night);
                                    }                                    
                                }
                                else if(jQuery("#pnl_avg_blk").hasClass('active')==true)
                                {
                                    var amt_avg_stay=(jQuery(this).find("#rmamt_avg_night").text());
                                   
                                    var frenchhotel_hotelcode="res_HotelCode";
                                    if(sessionStorage.getItem(frenchhotel_hotelcode)!=null)	
                                    { 
                                        var h_code_val=sessionStorage.getItem("res_HotelCode");
                                        var fr_val=sessionStorage.getItem("res_FrenchHotel_"+h_code_val);
                                        //console.log("fr_val : "+ fr_val);
                                        if(fr_val!=null && fr_val=='-fr-French')	
                                        {
                                            amt_avg_stay = amt_avg_stay.replace(/ /g , "");//for french case -flora
                                            amt_avg_stay = amt_avg_stay.replace(/,/g , ".");
                                        }
                                        else
                                        {
                                           amt_avg_stay = amt_avg_stay.replace(/,/g , "");
                                        }
                                    }
                                    else
                                    {
                                        amt_avg_stay = amt_avg_stay.replace(/,/g , "");
                                    }
                                    compare_amt=parseFloat(amt_avg_stay);
                                }
                            }
                        }
                                        //Price wise show rate plans - end
                                        //console.log(compare_amt +" : > " + price_start + " === " + compare_amt + " >= "+ price_end);
                                        
                                        //Message for filter applied result
                                        if(rmtype_cnt_found>0) {
                                            filterapply_roomtype=1;
                                        }
                                        if((cnt_amenity_found==0) ||
                                                    (cnt_caption_found>0) ||
                                                    filterapply_roomtype==1 ||
                                                    (non_refundable_count > 0) ||                                                    
                                                    (exclude_notavailable > 0) ||
                                                    (rating_cnt==0 && guest_rating.length!=0))
                                        {
                                            //Chinmay Gandhi - Start
                                            if(opt.HideResetFilter == false)
                                            {
                                                jQuery("#appliedfilter_msg").show();
                                            }
                                            //Chinmay Gandhi - End
                                        }
                                        else
                                        {
                                           jQuery("#appliedfilter_msg").hide(); 
                                        }
                                        
                                       // console.log(jQuery(this).find(".vrmprice > p#not_available").hasClass('rm_sold'));
                                        //.hasClass('rm_sold')
    
                    if(display_view==1) {//Rooms  //show rooms only  - hide   packages
                      //console.log("execute this case");
                      jQuery(this).removeClass('hidereadmore');//read more , less                                        
                         if(typeof(resgrid[0][rowinx[1]].deals)!='undefined' ||
                                (cnt_amenity_found==0) ||
                                (cnt_caption_found==0) ||
                                (rmtype_cnt_found>0) ||                                                    
                                (non_refundable_count > 0) ||
                                (exclude_notavailable > 0) ||
                                (rating_cnt==0 && guest_rating.length!=0) ||
                                (typeof(resgrid[0][rowinx[1]].roomrateunkid)!='undefined' && sflroomrateunkid_cnt_found>0
                                    && (sfl_roomid_arr.length!=0 && jQuery.inArray(resgrid[0][rowinx[1]].roomrateunkid,sfl_roomid_arr)!='-1')
                                    && (typeof(resgrid[0][rowinx[1]].specialunkid)=='undefined')) || //Chandrakant 
                                (typeof(resgrid[0][rowinx[1]].specialunkid)!='undefined' && sflspecialunkid_cnt_found>0
                                   && (sfl_splid_arr.length!=0 && jQuery.inArray(resgrid[0][rowinx[1]].specialunkid,sfl_splid_arr)!='-1')) || //Chandrakant 
                                (price_start!='' && price_end!='' &&
                                opt.ResUIshowfilterPrice==true && compare_amt!='' && 
                                (compare_amt < parseFloat(price_start) || compare_amt>=parseFloat(price_end))                                                   
                                ) || 
                            (typeof(resgrid[0][rowinx[1]].hiderateplan)!='undefined' && resgrid[0][rowinx[1]].hiderateplan=='1'))
                            {                                                  
                                //console.log(jQuery(this));
                                //console.log("hide");
                                jQuery(this).hide();
                                na_cnt++;
                            }
                            /*Read more & less case -start*/
                            else
                            {
                                if(parseInt(hidereadmore)<parseInt(opt.ResUINoOfRatePlanPackage)
                                    && show_on_limit==1) {                                    
                                    jQuery(this).removeClass('read-more-content');
                                    hidereadmore++;                                                        
                                }
                            }
                            
                            if (show_on_limit==1) {
                                 $('.read-more-content').addClass('hidereadmore');//read more
                                 
                                //Read more count
                                if(jQuery(this).attr('style')=='display: none;')
                                {
                                    hidereadmore_cls++;
                                }                                                   
                                /*Read more & less case -start*/
                            }
                            
                            //hide show more & less - mobile view
                            methods._ShowMoreLessMobileViewOnload(obj_id,opt);
                            //hide show more & less - mobile view
                    }
                                        if (display_view==2) {//Packages - hide rate plans
                                            
                                            jQuery(this).removeClass('hidereadmore');//read more , less 
                                            if (typeof(resgrid[0][rowinx[1]].deals)=='undefined' ||
                                                (cnt_amenity_found==0) ||
                                                (cnt_caption_found==0) ||
                                                 (rmtype_cnt_found>0) ||     
                                                (non_refundable_count > 0) ||
                                                (exclude_notavailable > 0) ||
                                                  (rating_cnt==0 && guest_rating.length!=0) ||
                                                (typeof(resgrid[0][rowinx[1]].roomrateunkid)!='undefined' && sflroomrateunkid_cnt_found>0
                                                    && (sfl_roomid_arr.length!=0 && jQuery.inArray(resgrid[0][rowinx[1]].roomrateunkid,sfl_roomid_arr)!='-1')
                                                    && (typeof(resgrid[0][rowinx[1]].specialunkid)=='undefined')) || //Chandrakant 
                                                (typeof(resgrid[0][rowinx[1]].specialunkid)!='undefined' && sflspecialunkid_cnt_found>0
                                                   && (sfl_splid_arr.length!=0 && jQuery.inArray(resgrid[0][rowinx[1]].specialunkid,sfl_splid_arr)!='-1')) || //Chandrakant 
                                                 (price_start!='' && price_end!='' &&
                                                    opt.ResUIshowfilterPrice==true && compare_amt!='' && 
                                                    (compare_amt < parseFloat(price_start) || compare_amt>=parseFloat(price_end))
                                                    ) || 
                                                (typeof(resgrid[0][rowinx[1]].hiderateplan)!='undefined' && resgrid[0][rowinx[1]].hiderateplan=='1')) {
						
                                                jQuery(this).hide();
                                                na_cnt++;
                                            }
                                            /*Read more & less case -start*/
                                            else
                                            {                                                
                                                if(parseInt(hidereadmore)<parseInt(opt.ResUINoOfRatePlanPackage)
                                                    && show_on_limit==1) {                                    
                                                    jQuery(this).removeClass('read-more-content');
                                                    hidereadmore++;
                                                }
                                            }
                                            
                                            if (show_on_limit==1) {
                                                $('.read-more-content').addClass('hidereadmore');//read more
                                                 if(jQuery(this).attr('style')=='display: none;')
                                                {
                                                    hidereadmore_cls++;
                                                }
                                                /*Read more & less case -start*/                                                
                                            }
                                            
                                            //hide show more & less - mobile view
                                            methods._ShowMoreLessMobileViewOnload(obj_id,opt);
                                            //hide show more & less - mobile view
                                           
                                        }
					
                                        if(display_view==0) {
                                            //console.log("dddddddddddddd");
                                            jQuery(this).removeClass('hidereadmore');
                                            if(((cnt_amenity_found==0)) ||
                                            (cnt_caption_found==0) ||
                                            (rmtype_cnt_found>0) ||     
                                            (non_refundable_count > 0) ||
                                            (exclude_notavailable > 0) ||
                                            (typeof(resgrid[0][rowinx[1]].roomrateunkid)!='undefined' && sflroomrateunkid_cnt_found>0
                                            && (sfl_roomid_arr.length!=0 && jQuery.inArray(resgrid[0][rowinx[1]].roomrateunkid,sfl_roomid_arr)!='-1')
                                            && (typeof(resgrid[0][rowinx[1]].specialunkid)=='undefined')) ||
                                            (typeof(resgrid[0][rowinx[1]].specialunkid)!='undefined' && sflspecialunkid_cnt_found>0
                                            && (sfl_splid_arr.length!=0 && jQuery.inArray(resgrid[0][rowinx[1]].specialunkid,sfl_splid_arr)!='-1')) || 
                                            (rating_cnt==0 && guest_rating.length!=0) ||
                                            (price_start!='' && price_end!='' &&
                                            opt.ResUIshowfilterPrice==true && compare_amt!='' && 
                                            (compare_amt < parseFloat(price_start) || compare_amt>=parseFloat(price_end)))
                                            || 
                                            (typeof(resgrid[0][rowinx[1]].hiderateplan)!='undefined' && resgrid[0][rowinx[1]].hiderateplan=='1'))
                                            {
                                                //console.log("hide this " + cnt_amenity_found);
                                                jQuery(this).hide();
                                                na_cnt++;						
                                            }
                                            /*Read more & less case -start*/
                                            else
                                            {
                                                if(hidereadmore<parseInt(opt.ResUINoOfRatePlanPackage)
                                                && show_on_limit==1) {                                    
                                                    jQuery(this).removeClass('read-more-content');
                                                    hidereadmore++;
                                                }                                                
                                            }
                                            
                                            if(show_on_limit==1) {
                                                $('.read-more-content').addClass('hidereadmore');//readmore                                               
                                                if(jQuery(this).attr('style')=='display: none;')
                                                {
                                                    hidereadmore_cls++;
                                                }
                                            }
                                            
                                                //hide show more & less - mobile view
                                                methods._ShowMoreLessMobileViewOnload(obj_id,opt);
                                                //hide show more & less - mobile view                                            
                                        }                                        
                                    });
                                    
                                    if(show_on_limit==1) {
                                        var t_row=parseInt(row_cnt)-parseInt(na_cnt);
                                      //console.log("t_row : " + t_row);
                                      //console.log("row_cnt : " + row_cnt + " == na_cnt " + na_cnt + " ResUINoOfRatePlanPackage : " +opt.ResUINoOfRatePlanPackage );
                                        if ((t_row)>parseInt(opt.ResUINoOfRatePlanPackage)) {
                                                //'<i class="fa fa-angle-double-up"></i>'+
                                            $(this).find('a.readmore_rtplan').html(js_stripslashes(langjs[0]["L_ReadMore"]));
                                            $(this).find('a.moreless_a').html('<i class="fa fa-angle-down"></i>');
                                            $(this).find('a.readmore_rtplan').addClass('read-more-toggle');
                                            $(this).find('a.readmore_rtplan').addClass('tmp_read-more-toggle');
                                            $(this).find('.moreless_div').show();
                                        }
                                        else
                                        {
                                            $(this).find('a.readmore_rtplan').text('');
                                            $(this).find('a.moreless_a').html('');
                                            $(this).find('a.readmore_rtplan').removeClass('read-more-toggle');
                                            $(this).find('a.readmore_rtplan').removeClass('tmp_read-more-toggle');
                                            $(this).find('.moreless_div').hide();
                                        }                                       
                                    }
                                    
                                    //Show more less for small screen - start
                                    methods._ShowMoreLessMobileView(main_obj_id,opt);
                                    //Show more less for small screen - end
                                    
                                    //hide room type if all not available
                                    if (na_cnt==row_cnt) {
                                        // console.log(jQuery(this));
                                        jQuery(this).hide();
                                        main_div_hide++;				       
                                        jQuery(this).removeClass('rml-first');   //design team                                    
                                    }
                                    else
                                    {
                                        jQuery(this).show();
                                        //design team   
                                        if (main_otarow_cnt==0) {				    
                                            jQuery(this).removeClass('rml-first').addClass('rml-first'); 
                                            main_otarow_cnt++;
                                        }
                                    }
                                    
                                 });
                                var windowWidth = $(window).width();
                                $(window).on('resize', function(){
                                     if($(window).width() !== windowWidth){
                                         resize_done=0;
                                         if($(window).width() >= 768){                                           
                                            if (resize_done==0) {
                                                //methods._readmoretoggle_event(opt);
                                                resize_done=1;
                                            }                                           
                                         }
                                     }
                                });
                                  // 
                                  ////Set up the toggle.
                                  //if (resize_done==-1) {
                                    $('.read-more-toggle,.moreless_a').on('click', function(e) {
                                        e.preventDefault();
                                       // console.log($(this).parent().parent().find('.read-more-content').hasClass('hidereadmore'));
                                      //  console.log($(this).parent().parent().find('.read-more-content'));
                                         
                                        if ($(this).parent().parent().find('.read-more-content').hasClass('hidereadmore')==true) {
                                            //console.log("set false");
                                            $(this).parent().parent().find('.read-more-content').removeClass('hidereadmore');
                                        }
                                        else
                                        {
                                            $(this).parent().parent().find('.read-more-content').addClass('hidereadmore');
                                        }
                                        
                                        var text = $(this).parent().parent().find('.readmore_rtplan').text();
                                         //console.log("text : " +  text);
                                         $(this).parent().parent().find('.readmore_rtplan').text(
                                                text == js_stripslashes(langjs[0]["L_ReadMore"]) ? js_stripslashes(langjs[0]["L_ReadLess"]) : js_stripslashes(langjs[0]["L_ReadMore"]));
                                         
                                         if(text==js_stripslashes(langjs[0]["L_ReadMore"])) {
                                            $(this).parent().parent().find('a.moreless_a').html('<i class="fa fa-angle-up"></i>');
                                         }
                                         else
                                         {
                                            $(this).parent().parent().find('a.moreless_a').html('<i class="fa fa-angle-down"></i>');
                                         }
                                         $(this).parent().parent().find('a.readmore_rtplan').addClass('read-more-toggle');
                                         
                                         //methods._setExternalIframeHeight(opt); 
                                    });
                                    /*Read more , less */
                                // }
                                
                                if (main_div_hide==total_div_len) {
				    
				    if(typeof(resgrid[0])!='undefined' && resgrid[0]!=null && resgrid[0].length>0)
				    {
					jQuery("#no_filterrecord").addClass("showthis");
					jQuery("#no_filterrecord").show();
					jQuery("#no_filterrecord").prev().hide();
					jQuery("#no_record").hide();
                    jQuery("#norecord_main_blk").hide();                    
					jQuery("#availcalmain").hide();
					jQuery("#enquirenotavail").hide();
				    }
				    else
				    {
					jQuery("#no_record").show();
                    jQuery("#norecord_main_blk").show();
					jQuery("#availcalmain").show();
					jQuery("#enquirenotavail").show();
                    methods._ShowClosestAvailability(opt);
                    if(opt.TripteasehotelKey!='' && opt.TripteasehotelAPIkey !='')
                        Paperboy.PriceCheck && Paperboy.PriceCheck.destroy();
				    }
				    
                                   jQuery("#filter-wrapper_div").hide();
                                   jQuery("#booking_summery_blk").hide();
                                   jQuery("#summary_head").hide();
                                   jQuery("#room_container").hide();                           
                                }
                            }                            
                            
                            methods._showfullyavailablerateplan(opt);
                            
                    //chinmay gandhi - 1.0.52.57 - Start
                    //Chinmay Gandhi - Hide filter for online hostel booking
                    if(opt.HideResetFilter == false)
                    {
                        if(opt.roomtypeunkid!='')
                            opt.roomtypeunkid='';
                        if(opt.specialunkid!='') //Pinal
                            opt.specialunkid='';
                    }
                    //chinmay gandhi - 1.0.52.57 - End
                    
                    //reset save for later variable - flora
                    if(opt.sflroomrateunkid!='' && typeof(opt.sflroomrateunkid)!='undefined' && opt.sflroomrateunkid!=null)
                    {
                        opt.sflroomrateunkid='';
                        opt.SelectedPromo='';
                    }
                    if(opt.sflspecialunkid!='' && typeof(opt.sflspecialunkid)!='undefined' && opt.sflspecialunkid!=null)
                    {
                        opt.sflspecialunkid='';
                        opt.SelectedPromo='';
                    }
                    //reset save for later variable - flora
                    /*Read more , less */
                    
                    //All Resize script -------- start
                     if($(window).width()<991){
                            winHeight = $(window).height();
                            var h=winHeight - (parseInt(jQuery("#filter_header").height())+parseInt(jQuery("#filter_apply").height()));
                            var content_height=jQuery("#main_filter_div").height();
                            jQuery("#main_filter_div").addClass('main_filter_div');
                            jQuery(".main_filter_div").css('height',h+'px');
                     }
                    
                    //footer section
                    if($(window).width()<1035){
                        var bb_block_height=jQuery("#booking_summery_blk").height()+20;
                        //console.log("bb_block_height : " + bb_block_height);
                        if($("#footer_main").css('display')=='none') //Chandrakant : when footer is hidden it will apply room listing div
                        {
                            jQuery("#room_container").css('margin-bottom',bb_block_height+'px');        
                        }
                        /*else
                        {
                            jQuery("#footer_main").css('margin-bottom',bb_block_height+'px');
                        }*/ //daksh - new booking summery as per suggestion not needed margin
                    }
                     
                    $(window).on('resize', function(){
                        if($(window).width() !== windowWidth){
                            if($(window).width()<991){
                                winHeight = $(window).height();
                                var h=parseInt(winHeight) - (parseInt(jQuery("#filter_header").height())+parseInt(jQuery("#filter_apply").height()));
                                jQuery("#main_filter_div").addClass('main_filter_div');
                                jQuery(".main_filter_div").css('height',h+'px');
                            }
                            else
                            {
                                jQuery("#main_filter_div").css("height","");
                                jQuery("#main_filter_div").removeClass('main_filter_div'); 
                            }
                        }
                    });
                        //All Resize script -------- end                 
                   
                   }//if resgrid is not undefined
                   else
                   {
                        jQuery("#no_record").show();
                        jQuery("#norecord_main_blk").show();
                        jQuery("#availcalmain").show();
                        jQuery("#enquirenotavail").show();
                        jQuery("#filter-wrapper_div").hide();
                        jQuery("#booking_summery_blk").hide();
                        jQuery("#summary_head").hide();
                        jQuery("#room_container").hide();
                        methods._ShowClosestAvailability(opt);
                   }
                   hideCheckavalabilityLoadingBar();

                if($("#ischeckavailabilityclicked").val()=="1" && $("#dataLayerScriptVersion").val()=="1"){
                    $('head script').each(function() {
                        if ($(this).html().includes("view_item_list") || $(this).html().includes("check_availability") || $(this).html().includes("add_to_cart") || $(this).html().includes("removeFromCart")) {
                            $(this).remove();
                        }
                    });
                    var arravilabledata=Array();
                    var arrIndex=0;
                    var $filteredDivs = $('div.card-list').filter(function() {
                        return !this.getAttribute('style');
                    });
                    var scriptval = "\<script\>";
                    scriptval +="window.dataLayer = window.dataLayer || [];";
                    scriptval +="dataLayer.push({ ";
                    scriptval +="event: 'view_item_list', ";
                    scriptval +="event_id: 'event_id', ";
                    scriptval +="arrivalDate: '"+jQuery("#datalyr_checkindate").val()+"', ";
                    scriptval +="brand: '"+js_addslashes(jQuery("#hbrandname").val())+"', ";
                    scriptval +="departureDate: '"+jQuery("#datalyr_checkoutdate").val()+"', ";
                    scriptval +="discountCoupon: '',";
                    scriptval +="hotelID: "+resgrid[0][0].hotel_code+",";
                    scriptval +="location: '"+js_addslashes(jQuery("#hlocation").val())+"', ";
                    scriptval +="noOfAdults: "+jQuery("#adult").val()+",";
                    scriptval +="noOfChild: "+jQuery("#child").val()+",";
                    scriptval +="noOfRooms: "+jQuery("#rooms").val()+",";
                    scriptval +="offer: '"+js_addslashes(jQuery("#hpromoname").val())+"', ";
                    scriptval +="optionSelected: 'View Item List',";
                    scriptval +="pageTitle: '"+js_addslashes(document.title)+"', ";
                    scriptval +="pageURL: '"+window.location.href+"', ";
                    scriptval +="promoCode: '"+jQuery("#hpromocode").val()+"', ";
                    scriptval +="ecommerce: { ";
                    scriptval +="'items': [ ";
                    if($filteredDivs.length > 0) {
                        $filteredDivs.each(function() {
                            var BEPackagename = js_addslashes($(this).find('h3.no_deal-head').text().trim());
                            var formatedprice = $(this).find('#rmamt').text().replace(/[^0-9\.]/g, '');
                            if(formatedprice=='')
                                formatedprice = $(this).find('#rmamt_avg_night').text().replace(/[^0-9\.]/g, '');
                            scriptval +="{ ";
                            scriptval +="'item_id': '"+arrIndex+"', ";
                            scriptval +="'item_name': '"+BEPackagename+"', ";
                            scriptval +="'affiliation': '"+js_addslashes(jQuery("#hname").text())+"', ";
                            scriptval +="'index': "+arrIndex+", ";
                            scriptval +="'item_brand': '"+BEPackagename+"', ";
                            scriptval +="'item_category': '"+BEPackagename+"', ";
                            scriptval +="'item_variant': '"+BEPackagename+"', ";
                            scriptval +="'price': "+(formatedprice==''?0:formatedprice)+", ";
                            scriptval +="'quantity': 1 ";
                            if(arrIndex+1<$filteredDivs.length)
                                scriptval +="},";
                            else
                                scriptval +="}";
                            arrIndex=arrIndex+1;
                        });
                    }
                    scriptval +="]}}); ";
                    scriptval +="\<\/script\>";
                    jQuery("head").append(scriptval);
                    var scriptavaulval = "\<script\>";
                    scriptavaulval +="dataLayer.push({ ";
                    scriptavaulval +="event: 'check_availability',";
                    scriptavaulval +="event_id: 'event_id',";
                    scriptavaulval +="arrivalDate: '"+jQuery("#datalyr_checkindate").val()+"', ";
                    scriptavaulval +="brand: '"+js_addslashes(jQuery("#hbrandname").val())+"', ";
                    scriptavaulval +="departureDate: '"+jQuery("#datalyr_checkoutdate").val()+"', ";
                    scriptavaulval +="discountCoupon: '"+js_addslashes(jQuery("#hpromoname").val())+"', ";
                    scriptavaulval +="hotelID: "+resgrid[0][0].hotel_code+", ";
                    scriptavaulval +="location: '"+js_addslashes(jQuery("#hlocation").val())+"', ";
                    scriptavaulval +="noOfAdults: "+jQuery("#adult").val()+",";
                    scriptavaulval +="noOfChild: "+jQuery("#child").val()+",";
                    scriptavaulval +="noOfRooms: "+jQuery("#rooms").val()+",";
                    scriptavaulval +="optionSelected: 'Check Availability', ";
                    scriptavaulval +="pageTitle: '"+js_addslashes(document.title)+"', ";
                    scriptavaulval +="pageURL: '"+window.location.href+"', ";
                    scriptavaulval +="promoCode: '"+js_addslashes(jQuery("#hpromocode").val())+"' ";
                    scriptavaulval +="});";
                    scriptavaulval +="\<\/script\>";
                    jQuery("head").append(scriptavaulval);
                }

		    //Pinal - 1.0.50.55 - 12 August 2016 - START
		    //Purpose - Getting minimum rates for Price Widget
		    try
		    {
                if (opt.WebDefaultCurrency=='')
                {
                    methods._pricewidgetreload(opt); //Sonam - 1.0.52.60 - 16 Aug 2017 - Purpose: to reload price widget when changing currency
                }
		    }
		    catch(e)
		    {
                console.log("Price Widget Exception "+e);
		    }
		    //Pinal - 1.0.50.55 - 12 August 2016 - END
		    
                   //Click Adult & Child , Generate Clone  - start
                   //Add Room               
                   jQuery(".add_field_button").on('click',function(e){ //add button click
                        var ava_clicked=methods._check_availability_clicked(opt);
                        if (ava_clicked==false) {
                            return false;
                        }
                        methods._showBookingSummary($(this),opt);
                        if($("#dataLayerScriptVersion").val()=="1"){
                            rowid=$(this).attr('id').split("_");
                            methods.addRoomToCart(rowid,opt);
                        }
                        var otatablview_id=jQuery(this).closest(".otatablview").attr('id');
                        var set_init_param=otatablview_id.split("_");
                        
                        var set_id=jQuery(this).attr('id');
                        var setidarr=set_id.split("_");                        
                        
                        var max_rooms_allowed=parseInt(resgrid[0][setidarr[1]]['MulRoomInventory']);//maximum input room_rowes allowed
                        var room_init_cnt=jQuery("#room_init_cnt_"+set_init_param[1]).text();//initlal text room_row count
                        var each_room_cnt=jQuery("#each_room_cnt_"+setidarr[1]).text();
                        
                        if(room_init_cnt < max_rooms_allowed){//max input room_row allowed
                           
                            room_init_cnt++;//text room_row increment
                            jQuery("#room_init_cnt_"+set_init_param[1]).text(room_init_cnt);
                            
                            var room_clone_cnt=jQuery("#room_clone_cnt_"+setidarr[1]).text();
                           
                            if(room_init_cnt <= max_rooms_allowed)
                            {                                
                                    
				    //Pinal - 1.0.50.55 - 23 August 2016 - START
				    //Purpose - Allow booker to book only specified no of rooms at a time.
				    //console.log("roombooklimitcal => "+(parseInt($("#roombooklimitcal").val())+1));
				    if ($("#roombooklimit").val()!='' && (parseInt($("#roombooklimitcal").val())+1)>parseInt($("#roombooklimit").val()))
				    {
					var alertmsglimit=js_stripslashes(langjs[0]["L_roombooklimit"]).replace("{nobook_rms_limit}",$("#roombooklimit").val())
					swal({
					    title: '',//title_message,
					    text: alertmsglimit,
					    type: 'error',
					    showCancelButton: false,
					    animation:false
					});
					return false;
				    }
				    //Pinal - 1.0.50.55 - 23 August 2016 - END
			    
				    $Obj_Room=$('#room_row_'+setidarr[1]+"_0")
                                   .clone(true)
                                   .attr('id', 'room_row_'+setidarr[1]+"_"+room_clone_cnt)                           
                                   .insertAfter('[id^=room_row_'+setidarr[1]+'_]:last');                     
                                   
                                   $Obj_Room.find(".adult").attr('name','rm_adult'  + '_' +setidarr[1]+"_"+  room_clone_cnt);
                                   $Obj_Room.find(".adult").attr('id','rm_adult'  + '_' +setidarr[1]+"_"+  room_clone_cnt);
                                   
                                    var Obj_Room_adult=$Obj_Room.find(".base_adult").text();
                                    if(opt.acr==true)
                                    {
                                        Obj_Room_adult=$("select[id$='eZ_adult']").val();
                                    }                        
                                    $Obj_Room.find(".adult").val(Obj_Room_adult);
                                     
                                   $Obj_Room.find(".child").attr('name','rm_child'   + '_' +setidarr[1]+"_"+  room_clone_cnt);
                                   $Obj_Room.find(".child").attr('id','rm_child'   + '_' +setidarr[1]+"_"+  room_clone_cnt);
                                   
                                   var Obj_Room_child=$Obj_Room.find(".base_child").text();
                                   if(opt.RunOnMaximumOccupanySettings==0)
                                   {
                                        if((opt.showchild_bb=="-1" && opt.showchild==true) || opt.showchild_bb==true)
                                        {
                                             Obj_Room_child=$("select[id$='eZ_child']").val();
                                             
                                        }
                                   }
                                   $Obj_Room.find(".child").val(Obj_Room_child);
                                   
                                   $Obj_Room.find(".childagediv").attr('id','childage_div'+'_'+setidarr[1]+"_"+  room_clone_cnt);
                                   $Obj_Room.find(".remove_field").attr('id','delete_room'  + '_' +setidarr[1]+"_"+  room_clone_cnt);
                                   $Obj_Room.find(".remove_field").css({
                                                    'display':'block',
                                                    'float':'right'}),
                          
                                   $Obj_Room.find(".lblrm").attr('id','rm_cnt_lbl'   + '_' +setidarr[1]+"_"+  room_clone_cnt);
                                   $Obj_Room.find(".row_index").attr('id','row_index_'+setidarr[1]+"_"+  room_clone_cnt);
                                   $Obj_Room.find(".row_index").text(room_clone_cnt);
                                   
                                   //methods._childagecombo($Obj_Room.find(".base_child").text(),setidarr[1],room_clone_cnt);
                                   methods._childagecombo(Obj_Room_child,setidarr[1],room_clone_cnt);                                   
                                   
                           }
                           //console.log(setidarr[1]+"  ---  "+room_clone_cnt);
                          // console.log("========================");
                            methods._calculateLongtayPrice(opt,setidarr[1],room_clone_cnt,'add_room');
                           
                           room_clone_cnt++;
                           jQuery("#room_clone_cnt_"+setidarr[1]).text(room_clone_cnt);
                           
                           each_room_cnt++;
                           jQuery("#rooms_"+setidarr[1]).val(each_room_cnt);
                           jQuery("#each_room_cnt_"+setidarr[1]).text(each_room_cnt);
                           
                            //console.log("#rm_cnt_lbl_"+setidarr[1]+"_"+  room_init_cnt+ "   ==> " + each_room_cnt);        
                            jQuery("#rm_cnt_lbl_"+setidarr[1]+"_"+  (room_clone_cnt-1)).text((room_clone_cnt));
                            
                            methods._setRoomLeftLbl(set_init_param[1],max_rooms_allowed);
                            methods._showBookingSummary($(this),opt);
                             
                        }//if
                   });
                   
                   //Delete Room
                   jQuery(".remove_field_button").on('click',function(e){ //remove button click
                            var ava_clicked=methods._check_availability_clicked(opt);
                            if (ava_clicked==false) {
                                return false;
                            }
                            if($("#dataLayerScriptVersion").val()=="1"){
                                $('head script').each(function() {
                                    if ($(this).html().includes("view_item_list") || $(this).html().includes("check_availability") || $(this).html().includes("add_to_cart") || $(this).html().includes("removeFromCart")) {
                                        $(this).remove();
                                    }
                                });

                                rowid=$(this).attr('id').split("_");
                                methods._showBookingSummary($(this),opt);
                                spanlist=jQuery("#summ_row_"+rowid[1]).find("span.fl-left span")
                                packagename=js_addslashes(spanlist[0].innerHTML);
                                extrainfo=spanlist[1].innerHTML;
                                plist=jQuery("#summ_row_"+rowid[1]).find("p.fl-left");
                                pprice=plist[0].innerText.replace(/[^0-9.]/g, '');
                                var $htmlContent = $('<div>').html(extrainfo);
                                var $divs = $htmlContent.find('div');
                                var extractedText = [];
                                extractedText['adult']=extractedText['child']=extractedText['room']=0;
                                $divs.each(function() {
                                    var tempid=$(this).attr('id');
                                    if(tempid=='listingadult'){
                                        extractedText['listingadult']=$(this).text().trim();
                                        extractedText['adult']=extractedText['listingadult'].split(" ")[0];
                                    }
                                    else if(tempid=='listingchild'){
                                        extractedText['listingchild']=$(this).text().trim();
                                        extractedText['child']=extractedText['listingchild'].split(" ")[0];
                                    }else if(tempid=='listroom'){
                                        extractedText['listroom']=$(this).text().trim();
                                        extractedText['room']=extractedText['listroom'].split(" ")[0];
                                    }
                                });
                                var scriptavaulval = "\<script\>";
                                scriptavaulval +="dataLayer.push({ ";
                                scriptavaulval +="event: 'removeFromCart', ";
                                scriptavaulval +="event_id: 'event_id', ";
                                scriptavaulval +="arrivalDate: '"+jQuery("#datalyr_checkindate").val()+"', ";
                                scriptavaulval +="brand: '"+js_addslashes(jQuery("#hbrandname").val())+"', ";
                                scriptavaulval +="departureDate: '"+jQuery("#datalyr_checkoutdate").val()+"', ";
                                scriptavaulval +="discountCoupon:  '"+js_addslashes(jQuery("#hpromoname").val())+"', ";
                                scriptavaulval +="offer: '"+js_addslashes(jQuery("#hpromoname").val())+"', ";
                                scriptavaulval +="hotelID: "+jQuery("#hcode").val()+", ";
                                scriptavaulval +="location: '"+js_addslashes(jQuery("#hcode").val())+"', ";
                                scriptavaulval +="noOfAdults: "+extractedText['adult']+", ";
                                scriptavaulval +="noOfChild: "+extractedText['child']+", ";
                                scriptavaulval +="noOfRooms: "+extractedText['room']+", ";
                                scriptavaulval +="pageTitle: '"+js_addslashes(document.title)+"', ";
                                scriptavaulval +="pageURL: '"+window.location.href+"', ";
                                scriptavaulval +="promoCode: '"+jQuery("#hpromocode").val()+"', ";
                                scriptavaulval +="ecommerce: { ";
                                scriptavaulval +="currency: '"+jQuery("#selected_curr").val()+"', ";
                                scriptavaulval +="value: "+pprice+", ";
                                scriptavaulval +="'items': [ ";
                                scriptavaulval +="{ ";
                                scriptavaulval +="'item_id': '"+packagename+"',";
                                scriptavaulval +="'item_name': '"+packagename+"',";
                                scriptavaulval +="'affiliation': '"+js_addslashes(jQuery("#hbrandname").val())+"', ";
                                scriptavaulval +="'index': "+rowid[1]+", ";
                                scriptavaulval +="'item_brand': '"+packagename+"',";
                                scriptavaulval +="'item_category': '"+packagename+"',";
                                scriptavaulval +="'item_variant': '"+packagename+"',";
                                scriptavaulval +="'price': "+pprice+",";
                                scriptavaulval +="'quantity': 1 ";
                                scriptavaulval +="}]} ";
                                scriptavaulval +="});";
                                scriptavaulval +="\<\/script\>";
                                jQuery("head").append(scriptavaulval);
                            }

                            e.preventDefault();
                            var set_id=jQuery(this).attr('id');
                            var setidarr=set_id.split("_");                        
                           
                            var otatablview_id=jQuery(this).closest(".otatablview").attr('id');
                            var set_init_param=otatablview_id.split("_");
                                
                            var room_init_cnt=jQuery("#room_init_cnt_"+set_init_param[1]).text();//initlal text room_row count
                            var room_clone_cnt=jQuery("#room_clone_cnt_"+setidarr[1]).text();//initlal text room_row count
                            var each_room_cnt=jQuery("#each_room_cnt_"+setidarr[1]).text();
                             
                            var max_rooms_allowed=parseInt(resgrid[0][setidarr[1]]['MulRoomInventory']);//maximum input room_rowes allowed
                            
                            //Reset Adult & Child once row removed to base occupany
                            rateplan2=resgrid[0][setidarr[1]];
                            if (typeof(rateplan2)!='undefined') {//Once remove set to default
                                 
                                var baseadult=eval("rateplan2.base_adult");
                                if(opt.acr==true)
                                    baseadult=$("select[id$='eZ_adult']").val();
                        
                                var basechild=eval("rateplan2.base_child");
                                if(opt.RunOnMaximumOccupanySettings==0)
                                {
                                    if((opt.showchild_bb=="-1" && opt.showchild==true) || opt.showchild_bb==true)
                                      basechild=$("select[id$='eZ_child']").val();
                                }
                                //Chinmay Gandhi - 1.0.53.61 - Start
                                //Perpose: Set MaxOccupancySetting
                                if(opt.RunOnMaximumOccupanySettings==1)
                                {
                                    if(opt.showchild==true)
                                        basechild=$("#rm_child_"+setidarr[1]+"_"+(room_clone_cnt-1)).val();
                                }
                                //Chinmay Gandhi - End
                                
                                jQuery("#rm_adult_"+setidarr[1]+"_"+(room_clone_cnt-1)).val(baseadult);
                                jQuery("#rm_child_"+setidarr[1]+"_"+(room_clone_cnt-1)).val(basechild);
                                 
                                //child age combo reset
                                methods._childagecombo(basechild,setidarr[1],(room_clone_cnt-1));                                  
                            }
                            //Reset Adult & Child once row removed to base occupany
                            
                            if(jQuery('[id^=room_row_'+setidarr[1]+'_]').length==1) {                        
                                $(this).parents('.card-list').find('.ad-rm').show().next('.vres_num').hide();                        
                                jQuery('[id^=room_row_'+setidarr[1]+'_0]').hide();
                            }
                            else{
                                jQuery('[id^=room_row_'+setidarr[1]+'_]:last').remove();
                                each_room_cnt--;                            
                                jQuery("#rooms_"+setidarr[1]).val(each_room_cnt);                               
                                jQuery("#each_room_cnt_"+setidarr[1]).text(each_room_cnt);
                            }
                            
                            methods._calculateLongtayPrice(opt,setidarr[1],room_clone_cnt,'rm_room');
                            
                            room_init_cnt--;
                            room_clone_cnt--;
                            
                            jQuery("#rm_cnt_lbl_"+setidarr[1]+"_"+  room_clone_cnt).text(room_clone_cnt);
                            jQuery("#room_init_cnt_"+set_init_param[1]).text(room_init_cnt);
                            jQuery("#room_clone_cnt_"+setidarr[1]).text(room_clone_cnt);
                                                        
                    
                            methods._setRoomLeftLbl(set_init_param[1],max_rooms_allowed);
                            methods._showBookingSummary($(this),opt);
                   });
                  //Click Adult & Child , Generate Clone  - end
                  
                  $('.ad-rm').on('click', function(){
                        if ($(this).hasClass('adrm-wrap2')==true ||
                            $(this).hasClass('adrm-wrap-close')==true
                            ) {//create issue while add-rom strike out errror on console only
                            return false;
                        }
                        methods._showBookingSummary($(this),opt);
                        if($("#dataLayerScriptVersion").val()=="1"){
                            rowid=$(this).attr('id').split("_");
                            methods.addRoomToCart(rowid,opt);
                        }
                    });
                
                //Chandrakant - 2.0.4 - 04 June 2018 - START
                //Purpose : - RES-1637 - click event not working on google chrome of iOS. So, replace it to change event. 
                //$(".adult").on('click', function(){
                $(".adult").on('change', function(){
                //Chandrakant - 2.0.4 - 04 June 2018 - END
                    if(jQuery(this).is(':visible')==true)
                    {
                       rowid=jQuery(this).attr('id').split("_");
                       methods._checkMaximumOccupancy(opt,rowid[2],rowid[3]); //Chandrakant - 2.0.12 - 23 October 2018 - Purpose : RES-1774                    
                       methods._calculateLongtayPrice(opt,rowid[2],rowid[3],'adult_click');
                       methods._showBookingSummary($(this).parents('.card-list'),opt);
                    }                                    
                  });
                 
                    $(".child").on('change', function(){                  
                        if(jQuery(this).is(':visible')==true)
                        {
                            rowid=jQuery(this).attr('id').split("_");                       
                            methods._checkMaximumOccupancy(opt,rowid[2],rowid[3]); //Maximum child capacity
                            methods._childagecombo(jQuery(this).val(),rowid[2],rowid[3]); //child age
                            methods._calculateLongtayPrice(opt,rowid[2],rowid[3],'child_click');
                            methods._showBookingSummary($(this).parents('.card-list'),opt);
                        }
                     });
                  
                    $(document.body).on('change', '.agecls', function(){
                        if(jQuery(this).is(':visible')==true)
                        {
                            rowid=jQuery(this).attr('id').split("_");
                            methods._calculateLongtayPrice(opt,rowid[1],rowid[2],'child_click');                       
                            methods._showBookingSummary($(this).parents('.card-list'),opt);
                            //child age error
                            jQuery(this).removeClass('error');//remove red color                      
                            if (jQuery(this).val()!='') {
                                // console.log(jQuery(this).parent().parent().parent().next().text(''));
                            }
                        }                                    
                    });
                  
                   //Store Login / visitor details
                   if(_quick_load==0)//flora
                   {
                        methods._ShowQuickNotes(opt); //flora
                        methods._InsertCheckAvail(opt);
                        //if (_selPromo!='') {                        
                        //    methods._InsertCheckAvail(opt); //Pinal                       
                        //}
                        _quick_load=1;
                   }                   
                   //if (_first_load==0) 
                   //{
                   //    methods._ShowOtherDetails(opt);                       
                   //    _first_load=1;
                   //}
                   /*if(opt.loadvisitor==true) //Pinal - 28 November 2019 - Purpose : Commented code for Mongodb removal, migrating to RDS
                   {
                        methods._storeAuthDetails(opt);
                        if(opt.ShowCurrencyCountryWise == 0)
                        {
                            console.log("get country nam1e");
                            methods._getCountryName(opt);//Chinmay Gandhi - Set currency list vise
                        }
                        opt.loadvisitor=false;
                   }*/
                   
                   methods._setExternalIframeHeight(opt);
                   //External Promo apply                    
		    
		    //More link on recent review - START
		    
		    //More link on recent review - END
                        //Chandrakant - 1.0.50.55 - 29 Sep 2016 - START
                        //Purpose : save for later
                        setTimeout(
                            function() {                                  
                                    if(savebtnarr.length!==0)
                                    {
                                        //console.log(allbtnarr);
                                        //console.log(savebtnarr);
                                        var uniquesavebtnarr=savebtnarr.filter(function(itm,i,a){
                                            return i==a.indexOf(itm);
                                        });
                                         //console.log(uniquesavebtnarr);
                                        jQuery.each(allbtnarr,function(i,v){
                                            if(jQuery.inArray(v,uniquesavebtnarr)=='-1')
                                            {
                                                if(jQuery("[id=addroombut_"+v+"]").is(":visible"))
                                                    jQuery("[id=addroombut_"+v+"]").trigger({ type: "click" });
                                            }
                                        });
                                        savebtnarr = [];
                                        allbtnarr = [];
                                    }
                                },
                            1000
                        );
                        //Chandrakant - 1.0.50.55 - 29 Sep 2016 - END
                },
                success: function( data ){
                    console.log("loadgrid success");
                    //jQuery("<div id='temp' style='display:block;'></div>").appendTo("div#eZ_BookingRooms");
                    //jQuery("#temp").html(data);
                    ////console.log($("#room").html());
                    jQuery('div#eZ_BookingRooms').html(data);
                    //jQuery("#temp").remove();
                    //console.log(jQuery(".pricewid-webprice").val());
                    
					if(typeof(resgrid[0])!='undefined' && resgrid[0]!=null) {                        
                        methods._calculateLongtayPrice(opt,'','','');   
                    }
                    
                    if(_first_load==0) 
                    {
                   //      console.log("call dddddddddddd...");
                         methods._ShowOtherDetails(opt);
                         methods._CountryCurrency(opt);//Chinmay Gandhi - 1.0.53.61 - Currency setting
                        _first_load=1;
                    }
                }
            });
			}
		    catch(err){
                hideLoadingBar();
                hideCheckavalabilityLoadingBar();
				console.log("Error - _loadGrid - "+err.message);                
		    }         
        },
        _ShowMoreLessMobileViewOnload:function(obj_id,opt){
            try{
                
                if($(window).width() <= 768){                                            
                        show_on_limit=-1;
                        $(obj_id).removeClass('read-more-content');
                        $(obj_id).removeClass('hidereadmore');
                        jQuery(".readmore_rtplan").hide();
                        jQuery(".moreless_a").hide();
                        jQuery('.moreless_div').hide();
               }
               var windowWidth = $(window).width();
               $(window).on('resize', function(){
                    if($(window).width() !== windowWidth){
                        if($(window).width() <= 768){                                           
                            show_on_limit=-1;
                            $(obj_id).removeClass('read-more-content');
                            $(obj_id).removeClass('hidereadmore');
                            jQuery(".readmore_rtplan").hide();
                            jQuery(".moreless_a").hide();
                            jQuery('.moreless_div').hide();
                        }
                        if($(window).width() >= 768){
                            if (opt.ResUILimitRatePlanPackage==1 && opt.ResUINoOfRatePlanPackage>=1) {
                                show_on_limit=1;
                                if($(obj_id).hasClass('tmp_read-more-content')==true)
                                {
                                     $(obj_id).addClass('read-more-content');
                                     jQuery(".read-more-content").addClass('hidereadmore');
                                     //jQuery(".readmore_rtplan").show();
                                     //jQuery(".moreless_a").show();
                                     $(obj_id).find('.moreless_div').show();
                                     $(obj_id).find('.moreless_a').show();
                                     $(obj_id).find('.readmore_rtplan').show();
                                }
                                else
                                {                            
                                     $(obj_id).find('.moreless_div').hide();
                                    // $(obj_id).find('.moreless_div').css('style','display:none;');
                                     //console.log("visible " + $(obj_id).find('.moreless_div').is(':visible'));
                                     $(obj_id).find('a.moreless_a').hide();
                                     $(obj_id).find('a.readmore_rtplan').hide();
                                }                          
                            }
                        }
                    }
               });
            }
            catch(err){
                hideLoadingBar();
                hideCheckavalabilityLoadingBar();
				console.log("Error - _ShowMoreLessMobileViewOnload - "+err.message);                
		    }  
        },
        _readmoretoggle_event:function(opt){
          try{            
                $('.read-more-toggle,.moreless_a').on('click', function(e) {
                        e.preventDefault();
                        //console.log("_readmoretoggle_event cal.....");
                        $(this).parent().parent().find('.read-more-content').toggleClass('hidereadmore');                        
                        var text = $(this).parent().parent().find('.readmore_rtplan').text();
                         //console.log("text : " + text);
                        // console.log($(this).parent().parent());
                         $(this).parent().parent().find('.readmore_rtplan').text(
                                text == js_stripslashes(langjs[0]["L_ReadMore"]) ? js_stripslashes(langjs[0]["L_ReadLess"]) : js_stripslashes(langjs[0]["L_ReadMore"]));
                         
                         if (text==js_stripslashes(langjs[0]["L_ReadMore"])) {
                        $(this).parent().parent().find('a.moreless_a').html('<i class="fa fa-angle-up"></i>');
                        }
                        else
                        {
                           $(this).parent().parent().find('a.moreless_a').html('<i class="fa fa-angle-down"></i>');
                        }
                        
                         $(this).parent().parent().find('a.readmore_rtplan').addClass('read-more-toggle');
                         methods._setExternalIframeHeight(opt); 
                });
          }
          catch(err)
          {
                hideLoadingBar();
                hideCheckavalabilityLoadingBar();
				console.log("Error - _readmoretoggle_event - "+err.message);    
          }
        },
        _ShowMoreLessMobileView:function(main_obj_id,opt){
            try{
                
                if($(window).width() <= 768){                        
                        show_on_limit=-1;
                        $(main_obj_id).find('a.readmore_rtplan').text('');
                        $(main_obj_id).find('a.readmore_rtplan').removeClass('read-more-toggle');
                        $(main_obj_id).find("a.moreless_a").html('');
                        $(main_obj_id).find('.moreless_div').hide();
                        }
                var windowWidth = $(window).width();
                $(window).on('resize', function(){
                    if($(window).width() !== windowWidth){
                        if($(window).width() <= 768){
                            show_on_limit=-1;
                            $(main_obj_id).find('a.readmore_rtplan').text('');
                            $(main_obj_id).find("a.moreless_a").html('');
                            $(main_obj_id).find('a.readmore_rtplan').removeClass('read-more-toggle');
                            $(main_obj_id).find('.moreless_div').hide();
                        }
                        if($(window).width() >= 768){
                        //alert("call");
                           //console.log("calllllll................................");
                           if (opt.ResUILimitRatePlanPackage==1 && opt.ResUINoOfRatePlanPackage>=1) {
                               show_on_limit=1;
                               if($(main_obj_id).find('a.readmore_rtplan').hasClass('tmp_read-more-toggle')==true)
                               {
                                    // alert("show");
                                    var text = js_stripslashes(langjs[0]["L_ReadMore"]);
                                    //console.log("sadasd " + text);
                                    if (text==js_stripslashes(langjs[0]["L_ReadMore"])) {
                                        $(main_obj_id).find('a.moreless_a').html('<i class="fa fa-angle-down"></i>');
                                    }
                                    else
                                    {
                                        $(main_obj_id).find('a.moreless_a').html('<i class="fa fa-angle-up"></i>');
                                    }                         
                                    $(main_obj_id).find('.readmore_rtplan').text(text);
                                    $(main_obj_id).find('a.readmore_rtplan').addClass('read-more-toggle');
                                    $(main_obj_id).find('.moreless_div').show();
                               }
                               else
                               {
                                  //console.log("else................................");
                                 // alert("hide");
                                  $(main_obj_id).find('.moreless_div').hide();
                                  $(main_obj_id).find('.moreless_div').css('style','display:none;');
                                  //console.log($(main_obj_id).find('.moreless_div').is(':visible'));
                                  $(main_obj_id).find('a.moreless_a').hide();
                                  $(main_obj_id).find('a.readmore_rtplan').hide();
                               }
                           }
                        }
                    }
               });
            }
            catch(err){
                hideLoadingBar();
                hideCheckavalabilityLoadingBar();
				console.log("Error - _ShowMoreLessMobileView - "+err.message);  
            }
        },
        _ShowClosestAvailability:function(opt){
            try{
                return false;//do not uncomment - flora
                    if(opt.ShowClosestAvailability==1){
                        var check_in=$("#eZ_chkin").datepicker('getDate');					
                        if(opt.ShowNights==true)
                           var nights=parseInt($("select#eZ_Nights").val());
                        else
                          var nights=parseInt($("input#eZ_Nights").val());
                        var checkin_date=$.datepicker.formatDate("yy-mm-dd ", check_in);
                        
                        //Flora - for chienese case it was not work
                        if (opt.selectedLang!='-zh-CN-Chinese' && opt.selectedLang!='-zh-TW-ChineseTraditional') {
                            var lang=opt.selectedLang.split("-");
                         }
                          else
                         {
                             var lang2=opt.selectedLang.split("-");
                                var lang=Array();
                                lang[2]=lang2[3];
                                lang[1]=lang2[1]+"-"+lang2[2];
                     
                         }
                         //Flora - for chienese case it was not work
                        //var lang=opt.selectedLang.split("-");//flora - - for chienese case it was not work - OLD
                        
                        
                        getUrl=opt.serverurl+'closestava-'+opt.HotelId;                        
                        $.ajax({
                        url: getUrl,
                        type: "POST",
                        dataType: "html",
                        data: {
                            HotelId: opt.HotelId,
                            chkin:checkin_date,
                            nights:nights,
                            lang_code:lang[1],
                            langtext:lang[2],
                            roomrateunkid:"",
                            specialunkid:"",
                            travelid:$("input[id$='request_TA']").val(),
                            ArrivalDate:$("input[id$='ArrvalDt']").val()
                            },
                            error: function(){
                            },
                            beforeSend: function(){
                                //showLoadingBar();
                                $("#smallloader").show();
                            },
                            complete: function(){
                                $("#smallloader").hide();
                                //hideLoadingBar();
                            },
                            success: function( data ){
                                $("#closestava_norec").html(data);                           
                            }                        
                        });
                    }                    
            }
           catch(err){
                hideLoadingBar();
                hideCheckavalabilityLoadingBar();
				console.log("Error - _ShowClosestAvailability - "+err.message);                
		    }  
        },
	_loadLoadingBar:function(){
            if($("#loadingbar").length<=0){
                $('<div class="transbg" style="display:none;"></div><div class="loadingbar" id="loadingbar" style="display:none;"><div class="loadspan"><div class="loader size-1"></div></div></div>').appendTo("body");
            }
        },
        _ShowOtherDetails:function(opt){
			try
			{
                //Pinal - 6 April 2018 - START
                //Purpose : Booking Engine Optimization
                var param='HotelId='+opt.HotelId+'&action=getnotifications&rb='+opt.isShowRecentBooking+'&gcw='+opt.isShowTotalGuestCountryWise+'&beview='+opt.isShowBEViewers;
                
                $.get(opt.serverurl+'service.php?'+param, function(data) {
                    
                    var response=JSON.parse(data);
                    
                    
                    if (opt.isShowRecentBooking==1 && response.TotRecentBooking!=undefined && response.TotRecentBooking!='') {
                        console.log("FindRecentbooking");
                                FindRecentbooking(response.TotRecentBooking);
                    }
                    if (opt.isShowTotalGuestCountryWise==1 && response.GuestCount!=undefined && response.GuestCount!='') {
                        console.log("isShowTotalGuestCountryWise");
                        var tmp_res=response.GuestCount;
                        var res=tmp_res.split("-");
                        TotalGuestCountryWise(res[0],res[1]);
                    }
                    
                    if(response.review!='')
                    {
                        console.log("review");
                        var tmp_res=response.review;
                        var res=tmp_res.split("|");
                        TotalReviewAlerts(res[0],res[1],res[2]);
                    }
                    
                    if (opt.isShowBEViewers==1 && response.ViewersCnt!=undefined && response.ViewersCnt!='') {
                        console.log("isShowBEViewers");
                        checkHowManyViewers(response.ViewersCnt);
                    }
                    
				});

//				if (opt.isShowRecentBooking==1) {
//					$.get(opt.serverurl+'service.php?HotelId='+opt.HotelId+'&action=recentbooking', function(data) {
//						if(data!='')
//							FindRecentbooking(data);
//					});
//				}
//				if (opt.isShowTotalGuestCountryWise==1) {
//					$.get(opt.serverurl+'service.php?HotelId='+opt.HotelId+'&action=countryguestcount', function(data) {
//						if(data!='')
//						{
//							res=data.split("-");
//							TotalGuestCountryWise(res[0],res[1]);
//						}					
//					});
//				}
//                //review alert
//                $.get(opt.serverurl+'service.php?HotelId='+opt.HotelId+'&action=reviewalert', function(data) {
//                    if(data!='')
//                    {
//                        res=data.split("|");
//                        TotalReviewAlerts(res[0],res[1],res[2]);
//                    }					
//                });
//                // console.log(opt.isShowBEViewers);        
//				if (opt.isShowBEViewers==1) {	
//					$.get(opt.serverurl+'service.php?HotelId='+opt.HotelId+'&action=viewers', function(data) {
//						if(data!='')
//							checkHowManyViewers(data);
//					});
//				}
				
                //Pinal - 6 April 2018 - END
			}
			catch(e)
			{
				console.log("Error in _ShowOtherDetails"+ e);	
			}
		},
        _curr_time:function(){
            var d = new Date();
            var time = d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
            return time;
        },
        _childagecombo:function(obj,rowid2,rowid3){
          try{
                var res_AllowChildAgePolicy=eval("resgrid[0][0].AllowChildAgePolicy");
                var res_WebMaxChildAge=eval("resgrid[0][0].WebMaxChildAge");
                var res_WebChildAge=eval("resgrid[0][0].WebChildAge");
                                        
                if(res_AllowChildAgePolicy==0 && res_WebMaxChildAge!='' && res_WebChildAge!='')
                {										
                    totcmt=parseInt(obj);
                    //console.log(obj);
                    
                    var childlbl='<div class="vres-chk-box"><span>'+js_stripslashes(langjs[0]["L_select_child2"])+'</span>';
                    var L_age='<span>'+js_stripslashes(langjs[0]["L_Age2"])+'</span>';
                    
                    var childage_html='';                    
                    jQuery("#childage_div_"+rowid2+"_"+rowid3).html('');
                    
                    for(child_cnt=0;child_cnt<totcmt;child_cnt++)
                    {
                        //console.log("fill child");
                        result_html=childlbl.replace("[childno]",(child_cnt+1));
                        var child_html_data=fillChildStaticCombo(0,res_WebMaxChildAge,"age_"+rowid2+"_"+rowid3+"_"+child_cnt,'',L_age,rowid2,child_cnt,result_html,rowid3);
                        childage_html=childage_html+child_html_data;
                    }                    
                    $(childage_html).appendTo(jQuery("#childage_div_"+rowid2+"_"+rowid3));
                }
          }catch(err){
                hideLoadingBar();
                hideCheckavalabilityLoadingBar();
				console.log("Error - _childagecombo - "+err.message);    
          }
        },
        _showBookingSummary:function(sel_called,opt){
             try{
                
                iscurrencyprefix=false;
                var sel_called_t=sel_called.attr('id');
                var sel_called_y=sel_called_t.split("_");                
                var mul_inx_layout=sel_called_y[1];
                
                if(resgrid[0][mul_inx_layout].curr_prefix=="1"){
                    iscurrencyprefix=true;
                }
                
                var selectedrooms=$('input[id^=rooms_]').filter(function(){
                    if (jQuery(this).is(':visible')) {                                             
                       return $(this).val()>0;
                    }                    
                });               
                //console.log("room " + selectedrooms.length);
                                
                grand_total=0;
                total_rooms=0;
                //valid_sel=-1;
                var res;
                var L_adult_lbl='';
                var L_child_lbl='';
                var L_rooms_lbl='';
                var html_content='';
               
                jQuery("#summ_add").html('');
                jQuery("#checkin_summ_blk").hide();
                jQuery("#night_summ_blk").hide();
                          
                //Delete row if rom not selected
                if(jQuery('.ad-rm').is('visible')==false)
                {
                    jQuery("#summ_row_"+mul_inx_layout).remove();
                }
                
                //kishan Tailor 30 April 2019 purpose:To solve Issue While remove Room calculate Room limit START
                if(selectedrooms.length==0)
                    $("#roombooklimitcal").val(0);
                //kishan Tailor 30 April 2019 purpose:Issue While remove Room calculate Room limit END
                
                if(selectedrooms.length>0){
                    jQuery("#room_summary").show();
                    jQuery("#vres-total").show();
                    jQuery("#no_room_selected").hide();
                
                    var tot_rooms = 0;//Chinmay Gandhi - 1.0.53.61 - UX changes : release_add-room-footer
                
                    jQuery.each(selectedrooms,function(i){						
                        bookbtnid=$(this).attr('id');
                        var _rowd=bookbtnid.split("_");
                        var rowid='row_'+_rowd[1];
                        var rooms=$(this).val();
                                            
//                        res=_bookingProcess_multipleroomtype(rowid,_rowd[1],rooms);
//                        if(res!=false){                            
//                            valid_sel++;                    
//						}
                        
                        var istax=0;
                        if(eval('resgrid[0]['+_rowd[1]+'].ShowTaxInclusiveExclusiveSettings')=="1"){
                           // console.log(eval('resgrid[0]['+_rowd[1]+'].Tax_1'));
                           // console.log(typeof(eval('resgrid[0]['+_rowd[1]+'].Tax_1')));
                            if (//eval('resgrid[0]['+_rowd[1]+'].Tax_0')!=undefined ||
                                typeof(eval('resgrid[0]['+_rowd[1]+'].Tax_1'))!='undefined')
                            {
                               istax=1; 
                            }
                        }
                        //console.log("istax : " + istax);
                        
                        total_rooms+=parseInt(rooms);
			
                        if (istax==1) {
                            total_amount=methods._applyRoundOff(parseFloat(resgrid[0][_rowd[1]]['total_amount']));//*parseInt(rooms);
                        }
                        else
                            total_amount=(parseFloat(resgrid[0][_rowd[1]]['total_amount']));//*parseInt(rooms);
                        
                        total_amount = parseFloat(parseFloat(total_amount).toFixed(_display_decimalplaces));
                        //Grand Total
                        grand_total+=total_amount;                        
                        
                        //Room Total
                        total_amount=addCommas((parseFloat(total_amount)).toFixed(_display_decimalplaces));           
                        id2=jQuery("#row_"+_rowd[1]).find('.room_row').attr('id').split("room_row_");
                        
                        display_name=eval("resgrid[0]["+_rowd[1]+"].display_name");
                        
                        //No of adult
                        no_adults=0;                        
                        jQuery("select[id^=rm_adult_"+_rowd[1]+"_]").each(function(){                           
                           no_adults=no_adults+parseInt(jQuery(this).val());
                        });
                        if (no_adults>1)                           
                            L_adult_lbl=js_stripslashes(langjs[0]["L_Adultlbl5"]); 
                        else
                            L_adult_lbl=js_stripslashes(langjs[0]["L_Adultlbl4"]); 
                        
                        //No of child                        
                        no_child=0;
                        jQuery("select[id^=rm_child_"+_rowd[1]+"_]").each(function(){                           
                           no_child=no_child+parseInt(jQuery(this).val());
                        });
                        //L_child_lbl=js_stripslashes(langjs[0]["L_Child2"]);
                        //Chinmay Gandhi - 1.0.52.57 - Start - set childern
                        if (no_child>1)                           
                            L_child_lbl=js_stripslashes(langjs[0]["L_Child3"]);
                        else
                            L_child_lbl=js_stripslashes(langjs[0]["L_Child2"]);
                        //Chinmay Gandhi - 1.0.52.57 - End
                        
                        //No of rooms
                        no_rooms=jQuery("#rooms_"+_rowd[1]).val();
                        if (no_rooms>1)                           
                            L_rooms_lbl=js_stripslashes(langjs[0]["L_Roomcap1"]); 
                        else
                            L_rooms_lbl=js_stripslashes(langjs[0]["L_Roomcap"]); 
                        
                        html_content=$('#summary_row_clone').html();
                        result_html=html_content.replace("[rtplanname]",display_name);
                        result_html=result_html.replace("[adult_tot_room]",no_adults+' '+L_adult_lbl);
                        if(opt.showchild==true)
                            result_html=result_html.replace("[child_tot_room]",' '+no_child+' '+L_child_lbl);
                        else
                            result_html=result_html.replace("[child_tot_room]",'');
                            
                        result_html=result_html.replace("[room_tot]",' '+no_rooms+' '+L_rooms_lbl);
                        
                        tot_rooms = tot_rooms + parseInt(no_rooms);//Chinmay Gandhi - 1.0.53.61 - UX changes : release_add-room-footer
                        
                        //Set Currency
                        if(iscurrencyprefix){
                            result_html=result_html.replace("[precurreny]",resgrid[0][0]['MultiplyCurrSymbl']);
                            result_html=result_html.replace("[postcurreny]",'');                           
                        }
                        else
                        {
                            result_html=result_html.replace("[precurreny]",'');
                            result_html=result_html.replace("[postcurreny]",resgrid[0][0]['MultiplyCurrSymbl']);                            
                        }                       
                        
                        result_html=result_html.replace("[room_amt]",total_amount);
                        result_html=result_html.replace("[delete_row]","delete_row_"+_rowd[1]);
                        result_html=result_html.replace("[edit_row]","edit_row_"+_rowd[1]);
                        
                        jQuery("#summ_add").html(jQuery("#summ_add").html()+'<li id=summ_row_'+_rowd[1]+' class="highlight_ths_row">'+result_html+'</li>');
                        jQuery("#checkin_summ_blk").show();
                        jQuery("#night_summ_blk").show();
                
                        //Chinmay Gandhi - 1.0.53.61 - UX changes : release_add-room-footer - Start
                        if(iscurrencyprefix)
                            dis_grand_total="<span class='currency'>"+resgrid[0][0]['MultiplyCurrSymbl']+
                                            "</span> "+addCommas((parseFloat(grand_total)).toFixed(_display_decimalplaces));
                        else
                            dis_grand_total=addCommas((parseFloat(grand_total)).toFixed(_display_decimalplaces))
                                            +" <span class='currency'>"+resgrid[0][0]['MultiplyCurrSymbl']+"</span>";
                        //Chinmay Gandhi - 1.0.53.61 - UX changes : release_add-room-footer - End
                        
                        jQuery("#grand_total").html(dis_grand_total);
                    });
                    jQuery('#totroomcnt').val(tot_rooms);//Chinmay Gandhi - 1.0.53.61 - UX changes : release_add-room-footer
                    
		    if ($("#roombooklimit").val()!='') //Pinal - 1.0.50.55 - 23 August 2016 - Purpose - Allow booker to book only specified no of rooms at a time.
			    $("#roombooklimitcal").val(total_rooms);
			    
                    //check in , check out , nights
                    var checkin=$("#eZ_chkin").datepicker('getDate');					
                    var checkout=$("#eZ_chkin").datepicker('getDate');
					if(opt.ShowNights==true)
	                    checkout.setDate(checkin.getDate()+parseInt($("select#eZ_Nights").val()));
					else
						checkout.setDate(checkin.getDate()+parseInt($("input#eZ_Nights").val()));
                    
                    //Parth - 18 Apr 2019 - RES-1899 - For add configured date format. - START
                    $("span#_checkin").html(resgrid[0][0].dis_checkindate);//Chinmay Gandhi - 1.0.53.61 - UX changes : release_add-room-footer
                    $("span#_checkout").html(resgrid[0][0].dis_checkoutdate);//Chinmay Gandhi - 1.0.53.61 - UX changes : release_add-room-footer
                    //Parth - 18 Apr 2019 - RES-1899 - END
                    
                    /*$("span#_checkin").html($.datepicker.formatDate("dd M", checkin) + '<span class="chk_year">'+$.datepicker.formatDate(" yy ", checkin)+'</span>');//Chinmay Gandhi - 1.0.53.61 - UX changes : release_add-room-footer
                    $("span#_checkout").html($.datepicker.formatDate("dd M", checkout) + '<span class="chk_year">'+$.datepicker.formatDate(" yy ", checkout)+'</span>');//Chinmay Gandhi - 1.0.53.61 - UX changes : release_add-room-footer*/
                    
					if(opt.ShowNights==true)
                    {
	                    $("span#_totalnights").html($("select#eZ_Nights").val());
                        if($("select#eZ_Nights").val() > 1)
                            jQuery("span#_nightlbl").text(js_stripslashes(langjs[0]["L_Nightlblm"]));
                        else
                           jQuery("span#_nightlbl").text(js_stripslashes(langjs[0]["L_Nightlbls"]));
                    }
					else
                    {                        
						 $("span#_totalnights").html($("input#eZ_Nights").val());
                         if($("input#eZ_Nights").val() > 1)
                            jQuery("span#_nightlbl").text(js_stripslashes(langjs[0]["L_Nightlblm"]));
                        else
                            jQuery("span#_nightlbl").text(js_stripslashes(langjs[0]["L_Nightlbls"]));
                    }
                    
                    $("[id^=delete_row_]").on('click', function(){
                        var ava_clicked=methods._check_availability_clicked(opt);
                        if (ava_clicked==false) {
                            return false;
                        }
                           jQuery(this).parent().remove();
                           var del=jQuery(this).attr('id').split("delete_row_");                                                       
                           jQuery("[id^=room_row_"+del[1]+"_]").each(function(){                                
                                jQuery("#rooms_"+del[1]).parent().find('.remove_field_button').click();
                           });
                    });
                    
                    //summary edit section
                    $('.highlight_ths_row').find('a').on('click', function(){
                        jQuery(".highlight_ths_row").each(function(){
                            jQuery(this).removeClass('edit-row');
                        });                        
                        $(this).parents('.highlight_ths_row').addClass('edit-row');
                        
                    });
                    
                    $("[id^=edit_row_]").on('click', function(){
                        var ava_clicked=methods._check_availability_clicked(opt);
                        if (ava_clicked==false) {
                            return false;
                        }
                           var editid=jQuery(this).attr('id').split("edit_row_");                                                       
                           //jQuery("[id^=rooms_"+editid[1]).focus();
                           jQuery("[id^=row_"+editid[1]+"]").focus();
                           jQuery('html, body').animate({
                                scrollTop: jQuery("[id^=row_"+editid[1]+"]").offset().top - 34
                            }, 800);
                           window.location.hash = '#row_'+editid[1];
                           
                           jQuery("[id^=row_"+editid[1]+"]").removeClass('h_row_set').addClass('h_row_set');
                           //jQuery("[id^=row_"+editid[1]).css({
                           // 'border':'1px solid #ff0000',
                           // 'border-radius':'0 0 3px 3px'
                           // });
                           
                           var obj_row=jQuery(this);
                           
                           setTimeout(
                                function() {                                  
                                    //jQuery("[id^=row_"+editid[1]).css({
                                    //    'border':'1px solid #ccc'
                                    //    });
                                    jQuery("[id^=row_"+editid[1]+"]").removeClass('h_row_set');
                                    $(obj_row).parents().find('.edit-row').removeClass('edit-row');                                    
                                    },
                                2000
                            );
                            methods._setIframeEditRecord(jQuery("[id^=row_"+editid[1]+"]").offset().top);
                    });
                 }
                 else
                 {
                    //hide summary block
                    jQuery('#totroomcnt').val(0);
                    jQuery("#room_summary").hide();
                    jQuery("#vres-total").hide();
                    jQuery("#no_room_selected").show();
                 }
             }
             catch(err){
                hideLoadingBar();
                hideCheckavalabilityLoadingBar();
				console.log("Error - _showBookingSummary - "+err.message);
		    }   
        },
        _setRoomLeftLbl:function(para1,maxrooms){
          try{           
             sel_rooms=0;
             jQuery("[id^=otatablview_"+para1+"]").find('.ava_rooms').each(function(){
                if(jQuery(this).is(":visible"))
                    sel_rooms=sel_rooms+parseInt(jQuery(this).val());
             });             
             ava_rooms=maxrooms-sel_rooms;
             
             jQuery("[id^=otatablview_"+para1+"]").find('.ava_rooms').each(function(){
             if (ava_rooms!=0)
                jQuery(this).parent().parent().parent().find('.leftroomcls').text(ava_rooms);
             else
                jQuery(this).parent().parent().parent().find('.leftroomcls').text('');
             
               if(ava_rooms==1)
               {
                    var message2 = js_stripslashes(langjs[0]["L_Only2"]);
                    jQuery(this).parent().parent().parent().find('.leftroomcls_2').text(message2);
                    jQuery(this).parent().parent().parent().find('.leftroomcls_3').text('');
                    
                    var message = js_stripslashes(langjs[0]["L_RoomLeft1"]);                
                    jQuery(this).parent().parent().parent().find('.leftroomcls2').addClass('redclr');
                    jQuery(this).parent().parent().parent().find('.leftroomcls').addClass('redclr');
                    jQuery(this).parent().parent().parent().find('.leftroomcls5').text('');
               }
               else if (ava_rooms==0)
               {
                    var message = js_stripslashes(langjs[0]["Noroomsleftlbl"]);                
                    jQuery(this).parent().parent().parent().find('.leftroomcls2').addClass('redclr');
                    jQuery(this).parent().parent().parent().find('.leftroomcls').addClass('redclr');
                    
                    jQuery(this).parent().parent().parent().find('.leftroomcls4').text('');
                    jQuery(this).parent().parent().parent().find('.leftroomcls5').text('');
                    jQuery(this).parent().parent().parent().find('.leftroomcls_2').text('');
                    jQuery(this).parent().parent().parent().find('.leftroomcls_3').text('');
               }
               else
               {               
                if (parseInt(jQuery(this).parent().parent().parent().find('.MaxInvLimitedRoomsAvailablecls').text())!='' && ava_rooms<=parseInt(jQuery(this).parent().parent().parent().find('.MaxInvLimitedRoomsAvailablecls').text())) {
                    var message2 = js_stripslashes(langjs[0]["Hurrylbl"]);
                    jQuery(this).parent().parent().parent().find('.leftroomcls_3').text(message2);
                    jQuery(this).parent().parent().parent().find('.leftroomcls5').text('');
                    jQuery(this).parent().parent().parent().find('.leftroomcls_2').text('');
                }
                else{                    
                    jQuery(this).parent().parent().parent().find('.leftroomcls_3').text('');
                }
                
                var message = js_stripslashes(langjs[0]["L_RoomLeft2"]);
                jQuery(this).parent().parent().parent().find('.leftroomcls2').removeClass('redclr');
                jQuery(this).parent().parent().parent().find('.leftroomcls').removeClass('redclr');
               }
               
               jQuery(this).parent().parent().parent().find('.leftroomcls2').text(message);               
             });
          }
          catch(err)
          {
             hideLoadingBar();
			console.log("Error - _setRoomLeftLbl - "+err.message);
          }
        },
        _checkMaximumOccupancy:function(opt,row_id_val,itmcnt_val){
            try{
                if(typeof(resgrid[0])!='undefined' && resgrid[0]!=null)
                {
                    var maximum_occupancy_val= (eval("resgrid[0]["+row_id_val+"].max_occupancy"));
                    
                    if (maximum_occupancy_val!='' && maximum_occupancy_val!=0)
                    {
                        var maximum_occupancy= parseInt(maximum_occupancy_val);
                        var maximum_adult= eval("resgrid[0]["+row_id_val+"].max_adult");
                        var maximum_child= eval("resgrid[0]["+row_id_val+"].max_child");
                        var m_base_adult= eval("resgrid[0]["+row_id_val+"].base_adult");
                        var m_base_child= eval("resgrid[0]["+row_id_val+"].base_child");
                    
                        var selected_adult_val=parseInt(jQuery("#rm_adult_"+row_id_val+"_"+itmcnt_val).val());
                        var selected_child_val=parseInt(jQuery("#rm_child_"+row_id_val+"_"+itmcnt_val).val());
                        var selected_val=parseInt(selected_adult_val)+parseInt(selected_child_val);                        
                        
                        var PaxOnMaximumOccupancy= eval("resgrid[0][0].ResUIPaxOnMaximumOccupancy");
                        if (parseInt(selected_val) > parseInt(maximum_occupancy)) {
                            //console.log('--------->1');
                            var message=js_stripslashes(langjs[0]["L_MaxOccNote"]).replace("{number}",maximum_occupancy);
                            var title_message=js_stripslashes(langjs[0]["lblErrordate"]);                           
                            swal({
                                title: '',//title_message,
                                text: message,
                                type: 'warning',
                                showCancelButton: false,
                                animation:false																					
                             });    
                            hideLoadingBar();

                            //Chandrakant - 2.0.12 - 17 October 2018 - START
                            //Purpose : RES-1774
                            if (PaxOnMaximumOccupancy=='1')
                            {
                               if(maximum_occupancy>maximum_adult)
                                    jQuery("#rm_adult_"+row_id_val+"_"+itmcnt_val).val(maximum_adult);
                                else
                                    jQuery("#rm_adult_"+row_id_val+"_"+itmcnt_val).val(maximum_occupancy);
                                    
                                if (maximum_occupancy>=maximum_adult)
                                {
                                    var selected_child_1=maximum_occupancy-maximum_adult;
                                    if (selected_child_1>maximum_child)
                                    {
                                        jQuery("#rm_child_"+row_id_val+"_"+itmcnt_val).val(maximum_child);    
                                    }
                                    else
                                        jQuery("#rm_child_"+row_id_val+"_"+itmcnt_val).val(selected_child_1);    
                                }
                                else
                                    jQuery("#rm_child_"+row_id_val+"_"+itmcnt_val).val(0);
                            }
                            else
                            {
                                jQuery("#rm_adult_"+row_id_val+"_"+itmcnt_val).val(m_base_adult);
                                jQuery("#rm_child_"+row_id_val+"_"+itmcnt_val).val(m_base_child);    
                            }
                            //Chandrakant - 2.0.12 - 17 October 2018 - END
                            
                            
                            var selected_adult_val=parseInt(jQuery("#rm_adult_"+row_id_val+"_"+itmcnt_val).val());
                            var selected_child_val=parseInt(jQuery("#rm_child_"+row_id_val+"_"+itmcnt_val).val());
                            
                            methods._childagecombo(selected_child_val,row_id_val,itmcnt_val);
                        }
                        if (selected_adult_val > maximum_adult) {
                            //console.log('--------->2');
                            //Chandrakant - 2.0.12 - 17 October 2018 - START
                            //Purpose : RES-1774
                            //jQuery("#rm_adult_"+row_id_val+"_"+itmcnt_val).val(m_base_adult);
                            if (PaxOnMaximumOccupancy=='1')
                            {
                                if(maximum_occupancy>maximum_adult)
                                    jQuery("#rm_adult_"+row_id_val+"_"+itmcnt_val).val(maximum_adult);
                                else
                                    jQuery("#rm_adult_"+row_id_val+"_"+itmcnt_val).val(maximum_occupancy);
                                    
                                if (maximum_occupancy>=maximum_adult)
                                {
                                    var selected_child_1=maximum_occupancy-maximum_adult;
                                    if (selected_child_1>maximum_child)
                                    {
                                        jQuery("#rm_child_"+row_id_val+"_"+itmcnt_val).val(maximum_child);    
                                    }
                                    else
                                        jQuery("#rm_child_"+row_id_val+"_"+itmcnt_val).val(selected_child_1);    
                                }
                                else
                                    jQuery("#rm_child_"+row_id_val+"_"+itmcnt_val).val(0);
                            }
                            else
                            {
                                jQuery("#rm_adult_"+row_id_val+"_"+itmcnt_val).val(m_base_adult);
                                jQuery("#rm_child_"+row_id_val+"_"+itmcnt_val).val(m_base_child);    
                            }
                            methods._childagecombo(jQuery("#rm_child_"+row_id_val+"_"+itmcnt_val).val(),row_id_val,itmcnt_val);
                            //Chandrakant - 2.0.12 - 17 October 2018 - END
                            var message=js_stripslashes(langjs[0]["L_MaxAdultAllowed"]).replace("{number}",maximum_adult);
                            var title_message=js_stripslashes(langjs[0]["lblErrordate"]);                           
                            swal({
                                title: '',//title_message,
                                text:message,// message,
                                type: 'warning',
                                showCancelButton: false,
                                animation:false																					
                             });   
                           
                            hideLoadingBar();                            
                        }
                        if (selected_child_val > parseInt(maximum_child)) {
                            //console.log('--------->3');
                            //Chandrakant - 2.0.12 - 17 October 2018 - START
                            //Purpose : RES-1774
                            if (PaxOnMaximumOccupancy=='1')
                            {
                                if(maximum_occupancy>maximum_adult)
                                    jQuery("#rm_adult_"+row_id_val+"_"+itmcnt_val).val(maximum_adult);
                                else
                                    jQuery("#rm_adult_"+row_id_val+"_"+itmcnt_val).val(maximum_occupancy);
                                    
                                if (maximum_occupancy>=maximum_adult)
                                {
                                    var selected_child_1=maximum_occupancy-maximum_adult;
                                    if (selected_child_1>maximum_child)
                                    {
                                        jQuery("#rm_child_"+row_id_val+"_"+itmcnt_val).val(maximum_child);    
                                    }
                                    else
                                        jQuery("#rm_child_"+row_id_val+"_"+itmcnt_val).val(selected_child_1);    
                                }
                                else
                                    jQuery("#rm_child_"+row_id_val+"_"+itmcnt_val).val(0);
                            }
                            else
                            {
                                jQuery("#rm_child_"+row_id_val+"_"+itmcnt_val).val(m_base_child);
                                jQuery("#rm_adult_"+row_id_val+"_"+itmcnt_val).val(m_base_adult);    
                            }
                            methods._childagecombo(jQuery("#rm_child_"+row_id_val+"_"+itmcnt_val).val(),row_id_val,itmcnt_val);
                            //Chandrakant - 2.0.12 - 17 October 2018 - END
                            var message=js_stripslashes(langjs[0]["L_MaxChildAllowed"]).replace("{number}",maximum_child);
                            var title_message=js_stripslashes(langjs[0]["lblErrordate"]);                           
                            swal({
                                title: '',//title_message,
                                text: message,
                                type: 'warning',
                                showCancelButton: false,
                                animation:false																					
                             });   
                            hideLoadingBar(); 
                        }
                    }
                }
            }
             catch(err)
            {
               hideLoadingBar();
               hideCheckavalabilityLoadingBar();
               console.log("Error - _checkMaximumOccupancy - "+err.message);
            }
        },
        _calculateLongtayPrice:function(opt,row_id_val,itmcnt_val,call_flag){
            try{
                //alert($("#ratemode").val());
                console.log("call _calculateLongtayPrice...");                
                methods._setExternalIframeHeight(opt);
                
                _is_special=true;
                _is_promos=true;
                _total=0.00;
                _final_total=0.00;
                _final_strike_total=0.00;
				_striketotal=0.00;
                            
                var ret_default_val;
                var retstrike_default_val;
                
                _currency_symbol='';
                var rateplan;
                
                /*Set extra adult & child rates while change on adult & child*/
                execute_block=true;
                init_cnt=0;
                
                if (row_id_val!='') {
                    execute_block=false;
                    init_cnt=1;
                }
                
                 /*Set extra adult & child rates while change on adult & child*/
                var row_count_val='';
                //kishan Tailor 10th september 2018 purpose:triptease Price Widget integration START
                if(opt.TripteasehotelKey!='' && opt.TripteasehotelAPIkey !='' && call_flag=='')
                {
                var directarray=[];
                var ratenamearray=[];
                }
                //kishan Tailor 10th september 2018 purpose:triptease Price Widget integration END
                
                var promosarray=[];//Parth - 11 Jan 2019 - PromoDailyDiscount - Apply daily discount on promotion.
                
                jQuery.each(resgrid[0],function(ind){
                     
                    if (execute_block==false && init_cnt==1) {
                        ind=row_id_val;                        
                    }
                    row_count_val=ind;
                    var dealsarray=[];//kishan Tailor 24 Jun 2019 purpose :Package Overlapping RES-1800
                    //Parth - 11 Jan 2019 - PromoDailyDiscount - Apply daily discount on promotion. - START
                    for(var i_promo=1;i_promo<=eval("resgrid[0]["+ind+"].DayCnt");i_promo++)
                    {
                        promoval = eval("resgrid[0]["+ind+"].nightrule_"+i_promo);
                        if (promoval!=undefined && promoval!=null && promoval!='' ) promosarray.push(promoval);
                        dealval = eval("resgrid[0]["+ind+"].deals_"+i_promo);
                        if (dealval!=undefined && dealval!=null && dealval!='' ) dealsarray.push(dealval);//kishan Tailor 24 Jun 2019 purpose :Package Overlapping RES-1800
                    }
                    //Parth - 11 Jan 2019 - PromoDailyDiscount - END
		            spfm=eval("resgrid[0]["+ind+"].deals");
                    //promos=eval("resgrid[0]["+ind+"].nightrule");//Parth - 11 Jan 2019 - PromoDailyDiscount - Apply daily discount on promotion(Put into comment).
					SortPriceWise=eval("resgrid[0]["+ind+"].SortPriceWise");
                    
					if($("#row_"+ind).hasClass('otartrow')==true || SortPriceWise==1)//sort price issue in strike value for list view - flora
					{
					 _is_special=true;//added for solving issue of OTA interface while rearrange creating issue
					 _is_promos=true;//added for solving issue of OTA interface while rearrange creating issue
					}
					
                    if((typeof(spfm)=='undefined' || spfm=='' || spfm==null))
                        _is_special=false;
                    if(dealsarray[ind]==undefined && dealsarray[ind]=='')
                        _is_special=false;
                    if(promosarray[ind]==undefined && promosarray[ind]=='')//Parth - 11 Jan 2019 - PromoDailyDiscount - Apply daily discount on promotion.(Add promosarray) ((typeof(promos)=='undefined' || promos=='' || promos==null) &&)
                        _is_promos=false;
                        
                    if(dealsarray.length==0) _is_special=false;
                    if(promosarray.length==0) _is_promos=false;
                    
                    rateplan=eval("resgrid[0]["+ind+"]");
                    //console.log("after call _calculateLongtayPrice..."+parseInt(rateplan['NotAvailable']));
					if(parseInt(rateplan['NotAvailable'])<=0)
                    {
					    if(_is_special==false && _is_promos==false){
                        // Sheetal Panchal - 1.0.52.60 - 10th July 2017, Purpose - For Non linear rate mode setting - START
                        //console.log(row_id_val,"---",itmcnt_val,"---",ind,"---",row_count_val);
                        // console.log($("#ratemode").val());
                        
                            if($("#ratemode").val()=='NONLINEAR')
                            {
                                ret_default_val=methods._defaultLongStayRateCalculation_NL(opt,rateplan,row_id_val,itmcnt_val,ind,row_count_val,opt.showchild).split("|");
                                 
                            }
                            else
                                ret_default_val=methods._defaultLongStayRateCalculation(rateplan,row_id_val,itmcnt_val,ind,row_count_val,opt.showchild).split("|");
                                
                            _total = parseFloat(parseFloat(ret_default_val[0]).toFixed(_display_decimalplaces));
                        
                            if($("#ratemode").val()=='NONLINEAR')        
                                retstrike_default_val=methods._defaultLongStayRateCalculation_NL(opt,rateplan,row_id_val,itmcnt_val,ind,row_count_val,opt.showchild).split("|");
                            else
                                retstrike_default_val=methods._defaultLongStayRateCalculation(rateplan,row_id_val,itmcnt_val,ind,row_count_val,opt.showchild).split("|");
                            // Sheetal Panchal - 1.0.52.60 - 10th July 2017, Purpose - For Non linear rate mode setting - END
                            _striketotal = retstrike_default_val[0];
                            
					    }
                        else
                        {
                            //console.log("_is_special" + _is_special );
                            //console.log("_is_promos" + _is_promos );
                            if($("#ratemode").val()=='NONLINEAR')   
                                ret_default_val=methods._specialLongStayRateCalculation_NL(opt,rateplan,dealsarray,promosarray,row_id_val,itmcnt_val,row_count_val,opt.showchild).split("|");//Parth - 11 Jan 2019 - PromoDailyDiscount - Apply daily discount on promotion.(promos to promosarray)//kishan Tailor 24 Jun 2019 purpose :Package Overlapping(spfm to dealsarray) RES-1800
                            else
                                ret_default_val=methods._specialLongStayRateCalculation(rateplan,dealsarray,promosarray,row_id_val,itmcnt_val,row_count_val,opt.showchild).split("|");//Parth - 11 Jan 2019 - PromoDailyDiscount - Apply daily discount on promotion.(promos to promosarray)//kishan Tailor 24 Jun 2019 purpose :Package Overlapping(spfm to dealsarray) RES-1800
                            _total = parseFloat(parseFloat(ret_default_val[0]).toFixed(_display_decimalplaces));
                            //console.log("ret_default_val " + ret_default_val);
                            if($("#ratemode").val()=='NONLINEAR')   
                                retstrike_default_val=methods._specialStrikeLongStayRateCalculation_NL(opt,rateplan,dealsarray,promosarray,row_id_val,itmcnt_val,row_count_val,opt.showchild).split("|");//Parth - 11 Jan 2019 - PromoDailyDiscount - Apply daily discount on promotion.(promos to promosarray)//kishan Tailor 24 Jun 2019 purpose :Package Overlapping(spfm to dealsarray) RES-1800
                            else
                                retstrike_default_val=methods._specialStrikeLongStayRateCalculation(rateplan,dealsarray,promosarray,row_id_val,itmcnt_val,row_count_val,opt.showchild).split("|");//Parth - 11 Jan 2019 - PromoDailyDiscount - Apply daily discount on promotion.(promos to promosarray)//kishan Tailor 24 Jun 2019 purpose :Package Overlapping(spfm to dealsarray) RES-1800
			  				_striketotal = retstrike_default_val[0];                            
				        }
			          // console.log(ret_default_val);
                        if(call_flag!='add_room' && call_flag!='adult_click' && call_flag!='rm_room' && call_flag!='child_click')
                        {
                            _row_total.push({'key':ind,'row':0,'Total':_total});
                            _row_strike_total.push({'key':ind,'row':0,'Total':_striketotal});
                            //Store Value while all rows hide & show default calulated total
                            _final_total=(_total);
                            _final_strike_total=_striketotal;
                        }
                       
                        //console.log("_final_total === " + _total);
                        //methods._applyRoundOff
                        if(execute_block==false && init_cnt==1) {                            
                            
                            //Calculate Total
                            add_val=0;
                            for (var key2 in _row_total)
                            {                                
                                //Click Add room & adult or child click & not changed
                                if(_row_total[key2]['key']==ind && _row_total[key2]['row']==(parseInt(itmcnt_val)+1) && call_flag!='rm_room')                                {
                                    _row_total[key2]['Total']=_total;
                                    add_val++;
                                }
                            }
                            
                            if (add_val==0 ) {
                                if (call_flag=='rm_room') {
                                     //remove from object                                     
                                     //chinmay gandhi - 1.0.52.57
                                     //_row_total.pop({'key':ind,'row':jQuery('[id^=room_row_'+ind+'_]').length,'Total':_total});
                                     //When there is all rooms removed , total was set to original.
                                     
                                     //Chinmay Gandhi - 1.0.52.57 - Start
                                     //purpose: incerss decress rate on booking engine
                                        var result_l;
                                        var pos_l;
                                        var len_rm;
                                        for( var rm_i = 0, len_rm = _row_total.length; rm_i < len_rm; rm_i++ ) {
                                            if( (_row_total[rm_i]['key'] === ind ) && (_row_total[rm_i]['row'] === parseInt(itmcnt_val) ) ) {
                                                
                                                result_l = _row_total[rm_i];
                                                pos_l = _row_total.indexOf(result_l); 
                                                break;
                                            }
                                        }
                                        
                                        var removeItemIndex = pos_l;
                                        if (removeItemIndex != -1) _row_total.splice(removeItemIndex, 1);
                                     //Chinmay Gandhi - 1.0.52.57 - End
                                     
                                     if (jQuery('[id^=room_row_'+ind+'_]').length==1 && jQuery('[id^=room_row_'+ind+'_]').is(':visible')==false) {
                                        _final_total=_total;
                                     }
                                }
                                else//Add Row
                                {
                                    _row_total.push({'key':ind,'row':jQuery('[id^=room_row_'+ind+'_]').length,'Total':_total});
                                }
                            }
                            
                            //Calculate Strike Total
                            add_val=0;
                            
                            for (var key2 in _row_strike_total)
                            {
                                if(_row_strike_total[key2]['key']==ind && _row_strike_total[key2]['row']==(parseInt(itmcnt_val)+1) && call_flag!='rm_room')
                                {
                                    _row_strike_total[key2]['Total']=_striketotal;
                                    add_val++;
                                }
                            }
                           
                            if (add_val==0 ) {
                                if (call_flag=='rm_room') {
                                //_row_strike_total.pop({'key':ind,'row':jQuery('[id^=room_row_'+ind+'_]').length,'Total':_striketotal});
                                //Chinmay Gandhi - 1.0.52.57 - Start
                                //purpose: incerss decress rate on booking engine
                                   var result_l;
                                   var pos_l;
                                   var len_rm;
                                   for( var rm_i = 0, len_rm = _row_strike_total.length; rm_i < len_rm; rm_i++ ) {
                                       if( (_row_strike_total[rm_i]['key'] === ind ) && (_row_strike_total[rm_i]['row'] === parseInt(itmcnt_val) ) ) {
                                           result_l = _row_strike_total[rm_i];
                                           pos_l = _row_strike_total.indexOf(result_l); 
                                           break;
                                       }
                                   }
                                   
                                   var removeItemIndex = pos_l;
                                   if (removeItemIndex != -1) _row_strike_total.splice(removeItemIndex, 1);
                                //Chinmay Gandhi - 1.0.52.57 - End

                                    if (jQuery('[id^=room_row_'+ind+'_]').length==1 && jQuery('[id^=room_row_'+ind+'_]').is(':visible')==false)
                                    {
                                       _final_strike_total=_striketotal;
                                    }
                               }
                               else
                                   _row_strike_total.push({'key':ind,'row':jQuery('[id^=room_row_'+ind+'_]').length,'Total':_striketotal});
                           }
                        }
                    
                      //Find Total
                      for (var key in _row_total){
                        
                        if (_row_total[key]['key']==ind) {
                           if (_row_total[key]['row']!=0) {
                                if(jQuery("#addroombut_"+ind).is(':visible')==true)
                                    _final_total=parseFloat(_row_total[key]['Total']);
                                else
                                    _final_total=parseFloat(_final_total)+parseFloat(_row_total[key]['Total']);
                           }                           
                        }                         
                     }
                     
                     //console.log("_total : " +  methods._applyRoundOff(_final_total));
                      
                     //Find Total
                      for (var key in _row_strike_total){
                        if (_row_strike_total[key]['key']==ind) {
                           if (_row_strike_total[key]['row']!=0) {
                             if(jQuery("#addroombut_"+ind).is(':visible')==true)
                             {
                                _final_strike_total=parseFloat(_row_strike_total[key]['Total']);
                             }                                
                             else
                             {
                                _final_strike_total=parseFloat(_final_strike_total)+parseFloat(_row_strike_total[key]['Total']);
                             }
                           }                           
                        }                         
                      }
                      ///console.log("_final_total == " + _final_total);
                      //console.log("_final_strike_total : " + _final_strike_total);
                       
                        //Show Rate Plan level adult ,child & room - start                        
                        nrrooms=jQuery("#rooms_"+ind).val();                       
                        L_rooms_lbl=js_stripslashes(langjs[0]["L_Roomcap"]);   
                        if(jQuery("#addroombut_"+ind).is(':visible')==false)
                        {
                            if (jQuery('[id^=room_row_'+ind+'_]').length>1) {
                               L_rooms_lbl=js_stripslashes(langjs[0]["L_Roomcap1"]); 
                            }
                            jQuery("#room_tot_"+ind).text(parseInt(jQuery('[id^=room_row_'+ind+'_]').length) + " " +L_rooms_lbl);
                            if(opt.showchild==true){ 
                                 jQuery("#avg_room_tot_"+ind).text(parseInt(jQuery('[id^=room_row_'+ind+'_]').length) + " " +L_rooms_lbl);
                            }
                            else
                            {
                                 jQuery("#avg_room_tot_"+ind).text(", " +parseInt(jQuery('[id^=room_row_'+ind+'_]').length) + " " +L_rooms_lbl);
                            }
                        
                        }
                        else
                        {
                            if (nrrooms>1) {
                                L_rooms_lbl=js_stripslashes(langjs[0]["L_Roomcap1"]);
                            }
                            jQuery("#room_tot_"+ind).text(parseInt(nrrooms) + " " +L_rooms_lbl);
                            if(opt.showchild==true)
                                jQuery("#avg_room_tot_"+ind).text(parseInt(nrrooms) + " " +L_rooms_lbl);
                            else
                                jQuery("#avg_room_tot_"+ind).text(", "+parseInt(nrrooms) + " " +L_rooms_lbl);                            
                        }
                        
                        sel_adult=0;                      
                        if (jQuery('[id^=room_row_'+ind+'_]').length && jQuery('[id^=room_row_'+ind+'_]').is(':visible')==true) {                                jQuery("[id^=rm_adult_"+row_id_val+"_]").each(function(){
                                    if(jQuery("#addroombut_"+ind).is(':visible')==false)
                                    {                                     
                                         sel_adult=sel_adult+parseInt(jQuery(this).val());
                                    }
                                });
                        }
                        
                        if(opt.ShowNights==true)
                        {
                            var no_nights_1=$("select#eZ_Nights").val();
                        }
                        else
                        {
                            var no_nights_1=$("input#eZ_Nights").val();
                        }
                        var min_mights=eval("rateplan.Min_MinNight");
                        
                        //Chandrakant - 2.0.12 - 22 October 2018 - START
                        //Purpose : RES-1774
                        if(opt.RunOnMaximumOccupanySettings==1)
                        {
                            if(opt.ResUIPaxOnMaximumOccupancy=='1')
                            {
                                var maximum_occupancy_val= eval("rateplan.max_occupancy");
                                if (maximum_occupancy_val>eval("rateplan.max_adult")) {
                                    sel_adult_selected=eval("rateplan.max_adult");
                                }
                                else
                                {
                                    sel_adult_selected=maximum_occupancy_val;
                                }
                            }
                            else
                                var sel_adult_selected=eval("rateplan.base_adult");
                        }
                        else
                        //Chandrakant - 2.0.12 - 22 October 2018 - END
                            var sel_adult_selected=eval("rateplan.base_adult");
                        if(opt.acr==true)
                        {
                            sel_adult_selected=$("select[id$='eZ_adult']").val();
                        }
                        
                        if(jQuery("#addroombut_"+ind).is(':visible')==true)
                            sel_adult=sel_adult_selected;
                        else
                        {
                            /*check case for minimum nights criteria not matched - start , adult , child should be base instead of "0"*/
                            if (min_mights!='' && parseInt(min_mights) > parseInt(no_nights_1)) {
                                 sel_adult=sel_adult_selected;
                            }
                           // console.log("Min_MinNight : " + eval("rateplan.Min_MinNight"));
                        }
                         /*show "0" adult at rate plan level because rate plan is hide on show more so not fall to any above case*/
                        if(sel_adult==0)
                        {
                            sel_adult=sel_adult_selected;
                        }
                        
                        if (sel_adult>1)                           
                            L_adult_lbl=js_stripslashes(langjs[0]["L_Adultlbl5"]); 
                        else
                            L_adult_lbl=js_stripslashes(langjs[0]["L_Adultlbl4"]);
                        
                        ////Chandrakant - 2.0.12 - 22 October 2018 - START
                        ////Purpose : RES-1774
                        //if(opt.RunOnMaximumOccupanySettings==1)
                        //    jQuery("#base_adult_occ_"+ind).text(eval("rateplan.max_adult")+ " "+L_adult_lbl);    
                        //else
                        ////Chandrakant - 2.0.12 - 22 October 2018 - END
                            jQuery("#base_adult_occ_"+ind).text(sel_adult+ " "+L_adult_lbl);    
                        
                        jQuery("#avg_base_adult_occ_"+ind).text(sel_adult+ " "+L_adult_lbl);
                        
                        sel_child=0;
                        if (jQuery('[id^=room_row_'+ind+'_]').length && jQuery('[id^=room_row_'+ind+'_]').is(':visible')==true)
                        {
                            sel_child=0;
                            jQuery("[id^=rm_child_"+row_id_val+"_]").each(function(){
                                if(jQuery("#addroombut_"+ind).is(':visible')==false)
                                {
                                    sel_child=sel_child+parseInt(jQuery(this).val());
                                }
                            });
                        }
                       // console.log("sel_child === " + sel_child);
                        
                        var sel_child_selected=eval("rateplan.base_child");
                        
                        if(opt.RunOnMaximumOccupanySettings==0)
                        {
                            if((opt.showchild_bb=="-1" && opt.showchild==true) || opt.showchild_bb==true)
                            {
                                sel_child_selected=$("select[id$='eZ_child']").val();
                            }
                        }

                        //Chinmay Gandhi - 1.0.53.61 - Start
                        //Perpose: Set MaxOccupancySetting
                        if(opt.RunOnMaximumOccupanySettings==1)
                        {
                            if(opt.showchild==true)
                                sel_child_selected=$("#rm_child_"+ind+"_0").val();
                        }
                        //Chinmay Gandhi - End

                        //console.log("sel_child_selected === " + sel_child_selected);
                        var cncnt=0;
                        if(jQuery("#addroombut_"+ind).is(':visible')==true)
                        {
                            cncnt++;
                            sel_child=sel_child_selected;
                           // console.log("visible " + sel_child_selected + " : " + opt.RunOnMaximumOccupanySettings);
                        }
                        /*flora -it was not updated child value for cae of maximumoccupancy & rest case once change child combo avlue*/
                        //Chandrakant - 2.0.14 - 16 January 2019, Purpose - RES-1856 - opt.HotelId is added after discusssion with flora
                        else if(jQuery("#addroombut_"+ind).is(':visible')==false && opt.HotelId!='387')
                        {
                            cncnt++;
                            sel_child=sel_child;                          
                        }
                        /*flora -it was not updated child value for cae of maximumoccupancy & rest case once change child combo avlue*/
                        else
                        {
                            /*check case for minimum nights criteria not matched - start , adult , child should be base instead of "0"*/
                            if (min_mights!='' && parseInt(min_mights) > parseInt(no_nights_1))
                            {
                                sel_child=sel_child_selected;
                                cncnt++;
                            }
                        }
                        /*show "0" child at rate plan level because rate plan is hide on show more so not fall to any above case*/
                        if (cncnt==0)
                        {
                            sel_child=sel_child_selected;
                        }
                        //Chinmay Gandhi - 1.0.52.57 - Start - set childern
                        if (sel_child>1)                           
                            L_child_lbl=js_stripslashes(langjs[0]["L_Child3"]);
                        else
                            L_child_lbl=js_stripslashes(langjs[0]["L_Child2"]);
                        //Chinmay Gandhi - 1.0.52.57 - End
                       
                       
                        if(opt.showchild==true)
                        {
                            jQuery("#base_child_occ_"+ind).text(", " +sel_child+" "+L_child_lbl+", ");    
                            jQuery("#avg_base_child_occ_"+ind).text(", " +sel_child+" "+L_child_lbl+", ");
                        }
                        else
                        {
                             jQuery("#base_child_occ_"+ind).text(", ");
                             jQuery("#avg_base_child_occ_"+ind).text(", ");
                        }
                       //Show Rate Plan level adult ,child & room - start
                       
                                            
                        resgrid[0][ind]['total_amount']=_final_total;
                        _total=_final_total;
                        _striketotal=_final_strike_total;
                      
                         // Sheetal Panchal - 1.0.52.60 - 7th July 2017, Purpose - For Non linear rate mode setting - START
                        if($("#ratemode").val()=='NONLINEAR')
                        {
                          var daycount= resgrid[0][ind]['DayCnt'];
                          var _avg_total=_total/daycount;
                          var _avg_striketotal=_striketotal/daycount;
                          _avg_total=addCommas((parseFloat(_avg_total)).toFixed(_display_decimalplaces));
						  _avg_striketotal=addCommas((parseFloat(_avg_striketotal)).toFixed(_display_decimalplaces));
						}
                        // Sheetal Panchal - 1.0.52.60 - 7th July 2017, Purpose - For Non linear rate mode setting - END

                        //Falguni Rana - Roundoff - START
                        _total = methods._applyRoundOff(_total);
                        
                        //kishan Tailor 3rd August 2018 purpose:Triptease Price Widget Integration START
                        if(opt.TripteasehotelKey!='' && opt.TripteasehotelAPIkey !='' && call_flag=='')
                        {
                            ratenamearray.push(rateplan['display_name']);
                            //kishan Tailor 26 Nov 2020 purpose:Per Room per night price sending to tiptease RES-2648 START
                            if(jQuery("#pnl_avg_blk").hasClass('active')==true)
                            {    
                            directarray.push(_total/parseInt(no_nights_1));
                            }
                            else
                            {
                            directarray.push(_total);
                            }
                            //kishan Tailor 26 Nov 2020 purpose:Per Room per night price sending to tiptease RES-2648 END
                        }
                        //kishan Tailor 3rd August 2018 purpose:Triptease Price Widget Integration END
                        
                        //Chandrakant - 13 October 2022 - START
                        //Purpose : RES-3079
                        var new_avg_total = _total/parseInt(no_nights_1);
                        new_avg_total=addCommas((parseFloat(new_avg_total)).toFixed(_display_decimalplaces));
                        //Chandrakant - 13 October 2022 - END

                        _striketotal = methods._applyRoundOff(_striketotal);
						_total=addCommas((parseFloat(_total)).toFixed(_display_decimalplaces));
						_striketotal=addCommas((parseFloat(_striketotal)).toFixed(_display_decimalplaces));
                        //Falguni Rana - Roundoff - END
                        
                        if(_total==_striketotal)
                        {
							$("#row_"+ind).find("#rmamt_strike").hide();
                            $("#row_"+ind).find(".up_strike_currency_display_pre_cls").hide();
                            $("#row_"+ind).find("#rmamt_strike").addClass('hidethis_row');
                            $("#row_"+ind).find(".strike_strong_cls").hide();
                        }
						    
                        _currency_symbol=resgrid[0][0]['MultiplyCurrSymbl'];
                        if(resgrid[0][ind].curr_prefix=="1"){                            
                            $("#row_"+ind).find("#tot_currency_display_pre").html(_currency_symbol);
                            $("#row_"+ind).find("#tot_currency_display_post").html('');
                            
                            $("#row_"+ind).find("#up_strike_currency_display_pre").html(_currency_symbol);
                            $("#row_"+ind).find("#up_strike_currency_display_post").html('');
                            
                            
                            $("#row_"+ind).find("#strike_currency_display_pre").html(_currency_symbol);
                            $("#row_"+ind).find("#strike_currency_display_post").html('');
                            
                            $("#row_"+ind).find("#upavg_strike_currency_display_pre").html(_currency_symbol);
                            $("#row_"+ind).find("#upavg_strike_currency_display_post").html('');
                            
                        }
                        else{
                            $("#row_"+ind).find("#tot_currency_display_pre").html('');
                            $("#row_"+ind).find("#tot_currency_display_post").html(_currency_symbol);
                            
                            $("#row_"+ind).find("#up_strike_currency_display_pre").html('');
                            $("#row_"+ind).find("#up_strike_currency_display_post").html(_currency_symbol);
                            
                            
                            $("#row_"+ind).find("#strike_currency_display_pre").html('');
                            $("#row_"+ind).find("#strike_currency_display_post").html(_currency_symbol);
                            
                            $("#row_"+ind).find("#upavg_strike_currency_display_pre").html('');
                            $("#row_"+ind).find("#upavg_strike_currency_display_post").html(_currency_symbol);
                        }
						
                        //Chandrakant - 02 December 2019 - START
                        //Purpose : RES-2320 - Apply round off before applying decimal places.
                        //Falguni Rana - Roundoff - Shifted above
                        /*
                        _total = methods._applyRoundOff(_total);
                        _striketotal = methods._applyRoundOff(_striketotal);
                        _total=addCommas((parseFloat(_total)).toFixed(_decimalplaces));
						_striketotal=addCommas((parseFloat(_striketotal)).toFixed(_decimalplaces));
						*/
						$("#row_"+ind).find("#rmamt_strike").html(_striketotal);
						$("#row_"+ind).find("#rmamt").html(_total);
                        //Chandrakant - 02 December 2019 - END

                        $("#row_"+ind).find("#rmamt_avg_night").html(new_avg_total); //Chandrakant - 13 October 2022, Purpose - RES-3079
                        
                        // Sheetal Panchal - 1.0.52.60 - 7th July 2017, Purpose - For Non linear rate mode setting - START
                        if($("#ratemode").val()=='NONLINEAR')
                        {
                            $("#row_"+ind).find("#rmamt_avg_night").html(_avg_total);
                            $("#row_"+ind).find("#rmamt_strike_avg_night").html(_avg_striketotal);
                        }
                        // Sheetal Panchal - 1.0.52.60 - 7th July 2017, Purpose - For Non linear rate mode setting - END
                        
                        //$("#row_"+ind).find("#rmamt_"+ind).html(_total);
                    }
                    else{					
                        resgrid[0][ind]['total_amount']=0;
                        $("#row_"+ind).find("#rmamt_strike").html(_striketotal);
						$("#row_"+ind).find("#rmamt").html('&nbsp;');
                        //$("#row_"+ind).find("#rmamt_"+ind).html('&nbsp;');
                    }
                    promosarray=[];//Parth - 11 Jan 2019 - PromoDailyDiscount - Apply daily discount on promotion.
                    
                    if (execute_block==false && init_cnt==1)
                       return false;
                });
                //kishan Tailor 10th sept 2018 purpose:Triptease Price Widget START
                if(opt.TripteasehotelKey!='' && opt.TripteasehotelAPIkey !='' && call_flag=='')
                {
                    console.log(directarray);
                    var isdirect=0;
                    if(directarray.length>0)
                    {
                        console.log("-=-=-=-=Triptease Price Widget Called=-=-=-=-=-=");
                        var directprice=Math.min.apply(null, directarray);
                        var indexdirectprice=directarray.indexOf(directprice);
                        var currencycode=$("#selected_curr").val();
                        jQuery(".price-fighter-widget").attr("data-pf-currency",currencycode);
                        jQuery(".price-fighter-widget").attr("data-pf-direct-price",directprice);
                        jQuery(".price-fighter-widget").attr("data-pf-room-rate",ratenamearray[indexdirectprice]);
                        isdirect=1
                    }
                    else
                    {
                        
                        isdirect=0;
                    }
                    setTimeout(function(){
                       console.log("=-=-=-time out-=-=-=-=");
                       if(isdirect==1)
                       {
                             console.log("IN else if condition RESET");
                             Paperboy.PriceCheck.reset();
                       }
                       else
                       {
                            console.log("IN else if condition DESTROY");
                            Paperboy.PriceCheck.destroy();
                       }
                   },5000);
                   
                }
                //kishan Tailor 10th sept 2018 purpose:Triptease Price Widget END
            }
            catch(err){
                hideLoadingBar();
                hideCheckavalabilityLoadingBar();
                console.log("Error - _calculateLongtayPrice - "+err.message);		
            }
        },
        _defaultLongStayRateCalculation:function(rateplan,row_id_val,itmcnt_val,ind,row_count_val,maxchildshow){
            //Chandrakant - 1.0.53.61 - 09 November 2017 - Purpose : maxchildshow is added to check system runs on max occupancy settings or not
            try{
                 console.log('_defaultLongStayRateCalculation');
                 var maxchildshow = maxchildshow || ''; //Chandrakant - 1.0.53.61 - 09 November 2017
                 var i=0;
                 var extra_child_rate=0;
                 var extra_adult_rate=0;
                _totalBookingAmount=0.00;
                
                if(typeof(rateplan.dt_1)!='undefined'){
                    if(rateplan.coa_1){}
                }
                if (row_id_val!='') {
                   rateplan=resgrid[0][row_id_val];                  
                }
                
                //child age policy
                var AllowChildAgePolicy=eval("rateplan.AllowChildAgePolicy");	                        
                var AllowChildAgeOnBaseOccupancy=eval("rateplan.AllowChildAgeOnBaseOccupancy");
                var WebChildAge=eval("rateplan.WebChildAge");	                        
                var WebMaxChildAge=eval("rateplan.WebMaxChildAge");   
                var d=0;
                var slabbefore_discount = jQuery("#slab_before_discount").val(); //Chandrakant - 1.0.53.61 - 27 November 2017
                while(typeof(eval("rateplan.dt_"+eval(++i)))!='undefined'){
                    //console.log(eval("rateplan.display_name"));
                    //console.log("factor : " + resgrid[0][0]['MultiplyFactor'] + " : " + _decimalplaces);
                    //Find Extra adult & Child + Rates - start
                    adultrate=parseFloat(eval("rateplan.o_day_extra_adult_"+i));
                    childrate=parseFloat(eval("rateplan.o_day_extra_child_"+i));
                                       
                    var baseadult=eval("rateplan.base_adult");	                        
                    var basechild=eval("rateplan.base_child");
                    
                    var extra_adult=0;
                    //case added to default set extra adult rate calculation while check availability with more adults & child - flora
                    if ( jQuery("#rm_adult_"+row_id_val+"_"+itmcnt_val).val()==''
                    || jQuery("#rm_adult_"+row_id_val+"_"+itmcnt_val).val()==undefined) {
                        //Chandrakant - 2.0.12 - 22 October 2018 - START
                        //Purpose : RES-1774
                        if (resgrid[0][0].RunOnMaximumOccupanySettings=='1' && resgrid[0][0].acr=='false')
                        {
                            var maximum_occupancy_val= eval("rateplan.max_occupancy");
                            if (resgrid[0][0].ResUIPaxOnMaximumOccupancy=='1')
                            {
                                if (maximum_occupancy_val>eval("rateplan.max_adult")) {
                                    selected_adult=eval("rateplan.max_adult");
                                }
                                else
                                    selected_adult=maximum_occupancy_val;
                            }
                            else
                                selected_adult=eval("rateplan.base_adult");
                        }
                        else
                        //Chandrakant - 2.0.12 - 22 October 2018 - END
                            selected_adult=jQuery( "#rm_adult_"+row_count_val+"_0").val();
                        
                     //console.log("adult  : " + jQuery( "#rm_adult_"+ind+"_0").val());
                    }
                    else
                        selected_adult=jQuery("#rm_adult_"+row_id_val+"_"+itmcnt_val).val();                   
                    
                    if(parseInt(selected_adult) > parseInt(baseadult))                    
                            extra_adult=(parseInt(selected_adult) - parseInt(baseadult));
                   
                    var extra_child=0;
                    var selected_child=0; //Chandrakant - 1.0.53.61 - 09 November 2017
                    if(maxchildshow!='' && maxchildshow!=false) //Chandrakant - 1.0.53.61 - 09 November 2017
                    {
                        if (jQuery("#rm_child_"+row_id_val+"_"+itmcnt_val).val()==''
                         || jQuery("#rm_child_"+row_id_val+"_"+itmcnt_val).val()==undefined) {
                            //Chandrakant - 2.0.12 - 22 October 2018 - START
                            //Purpose : RES-1774
                            if (resgrid[0][0].RunOnMaximumOccupanySettings=='1' && resgrid[0][0].acr=='false')
                            {
                                if (resgrid[0][0].ResUIPaxOnMaximumOccupancy=='1')
                                {
                                    if (eval('rateplan.max_occupancy')>=eval('rateplan.max_adult'))
                                    {
                                        var selected_child=eval('rateplan.max_occupancy')-eval('rateplan.max_adult');
                                        if (selected_child>eval('rateplan.max_child'))
                                        {
                                            var selected_child=eval('rateplan.max_child');
                                        }
                                    }
                                    else
                                        var selected_child=0;
                                }
                                else
                                    selected_child=eval("rateplan.base_child");
                            }
                            else
                            //Chandrakant - 2.0.12 - 22 October 2018 - END
                                selected_child=jQuery( "#rm_child_"+row_count_val+"_0").val();   
                        }
                        else
                            selected_child=jQuery("#rm_child_"+row_id_val+"_"+itmcnt_val).val();
                    }
                     
                    if(AllowChildAgePolicy==0 && AllowChildAgeOnBaseOccupancy==1)//true
                    {
                       
                        if(selected_child > parseInt(basechild))
                        {
                            var childagelimit=WebChildAge;
                            chkchild_cnt=1;
                            for(childcnt=0;childcnt<selected_child;childcnt++)
                            {			
                                var childage=jQuery('#age_'+row_id_val+'_'+itmcnt_val+'_'+childcnt).val();                                 
                                if(parseInt(childage) > parseInt(childagelimit) && childage!='' && parseInt(chkchild_cnt)>parseInt(basechild))
                                    extra_child++;
                                chkchild_cnt++;    
                            }
                        }
                    }
                    else if(AllowChildAgePolicy==0)//true
                    {                           
                        var childagelimit=WebChildAge;
                        for(childcnt=0;childcnt<selected_child;childcnt++)
                        {			
                            var childage=jQuery('#age_'+row_id_val+'_'+itmcnt_val+'_'+childcnt).val();                               
                            if(parseInt(childage) > parseInt(childagelimit) && childage!='')
                                extra_child++;
                        }
                    }
                    else if(parseInt(selected_child) > parseInt(basechild))
                    {
                        //console.log("selected_child : " + selected_child);
                        //console.log("basechild : " + basechild);
                        extra_child=(parseInt(selected_child) - parseInt(basechild));
                    }
                    
                    //console.log("extra_child "+extra_child);

                    var apply_extra_adult=0
                    var apply_extra_child=0;
                    
                    if (extra_adult>0) {
                        apply_extra_adult=1;
                    }
                    if (extra_child>0) {
                        apply_extra_child=1;
                    }
                    
                    //console.log("extra_adult : " + extra_adult+ " ==  extra_child  : " + extra_child );
                    
                    //Issue in no of nights more then 1 , calculating double amount
                    //extra_child_rate = extra_child_rate + extra_child * childrate;                  
                    //extra_adult_rate = extra_adult_rate + extra_adult * adultrate;
                    
                    extra_child_rate =  extra_child * childrate;                   
                    extra_adult_rate =  extra_adult * adultrate;
                    //Find Extra adult & Child + Rates - end    
                  
                   if(eval("rateplan.ShowTaxInclusiveExclusiveSettings")=="1"){                   
                        if (eval("rateplan.Tax_"+(i))!="[0]" //||
                            //typeof(eval("rateplan.Tax_"+(i)))!='undefined'
                            )
                        { 
                          tax_formula=eval("rateplan.Tax_"+(i));
                          //if (resgrid[0][0]['MultiplyFactor']!=1) {
                          //  d=parseFloat(eval("rateplan.before_discount_day_base_"+i)); 
                          //  d=parseFloat(d)*parseFloat(resgrid[0][0]['MultiplyFactor']);
                          //  
                          //  extra_child_rate=parseFloat(extra_child_rate)*parseFloat(resgrid[0][0]['MultiplyFactor']);
                          //  extra_adult_rate=parseFloat(extra_adult_rate)*parseFloat(resgrid[0][0]['MultiplyFactor']);
                          //  
                          //  //console.log("d : " + extra_child_rate + " : " +  d);
                          //  total_tax=getTax(d,tax_formula,_decimalplaces,0,rateplan.RackRate,rateplan.EARate,rateplan.ECRate,selected_adult,selected_child,'base',extra_adult,extra_child);
                          //}
                          //else
                          //{
                           // console.log("tax apply");
                            d=parseFloat(eval("rateplan.before_discount_day_base_"+i));
                            //console.log("base amount : " +d);
                            d=parseFloat(d)+parseFloat(extra_child_rate)+parseFloat(extra_adult_rate); //added
                            //Chandrakant - 1.0.53.61 - 29 November 2017 - START
                            //Purpose : Find Slab using base Rate not discount rate
                            if(slabbefore_discount==1)
                            {
                                d_amountGST=parseFloat(eval("rateplan.before_discount_day_base_"+i));
                                //console.log("base amount GST : " +d_amountGST);
                                d_amountGST=parseFloat(d_amountGST)+parseFloat(extra_child_rate)+parseFloat(extra_adult_rate);
                                total_tax=getTax(d_amountGST,tax_formula,_decimalplaces,0,rateplan.RackRate,rateplan.EARate,rateplan.ECRate,selected_adult,selected_child,'base',extra_adult,extra_child);
                            }
                            else
                            {
                                total_tax=getTax(d,tax_formula,_decimalplaces,0,rateplan.RackRate,rateplan.EARate,rateplan.ECRate,selected_adult,selected_child,'base',extra_adult,extra_child);        
                            }
                            //console.log("extra_child_rate : " +extra_child_rate );
                            //console.log("extra_adult_rate : " +extra_adult_rate );
                            //console.log("totalamount : " +d );
                            //console.log("total_tax : " +total_tax );
                            //console.log('-------------------------------------------');
                            //Chandrakant - 1.0.53.61 - 27 November 2017 - END  
                            if(_decimalplaces=='0') //Chandrakant - 20 April 2021, Purpose - RES-2791
                                d=d.toFixed(_decimalplaces); //Chandrakant - 20 April 2021, Purpose - RES-2791
                            d=methods._applyRoundOff(parseFloat(d)+parseFloat(total_tax));//updated
                        }
                        else{
                            //extra adult & Child Rate                            
                            d=parseFloat(eval("rateplan.o_day_base_"+i))+parseFloat(extra_child_rate)+parseFloat(extra_adult_rate);
                        }
                    }
                    else{                       
                           d=parseFloat(eval("rateplan.o_day_base_"+i))+parseFloat(extra_child_rate)+parseFloat(extra_adult_rate);
                       }                           
                    //d=parseFloat(eval("rateplan.o_day_base_"+i))+parseFloat(extra_child_rate)+parseFloat(extra_adult_rate);                    
                    d=parseFloat(d.toFixed(_display_decimalplaces)); //Falguni Rana
                    if(!(d>=0))
                        d=0;                                       
                    if(parseFloat(_totalBookingAmount)>=0){
                        //Apply fix while tax setting is on , but tax not configured , it was rounding the amount. - flora
                        if(eval("rateplan.ShowTaxInclusiveExclusiveSettings")=="1"){                   
                            if (eval("rateplan.Tax_"+(i))!="[0]"
                                //typeof(eval("rateplan.Tax_"+(i)))!='undefined'
                                )
                            {                                                              
                                _totalBookingAmount+=methods._applyRoundOff(d);//old - default
                            }
                            else
                            {
                                //console.log("else if");
                                //_totalBookingAmount+=(d); //Old one
				_totalBookingAmount+=methods._applyRoundOff(d); //Pinal - 1.0.50.55 - 10 September 2016 - Purpose - Applying round off
                            }
                        }
                        else
                        {
                            //console.log("else else if");
                            //_totalBookingAmount+=(d); //Old one
			    _totalBookingAmount+=methods._applyRoundOff(d); //Pinal - 1.0.50.55 - 10 September 2016 - Purpose - Applying round off
                        }
                    }                   
                }
                //console.log("_totalBookingAmount : " + d);
               
	        //console.log("_totalBookingAmount :2 " + parseFloat(resgrid[0][0]['MultiplyFactor']));// if (resgrid[0][0]['MultiplyFactor']==1)
		
		//if (parseFloat(resgrid[0][0]['MultiplyFactor'])!=1) //Pinal - 1.0.50.55 - 10 September 2016 - Purpose - Applying round off for configure exchange rate
		//{
		//    return (methods._applyRoundOff(_totalBookingAmount*parseFloat(resgrid[0][0]['MultiplyFactor'])))+"|"+apply_extra_adult+"|"+apply_extra_child;
		//}
		//else
                return ((_totalBookingAmount*parseFloat(resgrid[0][0]['MultiplyFactor'])))+"|"+apply_extra_adult+"|"+apply_extra_child;
                //else
                //return ((_totalBookingAmount))+"|"+apply_extra_adult+"|"+apply_extra_child;
            }
            catch(err){
                hideLoadingBar();
                hideCheckavalabilityLoadingBar();
                console.log("Error - _defaultLongStayRateCalculation - "+err.message);
		
            }
        },
        // Sheetal Panchal - 1.0.52.60 - 7th July 2017, Purpose - For Non linear rate mode setting - START
        _defaultLongStayRateCalculation_NL:function(opt,rateplan,row_id_val,itmcnt_val,ind,row_count_val,maxchildshow){
            //Chandrakant - 1.0.53.61 - 09 November 2017 - Purpose : maxchildshow is added to check system runs on max occupancy settings or not
            try{
                 console.log('_defaultLongStayRateCalculation_NL');
                 var maxchildshow = maxchildshow || ''; //Chandrakant - 1.0.53.61 - 09 November 2017
                 var i=0;
                 var extra_child_rate=0;
                 var extra_adult_rate=0;
                _totalBookingAmount=0.00;
            
                if(typeof(rateplan.dt_1)!='undefined'){
                    if(rateplan.coa_1){}
                }
                if (row_id_val!='') {
                   rateplan=resgrid[0][row_id_val];                  
                }                
                
                //child age policy
                var AllowChildAgePolicy=eval("rateplan.AllowChildAgePolicy");	                        
                var AllowChildAgeOnBaseOccupancy=eval("rateplan.AllowChildAgeOnBaseOccupancy");
                var WebChildAge=eval("rateplan.WebChildAge");	                        
                var WebMaxChildAge=eval("rateplan.WebMaxChildAge");   
                var d=0;
                while(typeof(eval("rateplan.dt_"+eval(++i)))!='undefined'){
                    //Find Extra adult & Child + Rates - start
                    adultrate=parseFloat(eval("rateplan.o_day_extra_adult_"+i));
                    childrate=parseFloat(eval("rateplan.o_day_extra_child_"+i));
                                       
                    var baseadult=eval("rateplan.base_adult");	                        
                    var basechild=eval("rateplan.base_child");
                    var base_adult=0;
                    var base_child=0;
                    
                    var rackratetax_extra_adult=0;
                    var rackratetax_extra_child=0;
                    
                    var extra_adult=0;
                    
                    //case added to default set extra adult rate calculation while check availability with more adults & child - flora
                    if ( jQuery("#rm_adult_"+row_id_val+"_"+itmcnt_val).val()==''
                    || jQuery("#rm_adult_"+row_id_val+"_"+itmcnt_val).val()==undefined) {
                        //Chandrakant - 2.0.12 - 22 October 2018 - START
                        //Purpose : RES-1774
                        if (resgrid[0][0].RunOnMaximumOccupanySettings=='1' && resgrid[0][0].acr=='false')
                        {
                            if (resgrid[0][0].ResUIPaxOnMaximumOccupancy=='1')
                            {
                                var maximum_occupancy_val= eval("rateplan.max_occupancy");
                                if (maximum_occupancy_val>eval("rateplan.max_adult")) {
                                    selected_adult=eval("rateplan.max_adult");
                                }
                                else
                                {
                                    selected_adult=maximum_occupancy_val;
                                }
                            }
                            else
                                selected_adult=eval("rateplan.base_adult");
                        }
                        else
                        //Chandrakant - 2.0.12 - 22 October 2018 - END
                            selected_adult=jQuery( "#rm_adult_"+row_count_val+"_0").val();
                     //console.log("adult  : " + jQuery( "#rm_adult_"+ind+"_0").val());
                    }
                    else
                        selected_adult=jQuery("#rm_adult_"+row_id_val+"_"+itmcnt_val).val();                   
                        //console.log("adult  : " + jQuery( "#rm_adult_"+ind+"_0").val());
                 
                    if(parseInt(selected_adult) > 7)
                    {
                            extra_adult=(parseInt(selected_adult)-7);// - parseInt(baseadult));
                            base_adult=7;
                    }
                    else
                    {
                            extra_adult=0;// - parseInt(baseadult));
                            base_adult=selected_adult;
                    }
                    
                    var extra_child=0;
                    var selected_child=0; //Chandrakant - 1.0.53.61 - 09 November 2017
                    if(maxchildshow!='' && maxchildshow!=false) //Chandrakant - 1.0.53.61 - 09 November 2017
                    {
                        if (jQuery("#rm_child_"+row_id_val+"_"+itmcnt_val).val()==''
                        || jQuery("#rm_child_"+row_id_val+"_"+itmcnt_val).val()==undefined) {
                            //Chandrakant - 2.0.12 - 22 October 2018 - START
                            //Purpose : RES-1774
                            if (resgrid[0][0].RunOnMaximumOccupanySettings=='1' && resgrid[0][0].acr=='false')
                            {
                                if (resgrid[0][0].ResUIPaxOnMaximumOccupancy=='1')
                                {
                                    if (eval('rateplan.max_occupancy')>=eval('rateplan.max_adult'))
                                    {
                                        var selected_child=eval('rateplan.max_occupancy')-eval('rateplan.max_adult');
                                        if (selected_child>eval('rateplan.max_child'))
                                        {
                                            var selected_child=eval('rateplan.max_child');
                                        }
                                    }
                                    else
                                        var selected_child=0;
                                }
                                else
                                    selected_child=eval("rateplan.base_child");
                            }
                            else
                            //Chandrakant - 2.0.12 - 22 October 2018 - END
                                selected_child=jQuery( "#rm_child_"+row_count_val+"_0").val();   
                        }
                        else
                            selected_child=jQuery("#rm_child_"+row_id_val+"_"+itmcnt_val).val();
                    }
                    
                    /*if(AllowChildAgePolicy==0 && AllowChildAgeOnBaseOccupancy==1)//true
                    {
                       
                        if(selected_child > parseInt(basechild))
                        {
                            var childagelimit=WebChildAge;
                            chkchild_cnt=1;
                            for(childcnt=0;childcnt<selected_child;childcnt++)
                            {			
                                var childage=jQuery('#age_'+row_id_val+'_'+itmcnt_val+'_'+childcnt).val();                                 
                                if(parseInt(childage) > parseInt(childagelimit) && childage!='' && parseInt(chkchild_cnt)>parseInt(basechild))
                                    extra_child++;
                                chkchild_cnt++;    
                            }
                        }
                    }
                    else */if(AllowChildAgePolicy==0)//true
                    {                           
                        var childagelimit=WebChildAge;
                        for(childcnt=0;childcnt<selected_child;childcnt++)
                        {			
                            var childage=jQuery('#age_'+row_id_val+'_'+itmcnt_val+'_'+childcnt).val();                               
                            if(parseInt(childage) > parseInt(childagelimit) && childage!='')
                                extra_child++;
                        }
                    }
                    else //if(parseInt(selected_child) > parseInt(basechild))    //if(parseInt(selected_child) > 7)   
                    {
                        //console.log("selected_child : " + selected_child);
                        //console.log("basechild : " + basechild);
                        extra_child=(parseInt(selected_child)); //- parseInt(basechild)
                    }
                   // console.log("Child "+extra_child+"Adult "+extra_adult+"Child "+extra_child);
                   
                    var apply_extra_adult=0
                    var apply_extra_child=0;
                    
                    if (extra_adult>0) {
                        apply_extra_adult=1;
                    }
                    if (extra_child>0) {
                        apply_extra_child=1;
                    }
                    
                    //Issue in no of nights more then 1 , calculating double amount
                    //extra_child_rate = extra_child_rate + extra_child * childrate;                  
                    //extra_adult_rate = extra_adult_rate + extra_adult * adultrate;
                    if (parseInt(selected_adult)>7) {
                       baserate = parseFloat(eval("rateplan.o_day_adult7_"+i));
                       extra_adult_rate =  (selected_adult-7) * adultrate;
                    }
                    else
                    {
                        baserate = parseFloat(eval("rateplan.o_day_adult"+parseInt(selected_adult)+"_"+i));
                    }
                    
                    if (extra_child > 7) {
                       baserate += parseFloat(eval("rateplan.o_day_child7_"+i));
                       extra_child_rate = (extra_child-7) * childrate;
                       base_child=7;
                    }
                    else
                    {
                        if (extra_child>0) {
                            baserate += parseFloat(eval("rateplan.o_day_child"+parseInt(extra_child)+"_"+i));
                            base_child=extra_child;
                        }
                    }
                    
                   //Find Extra adult & Child + Rates - end    
                  var roomno=parseInt(itmcnt_val)+1;
                   if(eval("rateplan.ShowTaxInclusiveExclusiveSettings")=="1"){                   
                        if (eval("rateplan.Tax_"+(i))!="[0]" //||
                            //typeof(eval("rateplan.Tax_"+(i)))!='undefined'
                            )
                        { 
                          tax_formula=eval("rateplan.Tax_"+(i));
                          //if (resgrid[0][0]['MultiplyFactor']!=1) {
                          //  d=parseFloat(eval("rateplan.before_discount_day_base_"+i)); 
                          //  d=parseFloat(d)*parseFloat(resgrid[0][0]['MultiplyFactor']);
                          //  
                          //  extra_child_rate=parseFloat(extra_child_rate)*parseFloat(resgrid[0][0]['MultiplyFactor']);
                          //  extra_adult_rate=parseFloat(extra_adult_rate)*parseFloat(resgrid[0][0]['MultiplyFactor']);
                          //  
                          //  //console.log("d : " + extra_child_rate + " : " +  d);
                          //  total_tax=getTax(d,tax_formula,_decimalplaces,0,rateplan.RackRate,rateplan.EARate,rateplan.ECRate,selected_adult,selected_child,'base',extra_adult,extra_child);
                          //}
                          //else
                          //{
                           // console.log("tax apply");
                           /*var tax_extra_child;
                           if(extra_child>7)
                            tax_extra_child=extra_child-7;
                           else
                            tax_extra_child=0;*/
                           
                            //For apply on rackrate tax as for that extra adult/child will be calculated from base occupancy
                            if(parseInt(selected_adult)  > parseInt(baseadult))
                              rackratetax_extra_adult=(parseInt(selected_adult)- parseInt(baseadult));
                            if(parseInt(extra_child)  > parseInt(basechild))
                              rackratetax_extra_child=(parseInt(extra_child)- parseInt(basechild));
                              
                            d=parseFloat(baserate)+parseFloat(extra_child_rate)+parseFloat(extra_adult_rate);
                            total_tax=getTax(d,tax_formula,_decimalplaces,0,rateplan.RackRate,rateplan.EARate,rateplan.ECRate,selected_adult,selected_child,'base',rackratetax_extra_adult,rackratetax_extra_child);
                            
                            total_base_tax=getTax(d,tax_formula,_decimalplaces,0,rateplan.RackRate,rateplan.EARate,rateplan.ECRate,base_adult,base_child,'base',rackratetax_extra_adult,rackratetax_extra_child);
//                            console.log("Tax"+total_tax+"total_base_tax"+total_base_tax);

                            if(_decimalplaces=='0') //Chandrakant - 20 April 2021, Purpose - RES-2791
                                d=d.toFixed(_decimalplaces); //Chandrakant - 20 April 2021, Purpose - RES-2791
                            d=methods._applyRoundOff(parseFloat(d)+parseFloat(total_tax));//updated
                            var totalbase=parseFloat(baserate)+parseFloat(total_base_tax);
                         
                            if (row_id_val!='')  eval("resgrid[0]["+row_id_val+"].o_day_base_"+i+"_"+roomno+"="+methods._applyRoundOff(totalbase));
                         }
                         else{
                             //extra adult & Child Rate                            
                             d=baserate+parseFloat(extra_child_rate)+parseFloat(extra_adult_rate);
                             if (row_id_val!='')  eval("resgrid[0]["+row_id_val+"].o_day_base_"+i+"_"+roomno+"="+methods._applyRoundOff(baserate));                           
                         }
                    }
                    else{                       
                           d=baserate+parseFloat(extra_child_rate)+parseFloat(extra_adult_rate);
                           if (row_id_val!='')  eval("resgrid[0]["+row_id_val+"].o_day_base_"+i+"_"+roomno+"="+methods._applyRoundOff(baserate));                  
                       }
                       
                         
                    //d=parseFloat(eval("rateplan.o_day_base_"+i))+parseFloat(extra_child_rate)+parseFloat(extra_adult_rate);                    
                    d = parseFloat(d.toFixed(_display_decimalplaces)); //Falguni Rana
                    if(!(d>=0))
                        d=0;                                       
                    if(parseFloat(_totalBookingAmount)>=0){
                        //Apply fix while tax setting is on , but tax not configured , it was rounding the amount. - flora
                        if(eval("rateplan.ShowTaxInclusiveExclusiveSettings")=="1"){                   
                            if (eval("rateplan.Tax_"+(i))!="[0]"
                                //typeof(eval("rateplan.Tax_"+(i)))!='undefined'
                                )
                            {                                                              
                                _totalBookingAmount+=methods._applyRoundOff(d);//old - default
                            }
                            else
                            {
                                //console.log("else if");
                                //_totalBookingAmount+=(d); //Old one
                                _totalBookingAmount+=methods._applyRoundOff(d); //Pinal - 1.0.50.55 - 10 September 2016 - Purpose - Applying round off
                            }
                        }
                        else
                        {
                            //console.log("else else if");
                            //_totalBookingAmount+=(d); //Old one
                                _totalBookingAmount+=methods._applyRoundOff(d); //Pinal - 1.0.50.55 - 10 September 2016 - Purpose - Applying round off
                        }
                    }                   
                }
                //console.log("_totalBookingAmount : " + d);
               
               if (row_id_val!='') {
                
           
                
                 console.log("Nonlinear Ajax call......baserate"+baserate);
                 
                if(opt.ShowNights==true)
                    var n_night=parseInt($("select#eZ_Nights").val());
                else
                    var n_night=parseInt($("input#eZ_Nights").val());
                                        
              
                  //$('#bookingmulbtn').hide();
                 $('#bookingmulbtn').text(langjs[0]["L_Loading"]);
                 $('#nl_loader').val(1); 
                 var param="action=getNonlinearRate&HotelId="+opt.HotelId+"&roomrateunkid="+rateplan.roomrateunkid+"&roomtypeunkid="+rateplan.roomtypeunkid+"&ratetypeunkid="+rateplan.ratetypeunkid+"&checkin_date="+$("#eZ_chkin").val()+"&nights="+n_night+"&adult="+selected_adult+"&child="+extra_child+"&row_id_val="+row_id_val+"&itmcnt_val="+itmcnt_val+"&CalDtFormat="+opt.CalDtFormat+"&DayCnt="+rateplan.DayCnt;
                 $.ajax({
                        type: "POST", 
                        url: opt.serverurl+'service.php',
                        data: param,
                        success: function(response) {
                           $('#bookingmulbtn').text(langjs[0]["L_NLBOOK"]); //Priyanka Maurya - 13 Jun 2019 - Purpose : Language Caption Issue - RES-2042
                           $('#nl_loader').val(0); 
                         }
                    });
               }
              
                  
	        //console.log("_totalBookingAmount :2 " + parseFloat(resgrid[0][0]['MultiplyFactor']));// if (resgrid[0][0]['MultiplyFactor']==1)
		
		//if (parseFloat(resgrid[0][0]['MultiplyFactor'])!=1) //Pinal - 1.0.50.55 - 10 September 2016 - Purpose - Applying round off for configure exchange rate
		//{
		//    return (methods._applyRoundOff(_totalBookingAmount*parseFloat(resgrid[0][0]['MultiplyFactor'])))+"|"+apply_extra_adult+"|"+apply_extra_child;
		//}
		//else
                //resgrid[0][row_id_val]['o_day_base_1']=eval(100);
              // var row_id_val=1;
               
                
                return ((_totalBookingAmount*parseFloat(resgrid[0][0]['MultiplyFactor'])))+"|"+apply_extra_adult+"|"+apply_extra_child;
                //else
                //return ((_totalBookingAmount))+"|"+apply_extra_adult+"|"+apply_extra_child;
            }
            catch(err){
                hideLoadingBar();
                hideCheckavalabilityLoadingBar();
                console.log("Error - _defaultLongStayRateCalculation_NL - "+err.message);
		
            }
        },
        // Sheetal Panchal - 1.0.52.60 - 7th July 2017, Purpose - For Non linear rate mode setting - END
        
         _specialLongStayRateCalculation:function(rateplan,spfm,promosarray,row_id_val,itmcnt_val,row_count_val,maxchildshow){
            //Chandrakant - 1.0.53.61 - 09 November 2017 - Purpose : maxchildshow is added to check system runs on max occupancy settings or not //Parth - 11 Jan 2019 - PromoDailyDiscount - Apply daily discount on promotion(promos to promosarray).
            try{
                console.log("_specialLongStayRateCalculation");
                var maxchildshow = maxchildshow || ''; //Chandrakant - 1.0.53.61 - 09 November 2017
                //kishan Tailor 24 Jun 2019 purpose :Package Overlapping RES-1800 START
                //if(typeof(spfm)!='undefined' && spfm!='' && spfm!=null){
                //    spfm=spfm.split(':');
                //    frml=spfm[1].split("|");
                //}
                //else
                //{
                //    spfm='';                    
                //}
                //kishan Tailor 24 Jun 2019 purpose :Package Overlapping RES-1800 END
                
                //Parth - 11 Jan 2019 - PromoDailyDiscount - Apply daily discount on promotion(Put into comment). - START
                /*if(typeof(promos)!='undefined' && promos!='' && promos!=null)
                    promos=promos.split(':');
                else
                    promos='';*/
                //Parth - 11 Jan 2019 - PromoDailyDiscount - END
                    
                var total=0;
                var b_amount=0;

                var ind=0;
                var i=-1;
                
                var extra_child_rate=0;
                var extra_adult_rate=0;
                 
                _totalBookingAmount=0.00;
                
                if (row_id_val!='') {
                   rateplan=resgrid[0][row_id_val];                  
                }
                
                //child age policy
                var AllowChildAgePolicy=eval("rateplan.AllowChildAgePolicy");	                        
                var AllowChildAgeOnBaseOccupancy=eval("rateplan.AllowChildAgeOnBaseOccupancy");
                var WebChildAge=eval("rateplan.WebChildAge");	                        
                var WebMaxChildAge=eval("rateplan.WebMaxChildAge");
                var ApplyPackageOnExtras = resgrid[0][0].ApplyPackageOnExtras; //Priyanka Maurya - 02 March 2020 - Purpose : RES-1825
                var slabbefore_discount = jQuery("#slab_before_discount").val(); //Chandrakant - 1.0.53.61 - 27 November 2017
                while(typeof(eval("rateplan.dt_"+eval(++ind)))!='undefined'){
                    i++;
                    b_amount=parseFloat(eval("rateplan.before_discount_day_base_"+ind));
                    b_amountGST=parseFloat(eval("rateplan.before_discount_day_base_"+ind));
                    a_tax=false;
                    
                    adultrate=parseFloat(eval("rateplan.o_day_extra_adult_"+ind));
                    childrate=parseFloat(eval("rateplan.o_day_extra_child_"+ind));
                    
                    adultrate_GST=parseFloat(eval("rateplan.o_day_extra_adult_"+ind));
                    childrate_GST=parseFloat(eval("rateplan.o_day_extra_child_"+ind));
                        
                    //kishan Tailor 24 Jun 2019 purpose :Package Overlapping RES-1800 START
                    if(typeof(spfm[i])!='undefined' && spfm[i]!='' && spfm[i]!=null){//&& typeof(frml)!='undefined' && frml!=''
                        spfm1=spfm[i].split(':');
                        if(spfm1[1]!=undefined && spfm1[1]!='')
                        {
                        frml=spfm1[1].split("|");
                        //kishan Tailor 24 Jun 2019 purpose :Package Overlapping RES-1800 END
                        nn=parseFloat(frml[1]);
                        tn=parseFloat(frml[2]);
                        r=frml[3];
                        switch(frml[0]){
                            case 'PAYSTAYDEAL':
                                r=frml[3];
                                j=i+1;								
                                if((((j % tn > nn || j%tn==0) && r=='Y') || (j<=tn && j>nn && r!='Y')) && eval("rateplan.isdisplayregularrate_"+ind)=='N')
                                    b_amount=0.00;
                                break;
                            case 'PACKAGEDEAL':                               
							   if((i<nn || r=='Y') && eval("rateplan.isdisplayregularrate_"+ind)=='N'){
									srate=tn;
									b_amount=parseFloat(eval("rateplan.before_discount_day_base_"+ind));
									if(frml[4]=="AMT"){
										b_amount=b_amount-srate;
									}
									else if(frml[4]=="PER"){
										b_amount=(b_amount*(100-srate))/(100);
                                        
                                        //Priyanka Maurya - 27 Feb 2020 - Purpose : RES-1825
                                        if(ApplyPackageOnExtras=='1') 
                                        {
                                            adultrate=(adultrate*(100-srate))/(100);
                                            childrate=(childrate*(100-srate))/(100);
                                        }
                                        //Priyanka Maurya - END
									}
									else
										 //b_amountGST=b_amount=srate; //Chandrakant - 1.0.53.61 - 27 November 2017 Purpose : b_amountGST added
                                         b_amount=srate;
								}
                                break;
                            case "PERCENTDISCOUNT":
                            case "AMOUNTDISCOUNT":
                                srate=parseFloat(frml[1]);
                                
                                b_amount=parseFloat(eval("rateplan.before_discount_day_base_"+ind));
								
                                if(frml[0]=="AMOUNTDISCOUNT" && eval("rateplan.isdisplayregularrate_"+ind)=='N'){
                                    b_amount=b_amount-srate;
                                }
                                else if(frml[0]=="PERCENTDISCOUNT" && eval("rateplan.isdisplayregularrate_"+ind)=='N'){
                                    b_amount=(b_amount*(100-srate))/(100);
                                    
                                    if(ApplyPackageOnExtras=='1')
                                    {
                                        adultrate=(adultrate*(100-srate))/(100);
                                        childrate=(childrate*(100-srate))/(100);
                                    }
                                }
                                break;
                        }
                        }
                    }
                    if(promosarray[i]!=undefined && promosarray[i]!='')//Parth - 11 Jan 2019 - PromoDailyDiscount - Apply daily discount on promotion(Add promosarray) (typeof(promos)!='undefined' && promos!='' && promos!=null &&)
                    {
                        //drate=parseFloat(promos[3]);
                        
                        //Parth - 11 Jan 2019 - PromoDailyDiscount - Apply daily discount on promotion. - START
                        promos=promosarray[i].split(':');
                        drate=parseFloat(promos[3]);
                        //Parth - 11 Jan 2019 - PromoDailyDiscount - END
                        
                        switch(promos[1]){
                            case "FN":
                                if(i==0 && b_amount>0 && eval("rateplan.isdisplayregularrate_"+ind)=='N'){
                                    if(promos[4]=="AMT"){
                                        b_amount=b_amount-drate;
                                    }
                                    else if(promos[4]=="PER"){
                                        b_amount=(b_amount*(100-drate))/(100);
                                    }
                                }
                                break;
                            case "LN":
                                if(((i+1)==(eval("rateplan.DayCnt"))) &&  b_amount>0 && eval("rateplan.isdisplayregularrate_"+ind)=='N'){							         if(b_amount>0){
                                        if(promos[4]=="AMT"){
                                            b_amount=b_amount-drate;
                                        }
                                        else if(promos[4]=="PER"){
                                            b_amount=(b_amount*(100-drate))/(100);
                                        }
                                    }
                                }								
                                break;
                            case "AE":
                                ae=parseInt(promos[2])+1;
                                if((i+1) % ae ==0 && b_amount>0  && eval("rateplan.isdisplayregularrate_"+ind)=='N'){
                                    if(promos[4]=="AMT"){
                                        b_amount=b_amount-drate;
                                    }
                                    else if(promos[4]=="PER"){
                                        b_amount=(b_amount*(100-drate))/(100);
                                    }
                                }
                                break;                            
                            case "AN":							
                                if(promos[4]=="AMT"  && eval("rateplan.isdisplayregularrate_"+ind)=='N'){
                                    b_amount=b_amount-drate;
                                }
                                else if(promos[4]=="PER"  && eval("rateplan.isdisplayregularrate_"+ind)=='N'){
                                    b_amount=(b_amount*(100-drate))/(100);
                                }
                                break;
                        }
                    }
                    
                    //Chandrakant - 1.0.54.62 - 22 February 2018 - START
                    //Purpose : Disable below condition because tax not apply on rate plan after applying promotion when no package is create in system
                    //if(typeof(frml)!='undefined' && frml!='')
                    //{
                    //    if((b_amount>=0 && frml[0] == 'PAYSTAYDEAL') || (b_amount>0 && frml[0] != 'PAYSTAYDEAL'))//Chinmay Gandhi - 1.0.52.60 - add tax on free days
                    //    {
                    if(b_amount>=0)
                    {
                    //Chandrakant - 1.0.54.62 - 22 February 2018 - END
                        //Find Extra adult & Child + Rates - start
                        //adultrate=parseFloat(eval("rateplan.o_day_extra_adult_"+ind));
                        //childrate=parseFloat(eval("rateplan.o_day_extra_child_"+ind));
                        // console.log("befor e adultrate "+  adultrate);                  
                        var baseadult=eval("rateplan.base_adult");	                        
                        var basechild=eval("rateplan.base_child");
                        
                        var extra_adult=0;
                        var selected_adult=0;
                        //case added to default set extra adult rate calculation while check availability with more adults & child - flora
                        
                        if ( jQuery("#rm_adult_"+row_id_val+"_"+itmcnt_val).val()==''
                        || jQuery("#rm_adult_"+row_id_val+"_"+itmcnt_val).val()==undefined) {
                            //Chandrakant - 2.0.12 - 22 October 2018 - START
                            //Purpose : RES-1774
                            if (resgrid[0][0].RunOnMaximumOccupanySettings=='1' && resgrid[0][0].acr=='false')
                            {
                                if (resgrid[0][0].ResUIPaxOnMaximumOccupancy=='1')
                                {
                                    var maximum_occupancy_val= eval("rateplan.max_occupancy");
                                    if (maximum_occupancy_val>eval("rateplan.max_adult")) {
                                        selected_adult=eval("rateplan.max_adult");
                                    }
                                    else
                                    {
                                        selected_adult=maximum_occupancy_val;
                                    }
                                }
                                else
                                    selected_adult=eval("rateplan.base_adult");
                            }
                            else
                                selected_adult=jQuery( "#rm_adult_"+row_count_val+"_0").val();                         
                            //Chandrakant - 2.0.12 - 22 October 2018 - END
                        }
                        else
                            selected_adult=jQuery("#rm_adult_"+row_id_val+"_"+itmcnt_val).val();
                        
                        if(parseInt(selected_adult) > parseInt(baseadult))                    
                                extra_adult=(parseInt(selected_adult) - parseInt(baseadult));
                        
                        var extra_child=0;                        
                        var selected_child=0;
                        //case added to default set extra adult rate calculation while check availability with more adults & child - flora
                        
                        if(maxchildshow!='' && maxchildshow!=false) //Chandrakant - 1.0.53.61 - 09 November 2017
                        {
                            if ( jQuery("#rm_child_"+row_id_val+"_"+itmcnt_val).val()==''
                            || jQuery("#rm_child_"+row_id_val+"_"+itmcnt_val).val()==undefined) {
                                //Chandrakant - 2.0.12 - 22 October 2018 - START
                                //Purpose : RES-1774
                                if (resgrid[0][0].RunOnMaximumOccupanySettings=='1' && resgrid[0][0].acr=='false')
                                {
                                    if (resgrid[0][0].ResUIPaxOnMaximumOccupancy=='1')
                                    {
                                        if (eval('rateplan.max_occupancy')>=eval('rateplan.max_adult'))
                                        {
                                            var selected_child=eval('rateplan.max_occupancy')-eval('rateplan.max_adult');
                                            if (selected_child>eval('rateplan.max_child'))
                                            {
                                                var selected_child=eval('rateplan.max_child');
                                            }
                                        }
                                        else
                                            var selected_child=0;
                                    }
                                    else
                                        selected_child=eval("rateplan.base_child");
                                }
                                else
                                //Chandrakant - 2.0.12 - 22 October 2018 - END
                                    selected_child=jQuery( "#rm_child_"+row_count_val+"_0").val();                         
                                
                            }
                            else
                                selected_child=jQuery("#rm_child_"+row_id_val+"_"+itmcnt_val).val();                            
                        }
                                                
                        if(AllowChildAgePolicy==0 && AllowChildAgeOnBaseOccupancy==1)//true
                        {                           
                            if(selected_child > parseInt(basechild))
                            {
                                var childagelimit=WebChildAge;
                                chkchild_cnt=1;
                                for(childcnt=0;childcnt<selected_child;childcnt++)
                                {			
                                    var childage=jQuery('#age_'+row_id_val+'_'+itmcnt_val+'_'+childcnt).val();                                 
                                    if(parseInt(childage) > parseInt(childagelimit) && childage!='' && parseInt(chkchild_cnt)>parseInt(basechild))
                                        extra_child++;
                                    chkchild_cnt++;    
                                }
                            }
                        }
                        else if(AllowChildAgePolicy==0)//true
                        {                           
                            var childagelimit=WebChildAge;
                            for(childcnt=0;childcnt<selected_child;childcnt++)
                            {			
                                var childage=jQuery('#age_'+row_id_val+'_'+itmcnt_val+'_'+childcnt).val();                               
                                if(parseInt(childage) > parseInt(childagelimit) && childage!='')
                                    extra_child++;
                            }
                        }
                        else if(parseInt(selected_child) > parseInt(basechild))                    
                                extra_child=(parseInt(selected_child) - parseInt(basechild));
                        
                        var apply_extra_adult=0
                        var apply_extra_child=0;
                        
                        if (extra_adult>0) {
                            apply_extra_adult=1;
                        }
                        if (extra_child>0) {
                            apply_extra_child=1;
                        }
                        
                       // extra_child_rate = extra_child_rate + extra_child * childrate;
                       // extra_adult_rate = extra_adult_rate + extra_adult * adultrate;
                        
                        extra_child_rate =  extra_child * childrate;
                        extra_adult_rate =  extra_adult * adultrate;
                        
                        var extra_child_rate_GST =  extra_child * childrate_GST;
                        var extra_adult_rate_GST =  extra_adult * adultrate_GST;
                        
                        //Find Extra adult & Child + Rates - end
                        //console.log("extra_adult_rate : " + adultrate + " : " + extra_adult_rate);
                        if(eval("rateplan.ShowTaxInclusiveExclusiveSettings")=="1")
                        {
                           if (
                               eval("rateplan.Tax_"+(i+1))!="[0]"
                               //typeof(eval("rateplan.Tax_"+(i)))!='undefined'
                               )
                           {
                                tax_formula=eval("rateplan.Tax_"+(i+1));
                                //Chandrakant - 1.0.53.61 - 27 November 2017 - START
                                //Purpose : Find Slab using base Rate not discount rate
                                //console.log("base amount GST : " +b_amountGST );
                                //console.log("base amount : " +b_amount );
                                b_amount=b_amount+parseFloat(extra_child_rate)+parseFloat(extra_adult_rate);
                                if(slabbefore_discount==1)
                                {
                                    b_amountGST=b_amountGST+parseFloat(extra_child_rate_GST)+parseFloat(extra_adult_rate_GST);
                                    total_tax=getTax(b_amountGST,tax_formula,_decimalplaces,0,rateplan.RackRate,rateplan.EARate,rateplan.ECRate,selected_adult,selected_child,'base',extra_adult,extra_child);
                                }
                                else
                                {
                                    total_tax=getTax(b_amount,tax_formula,_decimalplaces,0,rateplan.RackRate,rateplan.EARate,rateplan.ECRate,selected_adult,selected_child,'base',extra_adult,extra_child);    
                                }
                                //console.log("extra_child_rate : " +extra_child_rate );
                                //console.log("extra_adult_rate : " +extra_adult_rate );
                                //console.log("totalamount : " +b_amount );
                                //console.log("total_tax : " +total_tax );
                                //console.log('-------------------------------------------');
                                //Chandrakant - 1.0.53.61 - 27 November 2017 - END                                        //b_amount=methods._applyRoundOff(parseFloat(b_amount)+parseFloat(extra_child_rate)+parseFloat(extra_adult_rate)+parseFloat(total_tax));//before
                                if(_decimalplaces=='0') //Chandrakant - 20 April 2021, Purpose - RES-2791
                                    b_amount=b_amount.toFixed(_decimalplaces); //Chandrakant - 20 April 2021, Purpose - RES-2791
                                b_amount=methods._applyRoundOff(parseFloat(b_amount)+parseFloat(total_tax));//updated
                               // console.log("b_amount : " +b_amount ); 
                            }
                            else
                            {
                                //extra adult & Child Rate
                                //b_amount=b_amount+parseFloat(extra_child_rate)+parseFloat(extra_adult_rate); //Old One
                                b_amount=methods._applyRoundOff(b_amount+parseFloat(extra_child_rate)+parseFloat(extra_adult_rate)); //Pinal - 1.0.50.55 - 10 September 2016 - Purpose - Applying round off
                            }
                        }
                        else
                        {
                            //extra adult & Child Rate
                            //b_amount=b_amount+parseFloat(extra_child_rate)+parseFloat(extra_adult_rate); //Old One
                            b_amount=methods._applyRoundOff(b_amount+parseFloat(extra_child_rate)+parseFloat(extra_adult_rate)); //Pinal - 1.0.50.55 - 10 September 2016 - Purpose - Applying round off
                        }
                    }
                    b_amount = parseFloat(b_amount.toFixed(_display_decimalplaces)); //Falguni Rana
                    if(b_amount>0)
                    {                        
                        total=total+b_amount;
                    }					
                }
                return ((total*parseFloat(resgrid[0][0]['MultiplyFactor'])))+"|"+apply_extra_adult+"|"+apply_extra_child;            
            }
            catch(err){
                hideLoadingBar();
                hideCheckavalabilityLoadingBar();
                console.log("Error - _specialLongStayRateCalculation - "+err.message);
		
            }
        },
        
        // Sheetal Panchal - 1.0.52.60 - 20th July 2017, Purpose - For Non linear rate mode setting - START
         _specialLongStayRateCalculation_NL:function(opt,rateplan,spfm,promosarray,row_id_val,itmcnt_val,row_count_val,maxchildshow){
            //Chandrakant - 1.0.53.61 - 09 November 2017 - Purpose : maxchildshow is added to check system runs on max occupancy settings or not
            //Parth - 11 Jan 2019 - PromoDailyDiscount - Apply daily discount on promotion. (promos to promosarray)
            try{
                console.log("_specialLongStayRateCalculation_NL");
                var maxchildshow = maxchildshow || ''; //Chandrakant - 1.0.53.61 - 09 November 2017
                //kishan Tailor 24 Jun 2019 purpose :Package Overlapping RES-1800 START
                //if(typeof(spfm)!='undefined' && spfm!='' && spfm!=null){
                //    spfm=spfm.split(':');
                //    frml=spfm[1].split("|");
                //}
                //else
                //    spfm='';
                //kishan Tailor 24 Jun 2019 purpose :Package Overlapping RES-1800 END
                    
                 //Parth - 11 Jan 2019 - PromoDailyDiscount - Apply daily discount on promotion. (Put into comment) - START
                /*if(typeof(promos)!='undefined' && promos!='' && promos!=null)
                    promos=promos.split(':');
                else
                    promos='';*/
                //Parth - 11 Jan 2019 - PromoDailyDiscount - END
                    
                var total=0;
                var b_amount=0;

                var ind=0;
                var i=-1;
                
                var extra_child_rate=0;
                var extra_adult_rate=0;
                var extra_adult_rate_GST=0;
                var extra_child_rate_GST=0;
                var base_adult=0;
                var base_child=0;
                _totalBookingAmount=0.00;
                
                if (row_id_val!='') {
                   rateplan=resgrid[0][row_id_val];                  
                }
                var rateGST=0; //Chandrakant - 1.0.53.61 - 05 December 2017
                //child age policy
                var AllowChildAgePolicy=eval("rateplan.AllowChildAgePolicy");	                        
                var AllowChildAgeOnBaseOccupancy=eval("rateplan.AllowChildAgeOnBaseOccupancy");
                var WebChildAge=eval("rateplan.WebChildAge");	                        
                var WebMaxChildAge=eval("rateplan.WebMaxChildAge");
                var slabbefore_discount = jQuery("#slab_before_discount").val(); //Chandrakant - 1.0.53.61 - 05 December 2017
                var ApplyPackageOnExtras= resgrid[0][0].ApplyPackageOnExtras; //Priyanka Maurya - 02 March 2020 - Purpose : RES-1825
                while(typeof(eval("rateplan.dt_"+eval(++ind)))!='undefined'){
                    i++;
                  
                    //base rate
                     //  b_amount=parseFloat(eval("rateplan.before_discount_day_base_"+ind));
                 
                    //Find Extra adult & Child + Rates - start
                        adultrate=parseFloat(eval("rateplan.o_day_extra_adult_"+ind));
                        childrate=parseFloat(eval("rateplan.o_day_extra_child_"+ind));

                        adultrate_GST=parseFloat(eval("rateplan.o_day_extra_adult_"+ind));
                        childrate_GST=parseFloat(eval("rateplan.o_day_extra_child_"+ind));
                 
                        var baseadult=eval("rateplan.base_adult");	                        
                        var basechild=eval("rateplan.base_child");
                        
                        var extra_adult=0;
                        var selected_adult=0;
                        
                        var rackratetax_extra_adult=0;
                        var rackratetax_extra_child=0;
                        //case added to default set extra adult rate calculation while check availability with more adults & child - flora
                        
                        if ( jQuery("#rm_adult_"+row_id_val+"_"+itmcnt_val).val()==''
                        || jQuery("#rm_adult_"+row_id_val+"_"+itmcnt_val).val()==undefined) {
                            //Chandrakant - 2.0.12 - 22 October 2018 - START
                            //Purpose : RES-1774
                            if (resgrid[0][0].RunOnMaximumOccupanySettings=='1' && resgrid[0][0].acr=='false')
                            {
                                if (resgrid[0][0].ResUIPaxOnMaximumOccupancy=='1')
                                {
                                    var maximum_occupancy_val= eval("rateplan.max_occupancy");
                                    if (maximum_occupancy_val>eval("rateplan.max_adult")) {
                                        selected_adult=eval("rateplan.max_adult");
                                    }
                                    else
                                    {
                                        selected_adult=maximum_occupancy_val;
                                    }
                                }
                                else
                                    selected_adult=eval("rateplan.base_adult");
                            }
                            else
                            //Chandrakant - 2.0.12 - 22 October 2018 - END
                                selected_adult=jQuery( "#rm_adult_"+row_count_val+"_0").val();                         
                        }
                        else                    
                            selected_adult=jQuery("#rm_adult_"+row_id_val+"_"+itmcnt_val).val();
                        
                        if(parseInt(selected_adult) > parseInt(baseadult))                    
                                extra_adult=(parseInt(selected_adult) - parseInt(baseadult));
                                                
                        var extra_child=0;                        
                        var selected_child=0;
                        //case added to default set extra adult rate calculation while check availability with more adults & child - flora
                        if(maxchildshow!='' && maxchildshow!=false) //Chandrakant - 1.0.53.61 - 09 November 2017
                        {
                            if ( jQuery("#rm_child_"+row_id_val+"_"+itmcnt_val).val()==''
                            || jQuery("#rm_child_"+row_id_val+"_"+itmcnt_val).val()==undefined) {
                                //Chandrakant - 2.0.12 - 22 October 2018 - START
                                //Purpose : RES-1774
                                if (resgrid[0][0].RunOnMaximumOccupanySettings=='1' && resgrid[0][0].acr=='false')
                                {
                                    if (resgrid[0][0].ResUIPaxOnMaximumOccupancy=='1')
                                    {
                                        if (eval('rateplan.max_occupancy')>=eval('rateplan.max_adult'))
                                        {
                                            var selected_child=eval('rateplan.max_occupancy')-eval('rateplan.max_adult');
                                            if (selected_child>eval('rateplan.max_child'))
                                            {
                                                var selected_child=eval('rateplan.max_child');
                                            }
                                        }
                                        else
                                            var selected_child=0;
                                    }
                                    else
                                        selected_child=eval("rateplan.base_child");
                                }
                                else
                                //Chandrakant - 2.0.12 - 22 October 2018 - END
                                    selected_child=jQuery( "#rm_child_"+row_count_val+"_0").val();
                              }
                            else
                            {
                                selected_child=jQuery("#rm_child_"+row_id_val+"_"+itmcnt_val).val();                            
                            }
                        }
                                                
                       /* if(AllowChildAgePolicy==0 && AllowChildAgeOnBaseOccupancy==1)//true
                        {                           
                            if(selected_child > parseInt(basechild))
                            {
                                var childagelimit=WebChildAge;
                                chkchild_cnt=1;
                                for(childcnt=0;childcnt<selected_child;childcnt++)
                                {			
                                    var childage=jQuery('#age_'+row_id_val+'_'+itmcnt_val+'_'+childcnt).val();                                 
                                    if(parseInt(childage) > parseInt(childagelimit) && childage!='' && parseInt(chkchild_cnt)>parseInt(basechild))
                                        extra_child++;
                                    chkchild_cnt++;    
                                }
                            }
                        }
                        else */if(AllowChildAgePolicy==0)//true
                        {                           
                            var childagelimit=WebChildAge;
                            for(childcnt=0;childcnt<selected_child;childcnt++)
                            {			
                                var childage=jQuery('#age_'+row_id_val+'_'+itmcnt_val+'_'+childcnt).val();                               
                                if(parseInt(childage) > parseInt(childagelimit) && childage!='')
                                    extra_child++;
                            }
                        }
                        else
                            extra_child=(parseInt(selected_child)) ;
                        
                        var apply_extra_adult=0
                        var apply_extra_child=0;
                        
                       /* if (extra_adult>0) {
                            apply_extra_adult=1;
                        }
                        if (extra_child>0) {
                            apply_extra_child=1;
                        }*/
                         if (parseInt(selected_adult)>7) {
                            baserate = parseFloat(eval("rateplan.o_day_adult7_"+ind));
                            extra_adult_rate =  (selected_adult-7) * adultrate;
                            extra_adult_rate_GST =  (selected_adult-7) * adultrate_GST;
                            apply_extra_adult=1;
                            base_adult=7;
                         }
                         else
                         {
                             baserate = parseFloat(eval("rateplan.o_day_adult"+parseInt(selected_adult)+"_"+ind));
                             base_adult=selected_adult;
                         }

                         if (extra_child > 7) {
                            //baserate += parseFloat(eval("rateplan.o_day_child_"+ind));
                            baserate += parseFloat(eval("rateplan.o_day_child7_"+ind));
                            extra_child_rate = (extra_child-7) * childrate;
                            extra_child_rate_GST =  (extra_child-7) * childrate_GST;
                            apply_extra_child=1;
                            base_child=7;
                         }
                         else
                         {
                            if (extra_child>0) {
                                baserate += parseFloat(eval("rateplan.o_day_child"+parseInt(extra_child)+"_"+ind));
                                base_child=extra_child;
                            }
                         }
                         
                         rateGST=b_amount=baserate; //Chandrakant - 1.0.53.61 - 05 December 2017
                        
                     //end extra adult/child
                    a_tax=false;
                    //kishan Tailor 24 Jun 2019 purpose :Package Overlapping RES-1800 START
                    if(typeof(spfm[i])!='undefined' && spfm[i]!='' && spfm[i]!=null){//&& typeof(frml)!='undefined' && frml!=''
                        console.log(spfm[i]);
                        spfm1=spfm[i].split(':');
                        if(spfm1[1]!=undefined && spfm1[1]!='')
                        {
                        frml=spfm1[1].split("|");
                        //kishan Tailor 24 Jun 2019 purpose :Package Overlapping RES-1800 END
                        nn=parseFloat(frml[1]);
                        tn=parseFloat(frml[2]);
                        r=frml[3];
                        switch(frml[0]){
                            case 'PAYSTAYDEAL':
                                r=frml[3];
                                j=i+1;								
                                if((((j % tn > nn || j%tn==0) && r=='Y') || (j<=tn && j>nn && r!='Y')) && eval("rateplan.isdisplayregularrate_"+ind)=='N')
                                    b_amount=0.00;                                
                                break;
                            case 'PACKAGEDEAL':                               
							   if((i<nn || r=='Y') && eval("rateplan.isdisplayregularrate_"+ind)=='N'){
									srate=tn;
									//b_amount=parseFloat(eval("rateplan.before_discount_day_base_"+ind));
									if(frml[4]=="AMT"){
										b_amount=b_amount-srate;
									}
									else if(frml[4]=="PER"){
										b_amount=(b_amount*(100-srate))/(100);
                                        
                                        //Priyanka Maurya - 27 Feb 2020 - Purpose : RES-1825
                                        if(ApplyPackageOnExtras=='1') 
                                        {
                                            extra_adult_rate=(extra_adult_rate*(100-srate))/(100);
                                            extra_child_rate=(extra_child_rate*(100-srate))/(100);
                                        }
                                        //Priyanka Maurya - END
									}
									else
										 //rateGST=b_amount=srate; //Chandrakant - 1.0.53.61 - 05 December 2017
                                         //b_amount=srate; //Chandrakant - 1.0.53.61 - 05 December 2017
                                         b_amount = b_amount; // Priyanka Maurya - 01 March 2019 - Purpose : Solve the NonLinear Package Issue - RES-1721  
								}
	                               
                                break;
                            case "PERCENTDISCOUNT":
                            case "AMOUNTDISCOUNT":
                                srate=parseFloat(frml[1]);
                                
                              //  b_amount=parseFloat(eval("rateplan.before_discount_day_base_"+ind));
								
                                if(frml[0]=="AMOUNTDISCOUNT" && eval("rateplan.isdisplayregularrate_"+ind)=='N'){
                                    b_amount=b_amount-srate;
                                }
                                else if(frml[0]=="PERCENTDISCOUNT" && eval("rateplan.isdisplayregularrate_"+ind)=='N'){
                                    b_amount=(b_amount*(100-srate))/(100);
                                    
                                    //Priyanka Maurya - 27 Feb 2020 - Purpose : RES-1825
                                    if(ApplyPackageOnExtras=='1') 
                                    {
                                        extra_adult_rate=(extra_adult_rate*(100-srate))/(100);
                                        extra_child_rate=(extra_child_rate*(100-srate))/(100);
                                    }
                                    //Priyanka Maurya - END
                                }                               
                                break;
                        }
                        }
                    }
                    
                    if(promosarray[i]!=undefined && promosarray[i]!='')//Parth - 11 Jan 2019 - PromoDailyDiscount - Apply daily discount on promotion. (typeof(promos)!='undefined' && promos!='' && promos!=null && ) // Priyanka Maurya - 24 April 2019 - Purpose : Promotion is not Applying the Booking Engine First Page. So replace the ind index to i
                    {
                        //drate=parseFloat(promos[3]);
                        
                        //Parth - 11 Jan 2019 - PromoDailyDiscount - Apply daily discount on promotion. - START
                        promos=promosarray[i].split(':');
                        drate=parseFloat(promos[3]);
                        //Parth - 11 Jan 2019 - PromoDailyDiscount - END
                        
                        switch(promos[1]){
                            case "FN":
                                if(i==0 && b_amount>0 && eval("rateplan.isdisplayregularrate_"+ind)=='N'){
                                    if(promos[4]=="AMT"){
                                        b_amount=b_amount-drate;
                                    }
                                    else if(promos[4]=="PER"){
                                        b_amount=(b_amount*(100-drate))/(100);
                                    }
                                }
                                break;
                            case "LN":
                                if(((i+1)==(eval("rateplan.DayCnt"))) &&  b_amount>0 && eval("rateplan.isdisplayregularrate_"+ind)=='N'){							         if(b_amount>0){
                                        if(promos[4]=="AMT"){
                                            b_amount=b_amount-drate;
                                        }
                                        else if(promos[4]=="PER"){
                                            b_amount=(b_amount*(100-drate))/(100);
                                        }
                                    }
                                }								
                                break;
                            case "AE":
                                ae=parseInt(promos[2])+1;
                                if((i+1) % ae ==0 && b_amount>0  && eval("rateplan.isdisplayregularrate_"+ind)=='N'){
                                    if(promos[4]=="AMT"){
                                        b_amount=b_amount-drate;
                                    }
                                    else if(promos[4]=="PER"){
                                        b_amount=(b_amount*(100-drate))/(100);
                                    }
                                }
                                break;                            
                            case "AN":							
                                if(promos[4]=="AMT"  && eval("rateplan.isdisplayregularrate_"+ind)=='N'){
                                    b_amount=b_amount-drate;
                                }
                                else if(promos[4]=="PER"  && eval("rateplan.isdisplayregularrate_"+ind)=='N'){
                                    b_amount=(b_amount*(100-drate))/(100);
                                }
                                break;
                        }
                    }
                    
                    //Chandrakant - 1.0.54.62 - 22 February 2018 - START
                    //Purpose : Disable below condition because tax not apply on rate plan after applying promotion when no package is create in system
                    //Chinmay Gandhi - 1.0.52.60 - add tax on free days - Start
                    /*if(typeof(frml)!='undefined' && frml!='')
                    {
                        if((b_amount>=0 && frml[0] == 'PAYSTAYDEAL') || (b_amount>0 && frml[0] != 'PAYSTAYDEAL'))
                        {*///Chinmay Gandhi - 1.0.52.60 - End
                    if(b_amount>=0)
                    {
                    //Chandrakant - 1.0.54.62 - 22 February 2018 - END
                        roomno=parseInt(itmcnt_val)+1;
                        //console.log("ind"+ind+"Roomno"+roomno);
                        //Find Extra adult & Child + Rates - end
                        //console.log("extra_adult_rate : " + adultrate + " : " + extra_adult_rate);
                        if(eval("rateplan.ShowTaxInclusiveExclusiveSettings")=="1"){
                           if (
                               eval("rateplan.Tax_"+(i+1))!="[0]"
                               //typeof(eval("rateplan.Tax_"+(i)))!='undefined'
                               )
                           {
                            /*var tax_extra_child;
                            if(extra_child>7)
                                tax_extra_child=extra_child-7;
                            else
                                tax_extra_child=0;
                            */
                            
                                 //For apply on rackrate tax as for that extra adult/child will be calculated from base occupancy
                            if(parseInt(selected_adult)  > parseInt(baseadult))
                              rackratetax_extra_adult=(parseInt(selected_adult)- parseInt(baseadult));
                            if(parseInt(extra_child)  > parseInt(basechild))
                              rackratetax_extra_child=(parseInt(extra_child)- parseInt(basechild));
                           
                           
                             tax_formula=eval("rateplan.Tax_"+(i+1));
                             totalbase=b_amount;
                             b_amount=b_amount+parseFloat(extra_child_rate)+parseFloat(extra_adult_rate); 
                             
                            //Chandrakant - 1.0.53.61 - 05 December 2017 - START
                            //Purpose : Find Slab using base Rate not discount rate
                            //b_amount=b_amount+parseFloat(extra_child_rate)+parseFloat(extra_adult_rate); //added //added [ Priyanka Maurya - 03 April 2019 - Purpose : During this extra Adult or Child rate is added two times - Display issue in booking Page - RES-1721 ]
                            if(slabbefore_discount==1)
                            {
                                rateGST=rateGST+parseFloat(extra_child_rate_GST)+parseFloat(extra_adult_rate_GST); //added
                                total_tax=getTax(rateGST,tax_formula,_decimalplaces,0,rateplan.RackRate,rateplan.EARate,rateplan.ECRate,selected_adult,selected_child,'base',rackratetax_extra_adult,rackratetax_extra_child);  //Pinal - 10 May 2019 - Purpose : RES-1825
                            }
                            else
                            {
                                total_tax=getTax(b_amount,tax_formula,_decimalplaces,0,rateplan.RackRate,rateplan.EARate,rateplan.ECRate,selected_adult,selected_child,'base',rackratetax_extra_adult,rackratetax_extra_child);
                            }
                            //Chandrakant - 1.0.53.61 - 05 December 2017 - END
                             //console.log("total_tax : " + extra_adult + " : " + total_tax + " : " + b_amount + " : " + extra_child_rate + " : " + extra_adult_rate + " : " +total_tax );
                             // Sheetal Panchal - 1.0.52.60 - 23rd Sep 2017, Purpose fixes of extra adult/child rate is not applicable for rackrate tax.passed rackratetax_extra_adult,rackratetax_extra_child

                             var total_base_tax=getTax(totalbase,tax_formula,_decimalplaces,0,rateplan.RackRate,rateplan.EARate,rateplan.ECRate,base_adult,base_child,'base',rackratetax_extra_adult,rackratetax_extra_child);
                             var totalbase=parseFloat(totalbase)+parseFloat(total_base_tax);
                           
                             //totalbase+=parseFloat(total_tax);
                            if (row_id_val!='')  eval("resgrid[0]["+row_id_val+"].o_day_base_"+ind+"_"+roomno+"="+methods._applyRoundOff(totalbase));                           
                             //b_amount=methods._applyRoundOff(parseFloat(b_amount)+parseFloat(extra_child_rate)+parseFloat(extra_adult_rate)+parseFloat(total_tax));//before
                             
                             if(_decimalplaces=='0') //Chandrakant - 20 April 2021, Purpose - RES-2791
                                b_amount=b_amount.toFixed(_decimalplaces); //Chandrakant - 20 April 2021, Purpose - RES-2791
                             b_amount=methods._applyRoundOff(parseFloat(b_amount)+parseFloat(total_tax));//updated
                           
                             //console.log("b_amount : " +b_amount ); 
                            }
                            else{
                                //extra adult & Child Rate
                                //b_amount=b_amount+parseFloat(extra_child_rate)+parseFloat(extra_adult_rate); //Old One
                                if (row_id_val!='')  eval("resgrid[0]["+row_id_val+"].o_day_base_"+ind+"_"+roomno+"="+methods._applyRoundOff(b_amount)); 
                                b_amount=methods._applyRoundOff(b_amount+parseFloat(extra_child_rate)+parseFloat(extra_adult_rate)); //Pinal - 1.0.50.55 - 10 September 2016 - Purpose - Applying round off
                               //console.log("b_amount : " +b_amount ); 
                            }
                        }
                        else{
                               //extra adult & Child Rate
                               //b_amount=b_amount+parseFloat(extra_child_rate)+parseFloat(extra_adult_rate); //Old One
                                if (row_id_val!='')  eval("resgrid[0]["+row_id_val+"].o_day_base_"+ind+"_"+roomno+"="+methods._applyRoundOff(b_amount)); 
                                b_amount=methods._applyRoundOff(b_amount+parseFloat(extra_child_rate)+parseFloat(extra_adult_rate)); //Pinal - 1.0.50.55 - 10 September 2016 - Purpose - Applying round off
                                 //console.log("b_amount : " +b_amount); 
                            }
                        
                    }
                    b_amount = parseFloat(b_amount.toFixed(_display_decimalplaces)); //Falguni Rana
                    if(b_amount>0)
                    {
                        total=total+b_amount;
                    }
                }
                //console.log("total : " +total );
                 if (row_id_val!='') {
                    
                 if(opt.ShowNights==true)
                    var n_night=parseInt($("select#eZ_Nights").val());
                 else
                    var n_night=parseInt($("input#eZ_Nights").val());
                                        
                                        
                 console.log("Nonlinear Ajax call......baserate "+baserate);
                 $('#bookingmulbtn').text(langjs[0]["L_Loading"]);
                 $('#nl_loader').val(1);
                 
                 //Chandrakant - 2.0.16 - 04 May 2019 - START
                 //Purpose : To solve error undefined error. Remove frml[4] variable in param variable
                 var Packagetype='';
                 if (spfm!='') {
                    if(typeof(frml)!='undefined' && frml!='')
                       Packagetype = frml[4];
                 }
                 //Chandrakant - 2.0.16 - 04 May 2019 - END
              
                 var param="action=getNonlinearRate&HotelId="+opt.HotelId+"&roomrateunkid="+rateplan.roomrateunkid+"&roomtypeunkid="+rateplan.roomtypeunkid+"&ratetypeunkid="+rateplan.ratetypeunkid+"&checkin_date="+$("#eZ_chkin").val()+"&nights="+n_night+"&adult="+selected_adult+"&child="+extra_child+"&row_id_val="+row_id_val+"&itmcnt_val="+itmcnt_val+"&CalDtFormat="+opt.CalDtFormat+"&DayCnt="+rateplan.DayCnt+"&Nonlinear_package="+resgrid[0][row_count_val].specialunkid+"&PackageType="+Packagetype; // Priyanka Maurya - Purpose : 01 March 2019 - Solve NonLinear Package Issue - RES-1721
                 $.ajax({
                        type: "POST", 
                        url: opt.serverurl+'service.php',
                        data: param,
                        success: function(response) {
                             $('#bookingmulbtn').text(langjs[0]["L_NLBOOK"]); //Priyanka Maurya - 13 Jun 2019 - Purpose : Language Caption Issue - RES-2042
                             $('#nl_loader').val(0);  
                        }
                    });
                 }
                return ((total*parseFloat(resgrid[0][0]['MultiplyFactor'])))+"|"+apply_extra_adult+"|"+apply_extra_child;            
            }
            catch(err){
                hideLoadingBar();
                hideCheckavalabilityLoadingBar();
                console.log("Error - _specialLongStayRateCalculation_NL - "+err.message);
		
            }
        },
        // Sheetal Panchal - 1.0.52.60 - 20th July 2017, Purpose - For Non linear rate mode setting - END

         _specialStrikeLongStayRateCalculation:function(rateplan,spfm,promosarray,row_id_val,itmcnt_val,row_count_val,maxchildshow){
            //Chandrakant - 1.0.53.61 - 09 November 2017 - Purpose : maxchildshow is added to check system runs on max occupancy settings or not
            //Parth - 11 Jan 2019 - PromoDailyDiscount - Apply daily discount on promotion.(promos to promosarray)
            try{
                    //console.log("_specialStrikeLongStayRateCalculation");
                    var maxchildshow = maxchildshow || ''; //Chandrakant - 1.0.53.61 - 09 November 2017
                    var ind=0;
                    var i=-1;                
                    var b_amount=0;
                    var total=0.00;
                
                    var extra_child_rate=0;
                    var extra_adult_rate=0;
                    
                     if (row_id_val!='') {
                        rateplan=resgrid[0][row_id_val];                  
                     }
                    
                    //child age policy
                    var AllowChildAgePolicy=eval("rateplan.AllowChildAgePolicy");	                        
                    var AllowChildAgeOnBaseOccupancy=eval("rateplan.AllowChildAgeOnBaseOccupancy");
                    var WebChildAge=eval("rateplan.WebChildAge");	                        
                    var WebMaxChildAge=eval("rateplan.WebMaxChildAge");
                    var slabbefore_discount = jQuery("#slab_before_discount").val(); //Chandrakant - 1.0.53.61 - 29 November 2017
                    while(typeof(eval("rateplan.dt_"+eval(++ind)))!='undefined'){
                        i++;
                        b_amount=parseFloat(eval("rateplan.before_discount_day_base_"+ind));
                        b_amountGST=parseFloat(eval("rateplan.before_discount_day_base_"+ind)); //Chandrakant - 1.0.53.61 - 29 November 2017
                        if(b_amount>0)
                        {
                            //Find Extra adult & Child + Rates - start
                            adultrate=parseFloat(eval("rateplan.o_day_extra_adult_"+ind));
                            childrate=parseFloat(eval("rateplan.o_day_extra_child_"+ind));
                                               
                            var baseadult=eval("rateplan.base_adult");	                        
                            var basechild=eval("rateplan.base_child");
                            
                        var extra_adult=0;
                        //case added to default set extra adult rate calculation while check availability with more adults & child - flora
                        if ( jQuery("#rm_adult_"+row_id_val+"_"+itmcnt_val).val()==''
                        || jQuery("#rm_adult_"+row_id_val+"_"+itmcnt_val).val()==undefined) {
                            //Chandrakant - 2.0.12 - 22 October 2018 - START
                            //Purpose : RES-1774
                            if (resgrid[0][0].RunOnMaximumOccupanySettings=='1' && resgrid[0][0].acr=='false')
                            {
                                if (resgrid[0][0].ResUIPaxOnMaximumOccupancy=='1')
                                {
                                    var maximum_occupancy_val= eval("rateplan.max_occupancy");
                                    if (maximum_occupancy_val>eval("rateplan.max_adult")) {
                                        selected_adult=eval("rateplan.max_adult");
                                    }
                                    else
                                    {
                                        selected_adult=maximum_occupancy_val;
                                    }
                                }
                                else
                                    selected_adult=eval("rateplan.base_adult");
                            }
                            else
                            //Chandrakant - 2.0.12 - 22 October 2018 - END
                                selected_adult=jQuery( "#rm_adult_"+row_count_val+"_0").val();
                         //console.log("adult  : " + jQuery( "#rm_adult_"+ind+"_0").val());
                        }
                        else 
                            selected_adult=jQuery("#rm_adult_"+row_id_val+"_"+itmcnt_val).val();
                            
                            if(parseInt(selected_adult) > parseInt(baseadult))                    
                                    extra_adult=(parseInt(selected_adult) - parseInt(baseadult));
                            
                            var extra_child=0;
                            var selected_child=0; //Chandrakant - 1.0.53.61 - 09 November 2017
                            if(maxchildshow!='' && maxchildshow!=false) //Chandrakant - 1.0.53.61 - 09 November 2017
                            {
                                if ( jQuery("#rm_child_"+row_id_val+"_"+itmcnt_val).val()==''
                                || jQuery("#rm_child_"+row_id_val+"_"+itmcnt_val).val()==undefined) {
                                    //Chandrakant - 2.0.12 - 22 October 2018 - START
                                    //Purpose : RES-1774
                                    if (resgrid[0][0].RunOnMaximumOccupanySettings=='1' && resgrid[0][0].acr=='false')
                                    {
                                        if (resgrid[0][0].ResUIPaxOnMaximumOccupancy=='1')
                                        {
                                            if (eval('rateplan.max_occupancy')>=eval('rateplan.max_adult'))
                                            {
                                                var selected_child=eval('rateplan.max_occupancy')-eval('rateplan.max_adult');
                                                if (selected_child>eval('rateplan.max_child'))
                                                {
                                                    var selected_child=eval('rateplan.max_child');
                                                }
                                            }
                                            else
                                                var selected_child=0;
                                        }
                                        else
                                            selected_child=eval("rateplan.base_child");
                                    }
                                    else
                                    //Chandrakant - 2.0.12 - 22 October 2018 - END
                                        selected_child=jQuery( "#rm_child_"+row_count_val+"_0").val();
                                }
                                else
                                    selected_child=jQuery("#rm_child_"+row_id_val+"_"+itmcnt_val).val();
                            }
                            
                            if(AllowChildAgePolicy==0 && AllowChildAgeOnBaseOccupancy==1)//true
                            {                               
                                if(selected_child > parseInt(basechild))
                                {
                                    var childagelimit=WebChildAge;
                                    chkchild_cnt=1;
                                    for(childcnt=0;childcnt<selected_child;childcnt++)
                                    {			
                                        var childage=jQuery('#age_'+row_id_val+'_'+itmcnt_val+'_'+childcnt).val();                                 
                                        if(parseInt(childage) > parseInt(childagelimit) && childage!='' && parseInt(chkchild_cnt)>parseInt(basechild))
                                            extra_child++;
                                        chkchild_cnt++;    
                                    }
                                }
                            }
                            else if(AllowChildAgePolicy==0)//true
                            {                           
                                var childagelimit=WebChildAge;
                                for(childcnt=0;childcnt<selected_child;childcnt++)
                                {			
                                    var childage=jQuery('#age_'+row_id_val+'_'+itmcnt_val+'_'+childcnt).val();                               
                                    if(parseInt(childage) > parseInt(childagelimit) && childage!='')
                                        extra_child++;
                                }
                            }
                            else if(parseInt(selected_child) > parseInt(basechild))                    
                                extra_child=(parseInt(selected_child) - parseInt(basechild));
                            
                            var apply_extra_adult=0
                            var apply_extra_child=0;
                            
                            if (extra_adult>0) {
                                apply_extra_adult=1;
                            }
                            if (extra_child>0) {
                                apply_extra_child=1;
                            }
                            
                           // extra_child_rate = extra_child_rate + extra_child * childrate;
                           // extra_adult_rate = extra_adult_rate + extra_adult * adultrate;
                            
                            extra_child_rate =  extra_child * childrate;
                            extra_adult_rate =  extra_adult * adultrate;
                           
                            //Find Extra adult & Child + Rates - end
                        
                            if(eval("rateplan.ShowTaxInclusiveExclusiveSettings")=="1"){                                
                                if (eval("rateplan.Tax_"+(i+1))!="[0]")
                                {                                
                                    tax_formula=eval("rateplan.Tax_"+(i+1));
                                    //Chandrakant - 1.0.53.61 - 29 November 2017 - START
                                    //Purpose : Find Slab using base Rate not discount rate
                                    //console.log("base amount GST : " +b_amountGST );
                                    //console.log("base amount : " +b_amount );
                                    b_amount=b_amount+parseFloat(extra_child_rate)+parseFloat(extra_adult_rate); //added
                                    if(slabbefore_discount==1)
                                    {
                                        b_amountGST=b_amountGST+parseFloat(extra_child_rate)+parseFloat(extra_adult_rate);
                                        total_tax=getTax(b_amountGST,tax_formula,_decimalplaces,0,rateplan.RackRate,rateplan.EARate,rateplan.ECRate,selected_adult,selected_child,'base',extra_adult,extra_child);
                                    }
                                    else
                                    {
                                        total_tax=getTax(b_amount,tax_formula,_decimalplaces,0,rateplan.RackRate,rateplan.EARate,rateplan.ECRate,selected_adult,selected_child,'base',extra_adult,extra_child);    
                                    }
                                    //console.log("extra_child_rate : " +extra_child_rate );
                                    //console.log("extra_adult_rate : " +extra_adult_rate );
                                    //console.log("totalamount : " +b_amount );
                                    //console.log("total_tax : " +total_tax );
                                    //console.log('-------------------------------------------');
                                    //Chandrakant - 1.0.53.61 - 29 November 2017 - END 
                                     //b_amount=methods._applyRoundOff(parseFloat(b_amount)+parseFloat(extra_child_rate)+parseFloat(extra_adult_rate)+parseFloat(total_tax));
                                     if(_decimalplaces=='0') //Chandrakant - 20 April 2021, Purpose - RES-2791
                                        b_amount=b_amount.toFixed(_decimalplaces); //Chandrakant - 20 April 2021, Purpose - RES-2791
                                    b_amount=methods._applyRoundOff(parseFloat(b_amount)+parseFloat(total_tax));//updated
                                }
                                else{
                                    //extra adult & Child Rate
                                    //b_amount=parseFloat(b_amount)+parseFloat(extra_child_rate)+parseFloat(extra_adult_rate); //Old One
                                    b_amount=methods._applyRoundOff(parseFloat(b_amount)+parseFloat(extra_child_rate)+parseFloat(extra_adult_rate)); //Pinal - 1.0.50.55 - 10 September 2016 - Purpose - Applying round off
                                }
                            }
                            else
                            {
                                //extra adult & Child Rate
                                //b_amount=parseFloat(b_amount)+parseFloat(extra_child_rate)+parseFloat(extra_adult_rate); //Old One
                                b_amount=methods._applyRoundOff(parseFloat(b_amount)+parseFloat(extra_child_rate)+parseFloat(extra_adult_rate)); //Pinal - 1.0.50.55 - 10 September 2016 - Purpose - Applying round off
                            }
                        }
                        b_amount = parseFloat(b_amount.toFixed(_display_decimalplaces)); //Falguni Rana
                        if(b_amount>0)
                        {
                            total=total+b_amount;
                        }
                        //console.log("total : " + total);
				}				
				return ((total*parseFloat(resgrid[0][0]['MultiplyFactor'])))+"|"+apply_extra_adult+"|"+apply_extra_child; ;            
            }
            catch(err){
                hideLoadingBar();
                hideCheckavalabilityLoadingBar();
                console.log("Error - _specialStrikeLongStayRateCalculation - "+err.message);		
            }
        },
        // Sheetal Panchal - 1.0.52.60 - 20th July 2017, Purpose - For Non linear rate mode setting - START
         _specialStrikeLongStayRateCalculation_NL:function(opt,rateplan,spfm,promosarray,row_id_val,itmcnt_val,row_count_val,maxchildshow){
            //Chandrakant - 1.0.53.61 - 09 November 2017 - Purpose : maxchildshow is added to check system runs on max occupancy settings or not
            //Parth - 11 Jan 2019 - PromoDailyDiscount - Apply daily discount on promotion.(promos to promosarray)
            try{
                    //console.log('_specialStrikeLongStayRateCalculation_NL');
                    var maxchildshow = maxchildshow || ''; //Chandrakant - 1.0.53.61 - 09 November 2017
                    var ind=0;
                    var i=-1;                
                    var b_amount=0;
                    var total=0.00;
                
                    var extra_child_rate=0;
                    var extra_adult_rate=0;
                    
                    var rackratetax_extra_child=0;
                    var rackratetax_extra_adult=0;
                    
                    
                    var base_adult=0;
                    var base_child=0;
                    
                     if (row_id_val!='') {
                        rateplan=resgrid[0][row_id_val];                  
                     }
                    
                    //child age policy
                    var AllowChildAgePolicy=eval("rateplan.AllowChildAgePolicy");	                        
                    var AllowChildAgeOnBaseOccupancy=eval("rateplan.AllowChildAgeOnBaseOccupancy");
                    var WebChildAge=eval("rateplan.WebChildAge");	                        
                    var WebMaxChildAge=eval("rateplan.WebMaxChildAge");
                
                    while(typeof(eval("rateplan.dt_"+eval(++ind)))!='undefined'){
                        i++;
                        //b_amount=parseFloat(eval("rateplan.before_discount_day_base_"+ind));
                        
                        
                            //Find Extra adult & Child + Rates - start
                            adultrate=parseFloat(eval("rateplan.o_day_extra_adult_"+ind));
                            childrate=parseFloat(eval("rateplan.o_day_extra_child_"+ind));
                                               
                            var baseadult=eval("rateplan.base_adult");	                        
                            var basechild=eval("rateplan.base_child");
                            
                        var extra_adult=0;
                        //case added to default set extra adult rate calculation while check availability with more adults & child - flora
                        if ( jQuery("#rm_adult_"+row_id_val+"_"+itmcnt_val).val()==''
                        || jQuery("#rm_adult_"+row_id_val+"_"+itmcnt_val).val()==undefined) {
                            //Chandrakant - 2.0.12 - 22 October 2018 - START
                            //Purpose : RES-1774
                            if (resgrid[0][0].RunOnMaximumOccupanySettings=='1' && resgrid[0][0].acr=='false')
                            {
                                if (resgrid[0][0].ResUIPaxOnMaximumOccupancy=='1')
                                {
                                    var maximum_occupancy_val= eval("rateplan.max_occupancy");
                                    if (maximum_occupancy_val>eval("rateplan.max_adult")) {
                                        selected_adult=eval("rateplan.max_adult");
                                    }
                                    else
                                    {
                                        selected_adult=maximum_occupancy_val;
                                    }
                                }
                                else
                                    selected_adult=eval("rateplan.base_adult");
                            }
                            else
                            {//Chinmay Gandhi - Set brecket
                            //Chandrakant - 2.0.12 - 22 October 2018 - END
                                selected_adult=jQuery( "#rm_adult_"+row_count_val+"_0").val();
                            }
                         //console.log("adult  : " + jQuery( "#rm_adult_"+ind+"_0").val());
                        }
                        else 
                            selected_adult=jQuery("#rm_adult_"+row_id_val+"_"+itmcnt_val).val();
                            
                            if(parseInt(selected_adult) > parseInt(baseadult))                    
                                    extra_adult=(parseInt(selected_adult) - parseInt(baseadult));
                            
                            var extra_child=0;
                            var selected_child=0; //Chandrakant - 1.0.53.61 - 09 November 2017
                            if(maxchildshow!='' && maxchildshow!=false) //Chandrakant - 1.0.53.61 - 09 November 2017
                            {
                                if ( jQuery("#rm_child_"+row_id_val+"_"+itmcnt_val).val()==''
                                || jQuery("#rm_child_"+row_id_val+"_"+itmcnt_val).val()==undefined) {
                                    //Chandrakant - 2.0.12 - 22 October 2018 - START
                                    //Purpose : RES-1774
                                    if (resgrid[0][0].RunOnMaximumOccupanySettings=='1' && resgrid[0][0].acr=='false')
                                    {
                                        if (resgrid[0][0].ResUIPaxOnMaximumOccupancy=='1')
                                        {
                                            if (eval('rateplan.max_occupancy')>=eval('rateplan.max_adult'))
                                            {
                                                var selected_child=eval('rateplan.max_occupancy')-eval('rateplan.max_adult');
                                                if (selected_child>eval('rateplan.max_child'))
                                                {
                                                    var selected_child=eval('rateplan.max_child');
                                                }
                                            }
                                            else
                                                var selected_child=0;
                                        }
                                        else
                                            selected_child=eval("rateplan.base_child");
                                    }
                                    else
                                    {//Chinmay Gandhi - Set brecket
                                    //Chandrakant - 2.0.12 - 22 October 2018 - END
                                        selected_child=jQuery( "#rm_child_"+row_count_val+"_0").val();
                                    }
                                }
                                else
                                    selected_child=jQuery("#rm_child_"+row_id_val+"_"+itmcnt_val).val();
                            }
                            
                           /* if(AllowChildAgePolicy==0 && AllowChildAgeOnBaseOccupancy==1)//true
                            {                               
                                if(selected_child > parseInt(basechild))
                                {
                                    var childagelimit=WebChildAge;
                                    chkchild_cnt=1;
                                    for(childcnt=0;childcnt<selected_child;childcnt++)
                                    {			
                                        var childage=jQuery('#age_'+row_id_val+'_'+itmcnt_val+'_'+childcnt).val();                                 
                                        if(parseInt(childage) > parseInt(childagelimit) && childage!='' && parseInt(chkchild_cnt)>parseInt(basechild))
                                            extra_child++;
                                        chkchild_cnt++;    
                                    }
                                }
                            }
                            else */if(AllowChildAgePolicy==0)//true
                            {                           
                                var childagelimit=WebChildAge;
                                for(childcnt=0;childcnt<selected_child;childcnt++)
                                {			
                                    var childage=jQuery('#age_'+row_id_val+'_'+itmcnt_val+'_'+childcnt).val();                               
                                    if(parseInt(childage) > parseInt(childagelimit) && childage!='')
                                        extra_child++;
                                }
                            }
                            else //if(parseInt(selected_child) > parseInt(basechild))                    
                                extra_child=(parseInt(selected_child));// - parseInt(basechild)
                            
                            var apply_extra_adult=0
                            var apply_extra_child=0;
                            
                           /* if (extra_adult>0) {
                                apply_extra_adult=1;
                            }
                            if (extra_child>0) {
                                apply_extra_child=1;
                            }
                            
                           // extra_child_rate = extra_child_rate + extra_child * childrate;
                           // extra_adult_rate = extra_adult_rate + extra_adult * adultrate;
                            
                            extra_child_rate =  extra_child * childrate;
                            extra_adult_rate =  extra_adult * adultrate;
                           */
                            // Sheetal Panchal - 1.0.52.60 - 20th July 2017, Purpose - For Non linear rate mode setting - START

                             if (parseInt(selected_adult)>7) {
                                baserate = parseFloat(eval("rateplan.o_day_adult7_"+ind));
                                extra_adult_rate =  (selected_adult-7) * adultrate;
                                apply_extra_adult=1;
                                base_adult=7;
                             }
                             else
                             {
                                 baserate = parseFloat(eval("rateplan.o_day_adult"+parseInt(selected_adult)+"_"+ind));
                                 base_adult=selected_adult;
                             }
                            
                             if (extra_child > 7) {
                                baserate += parseFloat(eval("rateplan.o_day_child7_"+ind));
                                extra_child_rate = (extra_child-7) * childrate;
                                apply_extra_child=1;
                                base_child=7;
                             }
                             else
                             {
                                 if (extra_child>0) {
                                     baserate += parseFloat(eval("rateplan.o_day_child"+parseInt(extra_child)+"_"+ind));
                                     base_child=extra_child;
                                 }
                             }
                             b_amount=baserate;
                            // Sheetal Panchal - 1.0.52.60 - 20th July 2017, Purpose - For Non linear rate mode setting - END
                             //console.log("baserate : " + baserate+"extra_child_rate::"+extra_child);
                             
                            //Find Extra adult & Child + Rates - end
                        if(b_amount>0)
                        {
                              roomno=parseInt(itmcnt_val)+1;
                            if(eval("rateplan.ShowTaxInclusiveExclusiveSettings")=="1"){                                
                                if (eval("rateplan.Tax_"+(i+1))!="[0]")
                                {                                
                                tax_formula=eval("rateplan.Tax_"+(i+1));
                                totalbase=b_amount;
                               /* if(extra_child>7)
                                    extra_child=extra_child-7;
                                else
                                    extra_child=0;
                            */
                               
                                //For apply on rackrate tax as for that extra adult/child will be calculated from base occupancy
                            if(parseInt(selected_adult)  > parseInt(baseadult))
                              rackratetax_extra_adult=(parseInt(selected_adult)- parseInt(baseadult));
                            if(parseInt(extra_child)  > parseInt(basechild))
                              rackratetax_extra_child=(parseInt(extra_child)- parseInt(basechild));
                           
                           
                                b_amount=b_amount+parseFloat(extra_child_rate)+parseFloat(extra_adult_rate); //added
                                
                                total_tax=getTax(b_amount,tax_formula,_decimalplaces,0,rateplan.RackRate,rateplan.EARate,rateplan.ECRate,selected_adult,selected_child,'base',rackratetax_extra_adult,rackratetax_extra_child);
                                // Sheetal Panchal - 1.0.52.60 - 23rd Sep 2017, Purpose fixes of extra adult/child rate is not applicable for rackrate tax.passed rackratetax_extra_adult,rackratetax_extra_child
 
                                var total_base_tax=getTax(totalbase,tax_formula,_decimalplaces,0,rateplan.RackRate,rateplan.EARate,rateplan.ECRate,base_adult,base_child,'base',rackratetax_extra_adult,rackratetax_extra_child);
                             
                                totalbase+=parseFloat(total_base_tax);
                                if (row_id_val!='')  eval("resgrid[0]["+row_id_val+"].d_before_discount_tax_day_base_"+ind+"_"+roomno+"="+methods._applyRoundOff(totalbase)); 
                  
                                //b_amount=methods._applyRoundOff(parseFloat(b_amount)+parseFloat(extra_child_rate)+parseFloat(extra_adult_rate)+parseFloat(total_tax));

                                if(_decimalplaces=='0') //Chandrakant - 20 April 2021, Purpose - RES-2791
                                    b_amount=b_amount.toFixed(_decimalplaces); //Chandrakant - 20 April 2021, Purpose - RES-2791
                                b_amount=methods._applyRoundOff(parseFloat(b_amount)+parseFloat(total_tax));//updated
                                
                                }
                                 else{
                                //extra adult & Child Rate
                                //b_amount=parseFloat(b_amount)+parseFloat(extra_child_rate)+parseFloat(extra_adult_rate); //Old One
                                if (row_id_val!='')  eval("resgrid[0]["+row_id_val+"].d_before_discount_tax_day_base_"+ind+"_"+roomno+"="+methods._applyRoundOff(b_amount)); 
                  
				b_amount=methods._applyRoundOff(parseFloat(b_amount)+parseFloat(extra_child_rate)+parseFloat(extra_adult_rate)); //Pinal - 1.0.50.55 - 10 September 2016 - Purpose - Applying round off
				}
                            }
                            else
                            {
                                //extra adult & Child Rate
                                //b_amount=parseFloat(b_amount)+parseFloat(extra_child_rate)+parseFloat(extra_adult_rate); //Old One
                                if (row_id_val!='')  eval("resgrid[0]["+row_id_val+"].d_before_discount_tax_day_base_"+ind+"_"+roomno+"="+methods._applyRoundOff(b_amount)); 
                  
				b_amount=methods._applyRoundOff(parseFloat(b_amount)+parseFloat(extra_child_rate)+parseFloat(extra_adult_rate)); //Pinal - 1.0.50.55 - 10 September 2016 - Purpose - Applying round off
                            }
                        }
                        b_amount = parseFloat(b_amount.toFixed(_display_decimalplaces)); //Falguni Rana
                        if(b_amount>0)
                        {
                            total=total+b_amount;
                        }
                   
				}				
				return ((total*parseFloat(resgrid[0][0]['MultiplyFactor'])))+"|"+apply_extra_adult+"|"+apply_extra_child; ;            
            }
            catch(err){
                hideLoadingBar();
                hideCheckavalabilityLoadingBar();
                console.log("Error - _specialStrikeLongStayRateCalculation - "+err.message);		
            }
        },
        // Sheetal Panchal - 1.0.52.60 - 20th July 2017, Purpose - For Non linear rate mode setting - END

        _applyRoundOff:function(inval){
			if(typeof(resgrid[0][0])!='undefined')
			{
				if(resgrid[0][0]['ShowTaxInclusiveExclusiveSettings']=="1")
				{
					if(!isNaN((resgrid[0][0].RoundOffType)))
					{
						var factor=(resgrid[0][0].RoundOffType);
						
						if (factor <= 0) return inval;
						var newinval = inval * factor;
						var sign = inval > 0 ? 1 : inval == 0 ? 0 : -1;
						var fixtemp = newinval + 0.5 * sign;
                        //Kevin Patel -25th Feb-2019-START
                        //Purpose:remove ~~ and fixed a Bug-[ABS-3324]
						fixtemp = Math.floor(fixtemp);
						if (newinval - Math.floor(newinval) == 0.5) if (fixtemp / 2 != Math.floor(fixtemp / 2)) fixtemp = fixtemp - sign;
						return fixtemp / factor;
                        //Kevin Patel -25th Feb-2019-END
					}
					else
						return inval;
				}
				else
					return inval;
			}
			return inval;
			
		},
        _showfullyavailablerateplan:function(opt){
            
                //Show only fully available rate plans - start
                 
                if(opt.ShowOnlyAvailableRatePlanOrPackage==1)
                {
                    jQuery("#summary_head").show();
                    jQuery("#filter-wrapper_div").show();
                    var row_cnt=0;
                    var total_div_len=jQuery("[id$='main_otarow']").length;
                    var main_div_hide=0;
                    var main_otarow_cnt=0;
                    
                    jQuery("[id$='main_otarow']").each(function(){
                            row_cnt=jQuery(this).find('.vres-cnt-card').find(".card-list").length;
                            var na_cnt=0;
                            var hidereadmore=0;
                            var setreasmore=0;
                            var hidereadmore_cls=0;
                            var displaynone_cls_cnt=0;
                            
                            var show_on_limit=-1;
                            if (opt.ResUILimitRatePlanPackage==1 && opt.ResUINoOfRatePlanPackage>=1) {
                                show_on_limit=1;
                            }
                                    
                            jQuery(this).find('.vres-cnt-card').find(".card-list").each(function(){
                            
                            var rowinx=jQuery(this).attr('id').split("_");
                            
                            jQuery(this).removeClass('hidereadmore');
                            
                            //hide specific row not_available
                            
                            if(jQuery(this).find('#not_available').index()==0 || jQuery(this).is(':hidden')==true) {                                jQuery(this).hide();                                
                                na_cnt++;
                            }
                            else
                            {
                                if (hidereadmore<parseInt(opt.ResUINoOfRatePlanPackage) && show_on_limit==1) {                                    
                                    jQuery(this).removeClass('read-more-content');
                                    hidereadmore++;
                                }
                            }
                            
                            if (show_on_limit==1) {
                                 $('.read-more-content').addClass('hidereadmore');//read more
                                //console.log(jQuery(this).css());
                                if(jQuery(this).attr('style')=='display: none;')
                                {
                                    displaynone_cls_cnt++;
                                }
                               
                            }
                           
                        });
                        
                        if(show_on_limit==1) {
                            /*Read More Caption*/     
                            var t_row=parseInt(row_cnt)-parseInt(na_cnt);
                            if ((t_row)>parseInt(opt.ResUINoOfRatePlanPackage)) {                          
                                $(this).find('a.readmore_rtplan').text(js_stripslashes(langjs[0]["L_ReadMore"]));
                                $(this).find('a.moreless_a').html('<i class="fa fa-angle-down"></i>');
                                $(this).find('a.readmore_rtplan').addClass('read-more-toggle');
                                $(this).find('.moreless_div').show();
                            }
                            else
                            {
                                $(this).find('a.readmore_rtplan').text('');
                                $(this).find('a.moreless_a').html('');
                                $(this).find('a.readmore_rtplan').removeClass('read-more-toggle');
                                $(this).find('.moreless_div').hide();
                            }
                        }
                        //hide room type if all not available
                        if (na_cnt==row_cnt) {
                           jQuery(this).hide();
                           main_div_hide++;			   
                           jQuery(this).removeClass('rml-first');
                        }
                        else
                        {
                            if(main_otarow_cnt==0 && jQuery(this).is(':visible')==true)
                            {
                                jQuery(this).removeClass('rml-first').addClass('rml-first'); 
                                main_otarow_cnt++;
                            }
                        }
                        //console.log(na_cnt + "  : " + row_cnt + " : " + jQuery(this).attr('id'));
                     });
                    if (main_div_hide==total_div_len) {
                            if (!jQuery("#no_filterrecord").hasClass("showthis")) //Pinal
                            {
                                jQuery("#no_record").show();
                                jQuery("#norecord_main_blk").show();
                                jQuery("#availcalmain").show();
                                jQuery("#enquirenotavail").show();
                                jQuery("#no_filterrecord").prev().show();
                                methods._ShowClosestAvailability(opt);
                            }
			
                       jQuery("#filter-wrapper_div").hide();
                       jQuery("#booking_summery_blk").hide();
                       jQuery("#summary_head").hide();                       
                       jQuery("#room_container").hide();
                    }
                }
                //Show only fully available rate plans - end  
        },
        _loadDefaultJsInit:function(opt){
            try
			{
                //console.log("_loadDefaultJsInit load");
                try
                    {
                        if(parent.document.domain!=opt.serverhost && !parent.editframe)
                            document.getElementById("frm").target = "_blank";	
                    }
                    catch(e)
                    {
                        try
                        {
                            if(!parent.editframe)
                                document.getElementById("frm").target = "_blank";	
                        }
                        catch(e)
                        {
                            document.getElementById("frm").target = "_blank";
                        }
                    }

            if(typeof(resgrid)!='undefined' && typeof(resgrid[0])!='undefined' && resgrid[0]!=null) //Priyanka Maurya - 15 Sep 2020 - Purpose : Sometime Fileter Menus Click Function is not called - RES-2599
            {
                jQuery(".txtInteger").keydown(fun_Integer);
                jQuery(".txtInteger").focusout(fun_Integer_keyup);
               
                $(".filter-div").swipe({
                    swipeLeft:function() {
                        $(this).removeClass('filt-open');
                        $('.filt_wrap_bg').removeClass('filt_wrap_bg_open');
                        /*console.log('swiped');*/
                    }
                });
                
               $('.filt_wrap_bg').swipe({
                    swipe:function(VERTICAL) {
                       // console.log('swiped');
                        $('.filt_wrap_bg').removeClass('filt_wrap_bg_open');
                        $('.filter-div').removeClass('filt-open');
                    }
                });
                // Sheetal Panchal - 1.0.52.60 - 2nd Aug 2017, Purpose - For Non linear rate mode setting - START

                $('.rate_model').on('click', function(){
                     if($("#ratemode").val()=='NONLINEAR')
                     {
                          var rateplan;
                          var curid=this.id.split("_");
                          var ind=curid[1];
                       
                          var row_id_val=ind;
                          var itmcnt_val=jQuery("#room_clone_cnt_"+ind).text();;
                          var row_count_val=ind;
                          rateplan=eval('resgrid[0]['+parseInt(ind)+']');
                          var totalbase=0;
                          var i=0;
                          var day=i;
                          while(typeof(eval("rateplan.dt_"+eval(++i)))!='undefined'){
                              day=i;
                              totalbase=0;
                              totalbase_strike=0;
                              for (room=1;room<=itmcnt_val;room++) {
                                totalbase+=eval('resgrid[0]['+parseInt(row_id_val)+'].o_day_base_'+day+'_'+room);
                                totalbase_strike+=eval('resgrid[0]['+parseInt(row_id_val)+'].d_before_discount_tax_day_base_'+day+'_'+room);
                              }
                              
                              //Chandrakant - 05 December 2019 - START
                              //Purpose : RES-2320 - Revise logic to fix display issue
                              //if (totalbase>0) 
                              //   $('span#rate2_'+ind+'_'+day).text((parseFloat(totalbase)*parseFloat(resgrid[0][0]['MultiplyFactor'])).toFixed(_decimalplaces));
                              //if (totalbase_strike>0) 
                              //   $('span#strikethrough_'+ind+'_'+day).text((parseFloat(totalbase_strike)*parseFloat(resgrid[0][0]['MultiplyFactor'])).toFixed(_decimalplaces));
                                 
                              if (totalbase>0)
                              {
                                 totalbase = parseFloat(totalbase)*parseFloat(resgrid[0][0]['MultiplyFactor']);
                                 totalbase = methods._applyRoundOff(totalbase);
                                 $('span#rate2_'+ind+'_'+day).text((totalbase).toFixed(_decimalplaces));
                              }
                              if (totalbase_strike>0)
                              {
                                 totalbase_strike = parseFloat(totalbase_strike)*parseFloat(resgrid[0][0]['MultiplyFactor']);
                                 totalbase_strike = methods._applyRoundOff(totalbase_strike);
                                 $('span#strikethrough_'+ind+'_'+day).text((totalbase_strike).toFixed(_decimalplaces));
                              }
                              //Chandrakant - 05 December 2019 - END
                              console.log("totalbase"+totalbase+"totalbase_strike"+totalbase_strike);
                           }
                     }
                  });
                // Sheetal Panchal - 1.0.52.60 - 2nd Aug 2017, Purpose - For Non linear rate mode setting - END

                $('.ad-rm').on('click', function(){
		   
                    var ava_clicked=methods._check_availability_clicked(opt);
                    if (ava_clicked==false) {
                        return false;
                    }
                    if ($(this).hasClass('adrm-wrap2')==true ||
                         $(this).hasClass('adrm-wrap-close')==true
                        ) {//create issue while add-rom strike out errror on console only
                        return false;
                    }
                    var otatablview_id=jQuery(this).closest(".otatablview").attr('id');
                    var set_init_param=otatablview_id.split("_");
                    
                    var set_id=jQuery(this).attr('id');
                    var setidarr=set_id.split("_");  
                    
                    var room_init_cnt=jQuery("#room_init_cnt_"+set_init_param[1]).text();//initlal text room_row count                    
                    var room_clone_cnt=jQuery("#room_clone_cnt_"+setidarr[1]).text();
                    var each_room_cnt=jQuery("#each_room_cnt_"+setidarr[1]).text();
                     
                    var set_id=jQuery(this).attr('id');
                    var setidarr=set_id.split("_");                        
                        
                    var max_rooms_allowed=parseInt(resgrid[0][setidarr[1]]['MulRoomInventory']);//maximum input room_rowes allowed
                   
                    //updated from  room_init_cnt==1 to "0" - issue in "-1" value while only 1 room left for all
                    if (room_init_cnt==0 || room_init_cnt<max_rooms_allowed) {
			    
			    //Pinal - 1.0.50.55 - 23 August 2016 - START
			    //Purpose - Allow booker to book only specified no of rooms at a time.
			    //console.log("roombooklimitcal => "+(parseInt($("#roombooklimitcal").val())+1));
			    if ($("#roombooklimit").val()!='' && (parseInt($("#roombooklimitcal").val())+1)>parseInt($("#roombooklimit").val()))
			    {
				var alertmsglimit=js_stripslashes(langjs[0]["L_roombooklimit"]).replace("{nobook_rms_limit}",$("#roombooklimit").val())
				swal({
				    title: '',//title_message,
				    text: alertmsglimit,
				    type: 'error',
				    showCancelButton: false,
				    animation:false
				});
				return false;
			    }
			    //Pinal - 1.0.50.55 - 23 August 2016 - END
			    
                            $(this).parents('.vres_add_wrapper').next('.vres-add-div').show();
                            $('.no-roomdiv').hide();
                            $('.vres-roombooked').addClass('vres-booked-dis');
                            $(this).hide().next('.vres_num').show();
                            
                            room_init_cnt++;//text room_row increment
                            jQuery("#room_init_cnt_"+set_init_param[1]).text(room_init_cnt);
                            
                            //Set Adult & Child to base occupany for maximum occupancy case - flora
                            if(opt.RunOnMaximumOccupanySettings==1){
                                rateplan2=resgrid[0][setidarr[1]];
                                if(typeof(rateplan2)!='undefined') {//set default value for max occupancy selected case
                                   
                                    var baseadult=eval("rateplan2.base_adult");                                 
                                    if(opt.acr==true)
                                        baseadult=$("select[id$='eZ_adult']").val();
                            
                                    var basechild=eval("rateplan2.base_child");
                                    if(opt.RunOnMaximumOccupanySettings==0)
                                    {
                                        if((opt.showchild_bb=="-1" && opt.showchild==true) || opt.showchild_bb==true)
                                        basechild=$("select[id$='eZ_child']").val();
                                    }
                                    //Chinmay Gandhi - 1.0.53.61 - Start
                                    //Perpose: Set MaxOccupancySetting
                                    else if(opt.RunOnMaximumOccupanySettings==1)
                                    {
                                        if(opt.acr==false)
                                        {
                                            //Chandrakant - 2.0.12 - 17 October 2018 - Purpose : RES-1774
                                            if (opt.ResUIPaxOnMaximumOccupancy=='1')
                                            {
                                                var maximum_occupancy_val= eval("rateplan2.max_occupancy");
                                                if (maximum_occupancy_val>eval("rateplan2.max_adult")) {
                                                    baseadult=eval("rateplan2.max_adult");
                                                }
                                                else
                                                {
                                                    baseadult=maximum_occupancy_val;
                                                }
                                            }
                                            else
                                            {
                                                baseadult=eval("rateplan2.base_adult"); 
                                            }
                                        }
                                        if(opt.showchild==true)
                                            basechild=$("#rm_child_"+setidarr[1]+"_"+(room_clone_cnt)).val();
                                    }
                                    //Chinmay Gandhi - End
                                     
                                    jQuery("#rm_adult_"+setidarr[1]+"_"+(room_clone_cnt)).val(baseadult);
                                    jQuery("#rm_child_"+setidarr[1]+"_"+(room_clone_cnt)).val(basechild);
                                    
                                    //child age combo reset
                                    methods._childagecombo(basechild,setidarr[1],(room_clone_cnt));                                  
                                }
                            }
                            //Set Adult & Child to base occupany for maximum occupancy case - flora
                            
                            methods._calculateLongtayPrice(opt,setidarr[1],room_clone_cnt,'add_room');
                            room_clone_cnt++;                            
                            jQuery("#room_clone_cnt_"+setidarr[1]).text(room_clone_cnt);
                                                        
                            jQuery("#rm_cnt_lbl_"+setidarr[1]+"_"+  (room_clone_cnt-1)).text(jQuery("#room_clone_cnt_"+setidarr[1]).text());
                             
                            jQuery("#rooms_"+setidarr[1]).val(each_room_cnt);
                            jQuery("#each_room_cnt_"+setidarr[1]).text(each_room_cnt);
                            each_room_cnt++;
                            methods._setRoomLeftLbl(set_init_param[1],max_rooms_allowed);			    
                        }
                        methods._setIframeBookNowSummary();
                });
                
                $('.dn-btn').on('click', function(){                    
                    var addbtn = $(this).parents('div.vres-add-div').prev();
                    $(this).parents('div.vres-add-div').prev().children('span').text(' Added');
                    $(this).parents('div.vres-add-div').prev().children('i').addClass('fa-check');
                    $(this).parents('div.vres-add-div').siblings('.ad-rm').addClass('vrmadded');
                    $(this).parents('div.vres-add-div').hide();
                    $('.no-roomdiv').show();
                    $('.vres-roombooked').removeClass('vres-booked-dis');
                    $(this).parents('.card-list').find('.ad-rm').show().next('.vres_num').hide();                   
                });
                
                $('.vres-dtl').on('click', function(){
                    $('#my-vres-modal').fadeIn();
                    $('body').addClass('vres-open-modal');
                    $('.vres-mdtab-cnt').hide();
                    $('.vres-md-tab').find('[data-tab="rmdetail"]').show();
                    $('ul.vres-in-tab li').removeClass('tab-active');
                    //console.log($('ul.vres-in-tab').find('[id="rmdetail"]').addClass('tab-active'));
                });
                
                $('.cls').on('click', function(){
                    $('#my-vres-modal').fadeOut();
                    $('body').removeClass('vres-open-modal');
                });
                
                //Navigation click for Mobile Devices - shifted to header.tpl file
                //$('.vres-navbtn').on('click', function(e){        
                //    e.stopPropagation();
                //    $(this).toggleClass('vres-nav-bg');
                //    $(this).parents('.vres-navright').find('.vres-navbar').slideToggle();
                //    $(this).parents('.vres-navright').find('.vres-navbar').toggleClass('vres-nav-bg');                
                //});
                
                //popup tab 2
                $('.vres-poptab2').find('ul > li').on('click', function(){
                    var pt2tabid = $(this).attr('id');
                    $('.vres-p2-tab').find('li').removeClass('tab-active');
                    $(this).addClass('tab-active');
                    $('.vres-poptab2').find('.inn-cnt-tab').hide();
                    $('.vres-poptab2').find('[data-tab='+pt2tabid+']').show();
                });
                
                    $('.vres_room_info').on('click', function(e){
                        e.stopPropagation();
                        /*$(this).next('.vres_detail_wrap').slideToggle(100);*/
                        $(this).next('.vres_detail_wrap').toggleClass('slideDetail');
                        $('.vres_room_infoBg').fadeToggle(400);
                    });
                   
                   //close on mobile version
                   $('.nav_li_closed').on('click', function(e){
                        e.stopPropagation();
                        /*$(this).next('.vres_detail_wrap').slideToggle(100);*/
                        $('.vres_room_info').next('.vres_detail_wrap').removeClass('slideDetail');
                        $('.vres_room_infoBg').fadeToggle(400);
                    });
                   
                   
                   
                   var openFilter = function(){
                        
                   };
                    
                    $('.filter-btn1').on('click', function(e){
                        e.stopPropagation();
                        console.log("clicked");
                        if($(window).width() > 992){
                            $('.filter-div').css('top',$('.filter-btn1').offset().top + 45);
                            $('.filter-div').css('left',$('.filter-btn1').offset().left);
                            $('.filter-div').show();
                            methods._setFilterHeight(opt);
                        }
                        $('.filter-div').toggleClass('filt-open');
                        $('.filt_wrap_bg').addClass('filt_wrap_bg_open');
                    });
                    
                    $('.filter-btn2').on('click', function(e){                       
                        e.stopPropagation();
                        if($(window).width() < 992){
                            $('.filter-div').css('top',$('.filter-btn1').offset().top + 45);
                            $('.filter-div').css('left',$('.filter-btn1').offset().left);
                            $('.filter-div').show();
                            methods._setFilterHeight(opt);
                        }
                        $('.filter-div').toggleClass('filt-open');
                        $('.filt_wrap_bg').addClass('filt_wrap_bg_open');
                    });
                    
                    $(window).on('scroll', function(){
                        
                    });
                    
                    $('.filt-close').on('click', function(){
                        $('.filter-div').removeClass('filt-open');
                        $('.filt_wrap_bg').removeClass('filt_wrap_bg_open');
                       // if($(window).width() > 992){
                            $('.filter-div').hide();
                       // }
                    });
                    
                    //$('.vres_promo-btn').on('click', function(e){
                    //    e.stopPropagation();
                    //    $('.promo-popUp').css('top', $(this).offset().top + 45);
                    //    $('.promo-popUp').css('left', $(this).offset().left - 160);
                    //    $('.promo-popUp').toggle();
                    //    $('.filt_wrap_bg').addClass('filt_wrap_bg_open');
                    //});
                    //
                    
                    //added - 2016-05-21 - start
                    //Chandrakant - 1.0.48.53 - 04 June 2016 - START
                    //Purpose : disable code due to responsive Menu auto close if we click to type on any input value on login, contact us etc...
                    //$(window).on('resize', function(e){
                    //    e.stopPropagation();
                    //    //$('.vres-navbtn').removeClass('vres-nav-bg');
                    //    //$('.vres-navright').find('.vres-navbar').removeClass('vres-nav-bg');
                    //    //$('.vres-navright').find('.vres-navbar').hide();
                    //    if($(window).width()>1080){
                    //        $('.vres-navright').find('.vres-navbar').show();
                    //    } else {
                    //        $('.vres-navright').find('.vres-navbar').hide();
                    //    }
                    //});
                    //Chandrakant - 1.0.48.53 - 04 June 2016 - END
                    $('.vres-checkin.bg').on('click', function(e){
                        e.stopPropagation();
                        $('.vres-checkin.bg').children('.vres-drop-down-div').toggle();
                    })
                    //added - 2016-05-21 - end
                    var windowWidth = $(window).width();
                    $(window).on('resize', function(){
                        if($(window).width() !== windowWidth){
                            $('.promo-popUp').hide();
                            $('.filt_wrap_bg').removeClass('filt_wrap_bg_open');
                        }
                    });
                    
                    $('.shpriceBtn').on('click', function(){
                        
                        $('.shpriceBtn').removeClass('active');
                        $(this).addClass('active');
                    });
                        
                //promo code  - start
                $("#Applypromo").on('click', function(e){
                    //Prashant Patel - 27/09/2021 - Purpose :  Add restriction for maxnights while apply promocode [RES-2881] ---START
                    startDate = $.datepicker.parseDate(opt.CalDtFormat, $("#eZ_chkin").val());
                    endDate = $.datepicker.parseDate(opt.CalDtFormat, $("#eZ_chkout").val());
                    selectednights = jQuery("Select[id$='eZ_Nights']").val();
                    if(opt.ShowNights==false && opt.ShowDepart==true)
                    {
                        var difference = (endDate - startDate) / (86400000);
                    }
                    else
                    {
                        var difference = selectednights;
                    }
                    if(difference > 90)
                    {
                        jQuery('#book').trigger('click');
                        return false;
                    }
                    //Prashant Patel - 27/09/2021 - Purpose :  Add restriction for maxnights while apply promocode [RES-2881] ---END
                    if (jQuery("#promotioncode").val()!='') {
                        var lstpromocode = jQuery("#promotioncode").val().toString().replace(/"/g, "'");//Chinmay Gandhi - 1.0.53.61 - 17th April 2018 - [RES-1588]
                        jQuery("#promotioncode").val(lstpromocode);//Chinmay Gandhi - 1.0.53.61 - 17th April 2018 - [RES-1588]
                        jQuery("#trackbtnclicked").val(1);
                        methods._checksession(opt,'promo');//Check session - florra
                        //methods._loadGrid(opt);
                    }
                    else
                    {
                        var message = js_stripslashes(langjs[0]["L_PromoWrong2"]);                                     
                        swal({
                                    title: "",
                                    text: message,
                                    type: 'warning',
                                    showCancelButton: false,
                                    animation:false																					
							});                        
                        hideCheckavalabilityLoadingBar();									 
                        return false;
                    }
                    methods._InsertCheckAvail(opt); //Pinal
                 });
                
                //small screen
                $("#Applypromo2").on('click', function(e){
                    //Prashant Patel - 27/09/2021 - Purpose :  Add restriction for maxnights while apply promocode [RES-2881] ---START
                    startDate = $.datepicker.parseDate(opt.CalDtFormat, $("#eZ_chkin").val());
                    endDate = $.datepicker.parseDate(opt.CalDtFormat, $("#eZ_chkout").val());
                    selectednights = jQuery("Select[id$='eZ_Nights']").val();
                    if(opt.ShowNights==false && opt.ShowDepart==true)
                    {
                        var difference = (endDate - startDate) / (86400000);
                    }
                    else
                    {
                        var difference = selectednights;
                    }
                    if(difference > 90)
                    {
                        jQuery('#book').trigger('click');
                        return false;
                    }
                    //Prashant Patel - 27/09/2021 - Purpose :  Add restriction for maxnights while apply promocode [RES-2881] ---END
                    if (jQuery("#promotioncode3").val()!='') {
                        jQuery("#promotioncode").val(jQuery("#promotioncode3").val());
                        jQuery("#trackbtnclicked").val(1);
                        methods._checksession(opt,'promo');//Check session - florra
                        //methods._loadGrid(opt);
                    }
                    else
                    {
                        var message = js_stripslashes(langjs[0]["L_PromoWrong2"]);                                     
                        swal({
                                    title: "",
                                    text: message,
                                    type: 'warning',
                                    showCancelButton: false,
                                    animation:false																					
							});                        
                        hideCheckavalabilityLoadingBar();									 
                        return false;
                    }
                    methods._InsertCheckAvail(opt); //Pinal
                 });
                
                
                $('input#promotioncode').keypress(function(event) {
                    if(event.keyCode == 13) {
                        jQuery("#trackbtnclicked").val(1);
                        methods._loadGrid(opt);
                    }
                });
                $('input#promotioncode3').keypress(function(event) {
                    if(event.keyCode == 13) {
                        jQuery("#trackbtnclicked").val(1);
                        methods._loadGrid(opt);
                    }
                });
                //promo code  - end                
                
                /*Comapre Rooms - start*/
                $('.add_to_compare').on('click', function(){
                    
                    jQuery("#compare_room").val('');
                    var com_bl1=langjs[0]["lblComparerooms_1"];
                    var com_bl2=langjs[0]["lblAddtocompare_1"];
                    
                    var f=jQuery(this).parent().next().text();
                    jQuery(this).parent().next().text((f == langjs[0]["lblComparerooms_1"] ? com_bl2 : com_bl1));
                   
                    if (jQuery(this).parent().next().hasClass('cmpback')) {
                        jQuery(this).parent().next().removeClass("cmpback");
                    }
                    else
                        jQuery(this).parent().next().addClass("cmpback");
                            
                    jQuery(".add_to_compare").each(function(){                        
                       if(jQuery(this).is(':checked')==true)
                       {                            
                            jQuery("#compare_room").val(jQuery("#compare_room").val() + "|" + jQuery(this).val());
                       }                       
                    });
                });
                
               
                $('#compareroom,span.compareroom').on('click', function(){
                    methods._checksession(opt,'');
                    if (jQuery(this).attr('id')!='compareroom') {
                        if (jQuery(this).parent().find('.add_to_compare').prop( "checked" )==true )
                        {                        
                            jQuery(this).parent().find('.add_to_compare').removeAttr('Checked'); 
                        }
                        else
                        {
                            jQuery(this).parent().find('.add_to_compare').click();
                            return false;
                        }
                    }
                
                    var ischecked=0;
                    jQuery(".add_to_compare").each(function(){
                       if(jQuery(this).is(':checked')==true)
                       {
                            ischecked++;                        
                       }
                    });
                    if (ischecked>4) {
                       var displaymsg=langjs[0]["lblCompareMessage2"];
                        swal({
                             title: '',//title_message,
                             text: displaymsg,
                             type: 'warning',
                             showCancelButton: false,
                             animation:false																					
                          });                           
                         hideLoadingBar();	
                         return false;
                    }
                    if(ischecked==1) {
                       var displaymsg=langjs[0]["lblCompareMessage3"];
                        swal({
                             title: '',//title_message,
                             text: displaymsg,
                             type: 'warning',
                             showCancelButton: false,
                             animation:false																					
                          });                           
                         hideLoadingBar();	
                         return false;
                    }
                    
                    if(ischecked>0 &&  jQuery("#compare_room").val()!=''){                        
                        var cmp_data=jQuery("#compare_room").val();                        
                        
                        //Chandrakant - 1.0.50.55 - 09 August 2016 - START
                        //Purpose : Added to solve display same rateplan/package in multiple times while comparing
                        cmp_data = cmp_data.split('|');
                        var unique_cmp_data = [];
                        $.each(cmp_data, function(i, el){
                            if($.inArray(el, unique_cmp_data) === -1) unique_cmp_data.push(el);
                        });
                        
                        unique_cmp_data = unique_cmp_data.filter(function(v){return v!==''});
                        if(unique_cmp_data.length==1)
                        {
                            var displaymsg=langjs[0]["lblCompareMessage3"];
                            swal({
                                 title: '',//title_message,
                                 text: displaymsg,
                                 type: 'warning',
                                 showCancelButton: false,
                                 animation:false																					
                              });                           
                             hideLoadingBar();	
                             return false;
                        }
                        unique_cmp_data.push(""); // add blank value to solve weird image widget issue
                        unique_cmp_data = unique_cmp_data.join('|');
                        //Chandrakant - 1.0.49.54 - 09 August 2016 - END
                        
                        getUrl=opt.serverurl+'compareroom-'+opt.HotelId;                        
                        $.ajax({
                        url: getUrl,
                        type: "POST",
                        dataType: "html",
                        data: {
                            HotelId: opt.HotelId,                       
                            roomrateids: unique_cmp_data                       
                            },
                            error: function(){
                            },
                            beforeSend: function(){
                                showLoadingBar();
                                $("#comparedata").html('');
                            },
                            complete: function(){
                               
                                $('#compare-modal').modal();//added code
                                
                            },
                            success: function( data ){
                                 $("#comparedata").html(data);                           
                            }                        
                        });
                    }
                    else
                    {
                           var displaymsg=langjs[0]["lblCompareMessage"];
                           swal({
                                title: '',//title_message,
                                text: displaymsg,
                                type: 'warning',
                                showCancelButton: false,
                                animation:false																					
                             });                           
                            hideLoadingBar();	
                            return false;
                    }
                });
                /*Comapre Rooms - end*/
                
                //Roomdetails , Gallery , Enquire
                $("[id^=allroomdetails_]").click(function() {
                    var id=$(this).attr("id");			    
                    roomsrates=id.split("_");
                    
                    var rooms_left_text=jQuery.trim($(this).parent().parent().parent().find('.vres_center > b').html());
                    //Chandrakant - 2.0.9 - 23 August 2018 - START
                    //Purpose : AguadaanchoragePriceIssue - find visible package/rateplan and display it on pop up when it call from roomtype image click
                    var newId = '';
                    if (typeof(roomsrates[8])!=='undefined') {
                        $('#otatablview_'+roomsrates[8]).find("div[id^=row_]").each(function(){
                            if ($(this).is(':visible')===true) {
                                newId = $(this).find("a[id^=allroomdetails_]").attr('id');
                                return false;
                            }
                        });
                    }
                    if (newId!='') {
                        roomsrates=newId.split("_");
                        roomsrates[7] = 3;
                    }
                    //Chandrakant - 2.0.9 - 23 August 2018 - END
                    
                    //flora - start
                    var img_roomrate_id='';
                    if (roomsrates[7]==3 && roomsrates[7]!=4) {
                     $("[id^=span_row_]").each(function() {
                        var span_raw=jQuery(this).attr('id');
                        var span_raw_id=span_raw.split("_");
                        
                        if(span_raw_id[2]==roomsrates[1]) {
                            img_roomrate_id=jQuery(this).text();
                        }
                     });
                    }			   
                    //flora - end
                    
                    getUrl=opt.serverurl+'rminfo-'+roomsrates[4];//+""+roomsrates[5];
                    $.ajax({
                    url: getUrl,
                    type: "POST",
                    dataType: "html",
                    data: {
                        HotelId: roomsrates[4],//opt.HotelId,
                        roomtypeid: roomsrates[1],
                        ratetypeid: roomsrates[2],
                        showchildflag: roomsrates[3],
                        specialunkid: roomsrates[6],
                        flag: roomsrates[7],
                        roomrateids: img_roomrate_id
                       // rm_left_caption:encodeURIComponent(rooms_left_text)
                        },
                        error: function(){
                        },
                        beforeSend: function(){
                            showLoadingBar();
                            $("#detail-modal").html('');
                        },
                        complete: function(){
                            hideLoadingBar();
                            $('#detail-modal').modal();//added code
                            
                            /* Tabbing */
                            $('ul.vres-tab li').click( function(){
                                var liid = $(this).attr('id');
                                var tabinf = $(this).parents('.vres-tab-div').attr('id');
                                $('#'+tabinf+' ul.vres-tab li').removeClass('tab-active');
                                $(this).addClass('tab-active');
                                $('#'+tabinf+' > .vres-tab-cnt').hide();
                                $('.vres-tab-div').find('[data-tab="'+liid+'"]').show();                    
                                $('#detail-modal').modal('handleUpdate');
                            });                
                            
                            $('.vres-dtl').click( function(){
                                $('.vres-tab-div#vres-modal2 .vres-tab-cnt').hide();
                                $('.vres-tab-div#vres-modal2').find('[data-tab="rmdetail"]').show();
                                $('.vres-tab-div#vres-modal2 ul.vres-tab li').removeClass('tab-active');
                                $('.vres-tab-div#vres-modal2 ul.vres-tab').find('[id="rmdetail"]').addClass('tab-active');
                            });
                            
                            $('.enquiretabpop').click( function(){
                                $('.vres-tab-div#vres-modal2 .vres-tab-cnt').hide();
                                $('.vres-tab-div#vres-modal2').find('[data-tab="enqu"]').show();
                                $('.vres-tab-div#vres-modal2 ul.vres-tab li').removeClass('tab-active');
                                $('.vres-tab-div#vres-modal2 ul.vres-tab').find('[id="enqu"]').addClass('tab-active');
                            });
                           
                            $('.findusonmap').click( function(){
                                $('.vres-tab-div#vres-modal2 .vres-tab-cnt').hide();
                                $('.vres-tab-div#vres-modal2').find('[data-tab="map"]').show();
                                $('.vres-tab-div#vres-modal2 ul.vres-tab li').removeClass('tab-active');
                                $('.vres-tab-div#vres-modal2 ul.vres-tab').find('[id="map"]').addClass('tab-active');
                            });
                            
                             $('.opengallery').click( function(){ //Chandrakant
                                $('.vres-tab-div#vres-modal2 .vres-tab-cnt').hide();
                                $('.vres-tab-div#vres-modal2').find('[data-tab="picgal"]').show();
                                $('.vres-tab-div#vres-modal2 ul.vres-tab li').removeClass('tab-active');
                                $('.vres-tab-div#vres-modal2 ul.vres-tab').find('[id="picgal"]').addClass('tab-active');
                            });
                           methods._setIframePopupPosition(opt,id,$("#detail-modal"));
                        },
                        success: function( data ){
                             $("#detail-modal").html(data);
                             //console.log(data);
                           
                        }
                    });
                });
                
//Chinmay Gandhi - 1.0.51.56 - 20th Feb 2017 - START
//Purpose: Get Hotel info
                $("[id^=allhoteldetails_]").click(function() {
                    var id=$(this).attr("id"); 
                    roomsrates=id.split("_");

                    var rooms_left_text=jQuery.trim($(this).parent().parent().parent().find('.vres_center > b').html());
                    
                    getUrl=serverurl+'rminfo-'+roomsrates[1];
                    $.ajax({
                    url: getUrl,
                    type: "POST",
                    dataType: "html",
                    data: {
                        HotelId: roomsrates[1],
                        flag: roomsrates[3],
                        Hotel_valid: 'HotelInformation'
                        },
                        error: function(){
                        },
                        beforeSend: function(){
                            showLoadingBar();
                            $("#detail-modal").html('');
                        },
                        complete: function(){
                            hideLoadingBar();
                            $('#detail-modal').modal();//added code
                            
                            /* Tabbing */
                            $('ul.vres-tab li').click( function(){
                                var liid = $(this).attr('id');
                                var tabinf = $(this).parents('.vres-tab-div').attr('id');
                                $('#'+tabinf+' ul.vres-tab li').removeClass('tab-active');
                                $(this).addClass('tab-active');
                                $('#'+tabinf+' > .vres-tab-cnt').hide();
                                $('.vres-tab-div').find('[data-tab="'+liid+'"]').show();                    
                                $('#detail-modal').modal('handleUpdate');
                            });
                        $('.vres-dtl').click( function(){
                                $('.vres-tab-div#vres-modal2 .vres-tab-cnt').hide();
                                $('.vres-tab-div#vres-modal2').find('[data-tab="rmdetail"]').show();
                                $('.vres-tab-div#vres-modal2 ul.vres-tab li').removeClass('tab-active');
                                $('.vres-tab-div#vres-modal2 ul.vres-tab').find('[id="rmdetail"]').addClass('tab-active');
                            });
                        
                           methods._setIframePopupPosition(opt,id,$("#detail-modal"));
                        },
                        success: function( data ){
                             $("#detail-modal").html(data);
                             //console.log(data);
                           
                        }
                    });
                });
//Chinmay Gandhi - 1.0.51.56 - 20th Feb - END
                
                
                 //Roomdetails , Gallery - mobile view
               /* $("[id^=roominfo_]").click(function() {
                    var id=$(this).attr("id");			    
                    roomsrates=id.split("_");
                    
                    var rooms_left_text=jQuery.trim($(this).parent().parent().parent().find('.vres_center > b').html());
                    
                    //flora - start
                    var img_roomrate_id='';
                    if (roomsrates[7]==3 && roomsrates[7]!=4) {
                     $("[id^=span_row_]").each(function() {
                        var span_raw=jQuery(this).attr('id');
                        var span_raw_id=span_raw.split("_");
                        if(span_raw_id[2]==roomsrates[1]) {
                            img_roomrate_id=jQuery(this).text();
                        }
                     });
                    }			   
                    //flora - end
                     
                    //Small layout room information                   
                    getUrl=opt.serverurl+'rminfo-'+roomsrates[4];
                    $.ajax({
                    url: getUrl,
                    type: "POST",
                    dataType: "html",
                    data: {
                        HotelId: roomsrates[4],
                        roomtypeid: roomsrates[1],
                        ratetypeid: roomsrates[2],
                        showchildflag: roomsrates[3],
                        specialunkid: roomsrates[6],
                        flag: roomsrates[7],
                        roomrateids: img_roomrate_id                       
                        },
                        error: function(){
                        },
                        beforeSend: function(){
                            showLoadingBar();
                            $("#mobile-roomdetails").show();
                            $("#room_container").hide();
                            $("#rmdetails_container").html('');
                        },
                        complete: function(){
                            hideLoadingBar();                           
                           //methods._setIframePopupPosition(opt,id,$("#detail-modal"));
                        },
                        success: function( data ){
                             //$("#detail-modal").html(data);
                             //console.log(data);                              
                             $("#rmdetails_container").html(data);
                        }
                    });
                });*/
                
                $("[id^=rateinfo_]").on('click', function(e){
                     var id=$(this).attr("id");
                     var roomsrate_info=id.split("_");
                     methods._setIframePopupPosition(opt,id,$("#detail-modal_"+roomsrate_info[1]));
                     //console.log(opt);
                });
                
                ////Closest availability dates click 
                //$(".clodatesclick").on('click', function(e){
                //    var start_dt=jQuery(this).next().find('clodatesclick_span_start').text();
                //    var end_dt=jQuery(this).next().find('clodatesclick_span_end').text();
                //    jQuery("#eZ_chkin").val(start_dt);
                //    
                //    if(opt.ShowNights==false && opt.ShowDepart==true)
                //    {
                //        jQuery("input[id$='eZ_chkout']").val(end_dt);
                //        methods._setChkNight();
                //    }
                //    else
                //    {
                //        var fromDate_var = jQuery("input[id$='eZ_chkout']").val();
                //        var fromDate_var=new Date(jQuery.datepicker.parseDate(opt.CalDtFormat,fromDate_var));
                //        
                //        var toDate_var=new Date(jQuery.datepicker.parseDate(opt.CalDtFormat,jQuery("#eZ_chkin").val()));                            toDate_var.setDate(toDate_var.getDate());
                //        if (toDate_var && fromDate_var) {
                //        var difference = 0;
                //            var oneDay = 1000*60*60*24;
                //            var difference = Math.ceil((toDate_var.getTime() - fromDate_var.getTime()) / oneDay);
                //        }                            
                //        console.log("difference " + difference);
                //    }
                //});
                
                  
                $("[id^=bookingmulbtn]").on('click', function(e){
                    //if (opt.HotelId==40) {                        
                    //    var ses_val=methods._checksession_test(opt,'');
                    //    if (ses_val==1) {
                    //       return false;
                    //    }
                    //}                   
                    if ($("#ratemode").val()=='NONLINEAR' && $('#nl_loader').val()==1) { 
                        return false;
                    }
                    var ava_clicked=methods._check_availability_clicked(opt);
                    if (ava_clicked==false) {
                        return false;
                    }
                    showLoadingBar();
                    $form = jQuery("#frm");
                    
                    var store_roomrateunkid=new Array();
                    var store_ratetypeunkid=new Array();
                    var store_roomtypeunkid=new Array();
                   
                    
                    var rowrecordid=new Array();
                     
                    var roomtypecnt=new Array();
                    var roomtypemax=new Array();
                    
                    var rateplanname=new Array();
                    var room_selected_cnt=0;
                    var rplanname=''; //Pinal
                    var no_rooms=''; //Pinal
                    var rt=''; //Pinal
                    var valid_sel=0;
                    var col_select=1;
                    var store_chkDt='';
                    var tot_rooms=0;
                    var rm_chk=0;
                    
                    var adult_concat='';
                    var child_concat='';
                    var childage_concat='';
                    
                    if(opt.ShowNights==false && opt.ShowDepart==true)//dept date
                    {
                         var no_of_nights=parseInt($("input[id$='eZ_Nights']").val());
                    }
                    else
                        var no_of_nights=1;//if nights not enable on BE page then multiple room type booking not working
                    
                    if(opt.ShowNights==true)
                    {
                        $("select[id$='eZ_Nights'] option[value="+$("select#eZ_Nights").val()+"]").attr("selected", "selected");
                        no_of_nights=parseInt($("select[id$='eZ_Nights']").val());                    
                    }
                
                    //console.log("no_of_nights : " +  no_of_nights);
                    var ObjData='';
                    var age_empty=0;//age cehcking
                    var foundnegetiverate = 0; //Chandrakant - 1.0.50.55 - 31 Mar 2017
                    $("[id^=rooms_]").each(function(){                
                    var adult_chk='';
                    var child_chk='';
                    var childage_chk='';
                    var _JsonObjData;
                    
                    
                    bookbtnid=$(this).attr('id');
                        
                        if(jQuery(this).val()!=0 && jQuery(this).val()!='' && jQuery(this).is(':visible')==true) 					 
                        {
                            var _rowd=bookbtnid.split("_");
                            var _rowd2=_rowd[1].split("-");					 
                            var rowid='row_'+_rowd2[0];
                            var rooms=jQuery(this).val();
                            
                                _JsonObjData = eval(resgrid[0][_rowd2[0]]);
                                //console.log(_JsonObjData);
                               //console.log(jQuery(this).val() + " : " + resgrid[0][_rowd2[0]]['display_name'] + " : " + resgrid[0][_rowd2[0]]['roomrateunkid']);
                               
                               if(roomtypecnt['rmt_'+resgrid[0][_rowd2[0]]['roomtypeunkid']])
                               {
                                   roomtypecnt['rmt_'+resgrid[0][_rowd2[0]]['roomtypeunkid']]=parseInt(roomtypecnt['rmt_'+resgrid[0][_rowd2[0]]['roomtypeunkid']])+parseInt($(this).val());                                
                               }
                               else
                               {
                                   roomtypecnt['rmt_'+resgrid[0][_rowd2[0]]['roomtypeunkid']]=parseInt($(this).val());                                
                                   roomtypemax['rmt_'+resgrid[0][_rowd2[0]]['roomtypeunkid']]=parseInt(resgrid[0][_rowd2[0]]['MulRoomInventory']);
                                   rateplanname['rmt_'+resgrid[0][_rowd2[0]]['roomtypeunkid']]=resgrid[0][_rowd2[0]]['display_name'];
                               }
                               
                               store_roomrateunkid.push(resgrid[0][_rowd2[0]]['roomrateunkid']);
                               store_ratetypeunkid.push(resgrid[0][_rowd2[0]]['ratetypeunkid']);
                               store_roomtypeunkid.push(resgrid[0][_rowd2[0]]['roomtypeunkid']);
                               rowrecordid.push(_rowd2[0]);
                               
                               store_chkDt=resgrid[0][_rowd2[0]]['dt_'+(no_of_nights)];//for plugin of payment page
                               store_chkDt2=resgrid[0][_rowd2[0]]['dt_1'];//for plugin of payment page
                               //console.log(store_chkDt);
                               col_select++;
                                
                               tot_rooms=tot_rooms + parseInt($(this).val());
                               rm_chk=rm_chk + parseInt(rooms);
                               console.log("rooms : " + rooms );
                               
                               res=_bookingProcess_multipleroomtype(rowid,_rowd[1],rooms);
                               
                               if(res!=false){                            
                                    valid_sel++;                    
                               }
                               else
                               {
                                    foundnegetiverate=1; //Chandrakant - 1.0.50.55 - 31 Mar 2017
                               }
                                              
                                jQuery("select[id^=rm_adult_"+_rowd2[0]+"_]").each(function(){
                                   adult_id=jQuery(this).attr('id');
                                   adult_splt=adult_id.split("_");                                  
                                   adult_chk=adult_chk+adult_splt[3]+":"+parseInt(jQuery(this).val())+",";                                  
                                });
                                adult_chk = adult_chk.substring(0, adult_chk.length - 1);
                                adult_concat=adult_concat+adult_chk+'|';
                                
                                if (opt.showchild==true) {
                                    jQuery("select[id^=rm_child_"+_rowd2[0]+"_]").each(function(){
                                       child_id=jQuery(this).attr('id');
                                       child_splt=child_id.split("_");
                                       child_chk=child_chk+child_splt[3]+":"+parseInt(jQuery(this).val())+",";                                    });
                                
                                    child_chk = child_chk.substring(0, child_chk.length - 1);
                                    child_concat=child_concat+child_chk+'|';
                                }
                                
                                jQuery("select[id^=age_"+_rowd2[0]+"_]").each(function(){
                                    if(jQuery(this).is(':visible'))
                                    {                                       
                                        if(jQuery(this).val()!='')
                                        {                                       
                                        childage_id=jQuery(this).attr('id');
                                        childage_splt=childage_id.split("_");                                        
                                        childage_chk=childage_chk+childage_splt[3]+":"+parseInt(jQuery(this).val())+",";      
                                        }
                                        else
                                        {
                                           age_empty++; 
                                        }
                                    }                                                               
                                });
                                
                                if (childage_chk!='') {
                                    childage_chk = childage_chk.substring(0, childage_chk.length - 1);
                                    childage_concat=childage_concat+childage_chk+'|';
                                }                                
                                room_selected_cnt++;
                               
                               //Pinal - 1.0.47.52 - 23 January 2016 - START
                               //Purpose - Check live availability of rooms
                               if(rplanname!='')
                                   rplanname=rplanname +"," + resgrid[0][_rowd2[0]]['display_name'];
                               else
                                   rplanname=resgrid[0][_rowd2[0]]['display_name'];
                                   
                               if(no_rooms!='')
                                   no_rooms=no_rooms +"," + $(this).val();
                               else
                                   no_rooms=$(this).val();
                                   
                               if(rt!='')
                                   rt=rt +"," + resgrid[0][_rowd2[0]]['roomtypeunkid'];
                               else
                                   rt=resgrid[0][_rowd2[0]]['roomtypeunkid'];
                               //Pinal - 1.0.47.52 - 23 January 2016 - END
                               
                               if (ObjData!='') {
                                    ObjData = ObjData + "<resgrid_123>" + JSON.stringify(_JsonObjData);
                               }
                               else{                                
                                     ObjData = JSON.stringify(_JsonObjData); //for single room selection
                               }
                        }                        
                    });
                   // console.log(ObjData);
                    jQuery("#hidData").html('');
                    
                    /*Facebook plugin , widget plugin - start*/
                    
                    // Priyanka Maurya - 04 Nov 2018 - START - FBEnhancement
                    // Purpose : BE page is not open in facebook iframe
                    
                    if ( opt.BookingFrom != '' )
                    {
                        jQuery('<div style="display:none"><textarea name="res_grid" id="res_grid">'+ObjData+'</textarea></div>').appendTo("#hidData");	
                        jQuery("<input type='hidden' name='callfrom' id='callfrom' value='FB' />").appendTo("#hidData");
                    }
                    // Priyanka Maurya - END 
                    
                    try{
                        if(parent.document.domain!=serverhost && !parent.editframe)
                        {
                            jQuery('<div style="display:none"><textarea name="res_grid" id="res_grid">'+ObjData+'</textarea></div>').appendTo("#hidData");                           
                        }
                    }
                    catch(e)
                    {
                            try{
                                if(!parent.editframe)
                                {
                                    jQuery('<div style="display:none"><textarea name="res_grid" id="res_grid">'+ObjData+'</textarea></div>').appendTo("#hidData");                                    
                                }
                            }
                            catch(e)
                            {
                                jQuery('<div style="display:none"><textarea name="res_grid" id="res_grid">'+ObjData+'</textarea></div>').appendTo("#hidData");	
                                jQuery("<input type='hidden' name='callfrom' id='callfrom' value='FB' />").appendTo("#hidData");                               
                            }
                    }
                    /*Facebook plugin , widget plugin - end*/
                    
                    adult_concat = adult_concat.substring(0, adult_concat.length - 1);
                    child_concat = child_concat.substring(0, child_concat.length - 1);
                    childage_concat = childage_concat.substring(0, childage_concat.length - 1);
                    
                    console.log(tot_rooms + " : " + valid_sel + " : " +  opt.HotelId + " : " + rm_chk);
                   // return false;
                    //flora -for checking case of multiple rate plan negative amoutn msg not coming 
                    //if(valid_sel>0 && parseInt(valid_sel)!=parseInt(rm_chk)) {
                    //   return false;
                    //}
                    //return false;
                    //flora -for checking case of multiple rate plan negative amoutn msg not coming
                    
                    if(valid_sel==0 || foundnegetiverate==1) { //Chandrakant - 1.0.50.55 - 31 Mar 2017 added foundnegetiverate
                        return false;
                    }
                    
                    var displaymsg=langjs[0]["L_NoSuffDataEntered"]+"<br><br>";
                    var errorcnt=0;
                    for (var p in roomtypecnt) {
                        if(roomtypecnt[p] > roomtypemax[p])
                        {
                        displaymsg+=" [ "+rateplanname[p]+" ] "+langjs[0]["L_WrongInvSelec1"].replace("{number}",roomtypemax[p])+"<br><br>";
                             errorcnt++;
                        }
                    }
                    if(errorcnt>0)
                    {
                        swal({
                                title: '',//title_message,
                                text: displaymsg,
                                type: 'warning',
                                showCancelButton: false,
                                animation:false																					
                             });  
                        hideLoadingBar();	
                        return false;	
                    }
                    else if(room_selected_cnt == 0)
                    {
                        var displaymsg2=langjs[0]["L_SelectRooms"]+"<br><br>";
                        swal({
                                title: '',//title_message,
                                text: displaymsg2,
                                type: 'warning',
                                showCancelButton: false,
                                animation:false																					
                             });  
                        hideLoadingBar();	
                        return false;	                            
                    }
                    else if(age_empty > 0)
                    {
                        hideLoadingBar();
                        var displaymsg2=langjs[0]["L_SelectAge"];//+"<br><br>";
                        var displaymsg3=langjs[0]["L_SelectAge2"];
                        showagecnt=0;
                        jQuery(".childageerr").each(function() {                            
                            if (jQuery(this).prev().is(':visible')==true) {                                
                                var ch_id=jQuery(this).prev().attr('id');
                                //Chandrakant - 1.0.50.55 - 10 August 2016 - START
                                //Purpose : To solve not selected child combo issue
                                //var child_cls_chk=jQuery("#"+ch_id).find('select.agecls').val();                                
                                //if (child_cls_chk=='') {                                    
                                //    jQuery(this).prev().find('.agecls').addClass('error');
                                //    jQuery(this).html(displaymsg2);
                                //    jQuery("#err_child_age").show();
                                //    jQuery("#err_child_age").text(displaymsg3);//book now
                                //    jQuery("#err_child_age").addClass('error_tag');
                                //    showagecnt++;
                                //}
                                //// console.log("showagecnt : " + showagecnt);
                                jQuery("#"+ch_id).find('.vres-chk-box').each(function() {
                                    var child_cls_chk=jQuery(this).find('select.agecls').val();
                                    if (child_cls_chk=='') {
                                        jQuery(this).find('.agecls').addClass('error');
                                        jQuery("#"+ch_id).next().html(displaymsg2);
                                        jQuery("#err_child_age").show();
                                        jQuery("#err_child_age").text(displaymsg3);//book now
                                        jQuery("#err_child_age").addClass('error_tag');
                                        showagecnt++;
                                    }
                                });
                                //Chandrakant - 1.0.50.55 - 10 August 2016 - END
                            }
                        });                       
                        return false;	                            
                    }
                    else
                    {
                           
            jQuery("<input type='hidden' name='roomrateunkid' id='roomrateunkid' value='" + store_roomrateunkid.join(',') + "' />").appendTo("#hidData");
			jQuery("<input type='hidden' name='roomtypeunkid' id='roomtypeunkid' value='" + store_roomtypeunkid.join(',') + "' />").appendTo("#hidData");
			jQuery("<input type='hidden' name='ratetypeunkid' id='ratetypeunkid' value='" + store_ratetypeunkid.join(',') + "' />").appendTo("#hidData");
			jQuery("<input type='hidden' name='rowid' id='rowid' value='" + rowrecordid.join(',') + "' />").appendTo("#hidData");	
			jQuery("<input type='hidden' name='noofrooms' id='noofrooms' value='" + no_rooms + "' />").appendTo("#hidData");
            jQuery("<input type='hidden' name='chk_in' value='" + store_chkDt2 + "' />").appendTo("#hidData");            
            jQuery("<input type='hidden' name='chk_out' value='" + resgrid[0][0].CheckoutDate + "' />").appendTo("#hidData");
            jQuery("<input type='hidden' name='selectenddate' value='" + store_chkDt + "' />").appendTo("#hidData");
            
            jQuery("<input type='hidden' name='CalDtFormat' value='" + jQuery("#CalDtFormat").val() + "' />").appendTo("#hidData");
            jQuery("<input type='hidden' name='Arrdt' value='" + jQuery("#ArrvalDt").val() + "' />").appendTo("#hidData");
            jQuery("<input type='hidden' name='range' value='1," + col_select + "' />").appendTo("#hidData");
            jQuery("<input type='hidden' name='rooms' id='rooms' value='" + tot_rooms + "' />").appendTo("#hidData");		
          
            jQuery("<input type='hidden' name='MultiplyFactor' value='" + resgrid[0][0]['MultiplyFactor'] + "' />").appendTo("#hidData");
            jQuery("<input type='hidden' name='MultiplyCurrSymbl' value='" + resgrid[0][0]['MultiplyCurrSymbl'] + "' />").appendTo("#hidData");
            jQuery("<input type='hidden' name='MultiplyCurrCode' value='" + resgrid[0][0]['MultiplyCurrCode'] + "' />").appendTo("#hidData");
            
            jQuery("<input type='hidden' name='adults' value='" + adult_concat + "' />").appendTo("#hidData");
            jQuery("<input type='hidden' name='child' value='" + child_concat + "' />").appendTo("#hidData");
            jQuery("<input type='hidden' name='childage' value='" + childage_concat + "' />").appendTo("#hidData");
            jQuery("<input type='hidden' name='selectedviewoptions' value='0' />").appendTo("#hidData");            
            jQuery("<input type='hidden' name='CHCurrency2' value='"+jQuery("#selected_curr").val()+"' />").appendTo("#hidData");                   
           
            jQuery("<input type='hidden' name='_TrackId' value='"+jQuery("#_TrackId").val()+"' />").appendTo("#hidData"); //Pinal - 1.0.44.49 - 4 Feb 2015 - Purpose - Booking Engine analytics
           //  return false;
            if(typeof($form.attr('target'))!='undefined')
                    hideLoadingBar();	                        
            }
             
            if (valid_sel > 0) {
                    //hideLoadingBar();
                   // console.log("form submit");
		    
		    //Pinal - 1.0.49.54 - 19 April 2016 - START
		    //Purpose - Check live room availability (give intimation to booker)
		    if (typeof(resgrid[0][0]['CheckLiveRoomsAvailability'])!='undefined' && resgrid[0][0]['CheckLiveRoomsAvailability']=='2')
		    {
			var no_nights=1;
			if(opt.ShowNights==false && opt.ShowDepart==true)
			    no_nights=$("input[id$='eZ_Nights']").val();
			else
			    no_nights=$("input#eZ_Nights2").val();
			    
			var param="action=getliveroomavailability&check_in_date="+$("#eZ_chkin").val()+"&nights="+no_nights+"&roomtypeunkid="+rt+"&HotelId="+opt.HotelId+"&noofrooms="+no_rooms+"&rateplanname="+encodeURIComponent(rplanname)+"&calendarDateFormat="+jQuery("#CalDtFormat").val();
			jQuery.ajax({
				type: "POST", 
				url: serverurl+'service.php',
				data: param,
				success: function(response) {
				    
				    var res = JSON.parse(response.trim());
				      
				    if(res!='' && res.allowornot=='Notallow')
				    {
					    var confirm_mess=langjs[0]['L_SameRoomNABook'];
					    
					    hideLoadingBar();					    
					    //var r=confirm(confirm_mess);
					    
					    swal({
						title: "",
						text: confirm_mess,
						type: "warning",
						showCancelButton: true,
						confirmButtonColor: "#DD6B55",
						confirmButtonText: "Yes ! I want to book.",
						cancelButtonText: "No",
						//closeOnConfirm: false,
						closeOnCancel: false,
						animation:false
					    },
					    function(isConfirm){
						if (!isConfirm)
						{
						    var mess_alert='';
						    mess_alert+=langjs[0]['L_SameRoomNAselected']+":\n";
						    
						    jQuery.each(res.data, function(jsonKey, jsonValue) {
						     
							if (mess_alert!=(confirm_mess+" "))
							   mess_alert+="\n";
							   
							if (jsonValue.totalavailable=='0' || jsonValue.totalavailable==0)
							   mess_alert+=""+jsonValue.bindedrateplan+" => "+langjs[0]['L_NOTAVAILABLE'];
							else
							   mess_alert+=""+jsonValue.bindedrateplan+" => "+jsonValue.totalavailable+" "+langjs[0]['L_roomsAvailable']+".";
							mess_alert+="\n";
						    });
						    
						    swal({
							title: '',//title_message,
							text: mess_alert,
							type: 'warning',
							showCancelButton: false,
							animation:false,
						    });  
						}
						else
						{
                            if($("#dataLayerScriptVersion").val()=="1"){
                                methods.addCheckoutDetails();
                            }
						    showLoadingBar();
						    $form.submit();
						}
					    });
				    }
				    else
				    {
                        if($("#dataLayerScriptVersion").val()=="1"){
                            methods.addCheckoutDetails();
                        }
					showLoadingBar();
					$form.submit();
				    }
				}
			});
		    }
		    //Pinal - 1.0.49.54 - 19 April 2016 - END
		    else{
                if($("#dataLayerScriptVersion").val()=="1"){
                    methods.addCheckoutDetails();
                }
                $form.submit();
            }
            }
            
            });
             
	    
	    //Reward on facebook share - START
	    var _isfacebookshare=false;
	//    if(('sessionStorage' in window))
	//    {
	//	    var keyname="share_facebook_"+opt.HotelId;
	//	    if(sessionStorage.getItem(keyname)!=null)	
	//	    {
	//		    var _isfacebookshare=sessionStorage.getItem(keyname);
	//	    }
	//	    else
	//	    {
	//		    var _isfacebookshare=false;
	//		    sessionStorage.setItem(keyname,false);
	//	    }
	//    }
	
	    var keyname="share_facebook_"+opt.HotelId;
	    var name = keyname + "=";
	    var ca = document.cookie.split(';');
	    var cookie_value='';
	    for(var i = 0; i <ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0)==' ') {
		    c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {			    
		    cookie_value=c.substring(name.length,c.length)
		}
	    }		    
	    
	    if (cookie_value.trim()=='true')
		    _isfacebookshare=true;
		    
	    var issecuresite=false;
	    if(serverurl.indexOf("https") != -1)
		issecuresite=true;
		
	    jQuery("#facebooksharefrm").attr("action","https://live.ipms247.com/booking/facebooksharereward.php?issecuresite="+issecuresite); //Chirag 14 SEP 2019 RES-2194 Purpose: added live.ipms for solving the cname issue.
		
	    if (_isfacebookshare)
		jQuery("#special_offers").hide();
	    else
		jQuery("#special_offers").show();
		
	    jQuery('.likebtn').click(function(){
		    methods.checkPromoCodeValidity(opt);
	    });
	    
	    jQuery('.slider_popup .cls_btn').click(function(e) {
		    e.stopPropagation();
		    jQuery('.slider_popup').removeClass('slider-right');
	    });
	    //Reward on facebook share - END
	    
	    jQuery("#noroomresetallfilters").on('click', function(){ //Click of reset_filter when no rooms available due to filters
		jQuery("#reset_filter").click();
	    });
		
	    //Load js for captcha - START
	    $("#rescontactusform").click(function() {
		    $(".error:input").removeClass("error");
		    $("#phoneno-error").remove();
		    $("#fromemail-error").remove();
		    $("#contactuserror").hide();
		    $("#invalidcaptchamsg").hide();
	    });
	    //Load js for captcha - END
	    
	    $(".disppromodesc").each(function(){
		
		var content=$(this).html();
		//console.log(content);
		var obj=jQuery(this).next("a");//.children(":first");
		
		obj.tipso({
		    //background: 'rgba(0, 0, 0, 0.6) none repeat scroll 0 0',
		    //color: '#fff',
		    //titleColor: '#55b555',
		    //titleBackground: '#55b555',
		    TextAlign:'left',
		    tooltipHover: true,
		    AddClass : 'spl_offer'
		});
		obj.tipso('update', 'content', content);
	    });
	    
	    
	    //Pinal - 1.0.50.55 - 12 August 2016 - START
	    //Purpose - Price Widget
	    jQuery('#re_pricecheckiframe').on('load', function(){
		
            if (opt.WebDefaultCurrency=='')
            {
                var price_obj=document.getElementById('re_pricecheckiframe').contentWindow.$('div.price-widget-wrap');
                price_obj.show();
                
                var li_length=document.getElementById('re_pricecheckiframe').contentWindow.$('#OTARates >ul >li').length; //Hide price widget whene there are no rates of ota displayed - Pinal
                
                if (li_length<1) //Hide price widget whene there are no rates of ota displayed - Pinal
                    price_obj.hide();
                    
                jQuery("#re_pricecheckiframe").hide();
            }
        });
        
	    var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
	    var eventer = window[eventMethod];
	    var messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";
	    eventer(messageEvent,function(e) {
		var hiddate=e.data;
        if(typeof hiddate === 'string' && hiddate.type!='triptease.onboard.eval' && hiddate.type!='triptease.onboard.response' && hiddate.type!='tt_local_storage_remove_item' && hiddate.type!='tt_local_storage_set_item')
        {
            hiddate=hiddate.split("^");
            
            if(hiddate.length==2) //Pinal - 8 November - Purpose : To avoid clash with chat bot event.
            {
                jQuery("#re_pricecheckiframe").animate({
                    "height": hiddate[0],
                    "width": hiddate[1],
                    }, 200);
                
                jQuery("#re_pricecheckiframe").show(500);
            }
        }
	    },false);
	    //Pinal - 1.0.50.55 - 12 August 2016 - END
	    
            //console.log("_loadDefaultJsInit load");
	    
            }//resgrid not undefined
            else
            {
                hideLoadingBar();                
            }
            
            //Pinal - 1.0.52.60 - 17 August 2017 - START
            //Purpose - Shifted below code from outside of resgrid confition since Enquiry and Availability calender not loading when rooms are not availble.
            
            //Availability Calender - START
            $(".availability_calender").click(function() {
            
            //console.log("availability calender load function - "+opt.HotelId+" | "+opt.CalDefaultDt);
            
            var prmcode='';		
            if (jQuery(this).next().next().hasClass("index_avcal"))
            {
                var avindex=parseInt(jQuery(this).next().next().val());
                
                if (resgrid[0][avindex].promotion!=undefined && resgrid[0][avindex].promotion==true && resgrid[0][avindex].promotioncode!=undefined)
                {
                prmcode=resgrid[0][avindex].promotioncode;
                }
            }
            jQuery("#avail_cal_prmcode").val(prmcode);
            
            $("input[id$='Jumpdt']").datepicker({
                    dateFormat: opt.CalDtFormat,
                    defaultDate: opt.CalDefaultDt ,
                    minDate: opt.CalMinDate,
                    maxDate: opt.CalMaxDate,
            });
            methods.dayTripper();
            //Priyanka Maurya - 22 May 2019 - Purpose : Change According to WCAG Guideline - WCAG 2.0 - START
            
            $('#Jumpdt').keydown( function (event) {
                var inst = $.datepicker._getInst(event.target);
                var isRTL = inst.dpDiv.is(".ui-datepicker-rtl");

                switch (event.keyCode) {
                    case 37:    // LEFT --> -1 day
                        $.datepicker._adjustDate(event.target, (isRTL ? +1 : -1), "D");
                    break;
                    case 38:    // UPP --> -7 day
                        $.datepicker._adjustDate(event.target, -7, "D");
                    break;
                    case 39:    // RIGHT --> +1 day
                        $.datepicker._adjustDate(event.target, (isRTL ? -1 : +1), "D");
                    break;
                    case 40:    // DOWN --> +7 day
                        event.preventDefault();
                        $.datepicker._adjustDate(event.target, +7, "D");
                    break;
                    default:
                    break;
                }
                $('.ui-datepicker-days-cell-over').find('a').css("background-color","#eeeeee");
            });
            //Priyanka Maurya - END
            
            var checkin=$("#eZ_chkin").val();
            
            var roomrateunkid=$(this).next(".avail_cal_d").val();
            
            //Jemin 15-Nov-2019 RES-2147 Purpose:For Individual Rateplan vise Availability Calendar -START
            var roomtype = $(this).next(".avail_cal_d").next(".avail_cal_d_roomtype").val();
            if(roomtype != '' && typeof(roomtype)!='undefined')    
                $('#avairoomtype').val(roomtype) 
            // Jemin END

            var isspecial=$(this).next(".avail_cal_d").hasClass("special");
            //console.log(isspecial);
            if (roomrateunkid!='' && typeof(roomrateunkid)!='undefined')
            {
                methods._availabilitycal(opt,checkin,roomrateunkid,isspecial,'');
                $("#avail_cal_d_dt").val(roomrateunkid);
                
                if (isspecial)
                $("#avail_cal_d_dt").addClass("special");
            }
            else
            {
                methods._availabilitycal(opt,checkin,'','','');
            }
            $("input[id$='Jumpdt']").val(checkin);
            var date = $('#Jumpdt').datepicker('getDate');   
            var ariaDateCheckOut = $.datepicker.formatDate("DD, MM d, yy", date);
            $('#Jumpdt').attr("aria-label", ariaDateCheckOut);
            //console.log($("#avail_cal_d_dt").val());
            });
            $("input[id$='Jumpdt']").change(function(){
                 var roomTypeunkId = $('#avairoomtype').val();     // Jemin RES-2147 Availability Calender-START
                methods._availabilitycal(opt,jQuery(this).val(),jQuery("#avail_cal_d_dt").val(),jQuery("#avail_cal_d_dt").hasClass("special"),'',roomTypeunkId,1);
            });

            // Jemin RES-2147 Availability Calender-START
            $('#avairoomtype').change(function()
            {
                var checkin = $("input[id$='Jumpdt']").val();
                methods._availabilitycal(opt,checkin,jQuery("#avail_cal_d_dt").val(),jQuery("#avail_cal_d_dt").hasClass("special"),'',this.val);
            });
            // Jemin RES-2147 Availability Calender-END
            
            (function( jQuery ){
            jQuery.fn.next_dt = function() {
                $("#next_dt_clk").click(function(){
                $("input[id$='Jumpdt']").val($("#next_dt").val());
                $("input[id$='Jumpdt']").change();
                });
            }; 
            })( jQuery );
            
            (function( jQuery ){
            jQuery.fn.prev_dt = function() {
                $("#prev_dt_clk").click(function(){
                
                var currendate = new Date();
                currendate.setDate(currendate.getDate()+opt.CalMinDate);
                
                var change=false;
                if (!($.datepicker.parseDate(opt.CalDtFormat, jQuery("#Jumpdt").val())<=currendate)
                && $.datepicker.parseDate(opt.CalDtFormat, jQuery("#prev_dt").val())<currendate)
                {
                change=true;
                }
                
                var d_prev = $.datepicker.parseDate(opt.CalDtFormat, jQuery("#prev_dt").val());
                d_prev.setDate(d_prev.getDate() - 12); //Priyanka Maurya - 20 July 2019 - Purpose : Rates are not showing properly in Calendar - RES-2082
                
                var d_next = $.datepicker.parseDate(opt.CalDtFormat, jQuery("#prev_dt").val());
                d_next.setDate(d_next.getDate() + 12); //Priyanka Maurya - 20 July 2019 - Purpose : Rates are not showing properly in Calendar - RES-2082
                
                var formated_prev=$.datepicker.formatDate(opt.CalDtFormat, d_prev);
                var formated_next=$.datepicker.formatDate(opt.CalDtFormat, d_next);
                
                $("input[id$='Jumpdt']").val(jQuery("#prev_dt").val());
                methods._availabilitycal(opt,$("input[id$='Jumpdt']").val(),jQuery("#avail_cal_d_dt").val(),jQuery("#avail_cal_d_dt").hasClass("special"),"prev");
                
                $("#prev_dt").val(formated_prev);
                $("#next_dt").val(formated_next);
                
                if (change)
                {
                jQuery("#Jumpdt").val($.datepicker.formatDate(opt.CalDtFormat, currendate))
                }
            });
            }; 
            })( jQuery );
            //Availability Calender - END
        
            //Main enquiry form on non-availability of room - START
            $("#enquirenotavail").click(function() {
                    
                    getUrl=opt.serverurl+'enquire-'+opt.HotelId;
            
                    $.ajax({
                        url: getUrl,
                        type: "POST",
                        dataType: "html",
                        data: {
                            HotelId: opt.HotelId,
                            roomtypeid: '',
                            ratetypeid: '',
                specialunkid: '',			
                        },
                        error: function(){
                        },
                        beforeSend: function(){
                    showLoadingBar();
                    $("#mainenq").html('');
                        },
                        success: function(data){
                    hideLoadingBar();
                    $("#mainenq").html(data);
                },
                complete:function(){
                var _jsPath=opt.serverurl+"/templates/resui/js/";
                $.getScript(_jsPath+"jquery.plugin.js");
                
                jQuery("input[id$='eqm_checkin_date']").attr( 'readOnly' , 'true' );					
                jQuery("input[id$='eqm_checkin_date']").datepicker({
                    minDate: opt.CalMinDate,
                    dateFormat: opt.CalDtFormat,
                    onSelect: function(selected) {
                        jQuery("input[id$='eqm_checkout_date']").datepicker("option","minDate", selected);
                        
                        var checkout_date=new Date(jQuery.datepicker.parseDate(opt.CalDtFormat,jQuery("#eqm_checkin_date").val()));
                        checkout_date.setDate(checkout_date.getDate() + parseInt(opt.WebAvailabilityCutOffDay));
                        checkout_date = jQuery.datepicker.formatDate(opt.CalDtFormat, checkout_date);
                        
                        jQuery("#eqm_checkout_date").val(checkout_date);
                        
                        //Disable past date till check in for check out
                        var fromDate_var = jQuery("input[id$='ArrvalDt_2']").val();
                        var fromDate_var=new Date(jQuery.datepicker.parseDate(opt.CalDtFormat,fromDate_var));
                        
                        var toDate_var=new Date(jQuery.datepicker.parseDate(opt.CalDtFormat,jQuery("#eqm_checkin_date").val()));
                        toDate_var.setDate(toDate_var.getDate());
                        if (toDate_var && fromDate_var) {
                        var difference = 0;
                            var oneDay = 1000*60*60*24;
                            //var difference = Math.ceil((toDate_var.getTime() - fromDate_var.getTime()) / oneDay);
                            var difference = Math.round((toDate_var.getTime() - fromDate_var.getTime()) / oneDay); //Pinal - 1.0.50.55 - 22 September 2016 - Purpose - For correcting no of nights calculation for day light saving change.
                            }                            
                        jQuery("#eqm_checkout_date").datepicker('option', 'minDate', difference+1);					
                        //$("input[id$='eqm_checkout_date']").focus(0); //Pinal
                     }
                });
                
                jQuery("input[id$='eqm_checkout_date']").attr( 'readOnly' , 'true' );					
                jQuery("input[id$='eqm_checkout_date']").datepicker({
                    minDate: opt.CalMinDate+1,
                    dateFormat: opt.CalDtFormat,
                });
                }		    
                });
                });
            //Main enquiry form on non-availability of room - END
            
            
            }        
            catch(err){
                hideLoadingBar();
                hideCheckavalabilityLoadingBar();
				console.log("Error - _loadDefaultJsInit - "+err.message);
		    }  
        },
        _check_availability_clicked:function(opt){
          try{
                methods._checksession(opt,'');//Check session - florra
                if(jQuery("#trackbook_btnclicked").val()==0 && jQuery("#trackbtnclicked").val()==1)
                {   
                    //Prashant Patel - 27/09/2021 - Purpose :  Add restriction for maxnight while book room without check availability [RES-2881] ---START
                    startDate = $.datepicker.parseDate(opt.CalDtFormat, $("#eZ_chkin").val());
                    endDate = $.datepicker.parseDate(opt.CalDtFormat, $("#eZ_chkout").val());
                    selectednights = jQuery("Select[id$='eZ_Nights']").val();
                    if(opt.ShowNights==false && opt.ShowDepart==true)
                    {
                        var difference = (endDate - startDate) / (86400000);
                    }
                    else
                    {
                        var difference = selectednights;
                    }
                    //Prashant Patel - 27/09/2021 - Purpose :  Add restriction for maxnight while book room without check availability [RES-2881] ---END

                    var message_title = js_stripslashes(langjs[0]["lblHangon"]); 
                    var message = js_stripslashes(langjs[0]["lblForgotcheckavailability"]);
                    var message_btn = js_stripslashes(langjs[0]["L_Pleaseclickchkava"]); 
                        curr_control_id='room';
                        methods._setIframeSweetAlertPosition(opt,curr_control_id);
                        swal({
                            title: message_title,
                            text: message,
                            type: "warning",
                            confirmButtonText: message_btn,
                            showCancelButton: false,
                            //cancelButtonText:"No",
                            confirmButtonColor: "#DD6B55",
                            animation:false,
                            closeOnConfirm: (difference > 90) ? false:true //Prashant Patel - 27/09/2021 - purpose : Add this attribute[RES-2881]		
                            },
                            function(){
                                //Prashant Patel - 27/09/2021 - purpose : Add condition as per maxnight restriction[RES-2881] --START
                                if(difference > 90)
                                {
                                    jQuery('#book').trigger('click');
                                }
                                else
                                {
                                    methods._loadGrid(opt);
                                }
                                //Prashant Patel - 27/09/2021 - purpose : Add condition as per maxnight restriction[RES-2881] --END
                            }
                        );                    
                hideLoadingBar();
                return false;
                }
          }
          catch(err){
                hideLoadingBar();
                hideCheckavalabilityLoadingBar();
				console.log("Error - _check_availability_clicked - "+err.message);
          }
        },
    _showdefaultCurrency:function(opt){
        try
        {
			if(typeof(resgrid[0])!='undefined' && resgrid[0]!=null && typeof(resgrid[0][0])!='undefined')
			{
                    var comapre_curr_code='';
                    if (resgrid[0][0]['MultiplyCurrCode1']=='')
                    {
                        $('.currname').text(resgrid[0][0]['MultiplyCurrCode']);
                        comapre_curr_code=resgrid[0][0]['MultiplyCurrCode'];
                        $('.currsym').text(resgrid[0][0]['MultiplyCurrSymbl']);
                        $("#selected_curr").val(resgrid[0][0]['MultiplyCurrCode']);
                        if (resgrid[0][0]['MultiplyCurrCode']!=resgrid[0][0]['MultiplyCurrSymbl']) {
                            $('.currsymbol').text(resgrid[0][0]['MultiplyCurrSymbl']);
                        }
                        else
                            $('.currsymbol').text('');
                        $("#spancurrsign").text(resgrid[0][0]['MultiplyCurrSymbl']);
                    }
                    else
                    {
                        $('.currname').text(resgrid[0][0]['MultiplyCurrCode1']);
                        comapre_curr_code=resgrid[0][0]['MultiplyCurrCode1'];
                        $('.currsym').text(resgrid[0][0]['MultiplyCurrSymbl1']);
                        $("#selected_curr").val(resgrid[0][0]['MultiplyCurrCode1']);
                        
                        if (resgrid[0][0]['MultiplyCurrCode1']!=resgrid[0][0]['MultiplyCurrSymbl1']) {
                            $('.currsymbol').text(resgrid[0][0]['MultiplyCurrSymbl1']);
                        }
                        else
                            $('.currsymbol').text('');
                            
                        $("#spancurrsign").text(resgrid[0][0]['MultiplyCurrSymbl1']);
                    }
                    
                    if(resgrid[0][0]['MultiplyCurrCode1']!='') {
                        methods._getCurrencyValue(opt,resgrid[0][0]['MultiplyCurrCode'],resgrid[0][0]['MultiplyCurrCode1'],resgrid[0][0].decimal);
                    }
                    else
                    {
                        methods._setSelectedCurrency(opt,comapre_curr_code);
                    }
			}
        }
        catch(e)
        {
            console.log("_showdefaultCurrency Error "+e);
        }
		},
        _setIframeSweetAlertPosition:function(opt,curr_control_id)
        {
            try{
                  //set_control_id
                   // console.log("_setIframeSweetAlertPosition- " +curr_control_id);
                    set_control_id=jQuery(".sweet-alert");
                    if(typeof(top.frames['editframe'])!='undefined' ) {
                    
                    var obj_html=curr_control_id;                    
                    var content_height = parseInt(jQuery("#"+obj_html).offset().top);//content height                    
                    var popup_height=parseInt(set_control_id.height());//popup height                    
                    var page_height=parseInt(jQuery("#re_main_div").height());
                     
                    var total_top=page_height-content_height;
                    
                    //console.log("content_height  " + content_height);
                    //console.log("popup_height  " + popup_height);
                    //console.log("page_height  " + page_height);
                    //console.log("total_top  " + total_top);
                    
                    if(total_top < popup_height) {
                       var set_height=content_height - popup_height;
                    }
                    else
                    {
                        var set_height=content_height + 50;
                    }
                 //   console.log("_setIframeSweetAlertPosition- set_height " +set_height);
                    
                    jQuery(".sweet-alert").css('top',set_height);
                    set_control_id.css('top',set_height);                    
                    set_control_id.scrollTop();                                                      
                }		    							   
            }
            catch(e)
            {
                console.log("Error - _setIframeSweetAlertPosition- "+e.message);
            }
        },
        _setIframePopupPosition:function(opt,id,detailmodel_id)
        {
            try{		    
                    //console.log("_setIframePopupPosition- " +id);
                  if (typeof(top.frames['editframe'])!='undefined' ) {
                    
                    var obj_html=id;
                    
                    var content_height = parseInt(jQuery("#"+obj_html).offset().top);//content height                    
                    var popup_height=parseInt(detailmodel_id.height());//popup height                    
                    var page_height=parseInt(jQuery("#re_main_div").height());
                     
                    var total_top=page_height-content_height;
                    
                    //console.log("content_height  " + content_height);
                    //console.log("popup_height  " + popup_height);
                    //console.log("page_height  " + page_height);
                    //console.log("total_top  " + total_top);
                    //
                    if(total_top < popup_height) {
                       var set_height=content_height - popup_height;
                    }
                    else
                    {
                        var set_height=content_height + 50;
                    }
                    jQuery(".modal.fade.in").css('top',set_height);
                    detailmodel_id.css('top',set_height);
                    
                    detailmodel_id.scrollTop();                                                      
                }		    							   
            }
            catch(e)
            {
                console.log("Error - _setIframePopupPosition- "+e.message);
            }
        },
        _setIframeBookNowSummary:function()
        {
            try{               
               if (typeof(top.frames['editframe'])!='undefined' ) {                       
                       var hidata = jQuery(".vres-bookdiv").position().top;
		      // console.log("post message _setIframeBookNowSummary "+hidata);
                       parent.postMessage({'action':'TOP', 'top':hidata},"*");
                    }			
            }
            catch(e)
            {
                console.log("Error - _setIframeBookNowSummary- "+e.message);
                var hidata = jQuery(".vres-bookdiv").position().top;		      
                parent.postMessage({'action':'TOP', 'top':hidata},"*");
            }
        },
        _setIframeEditRecord:function(row_height)
        {
            try{               
               if (typeof(top.frames['editframe'])!='undefined' ) {
		//console.log("post message _setIframeEditRecord "+row_height);
                       parent.postMessage({'action':'EDIT', 'top':row_height},"*");
                    }			
            }
            catch(e)
            {
                console.log("Error - _setIframeEditRecord- "+e.message);
                parent.postMessage({'action':'EDIT', 'top':row_height},"*");
            }
        },
        _setFilterHeight:function(opt){
            try{
                 if (typeof(top.frames['editframe'])!='undefined' ) {
                    var hidata1=parseInt(jQuery(".filter-div").height())/2;
                    var hidata2=jQuery("#re_main_div").height();
                    var hidata=(parseInt(hidata1)+parseInt(hidata2))-100;
                     parent.postMessage(hidata,"*");
                 }
            }
            catch(e){
                console.log("Error - _setFilterHeight- "+e.message);
            }
        },
        _setExternalIframeHeight:function(opt){	
            try {		
                    
                    //console.log("height  " + hidata);
                //console.log("_setExternalIframeHeight before call");
                    var _cssName=opt.serverurl+"templates/resui/css/";
                    
                    if (typeof(top.frames['editframe'])!='undefined' ) {
			//console.log("_setExternalIframeHeight call");
			var hidata=jQuery("#re_main_div").height();
		   // alert("_setExternalIframeHeight post message "+hidata);
		    //console.log("_setExternalIframeHeight post message "+hidata);
                        parent.postMessage(hidata,"*");
		    
                       // console.log("_setExternalIframeHeight call");
                        var frm = top.frames['editframe'].document;
                        var frame_name=top.frames['editframe'];
                        var otherhead = frm.getElementsByTagName("body")[0];
                        
                        var cssSelector = document.getElementById("uiCss");
                        if (!cssSelector) {
                            var link = frm.createElement("link");
                            link.setAttribute("rel", "stylesheet");
                            link.setAttribute("type", "text/css");
                            link.setAttribute("href", _cssName+"resframecss.css");
                            link.setAttribute("ID", "uiCss");
                            otherhead.appendChild(link);
                        }                        
                    }
            } catch(e) {
                console.log("_setExternalIframeHeight" + e);		
            }	
        },
        //Chinmay Gandhi - 1.0.51.56 - START
        //Purpose: Set currency list vise
        _getCountryName:function(opt){
			try
			{
				getURL = opt.serverurl+'service.php?HotelId='+opt.HotelId;
                $.ajax({
                    url: getURL,
                    type: "POST",
                    data: {
                        action: 'Getcountryname',
                        HotelId: opt.HotelId
                    },
                    success: function(data){
                        jQuery("#suggested_curr").val(jQuery.trim(data));
                        var country_curr = jQuery.trim(data);
                    },
                    complete: function(data){//Chinmay Gandhi - 1.0.53.61 - Set currency setting
                        //methods._CountryCurrency(opt);
                    }
                });
			}
			catch(e)
			{
				console.log("Error in _getCountryName"+ e);	
			}
		},
        //Chinmay Gandhi - 1.0.51.56 - END
        
        _getCurrencyValue:function(opt,_baseCurrCode,_toCurrCode,_decimalplaces){
            //alert("ff");
           	var getURL=opt.serverurl+'extratabsdetails-'+opt.HotelId+'-'+_baseCurrCode+'-'+_toCurrCode;
			try
				{
					$.ajax({
							url: getURL,
							type: "POST",
							data: {
							},
							dataType: "html",
							error: function(){
							},
							beforeSend: function(){
								showCheckavalabilityLoadingBar();
                                 //hide summary after currency changed
                                $("[id^=delete_row_]").each(function(){
                                        jQuery(this).parent().remove();
                                        var del=jQuery(this).attr('id').split("delete_row_");                                                       
                                        jQuery("[id^=room_row_"+del[1]+"_]").each(function(){                                
                                             jQuery("#rooms_"+del[1]).parent().find('.remove_field_button').click();
                                        });
                                });
                                  //loading rates
                                  $(".strike").each(function(){
                                        //if (opt.HotelId==26) {
                                           // jQuery(this).text("");
                                          // console.log(jQuery(this).is(':visible'));
                                           jQuery(this).hide();
                                       // }
                                  });
                                  $(".avg_strike").each(function(){
                                       // if (opt.HotelId==26) {
                                          //  jQuery(this).text("");                                    
                                       // }
                                       jQuery(this).hide();
                                  });                                  
                                  $(".rmamt_cls").each(function(){
                                       // if (opt.HotelId==26) {
                                            //jQuery(this).text("");
                                            jQuery(this).next().show();
                                            jQuery(this).hide();
                                            //}
                                  });
                                  $(".avg_cls").each(function(){
                                       // if (opt.HotelId==26) {
                                           // jQuery(this).text("");
                                            jQuery(this).next().show();
                                            jQuery(this).hide();
                                            //}
                                  });
                                  
                                  $(".tot_currency_display_pre_cls,.up_strike_currency_display_pre_cls").each(function(){
                                   // if (opt.HotelId==26) {
                                        jQuery(this).text("");
                                   // }
                                  });
                                  $(".tot_currency_display_post_cls,.up_strike_currency_display_post_cls").each(function(){
                                   // if (opt.HotelId==26) {
                                        jQuery(this).text("");
                                   // }
                                  });
                                   $(".strike_currency_display_pre_cls,.upavg_strike_currency_display_pre_cls").each(function(){
                                   // if (opt.HotelId==26) {
                                        jQuery(this).text("");
                                   // }
                                  });
                                  $(".strike_currency_display_post_cls,.upavg_strike_currency_display_post_cls").each(function(){
                                   // if (opt.HotelId==26) {
                                        jQuery(this).text("");
                                  //  }
                                  });
                                  var loading = js_stripslashes(langjs[0]["L_Loading"]);
                                  $(".currency_adrm").each(function(){
                                   // if (opt.HotelId==26) {
                                        jQuery(this).text(loading);                                        
                                        if (jQuery(this).hasClass('adrm-wrap2')==false) {
                                            jQuery(this).addClass('adrm-wrap-close');
                                        }
                                  //  }
                                  });
                                  $(".hiderate_info").each(function(){
                                        jQuery(this).hide();
                                  });
							},
							complete: function(result){
								hideCheckavalabilityLoadingBar();
								if(typeof(resgrid[0])!='undefined')
								{
									var CurrArrData=jQuery.trim(result.responseText).split("|");
									resgrid[0][0]['MultiplyFactor']=CurrArrData[0];
									
									if (CurrArrData[0]==0 || CurrArrData[0]==undefined) //Pinal - 24 June 2016 - when convertor returns 0 or does not return anything than show alert and change currency to default
									{
									    swal({
										title: "",
										text: "Sorry , Couldn't find conversion rate for selected currency. Please try again later.",
										type: 'error',
										animation:true	,
										closeOnConfirm: true,
									    },
									    function(){
										    jQuery("#CHCurrency > li[value="+_baseCurrCode+"]").click();
                                             jQuery("#CHSuggested > li[value="+_baseCurrCode+"]").click();
										}
									    );
									}
									
									if(CurrArrData[1]!='')
										resgrid[0][0]['MultiplyCurrSymbl']=CurrArrData[1];	
									else
										resgrid[0][0]['MultiplyCurrSymbl']=resgrid[0][0]['MultiplyCurrCode'];
									
									var currency=methods.setCurrencyValue(opt,resgrid[0][0]['MultiplyFactor'],_decimalplaces,_toCurrCode);

                                    if(resgrid[0][0]['MultiplyCurrSymbl']!=resgrid[0][0].currency)
                                    {
                                      opt.ResUIshowfilterPrice=false;
                                      jQuery("#filter_price").hide();
                                    }
                                    //loading rates
                                    $(".rmamt_cls").each(function(){
                                   // if (opt.HotelId==26) {                                        
                                        jQuery(this).next().hide();
                                        jQuery(this).show();
                                       // }
                                    });
                                    $(".strike").each(function(){
                                        //if (opt.HotelId==26) {
                                           // jQuery(this).text("");
                                           if (!jQuery(this).hasClass('hidethis_row')) {
                                            jQuery(this).show();
                                           }
                                           
                                       // }
                                   });
                                    $(".avg_cls").each(function(){
                                    //if (opt.HotelId==26) {                                        
                                        jQuery(this).next().hide();
                                        jQuery(this).show();
                                       // }
                                    });                                    
                                    $(".avg_strike").each(function(){
                                       // if (opt.HotelId==26) {
                                          //  jQuery(this).text("");                                    
                                       // }
                                       jQuery(this).show();
                                  });
                                    $(".hiderate_info").each(function(){
                                        jQuery(this).show();
                                  });
                                    var addroom_caption = js_stripslashes(langjs[0]["L_Add_Room"]);
                                    $(".currency_adrm").each(function(){
                                   // if (opt.HotelId==26) {
                                        jQuery(this).text(addroom_caption);
                                        jQuery(this).removeClass('adrm-wrap-close');                                    
                                   // }
                                    });
								}

                                //Chinmay Gandhi - 1.0.51.56 - Start
                                //Purpose: Set currency list vise
                                $("#CHCurrency li").each(function(){
                                    var val_catch=jQuery("#selected_curr").val();
                                    if (jQuery(this).hasClass(val_catch)==true) {
                                        var set_val= jQuery(this).html().split('<a>');
                                        jQuery("#CHSelection").html('<li class="selected_country_currency"><label>'+set_val[1]+'</label></li>');
                                          //sachi - 22 june-2019 to blank search box of search currency and display all currency
                                         var SEARCHWORD =$('#searchcurrency').val();
                                         console.log("Currency Search"+SEARCHWORD);
                                         $(".curr_select_suggest").show();
                                         //kishan 28 June 2019 purpose:Added if condition throwing error when SEARCHWORD value is undfined START 
                                         if(SEARCHWORD!=undefined)
                                         {   
                                            $("#CHCurrency li").each(function(){
                                                var thisobj=$(this);
                                                if(thisobj.find("a").text().trim().toUpperCase().indexOf(SEARCHWORD.toUpperCase()) >=0)
                                                {
                                                    thisobj.show();
                                                    $("#labelcur").show();
                                                }
                                                else
                                                {
                                                    thisobj.hide();
                                                }
                                            });
                                         }
                                         //kishan 28 June 2019 END
                                    //sachi - 22 june-2019 to blank search box of search currency and display all currency
                                    }
                                });
                                //Chinmay Gandhi - 1.0.51.56 - END
                                methods._pricewidgetreload(opt,_toCurrCode);//Sonam - 1.0.52.60 - 16 Aug 2017 - Purpose: to reload price widget when changing currency 

							},
							success: function( data ){
								console.log("AJAX Grid - success()");
							}
						});
				}
				catch(e)
				{
					console.log("Error in getCurrencyValue"+ e);
					hideLoadingBar();
				}	
		},
		setCurrencyValue:function(opt,multiplyfactor,_decimalplaces,_toCurrCode){
                //Price Filter - change currency - start
                console.log("setCurrencyValue=================== "+_toCurrCode);
                ////price slider 
                //var min_value=0;
                //var max_value=0;
                //if(opt.ResUIMinimumPrice>0) {
                //  min_value=parseInt(opt.ResUIMinimumPrice);
                //  min_value2=parseInt(min_value)*parseFloat(multiplyfactor);
                //  min_value=min_value2.toFixed(0);
                // jQuery("#ResUIMinimumPrice").text(min_value);                 
                //}
                //if(opt.ResUIMaximumPrice>0) {
                //  max_value=parseInt(opt.ResUIMaximumPrice);
                //  max_value2=parseInt(max_value)*parseFloat(multiplyfactor);
                //  max_value=max_value2.toFixed(0);
                //  jQuery("#ResUIMaximumPrice").text(max_value);
                //}
                //
                //if(typeof(resgrid)!='undefined') {
                //        var curr_lbl_pre=curr_lbl_post='';
                //        if(resgrid[0][0].curr_prefix==1) {
                //           curr_lbl_pre=_toCurrCode+' ';
                //        }
                //        else
                //           curr_lbl_post=_toCurrCode+' ';
                //}
                //
                //$( "#price_slider" ).val( curr_lbl_pre + min_value + curr_lbl_post + " - "+curr_lbl_pre+ max_value+ curr_lbl_post );
                //$("input#price_slider_val").val(min_value+"|"+max_value);
                //Price Filter - change currency - end
                
			var id='';//kishan 1.0.53.61 13 September 2017 purpose:slove Disparity issue
            var day=1;//kishan 1.0.53.61 13 September 2017 purpose:slove Disparity issue
            
            jQuery("[id^=col_]").each(function() {
                //kishan 13 September 2017
                //purpose:slove Disparity issue
                if(id != jQuery(this).attr('id'))
                {
                    day=1;
                }
                //kishan 13 September 2017
				id=jQuery(this).attr('id');
				var grate=id.split("_");
                var nm=eval('resgrid[0]['+parseInt(grate[1])+'].o_day_base_'+day);      //kishan 1.0.53.61 13 September 2017 purpose:slove Disparity issue  add day variable

               
                //Priyanka Maurya - 24 Feb 2020 - Purpose : Apply Package Discount on Extra Adult & Child Rate - RES-1825 - START
				//var extra_ad_rt=eval('resgrid[0]['+parseInt(grate[1])+'].o_day_extra_adult_'+day);//kishan 1.0.53.61 13 September 2017 purpose:slove Disparity issue	add day variable
				//var extra_ch_rt=eval('resgrid[0]['+parseInt(grate[1])+'].o_day_extra_child_'+day);	//kishan  1.0.53.61 13 September 2017 purpose:slove Disparity issue
                var extra_ad_rt=eval('resgrid[0]['+parseInt(grate[1])+'].tmp_o_day_extra_adult_'+day);
				var extra_ch_rt=eval('resgrid[0]['+parseInt(grate[1])+'].tmp_o_day_extra_child_'+day);
                //Priyanka Maurya - END
                  
                
                var istax=0;
                if(eval('resgrid[0]['+parseInt(grate[1])+'].ShowTaxInclusiveExclusiveSettings')=="1"){                   
                    if (eval('resgrid[0]['+parseInt(grate[1])+'].Tax_'+grate[2])!="[0]")
                    {
                       istax=1; 
                        // Jemin -7-Aug-2019 - RES-2133 - Purpose- Incorrect display of Extra adult rate 
                        extra_ad_rt=eval('resgrid[0]['+parseInt(grate[1])+'].dis_day_extra_adult_'+day);
                        extra_ch_rt=eval('resgrid[0]['+parseInt(grate[1])+'].dis_day_extra_child_'+day);
                        //Jemin End
                    }
                }
				var mrates=parseFloat(nm)*parseFloat(multiplyfactor);
				var ex_adrates=parseFloat(extra_ad_rt)*parseFloat(multiplyfactor);	
				var ex_chrates=parseFloat(extra_ch_rt)*parseFloat(multiplyfactor);
                if (istax==1) {
                    //Sheetal Panchal : issue need to fix as when 2 nights rates is different then below line is setting same rate in rate infor when display currency is different than base currency
                    jQuery(this).find('span.rate2').text(addCommas(methods._applyRoundOff(mrates).toFixed(_decimalplaces)));
                    jQuery(this).find('span.extraacurrval').text(addCommas(methods._applyRoundOff(ex_adrates).toFixed(_decimalplaces)));
                    jQuery(this).find('span.extraccurrval').text(addCommas(methods._applyRoundOff(ex_chrates).toFixed(_decimalplaces)));
                }
                else
                {
                    jQuery(this).find('span.rate2').text(addCommas((mrates).toFixed(_decimalplaces)));
                    
                    jQuery(this).find('span.extraacurrval').text(addCommas((ex_adrates).toFixed(_decimalplaces)));
                    jQuery(this).find('span.extraccurrval').text(addCommas((ex_chrates).toFixed(_decimalplaces)));
                }
               
               // console.log("symbol " + resgrid[0][0]['MultiplyCurrSymbl']);
				$("#spancurrsign").text(resgrid[0][0]['MultiplyCurrSymbl']);
				$("#spancurr").text(_toCurrCode);
                $(".currname").text(_toCurrCode);
                $("#rate_spancurr").text(_toCurrCode);//rate info
                $("#rate_spancurrsign").text(resgrid[0][0]['MultiplyCurrSymbl']);//rate info
                
                if (resgrid[0][0]['MultiplyCurrSymbl']!=_toCurrCode){
                   $(".currsymbol").text(resgrid[0][0]['MultiplyCurrSymbl']);   
                }
                else
                 $(".currsymbol").text('');
                 
                $("#selected_curr").val(_toCurrCode);
                
				var nm1=eval('resgrid[0]['+parseInt(grate[1])+'].before_discount_tax_day_base_'+day);
				if(nm1 > 0)
				{
					var mrates1=parseFloat(nm1)*parseFloat(multiplyfactor);
                     if (istax==1) 
                        jQuery(this).find('span.strikethrough').text(addCommas(methods._applyRoundOff(mrates1).toFixed(_decimalplaces)));
                     else
                        jQuery(this).find('span.strikethrough').text(addCommas((mrates1).toFixed(_decimalplaces)));
				}
				
				//RoomListing - start
				var nm_avg_per_night_strike=eval('resgrid[0]['+parseInt(grate[1])+']["MinAvgPerNightDiscount"]');
				var mrates_avg_night_strike=parseFloat(nm_avg_per_night_strike)*parseFloat(multiplyfactor);
                if (istax==1)                 
                    jQuery("#average_per_night_blk_"+parseInt(grate[1])).find('span#rmamt_strike_avg_night').text(addCommas(methods._applyRoundOff(mrates_avg_night_strike).toFixed(_decimalplaces)));
                else
                    jQuery("#average_per_night_blk_"+parseInt(grate[1])).find('span#rmamt_strike_avg_night').text(addCommas((mrates_avg_night_strike).toFixed(_decimalplaces)));
                
				var nm_avg_per_night=eval('resgrid[0]['+parseInt(grate[1])+']["MinAvgPerNight"]');				
				var mrates_avg_night=parseFloat(nm_avg_per_night)*parseFloat(multiplyfactor);	
                
                if (istax==1)
                jQuery("#average_per_night_blk_"+parseInt(grate[1])).find('span#rmamt_avg_night').text(addCommas(methods._applyRoundOff(mrates_avg_night).toFixed(_decimalplaces)));
                else
                jQuery("#average_per_night_blk_"+parseInt(grate[1])).find('span#rmamt_avg_night').text(addCommas((mrates_avg_night).toFixed(_decimalplaces)));
				//RoomListing - end
                day++;//kishan 1.0.53.61 13 September 2017 purpose:slove Disparity issue
			});
            //kishan Tailor 3rd August 2018 purpose:TTriptease Price Widget Integration START
            
            methods._setSelectedCurrency(opt,_toCurrCode);
            
            methods.setSelectedCurrency_configured(opt,_toCurrCode);//Chinmay Gandhi - 1.0.51.56 - show currency list vise
            
			if(typeof(resgrid[0])!='undefined' && resgrid[0]!=null) {
                methods._calculateLongtayPrice(opt,'','','');
            }
		},
        
        /*Chinmay Gandhi - 1.0.51.56 - Start*/
        /*Purpose: Set currency list vise*/
            _setSelectedCurrency:function(opt,c_code){
                try {
                    $("ul#CHSelection li").each(function(){
                    jQuery(this).removeClass('selected_country_currency');
                    });
                    
                    $("ul#CHSelection li").find('.countrycode_li').each(function(){
                      if(c_code==jQuery.trim(jQuery(this).text()))
                        {
                            jQuery(this).parent().parent().addClass('selected_country_currency');
                        }
                    });                
                } catch(e) {
                    console.log("Error in _setSelectedCurrency"+ e);
                    hideLoadingBar();
                }
            },
            setSelectedCurrency_configured:function(opt,c_code){
                try {
                    $("ul#CHCurrency_configured li").each(function(){
                    jQuery(this).removeClass('selected_country_currency');
                    });
                    
                    $("ul#CHCurrency_configured li").find('.countrycode_li').each(function(){
                      if(c_code==jQuery.trim(jQuery(this).text()))
                        {
                            jQuery(this).parent().parent().addClass('selected_country_currency');
                        }
                    });                
                } catch(e) {
                    console.log("Error in _setSelectedCurrency1"+ e);
                    hideLoadingBar();
                }
            },
        /*Chinmay Gandhi - 1.0.51.56 - END*/
        
        
        //_specialOffersCss:function(opt){ //Not in use shifted to _setThemes - Pinal
        //    $(".offr_bg").css('background',opt.NeedHelpBackgroundColor);
        //    $(".vres-specialoffer").css('background',opt.NeedHelpBackgroundColor);
        //    $(".vres-specialoffer p b.quickinfo-title").css('color',opt.NeedHelpTextColor);
        //    $(".vres-specialoffer p b").css('color',opt.NeedHelpTextColor);
        //    
        //    var quickbox_color=opt.NeedHelpTextColor.split("#");
        //    var box_color=methods._hextorgb(quickbox_color[1]);                
        //    $(".vres-specialoffer p").css('color','rgba('+box_color.r+","+box_color.g+","+box_color.b+",0.9)");  
        //},
        _setThemes:function(opt){
            try
			{
                console.log("_setThemes");
                //body
                $('body').css('font-family', opt.BodyFontFamily);
                var font_family=opt.BodyFontFamily;
                var set_font_family=font_family.split(",");
                if (opt.BodyFontFamily!='') {
                    var res = set_font_family[0].replace(/\'/g, "");                   
                    var res2 = res.replace(" ", ""); 
                    $('body').addClass(res2); 
                }
                /* Header */
                $(".vres_header").css('background',opt.HeaderBgColor1);               
                $(".vres-logo a h2 , .vres-navright ul.vres-navbar > li a").css('color',opt.HeaderFontColor);
                
                //Popup Window
                //$(".vres-modal-header").css('background',opt.HeaderBgColor1);
		
                $(".vres-navright ul.vres-navbar > li.vres-hdrop-box .drop-submenu ul li a").css('color','#000000');//issue for currency box text color
             
                var header_border_color=opt.HeaderBorderColor.split("#");
                var h_color=methods._hextorgb(header_border_color[1]);                
                $(".vres-navright ul.vres-navbar > li").css('border-left','1px solid rgba('+h_color.r+","+h_color.g+","+h_color.b+",0.2)");
                
                /*Special Offers*/
                //$(".offr_bg").css('background',opt.NeedHelpBackgroundColor);
                $(".vres-specialoffer").css('background',opt.NeedHelpBackgroundColor);
                $(".vres-specialoffer p b").css('color',opt.NeedHelpTextColor);
                var quickbox_color=opt.NeedHelpTextColor.split("#");
                var box_color=methods._hextorgb(quickbox_color[1]);                
                $(".vres-specialoffer p").css('color','rgba('+box_color.r+","+box_color.g+","+box_color.b+",0.7)");
                
                /*calender settings*/
                methods._setCalenderCss(opt);
            
                /*check availability & summary book button*/
                //$(".subhead-btn-bg,.subhead-btn").css({
                //        'background':opt.ResUIAvailabilityBackgroundColor,
                //        'color':opt.ResUIAvailabilityTextColor
                //         });
                
                /*rate plan block*/
                $(".vres-cnt-card .card-list h3").css('color',opt.ResUIRoomNameTextColor);
                //$(".vrt-links a").css('color',opt.ResUIRoomInfoEnquireTextColor);	
                $(".vrmprice strong").css('color',opt.ResUIRateTextColor);
                
                //$(".tab-active a").css('color',opt.ResUIRoomNameTextColor);
                /*$("a.rdrevie").css({
                    'color':opt.ResUIRoomNameTextColor,
                    'border':'1px solid '+opt.ResUIRoomNameTextColor,
                });*/
                //$(".tab-active").css('box-shadow','0 -3px 0 ' + opt.ResUIRoomNameTextColor +' inset' );
                 
                //filter
                $(".filter-btn1").css('color',opt.ResUIRateTextColor);
                //$(".filter-div").css('border-top','4px solid ' + opt.ResUIRateTextColor);
                //$(".filter-div::after").css('border-bottom','10px solid ' + opt.ResUIRateTextColor);
                //$(".filter-div h4").css('color','4px solid ' + opt.ResUIRoomNameTextColor);
                
                /*Add Button */
                //$(".bt-primary.bt-primary").css({
                //        'background':opt.ResUIAddRoomBackgroundColor,
                //        'color':opt.ResUIAddRoomTextColor
                //});               
                //
                //$(".bt-primary").mouseover(function() {
                //    $(this).css('background',opt.ResUIAddRoomBackgroundHoverColor);
                //}).mouseout(function() {
                //    $(".bt-primary.bt-primary").css('background',opt.ResUIAddRoomBackgroundColor);
                //});
                
                $(".vres_room_info").css({                        
                        'color':opt.ResUIAddRoomBackgroundColor
                }); 
                
                /*popup box*/
                $(".gallerybtn").css('background',opt.ResUIAddRoomBackgroundColor);
                $(".vres-photogroup-btn").css({                        
                    'color':opt.ResUIAddRoomTextColor
                });               
               
                //$(".filter-div a").css({
                //        'background':opt.ResUIAddRoomBackgroundColor,
                //        'color':opt.ResUIAddRoomTextColor
                //         });
                //
                //$(".filter-div a").mouseover(function() {
                //    $(this).css('background',opt.ResUIAddRoomBackgroundHoverColor);
                //}).mouseout(function() {
                //    $(".filter-div a").css('background',opt.ResUIAddRoomBackgroundColor);
                //});
               
                //$(".show-price-wrap .shPwrap a.active").css({
                //        'color':'#000000'                        
                //         });
                //$(".show-price-wrap .shPwrap a.active:before").css({
                //        'background':opt.ResUIAddRoomTextColor                        
                //         });
                //$(".show-price-wrap .shPwrap a.active:after").css({
                //        'border-left':'6px solid rgba(0, 0, 0, 0) ',
                //        'border-bottom':'6px solid rgba(0, 0, 0, 0) ',
                //        'border-top':'6px solid '+opt.ResUIAddRoomTextColor,
                //        'border-right':'6px solid rgba(0, 0, 0, 0) '
                //         });
                
                // $("#filter_gr a.gr-cnt:hover, #filter_gr a.gr-cnt:active, #filter_gr a.gr-cnt.selected, #filter_gr a.gr-cnt:focus")
                 $("#filter_gr a.gr-cnt.selected")
                 .css({
                    'background': opt.ResUIAddRoomBackgroundColor,
                    'color': opt.ResUIAddRoomTextColor,
                    'border-color': opt.ResUIAddRoomBackgroundHoverColor
                 });		
	
                 //filter
                 // Priyanka Maurya - 31 July 2018 - START
                 // Purpose : Hide the popup filter - BENotificationStop
                 /*$(".filter-help a.gotit").css({
                        'color':opt.ResUIAddRoomBackgroundColor                        
                         });
                 $(".filter-help").css({
                        'border':'3px solid '+opt.ResUIAddRoomBackgroundColor                        
                         });*/
                 //Priyamka Maurya - 31 July 2018 - STOP
                 
                 //$(".filter-help::after").css({
                 //       'border-right':'10px solid '+opt.ResUIAddRoomBackgroundColor                        
                 //        });
                 
                 //$(".promo-popUp:after").css({                       
                 //       'border-top': '10px solid rgba(0, 0, 0, 0)',
                 //       'border-left': '10px solid rgba(0, 0, 0, 0)',
                 //       'border-bottom': '10px solid '+opt.ResUIRateTextColor,
                 //       'border-right': '10px solid rgba(0, 0, 0, 0)'
                 //});
                 
                 $(".promo-popUp").css({
                     'border-top': '4px solid '+opt.ResUIAddRoomBackgroundColor
                 });
                
                $(".vres-roombooked ul li span a").css('color',opt.ResUIRoomNameTextColor);
                
                /*Help box*/
                 //var help_back_color=opt.ResUIHelpBoxBackColor.split("#");
                 //var help_color=methods._hextorgb(help_back_color[1]);
                 
                 $(".vres-helpup .vres-helpbtn").css('background',opt.ResUIHelpBoxBackColor);
                 
                 //var help_border_color=opt.ResUIHelpBoxBorderColor.split("#");
                 //var help_b_color=methods._hextorgb(help_border_color[1]);                
                 $(".vres-helpup .vres-helpbtn").css('border','6px solid '+ opt.ResUIHelpBoxBorderColor) ;
                 
                  $(".vres-helpup .vres-helpbtn").css({'color':opt.ResUIHelpBoxTextColor});
                  
                  $(".vres-helpdetail").css({                        
                        'border-color':opt.ResUIHelpBoxBackColor
                  });
                  
                  /* Chandrakant - 1.0.50.55 - 05 Jan 2017 - START */
                  $(".vres-modal-header a.cls").css({                        
                        'color':opt.HeaderFontColor
                  });
                  /* Chandrakant - 1.0.50.55 - 05 Jan 2017 - END */
                  
                  //$(".vres-helpdetail::after").css({                        
                  //      'border-color':'rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) '+opt.ResUIHelpBoxBackColor
                  //});
		  
		  var append_style='<style>';
		  
		  append_style+='.vres-helpdetail::after{border-color:rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) '+opt.ResUIHelpBoxBackColor+' !important}';
          //Priyanka Maurya - 31 July 2018 - START
          //Purpose - Hide the popup filter - BENotificationStop
		  //append_style+='.filter-help::after{border-right: 10px solid '+opt.ResUIAddRoomBackgroundColor+' !important}';
          //Priyanka Maurya - END
		  append_style+='.show-price-wrap .shPwrap a.active::before{background: '+opt.ResUIRateTextColor+' !important}';
		  append_style+='.show-price-wrap .shPwrap a.active:after{border-left:6px solid rgba(0, 0, 0, 0);border-bottom:6px solid rgba(0, 0, 0, 0);border-top: 6px solid '+opt.ResUIRateTextColor+';border-right:6px solid rgba(0, 0, 0, 0);}';
		  append_style+='.promo-popUp::after{border-color:rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) '+opt.ResUIAddRoomBackgroundColor+' !important;}';		  
		  append_style+='.availcal-head{background:'+opt.HeaderBgColor1+' !important; color:'+opt.HeaderFontColor+' !important;}';
		  append_style+='.vres-navright ul.vres-navbar > li.open > a,.vres-navright ul.vres-navbar > li .vres-cur-label.open > a{color:'+opt.HeaderBgColor1+' !important;}';
		  append_style+='.vres-modal-header{background:'+opt.HeaderBgColor1+' !important;}';
		  append_style+='.vres-modal-header h3,.vres-modal-header p,.vres-modal-header p.vrmprice strong,.vres-modal-header p.vrmprice .strike,.vres-modal-header p.vrmprice small{color:'+opt.HeaderFontColor+' !important}';		  
		  append_style+='.tab-active a,.log-form > form > a.forgo-pass{color: '+opt.ResUIRoomNameTextColor+' !important;}';
		  append_style+='.tab-active{box-shadow: 0 -3px 0 '+opt.ResUIRoomNameTextColor+' inset !important;}';		
		  append_style+='.subhead-btn-bg,.subhead-btn{background :'+opt.ResUIAvailabilityBackgroundColor+' !important;color:'+opt.ResUIAvailabilityTextColor+' !important;}';		  
		  append_style+='.ui-datepicker-month,.ui-datepicker-year{color: '+opt.CalHeaderFontColor+' !important;}';
		  
		  append_style+='.bt-primary,.filter-div a{background:'+opt.ResUIAddRoomBackgroundColor+' !important;color:'+opt.ResUIAddRoomTextColor+' !important;}';
		  append_style+='.bt-primary:hover,.filter-div a:hover{background:'+opt.ResUIAddRoomBackgroundHoverColor+' !important;}';
		  
		  append_style+='.vrt-links a,.vres_availcal_mobile a i::before{color:'+opt.ResUIRoomInfoEnquireTextColor+' !important}';
		  
		  append_style+='.offr_bg{background-color: '+opt.NeedHelpBackgroundColor+' !important;}';
		  //console.log(append_style);
		  append_style+="</style>";
		  
		  $(append_style).appendTo('head');
		    
                 /*no image check availability block*/
                 var vres_bhk_pos=opt.HeaderBgColor1.split("#");
                 var h_color=methods._hextorgb(vres_bhk_pos[1]);                
            //$(".chkimg_notfound .vres_bhk_pos .full-bar-wrapper").css('background','rgba('+h_color.r+","+h_color.g+","+h_color.b+",0.7)");
            $(".chkimg_notfound .vres_bhk_pos .full-bar-wrapper").css('background',opt.HeaderBgColor2);
		if (jQuery("#img_banner_div").is(":hidden")) {		    
                 $(".vres_bhk_pos .full-bar-wrapper .input-daterange label p").css('color',opt.HeaderFontColor2);
                 $(".vres_promo-btn").css('color',opt.HeaderFontColor2);
		}
            }        
            catch(err){
                hideLoadingBar();
                hideCheckavalabilityLoadingBar();
				console.log("Error - _setThemes - "+err.message);
		    } 
        },
        _hextorgb:function(hex) {
            var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            return result ? {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16)
            } : null;
        },
        _initDatePicker:function(opt){
			try
			{
              //  console.log("cal again");
            if(opt.Calinit==true)
            {
                $("input[id$='eZ_chkin']").focus(function(){                   
                     //jQuery("#eZ_chkin").select();
                     $(this).addClass('highlight_cal');
                });
                $("input[id$='eZ_chkin']").focusout(function(){
                    $(this).removeClass('highlight_cal');
                });                
                
                 $("input[id$='eZ_chkout']").focus(function(){                   
                    //jQuery("#eZ_chkout").select();
                    $(this).addClass('highlight_cal');
                    //jQuery("#eZ_chkout").show();
                    //$(this).off( "focus" );
                   });
                   
                   $("input[id$='eZ_chkout']").focusout(function(){
                       $(this).removeClass('highlight_cal');                       
                   });
                //Chandrakant - 30 September 2022 - START
                //Purpose : RES-3133
                var NewnumberOfMonths = 2;
                if(opt.ADABECountry=='223')
                    var NewnumberOfMonths = 1;
                //Chandrakant - 30 September 2022 - END
                //dept date
				if(opt.ShowNights==false && opt.ShowDepart==true)
				{
					$("input[id$='eZ_chkout']").datepicker({                   
                    defaultDate: opt.CalDefaultDt ,
                   // changeMonth: opt.CalChangeMonth,
                    //changeYear: true,
                    firstDay: 7,//Chinmay Gandhi - 1.0.52.57
                    numberOfMonths: NewnumberOfMonths, //Chandrakant - 30 September 2022, Purpose - RES-3133 - change value from 2 to NewnumberOfMonths                
                   // minDate:setmindt,//opt.CalMinDate,
                    minDate:opt.CalMinDate+1, //Pinal              
                    maxDate:opt.CalMaxDate,
                    dateFormat: opt.CalDtFormat,                
                    
                    onSelect: function(selectedDate) {
                        jQuery("#trackbtnclicked").val(1);
                        jQuery("#trackbook_btnclicked").val(0);
                        var date = $(this).datepicker('getDate');   
						//Chandrakant - 27 September 2022 - START
                        //Purpose : RES-3133 - Added Aria Label so assistive technology can read for blind people
                        var ariaDateCheckOut = $.datepicker.formatDate("DD, MM d, yy", date);
                        $(this).attr("aria-label", ariaDateCheckOut);
                        //Chandrakant - 27 September 2022 - END
                        //console.log("date " + date);
                        
                        //Disable past date till check in for check out
                        var fromDate_var = jQuery("input[id$='ArrvalDt_2']").val();
                        var fromDate_var=new Date(jQuery.datepicker.parseDate(opt.CalDtFormat,fromDate_var));
                        
                        var toDate_var=new Date(jQuery.datepicker.parseDate(opt.CalDtFormat,jQuery("#eZ_chkin").val()));                            toDate_var.setDate(toDate_var.getDate());
                       
                        if (toDate_var && fromDate_var) {
                            var difference = 0;
                            var oneDay = 1000*60*60*24;
                            //var difference = Math.ceil((toDate_var.getTime() - fromDate_var.getTime()) / oneDay);
                            var difference = Math.round((toDate_var.getTime() - fromDate_var.getTime()) / oneDay); //Pinal - 1.0.50.55 - 22 September 2016 - Purpose - For correcting no of nights calculation for day light saving change.
                        }
                       // console.log(difference);    
                        jQuery("#eZ_chkout").datepicker('option', 'minDate', difference+1);
                        //Disable past date till check in for check out
			
                        methods._setChkNight();
                        
					},
                     onClose: function() {                        
                        //$(this).select();
                        var container = $('#ui-datepicker-div');
                        $(container).off('keydown');
                        $('.chk-avail').focus(); //Chandrakant - 01 October 2022, Purpose - RES-3133
                     }
                }	,
                	$.datepicker.regional['nl']
                );
                   // $("input[id$='eZ_chkout']").focus();
                }
                
                var setmindt=0;
                $("input[id$='eZ_chkin']").datepicker({
                    //showOn: opt.CalShowOn,
                    defaultDate: opt.CalDefaultDt ,
                    //changeMonth: opt.CalChangeMonth,
                   // changeYear: true,
                    firstDay: 7,//Chinmay Gandhi - 1.0.52.57
                    numberOfMonths: NewnumberOfMonths, //Chandrakant - 30 September 2022, Purpose - RES-3133 - change value from 2 to NewnumberOfMonths
                    minDate:opt.CalMinDate,
                    maxDate:opt.CalMaxDate,
                    dateFormat: opt.CalDtFormat,
                    onSelect: function(selectedDate) {
                        jQuery("#trackbtnclicked").val(1);
                        jQuery("#trackbook_btnclicked").val(0);
                        var date = $(this).datepicker('getDate');                        
						dayName=($.datepicker.formatDate("DD", date));
                        $("#arrivalday").text("("+dayName+")");
                        //Chandrakant - 27 September 2022 - START
                        //Purpose : RES-3133 - Added Aria Label so assistive technology can read for blind people
                        var ariaDateCheckIn = $.datepicker.formatDate("DD, MM d, yy", date);
                        $(this).attr("aria-label", ariaDateCheckIn);
                        //Chandrakant - 27 September 2022 - END
						if(opt.ShowNights==false && opt.ShowDepart==true)
						{
							var checkout_date=new Date(jQuery.datepicker.parseDate(opt.CalDtFormat,jQuery("#eZ_chkin").val()));
							checkout_date.setDate(checkout_date.getDate() + parseInt(opt.WebAvailabilityCutOffDay));
							var ariaDateCheckout = $.datepicker.formatDate("DD, MM d, yy", checkout_date); //Chandrakant - 27 September 2022, Purpose - RES-3133 - Added Aria Label so assistive technology can read for blind people
                            checkout_date = jQuery.datepicker.formatDate(opt.CalDtFormat, checkout_date);
							jQuery("input[id$='eZ_chkout']").val(checkout_date);	
                            jQuery("input[id$='eZ_chkout']").attr("aria-label", ariaDateCheckout); //Chandrakant - 27 September 2022, Purpose - RES-3133 - Added Aria Label so assistive technology can read for blind people
                            //Disable past date till check in for check out
                            var fromDate_var = jQuery("input[id$='ArrvalDt_2']").val();
                            var fromDate_var=new Date(jQuery.datepicker.parseDate(opt.CalDtFormat,fromDate_var));
                            
                            var toDate_var=new Date(jQuery.datepicker.parseDate(opt.CalDtFormat,jQuery("#eZ_chkin").val()));
                            toDate_var.setDate(toDate_var.getDate());
                            //console.log(toDate_var);
                           // console.log(fromDate_var);
                            if (toDate_var && fromDate_var) {
                                var difference = 0;
                                var oneDay = 1000*60*60*24;
                                //var difference = Math.ceil((toDate_var.getTime() - fromDate_var.getTime()) / oneDay);
                                var difference = Math.round((toDate_var.getTime() - fromDate_var.getTime()) / oneDay); //Pinal - 1.0.50.55 - 22 September 2016 - Purpose - For correcting no of nights calculation for day light saving change.
                            }                            
                            
                            jQuery("#eZ_chkout").datepicker('option', 'minDate', difference);
                            
                            //It was creating issue for not allow 1 night booking if cut off days is 2 nights for other timezone - flora
                            //jQuery("#eZ_chkout").datepicker('option', 'minDate', difference+1);
                            //It was creating issue for not allow 1 night booking if cut off days is 2 nights for other timezone - flora
                            
                            methods._setChkNight();			    
                            //Disable past date till check in for check out
						}
                        methods._setCalenderCss(opt);
                       
                        if(opt.ShowNights==false && opt.ShowDepart==true)
                        {
                             //if (opt.HotelId==26) {
                             //   $("input[id$='eZ_chkout']").focus();
                             //}                             
                             //$("input[id$='eZ_chkout']").blur();
                             //$("input[id$='eZ_chkout']").trigger('onSelect');
                        }
                        
                        if(!navigator.userAgent.match(/(iPhone)/))//not to auto open on iphone device - flora
                        {
                            $("input[id$='eZ_chkout']").focus(0); //Pinal
                        }
                    },
                     onClose: function() {                        
                        //$(this).select();
                        var container = $('#ui-datepicker-div');
                        $(container).off('keydown');
                     },
                     onChangeMonthYear: function () {
                        //methods._setCalenderCss(opt);
                       //$(".ui-datepicker-next").addClass("customclass");                      
                    }
                }	,
                	$.datepicker.regional['nl']
                );
				
                //Priyanka Maurya - 22 May 2019 - Purpose : Change According to WCAG Guideline - WCAG 2.0 - START
                
				$('#eZ_chkin,#eZ_chkout').keydown( function (event) {
                    
                    var inst = $.datepicker._getInst(event.target);
                    var isRTL = inst.dpDiv.is(".ui-datepicker-rtl");

                    switch (event.keyCode) {
                        case 37:    // LEFT --> -1 day
                            $.datepicker._adjustDate(event.target, (isRTL ? +1 : -1), "D");
                        break;
                        case 38:    // UPP --> -7 day
                            $.datepicker._adjustDate(event.target, -7, "D");
                        break;
                        case 39:    // RIGHT --> +1 day
                            $.datepicker._adjustDate(event.target, (isRTL ? -1 : +1), "D");
                        break;
                        case 40:    // DOWN --> +7 day
                            event.preventDefault();
                            $.datepicker._adjustDate(event.target, +7, "D");
                        break;
                        default:
                        break;
                    }
                    $('.ui-datepicker-days-cell-over').find('a').css("background-color","#eeeeee");
                });
                //Priyanka Maurya - END

                //dayName='';//change here
                var date = $("input[id$='eZ_chkin']").datepicker('getDate');
               
                dayName=($.datepicker.formatDate("DD", date));
                //alert(dayName);
                $("#arrivalday").text("("+dayName+")");
                $("#CalDtFormat").val(opt.CalDtFormat);
                methods._setCalenderCss(opt);
                
                $(".chk-avail").on('click', function() {
                    $("#ischeckavailabilityclicked").val("1");
                    return;
                });

                $("input[id$='eZ_chkin'],.ui-datepicker-trigger,.ui-datepicker-next,.ui-datepicker-prev,.ui-datepicker-month,.ui-datepicker-year").on('click', function() {
                    methods._setCalenderCss(opt);
                });
				
                $(".ui-datepicker-month,.ui-datepicker-year").on('change', function() {
                    methods._setCalenderCss(opt);
                });
				
                $(".ui-datepicker-trigger").button().click(function() {
                    methods._setCalenderCss(opt);
                });
		
		
		//Pinal - 1.0.49.54 - 7 July 2016 - START
		//Purpose - Restrict booket to book for prev date when booker's system date is less than property's current date
		var current_dtcompare=new Date();
		
		var currentdate_hotel = $("input[id$='currentdate_hotel']").val();
		var currentdate_hotel=new Date(jQuery.datepicker.parseDate('yy-mm-dd',currentdate_hotel));
		
		var numberOfDaysToAdd = $("#eZ_chkin").datepicker( "option", "minDate" );
		
		var difference = 0;
		var oneDay = 1000*60*60*24;
		//var difference = Math.ceil((currentdate_hotel.getTime() - current_dtcompare.getTime()) / oneDay);
		var difference = Math.round((currentdate_hotel.getTime() - current_dtcompare.getTime()) / oneDay); //Pinal - 1.0.50.55 - 22 September 2016 - Purpose - For correcting no of nights calculation for day light saving change.
		
		//console.log(difference+" | "+numberOfDaysToAdd);
		
		if (difference>0)
		{
		    $("#eZ_chkin").datepicker('option', 'minDate', parseInt(difference)+parseInt(numberOfDaysToAdd));
		    
		    if(opt.ShowNights==false && opt.ShowDepart==true)
			$("#eZ_chkout").datepicker('option', 'minDate', parseInt(difference)+parseInt(numberOfDaysToAdd)+1);
		}
		//Pinal - 1.0.49.54 - 7 July 2016 - START
		
            if($(window).width()<767)
            {  
               $("#eZ_chkin").datepicker("option", "numberOfMonths",1);
               $("#eZ_chkout").datepicker("option", "numberOfMonths",1);
            }
            //if (opt.HotelId!='ck')
            {
                var windowWidth = $(window).width();
                $(window).on('resize', function(){
                    if($(window).width() !== windowWidth){
                        if($(window).width()<767)
                        {
                            $("#eZ_chkin").datepicker("option", "numberOfMonths",1);
                            $("#eZ_chkout").datepicker("option", "numberOfMonths",1);	
                        }
                        else
                        {
                            $("#eZ_chkin").datepicker("option", "numberOfMonths",NewnumberOfMonths); //Chandrakant - 30 September 2022, Purpose - RES-3133 - change value from 2 to NewnumberOfMonths
                            $("#eZ_chkout").datepicker("option", "numberOfMonths",NewnumberOfMonths); //Chandrakant - 30 September 2022, Purpose - RES-3133 - change value from 2 to NewnumberOfMonths
                        }
                    }
                });
            }
            
            }//true

            //Chandrakant - 27 September 2022 - START
            //Purpose : RES-3133 - Added Aria Label so assistive technology can read for blind people
            var checkin=$("#eZ_chkin").datepicker('getDate');					
            var checkout=$("#eZ_chkout").datepicker('getDate');
            var ariaDateCheckIn = $.datepicker.formatDate("DD, MM d, yy", checkin);
            $("#eZ_chkin").attr("aria-label", ariaDateCheckIn);
            var ariaDateCheckout = $.datepicker.formatDate("DD, MM d, yy", checkout);
            $("#eZ_chkout").attr("aria-label", ariaDateCheckout);
            //Chandrakant - 27 September 2022 - END
            //Chandrakant - 30 September 2022 - START
            //Purpose : RES-3133
            if(NewnumberOfMonths==1)
                methods.dayTripper();
			}
            //Chandrakant - 30 September 2022 - END
		    catch(err){
                hideLoadingBar();
                hideCheckavalabilityLoadingBar();
				console.log("Error - _initDatePicker - "+err.message);
		    }
        },
        _setChkNight:function(opt){			    
			 jQuery("input[id$='eZ_Nights']").val(parseInt(methods._setCheckoutNight(opt)));	
		},
        _setCheckoutNight:function(opt){	    
			var fromDate = jQuery("input[id$='eZ_chkin']").datepicker('getDate'); 
			var toDate = jQuery("input[id$='eZ_chkout']").datepicker('getDate');
		
			if (toDate && fromDate) {
				var difference = 0;
				var oneDay = 1000*60*60*24;
				//var difference = Math.ceil((toDate.getTime() - fromDate.getTime()) / oneDay);
				var difference = Math.round((toDate.getTime() - fromDate.getTime()) / oneDay); //Pinal - 1.0.50.55 - 22 September 2016 - Purpose - For correcting no of nights calculation for day light saving change.
			}
			return 	difference;
		},
        _setCalenderCss:function(opt){
            
            $('.ui-datepicker-month').css({
                                           'color':opt.CalHeaderFontColor,
                                           });
            $('.ui-datepicker-year').css({
                                           'color':opt.CalHeaderFontColor,
                                           });
            
//	    	$('.ui-datepicker-header').css({'background':opt.CalHeaderColor,
//                                            'color':opt.CalHeaderFontColor,
//                                           });
//            
//            $('.ui-datepicker th').css({'background':opt.CalHeaderColor,
//                                            'color':opt.CalHeaderFontColor,
//                                           });
//            
//           
//            
//            $('.ui-datepicker.ui-widget-content td a.ui-state-active').css({'background':opt.CalCellActiveColor,
//                                            'color':opt.CalHeaderFontColor,
//                                            'border':'1px solid ' + opt.CalCellActiveColor,
//            });
            
        },
        _setImages:function(opt,imageurl)
        {
          try{
                jQuery("#img_banner_div").show();
                jQuery("<img name='imglogo' alt='banner bg' id='imglogo' src='"+imageurl+"' />").appendTo("div#img_banner_div");
                jQuery("#chk_wrapper").removeClass('chkimg_notfound');
                jQuery("#img_banner_div").removeClass('vres_bnr');
                 $('#imglogo').error(function() {                                   
                        jQuery("#img_banner_div").html('');
                        jQuery("#img_banner_div").hide();
                        jQuery("#chk_wrapper").removeClass('chkimg_notfound').addClass('chkimg_notfound');
                        jQuery("#img_banner_div").removeClass('vres_bnr').addClass('vres_bnr');
			jQuery(".vres_bhk_pos .full-bar-wrapper").css({'width':'100%'});
                        var keyname="res_Logo_"+opt.HotelId;
                        document.cookie = keyname+"="+"";
                 });
                 if(typeof(imageurl)!='undefined' && imageurl!='') {
                    methods._setOtherThemes(opt);
                 } 
          }
          catch(err)
          {
                hideLoadingBar();
                hideCheckavalabilityLoadingBar();
				console.log("Error - _setImages - "+err.message);
          }
        },
	_setDeviceWiseImages:function(opt,imageurltab ,imageurlmobile,imageurldesktop)//Pinal - 1.0.50.55 - 13 October 2016 - Purpose - Different images for desktop,mobile and tablet.//kishan Taailor 9 june 2020 purpose:issue while image for mobile or tablet not uploded then checkin/checkout datepicker not visible RES-2508
        {
          try{
            //kishan Tailor 09 june 2020 purpose:issue while image for mobile or tablet not uploded then checkin/checkout datepicker not visible RES-2508 START
            //imageurltab=imageurltab || '';  // Jemin - 19-Nov-2019 - RES-2312 - To fix when  only one image is
            //imageurlmobile=imageurlmobile || '';  // Jemin - 19-Nov-2019 - RES-2312 - To fix when  only one image iss
            
            imageurltab = imageurltab==undefined?'':imageurltab;
            imageurlmobile = imageurlmobile==undefined?'':imageurlmobile;
            imageurldesktop = imageurldesktop==undefined?'':imageurldesktop;

                jQuery("#img_banner_div").show();
		jQuery("#img_banner_div").addClass("vres-responsive-bnr");
        if(!imageurltab == '')      // Jemin - 19-Nov-2019 - RES-2312 - To fix when  only one image is
                jQuery("<img id='imglogotab' alt='banner bg' class='hide sm-show' src='"+imageurltab+"' />").appendTo("div#img_banner_div");
        else
                jQuery("<img id='imglogotab' alt='banner bg' class='hide sm-show' src='"+imageurldesktop+"' />").appendTo("div#img_banner_div");
                
        if(!imageurlmobile== '') // Jemin - 19-Nov-2019 - RES-2312 - To fix when  only one image is set
                jQuery("<img id='imglogomobile' alt='banner bg' class='hide xs-show' src='"+imageurlmobile+"' />").appendTo("div#img_banner_div");
        else
                jQuery("<img id='imglogomobile' alt='banner bg' class='hide xs-show' src='"+imageurldesktop+"' />").appendTo("div#img_banner_div");
        //kishan Tailor 09 june 2020 RES-2508 END
		
                jQuery("#chk_wrapper").removeClass('chkimg_notfound');
                jQuery("#img_banner_div").removeClass('vres_bnr');
		jQuery("#imglogo").addClass("md-show");
		jQuery("#imglogo").addClass("hide");
		
		$('#imglogotab,#imglogomobile').error(function() {
                jQuery("#img_banner_div").removeClass("vres-responsive-bnr");
                $('#imglogotab,#imglogomobile').remove();
                // Jemin - 19-Nov-2019 - RES-2312 - To fix when  only one image is set - START
                    if(!imageurltab == '')
                    {
                        var keyname_tab="res_Logo_Tab_"+opt.HotelId;
                        document.cookie = keyname_tab+"="+"";
                    }
                    if(!imageurlmobile== '')
                    {
                        var keyname_mobile="res_Logo_Mobile_"+opt.HotelId;
                        document.cookie = keyname_mobile+"="+"";         
                    }
                    // Jemin END
                });
          }
          catch(err)
          {
                hideLoadingBar();
                hideCheckavalabilityLoadingBar();
				console.log("Error - _setImages - "+err.message);
          }
        },
        _getImages:function(opt)
		{
			try
			{
				//if(!('sessionStorage' in window))
				//{
				//	getUrl=opt.serverurl+'service.php';
				//	$.ajax({
				//		url: getUrl,
				//		type: "POST",
				//		data: {
				//			action: 'LogoBannerImage',
				//			HotelId: opt.HotelId
				//		},
				//		dataType: "html",
				//		success: function( data ){
				//			console.log( "AJAX _storeImages - success()" );
				//			var imageurl=jQuery.trim(data);
				//			if(imageurl=='')
				//			{
				//				jQuery("#img_banner_div").hide();
				//				jQuery("#img_banner_div").html('');                                
				//				jQuery("#img_banner_div").removeClass('vres_bnr');
				//				jQuery("#chk_wrapper").removeClass('chkimg_notfound').addClass('chkimg_notfound');
				//				jQuery(".vres_bhk_pos .full-bar-wrapper").css({'width':'100%'});
				//			}
				//			else
				//			{
				//				methods._setThemeImageLogo();
				//				methods._setImages(opt,imageurl); 
				//			}
				//		}
				//	});
				//}
				//else
				{
					//Pinal - 1.0.50.55 - 13 October 2016 - START
                    //Purpose - Different images for desktop,mobile and tablet.
					var keyname="res_Logo_"+opt.HotelId;					
					var keyname_tab="res_Logo_Tab_"+opt.HotelId;
					var keyname_mobile="res_Logo_Mobile_"+opt.HotelId;
					var name = keyname + "=";
					var name_tab = keyname_tab + "=";
					var name_mobile = keyname_mobile + "=";
                    var ca = document.cookie.split(';');
					var cookie_img=cookie_img_tab=cookie_m='';
					
					for(var i = 0; i <ca.length; i++) {
					    var c = ca[i];
					    while (c.charAt(0)==' ') {
						c = c.substring(1);
					    }
					    if (c.indexOf(name) == 0)
					    {
						cookie_img=c.substring(name.length,c.length)
					    }
					     if (c.indexOf(name_tab) == 0) // Jemin - 19-Nov-2019 - RES-2312 - To fix when  only one image is set  removed elseif to if
					    {
						cookie_img_tab=c.substring(name_tab.length,c.length)
					    }
					     if (c.indexOf(name_mobile) == 0)  // Jemin - 19-Nov-2019 - RES-2312 - To fix when  only one image is set  removed elseif to if
					    {
						cookie_m=c.substring(name_mobile.length,c.length)
					    }
					}
					
					//if(resLocalStorage.getItemObject(keyname)!=null)
                    //if(sessionStorage.getItem(keyname)!=null)
					if (cookie_img!="")
					{
					   //console.log("exist");
					    // console.log("if.....");
					    //var imageurl=resLocalStorage.getItemObject(keyname);
					    //var imageurl=sessionStorage.getItem(keyname);
					    var imageurl=cookie_img;
					    
					    //if(imageurl=='')
					    if(imageurl=='' || imageurl=='no-bnr')
					    {
						    jQuery("#img_banner_div").hide();
						    jQuery("#img_banner_div").html('');
						    jQuery("#img_banner_div").removeClass('vres_bnr');
						    jQuery("#chk_wrapper").removeClass('chkimg_notfound').addClass('chkimg_notfound');
						    jQuery(".vres_bhk_pos .full-bar-wrapper").css({'width':'100%'});
					    }
					    else
					    {
						    //methods._setThemeImageLogo();
						    //resLocalStorage.setItem(keyname,imageurl);
						    //sessionStorage.setItem(keyname,imageurl);
                            document.cookie = keyname+"="+imageurl;
						    methods._setImages(opt,imageurl);
						    //kishan Tailor 09 june 2020 purpose:issue while image for mobile or tablet not uploded then checkin/checkout datepicker not visible commented if condition and add imageurl param RES-2508 START
						    //if((cookie_m!=undefined && cookie_m!='') || (cookie_img_tab!=undefined && cookie_img_tab !=''))  // Jemin - 19-Nov-2019 - RES-2312 - To fix when  only one image is set 
						    //{
						       methods._setDeviceWiseImages(opt,cookie_img_tab,cookie_m,imageurl);
						    //}
                             //kishan Tailor 09 june 2020 END
					    }
					}
					else
					{
						//console.log("get banner image");
						getUrl=opt.serverurl+'service.php';
						$.ajax({
							url: getUrl,
							type: "POST",
							data: {
								action: 'LogoBannerImage',
								HotelId: opt.HotelId
							},
							dataType: "html",
							success: function( data ){
								//console.log( "AJAX session _storeImages - success()");	
								var imageurl=jQuery.trim(data);
								
								var response  = JSON.parse(imageurl);
                                
                                //Chandrakant - 14 October 2020, Purpose - 404URLError - Added undefined condition because getting 404 error on the access log
								if(response.DESKTOP=='' || response.DESKTOP===undefined)
								{
									jQuery("#img_banner_div").hide();
									jQuery("#img_banner_div").html('');
									jQuery("#img_banner_div").removeClass('vres_bnr');
									jQuery("#chk_wrapper").removeClass('chkimg_notfound').addClass('chkimg_notfound');
									jQuery(".vres_bhk_pos .full-bar-wrapper").css({'width':'100%'});
									//document.cookie = keyname+"="+"no-bnr";
								}
								else
								{
									//methods._setThemeImageLogo();
									//resLocalStorage.setItem(keyname,imageurl);
									//sessionStorage.setItem(keyname,imageurl);
									
									methods._setImages(opt,response.DESKTOP);
									// Jemin - 19-Nov-2019 - RES-2312 - To fix when  only one image is set - START 
                                    if(response.MOBILE!=undefined && response.MOBILE!='')
                                    {
                                        document.cookie = keyname_mobile+"="+response.MOBILE;
									   
                                        //methods._setDeviceWiseImages(opt,'',response.MOBILE);//kishan Tailor 09 june 2020 purpose:issue while image for mobile or tablet not uploded then checkin/checkout datepicker not visible RES-2508 
                                    }

									if(response.TAB!=undefined && response.TAB!='')
									{  
                                       document.cookie = keyname_tab+"="+response.TAB;   
									   //methods._setDeviceWiseImages(opt,response.TAB,'');//kishan Tailor 09 june 2020 purpose:issue while image for mobile or tablet not uploded then checkin/checkout datepicker not visible RES-2508
									}
									// Jemin END
                                    methods._setDeviceWiseImages(opt,response.TAB,response.MOBILE,response.DESKTOP);//kishan Tailor 09 june 2020 purpose:issue while image for mobile or tablet not uploded then checkin/checkout datepicker not visible START
									document.cookie = keyname+"="+response.DESKTOP;
									
								}
							}
						});						
					}
				}
				//Pinal - 1.0.50.55 - 13 October 2016 - END
			}
			catch(e)
			{
				console.log(" _getImages " + e);	
			}
		},
	_ShowQuickNotes:function(opt){ //Pinal - 18 December 2015 - QUICK NOTES
			try{				
				//if(('sessionStorage' in window))
				//{
				//	var keyname="res_quick_"+opt.HotelId;	
				//	if(sessionStorage.getItem(keyname)!=null)	
				//	{
				//		var _isQuickNotes=sessionStorage.getItem(keyname);
				//	}
				//	else
				//	{
				//		var _isQuickNotes='true';	
				//		sessionStorage.setItem(keyname,false);	
				//	}
				//}
				//else
				//	var _isQuickNotes='true';
				
				var _isQuickNotes='true';
				var keyname_quick="res_quick_"+opt.HotelId;
				
				var name = keyname_quick + "=";
				var ca = document.cookie.split(';');
				var cookie_filter='';
				for(var i = 0; i <ca.length; i++) {
				    var c = ca[i];
				    while (c.charAt(0)==' ') {
					c = c.substring(1);
				    }
				    if (c.indexOf(name) == 0)
				    {
					cookie_filter=c.substring(name.length,c.length)
				    }
				}
				
				if (cookie_filter!='')
				    var _isQuickNotes=cookie_filter;
				else
				    document.cookie = keyname_quick+"=false";
				
				var _isQuickNotesFlag='true';				
				//console.log(_isQuickNotes);	
					$.get(opt.serverurl+'service.php?HotelId='+opt.HotelId+'&_quicknotes='+_isQuickNotesFlag, function(data) {					
						var JSONobject = eval("["+data+"]");
						jQuery.each( JSONobject, function(JsonIndex , jsonData) {	
							if(jsonData[0]['QuickNotesFlag']==1 && jsonData[0]['qflag']==1 && jsonData[0]['qtitleflag']==1)
							{
								$("#quickinfo").html(jsonData[0]['quicktitle']);
								$("#qconte").html(jsonData[0]['quickcontent']);
								
								$("#ofr-btn").show();
								$(".vres-specialoffer").show();
								
								var offHeight = $('.vres-specialoffer').height();
								$('.vres-specialoffer').css({'margin-top': - offHeight, /*'transition':'all ease .3s'*/});
								
								if(_isQuickNotes=='true')
								{
									$('.vres-specialoffer').addClass('vres-offerposition').css({'transition':'all ease .3s'});
									setTimeout(function(){ //FOR TRANSITION
									    $('.vres-specialoffer').removeClass('vres-offerposition').css({'transition':'all ease .3s'});
									}, 5000);
									
									setTimeout(function(){ //FOR TRANSITION
									    $('#ofr-btn').removeClass('offr_bg');
									    $('.offer-btn').children('img').removeClass('animate_ofr-img');
									}, 5000);
									
									$('#ofr-btn').addClass('offr_bg');
								}
							}
						});
					});
			}
			catch(e)
			{
				console.log("Error in _ShowQuickNotes"+ e);		
			}
		},
		_InsertCheckAvail:function(opt)
		{
            //Pinal - 29 November 2019 - START
            //Purpose : Mongodb removal, migrating to RDS
            var trackvisit=0;
            if(opt.loadvisitor==true)
            {
                methods._storeAuthDetails(opt);
                trackvisit=1;
                if(opt.ShowCurrencyCountryWise == 0)
                {
                    console.log("get country nam1e");
                    methods._getCountryName(opt);//Chinmay Gandhi - Set currency list vise
                }
                opt.loadvisitor=false;
            }
            //Pinal - 29 November 2019 - END
            
		    var checkin=$("#eZ_chkin").val();
		   
		    var no_nights=1;
		    if(opt.ShowNights==false && opt.ShowDepart==true)
			no_nights=$("input[id$='eZ_Nights']").val();
		    else
			no_nights=$("input#eZ_Nights2").val();
			
		    var dateformat=$("input[id$='CalDtFormat']").val();
		    
             var promocode='';
             if(opt.SelectedPromo!=null && opt.SelectedPromo!='')//added for direct promo apply code - flora
                promocode=opt.SelectedPromo;
            
            if(promocode=='')
                promocode=$("input[id$='promotioncode']").val();
            if(promocode==undefined)
			promocode='';
			
		    var adult='1';
		    if(opt.acr==true)
			adult=$("select#eZ_adult").val();
			
		    var child='0';
		    if(opt.showchild_bb==true)
			child=$("select#eZ_child").val();
			
		    //console.log("_InsertCheckAvail - "+opt.HotelId+" | "+checkin+" | "+no_nights+" | "+adult+" | "+child+" | "+promocode+" | "+dateformat);
		    
		    $.ajax({
			    url: opt.serverurl+'service.php',
			    type: "POST",
			    data: {
				    action: 'CheckAvailData',
				    HotelId: opt.HotelId,
				    arrival_date: checkin,
				    adult:adult,
				    child:child,
				    nights: no_nights,
				    offer_rates: '',
				    rooms: '',
				    promotional_code:promocode,
				    book_multiple: '',
				    date_format:dateformat,
				    _TrackId:'',
				    device:opt.device_detect,//flora
                    trackvisit:trackvisit
			    },
			    dataType: "html",
			    success: function( data )
			    {
				    $("#_TrackId").val(jQuery.trim(data));
			    }
		    });
		    
		},
		_availabilitycal:function(opt,startdate,id,isspecial,calledfrom,roomTypeunkId,flag){
			try
			{
                roomTypeunkId = roomTypeunkId || '';
                flag = flag || 0;
                //Flora - for chienese case it was not work
                if (opt.selectedLang!='-zh-CN-Chinese' && opt.selectedLang!='-zh-TW-ChineseTraditional') {
                    var lang=opt.selectedLang.split("-");
                 }
                  else
                 {
                     var lang2=opt.selectedLang.split("-");
                     var lang=Array();
                     lang[2]=lang2[3];
                     lang[1]=lang2[1]+"-"+lang2[2];
                 }		 
                 //Flora - for chienese case it was not work
                //var lang=opt.selectedLang.split("-");//flora - - for chienese case it was not work - OLD
                
                // Jemin 14-Nov-2019 RES-2147-Purpose Check RoomType - Start
                    if(roomTypeunkId == '')
                        roomTypeunkId = $('#avairoomtype').val();
                    if(id != '')  //Hide room type combo when filter is applied
                    $('.roomtype-input').hide();
                    console.log("Selected RoomType" + roomTypeunkId);
                // Jemin END

			    var dateformat=$("input[id$='CalDtFormat']").val();
			    $.ajax({
				url: opt.serverurl+'availability_calender.php',
				type: "POST",
				dataType: "html",
				data: {
					HotelId: opt.HotelId,
					arrival_date:startdate,
					date_format:dateformat,
					filter:id,
					type:isspecial,
					frame:0,
					lang_code:lang[1],
					langtext:lang[2],
					TA_id:jQuery("#request_TA").val(),
					promotioncode:jQuery("#avail_cal_prmcode").val(),
                    through: 'BE',//Chinmay Gandhi - 19th June 2018 - [ AvaCalenderrates ]
                    roomTypeunkId : roomTypeunkId , //Jemin -14-Nov-2019 - RES-2147 - Availability Calender
				    },
				    error: function(){
				    },
				    beforeSend: function(){
					//showLoadingBar();
					//$("#checkavaildata").html('');
					$("#checkavaildata").html(jQuery(".loadingbar").html());
				    },
				    complete: function(){
					//hideLoadingBar();
					$('#checkavail-modal').modal();//added code
				    },
				    success: function( data ){
					//$("#checkavaildata").html('');
					$("#checkavaildata").html(data);
				    },
				    complete:function(){
					
					$('#prev_dt').prev_dt();
					$('#next_dt').next_dt();
					
					if (calledfrom!='prev')
					{
					    var d_prev = $.datepicker.parseDate(dateformat, jQuery("#Jumpdt").val());
					    d_prev.setDate(d_prev.getDate() - 12); //Priyanka Maurya - 20 July 2019 - Purpose : Rates are not showing properly in Calendar - RES-2082
					    
					    var formated_prev=$.datepicker.formatDate(dateformat, d_prev);
					    $("#prev_dt").val(formated_prev);
					    
					    var d_next = $.datepicker.parseDate(dateformat, jQuery("#Jumpdt").val());
					    d_next.setDate(d_next.getDate() + 12); //Priyanka Maurya - 20 July 2019 - Purpose : Rates are not showing properly in Calendar - RES-2082
					    
					    var formated_next=$.datepicker.formatDate(dateformat, d_next);
					    $("#next_dt").val(formated_next);
					}
					
					var currendate = new Date();
					currendate.setDate(currendate.getDate()+opt.CalMinDate);
					
					if ($.datepicker.parseDate(dateformat, jQuery("#Jumpdt").val())<=currendate)
					    jQuery("#prev_dt_clk").hide();
					else
					    $("#prev_dt_clk").show();

                        if(flag==1){
                            if($('.roomtype-input').is(":hidden")){
                                $('.ratecurrency').focus();
                            }
                            else{
                                $('#avairoomtype').focus();
                            }
                        }
				    }
				});
				
			}
			catch(err)
			{
				hideLoadingBar();
				console.log("Error - _availabilitycal - "+err.message);
			}
		},
        addCheckoutDetails: function(opt){
            $('head script').each(function() {
                if ($(this).html().includes("view_item_list") || $(this).html().includes("check_availability") || $(this).html().includes("add_to_cart") || $(this).html().includes("removeFromCart")) {
                    $(this).remove();
                }
            });
            var scriptval = "\<script\>";
            scriptval +="dataLayer.push({ ";
            scriptval +="event: 'initiate_checkout', ";
            scriptval +="event_id: 'event_id', ";
            scriptval +="agendID: '', ";
            scriptval +="arrivalDate: '"+jQuery("#datalyr_checkindate").val()+"', ";
            scriptval +="brand: '"+js_addslashes(jQuery("#hbrandname").val())+"', ";
            scriptval +="clientID: '', ";
            scriptval +="departureDate: '"+jQuery("#datalyr_checkoutdate").val()+"', ";
            scriptval +="discountCoupon: '',";
            scriptval +="offer: '"+js_addslashes(jQuery("#hpromoname").val())+"', ";
            scriptval +="hotelID: "+resgrid[0][0].hotel_code+",";
            scriptval +="location: '"+js_addslashes(jQuery("#hlocation").val())+"', ";
            scriptval +="noOfAdults: "+jQuery("#adult").val()+",";
            scriptval +="noOfChild: "+jQuery("#child").val()+",";
            scriptval +="noOfRooms: "+jQuery("#rooms").val()+",";
            scriptval +="pageTitle: '"+js_addslashes(document.title)+"', ";
            scriptval +="pageURL: '"+window.location.href+"', ";
            scriptval +="promoCode: '"+js_addslashes(jQuery("#hpromocode").val())+"', ";
            scriptval +="ecommerce: { ";
            scriptval +="'currency': '"+jQuery("#selected_curr").val()+"', ";
            scriptval +="'value':"+jQuery("#grand_total").text().replace(/[^0-9.]/g, '')+",";
            scriptval +="'coupon': '',";
            scriptval +="'items': [ ";
            var arrIndex=0;
            var $filteredLis = $('li.highlight_ths_row');
            if($filteredLis.length > 0) {
                $filteredLis.each(function() {
                    var linumberId = $(this).attr('id').replace(/[^0-9]/g, '');
                    var formatedprice = $(this).find('p.fl-left').text().replace(/[^0-9\.]/g, '');
                    var roomType = js_addslashes($(this).find('span.fl-left').find('span').first().text().trim());
                    var adultInfo = $(this).find('.summeryPaxinfo#listingadult').text().trim().split("_")[0];
                    var childInfo = $(this).find('.summeryPaxinfo#listingchild').text().trim().split("_")[0];
                    var roomInfo = $(this).find('.summeryPaxinfo#listroom').text().trim().split("_")[0];
                    scriptval +="{ ";
                    scriptval +="'item_id': '"+arrIndex+"', ";
                    scriptval +="'item_name': '"+roomType.trim()+"', ";
                    scriptval +="'affiliation': '"+js_addslashes(jQuery("#hname").text())+"', ";
                    scriptval +="'index': "+arrIndex+", ";
                    scriptval +="'item_brand': '"+roomType.trim()+"', ";
                    scriptval +="'item_category': '"+roomType.trim()+"', ";
                    scriptval +="'item_variant': '"+roomType.trim()+"', ";
                    scriptval +="'price': "+formatedprice+", ";
                    scriptval +="'quantity': 1 ";
                    if(arrIndex+1<$filteredLis.length)
                        scriptval +="},";
                    else
                        scriptval +="}";
                    arrIndex=arrIndex+1;
                });
            }
            scriptval +="]}}); ";
            scriptval +="\<\/script\>";
            jQuery("head").append(scriptval);
        },
        addRoomToCart: function(rowid,opt){
            $('head script').each(function() {
                if ($(this).html().includes("view_item_list") || $(this).html().includes("check_availability") || $(this).html().includes("add_to_cart") || $(this).html().includes("removeFromCart")) {
                    $(this).remove();
                }
            });
            spanlist=jQuery("#summ_row_"+rowid[1]).find("span.fl-left span")
            packagename=js_addslashes(spanlist[0].innerHTML);
            extrainfo=spanlist[1].innerHTML;
            plist=jQuery("#summ_row_"+rowid[1]).find("p.fl-left");
            pprice=plist[0].innerText.replace(/[^0-9.]/g, '');
            var $htmlContent = $('<div>').html(extrainfo);
            var $divs = $htmlContent.find('div');
            var extractedText = [];
            extractedText['adult']=extractedText['child']=extractedText['room']=0;
            $divs.each(function() {
                var tempid=$(this).attr('id');
                if(tempid=='listingadult'){
                    extractedText['listingadult']=$(this).text().trim();
                    extractedText['adult']=extractedText['listingadult'].split(" ")[0];
                }
                else if(tempid=='listingchild'){
                    extractedText['listingchild']=$(this).text().trim();
                    extractedText['child']=extractedText['listingchild'].split(" ")[0];
                }else if(tempid=='listroom'){
                    extractedText['listroom']=$(this).text().trim();
                    extractedText['room']=extractedText['listroom'].split(" ")[0];
                }
            });

            var scriptavaulval = "\<script\>";
            scriptavaulval +="window.dataLayer = window.dataLayer || [];";
            scriptavaulval +="dataLayer.push({ ";
            scriptavaulval +="event: 'add_to_cart', ";
            scriptavaulval +="event_id: 'event_id', ";
            scriptavaulval +="arrivalDate: '"+jQuery("#datalyr_checkindate").val()+"', ";
            scriptavaulval +="brand: '"+js_addslashes(jQuery("#hbrandname").val())+"', ";
            scriptavaulval +="departureDate: '"+jQuery("#datalyr_checkoutdate").val()+"', ";
            scriptavaulval +="discountCoupon:  '"+js_addslashes(jQuery("#hpromoname").val())+"', ";
            scriptavaulval +="offer: '"+js_addslashes(jQuery("#hpromoname").val())+"', ";
            scriptavaulval +="hotelID: "+resgrid[0][0].hotel_code+", ";
            scriptavaulval +="location: '"+js_addslashes(jQuery("#hlocation").val())+"', ";
            scriptavaulval +="noOfAdults: "+extractedText['adult']+", ";
            scriptavaulval +="noOfChild: "+extractedText['child']+", ";
            scriptavaulval +="noOfRooms: "+extractedText['room']+", ";
            scriptavaulval +="optionSelected: 'Add Room', ";
            scriptavaulval +="pageTitle: '"+js_addslashes(document.title)+"', ";
            scriptavaulval +="pageURL: '"+window.location.href+"', ";
            scriptavaulval +="promoCode: '"+js_addslashes(jQuery("#hpromocode").val())+"', ";
            scriptavaulval +="ecommerce: { ";
            scriptavaulval +="currency: '"+jQuery("#selected_curr").val()+"', ";
            scriptavaulval +="value: "+pprice+", ";
            scriptavaulval +="'items': [ ";
            scriptavaulval +="{ ";
            scriptavaulval +="'item_id': '"+packagename+"',";
            scriptavaulval +="'item_name': '"+packagename+"',";
            scriptavaulval +="'affiliation': '"+js_addslashes(jQuery("#hbrandname").val())+"', ";
            scriptavaulval +="'index': "+rowid[1]+", ";
            scriptavaulval +="'item_brand': '"+packagename+"',";
            scriptavaulval +="'item_category': '"+packagename+"',";
            scriptavaulval +="'item_variant': '"+packagename+"',";
            scriptavaulval +="'price': "+pprice+",";
            scriptavaulval +="'quantity': 1 ";
            scriptavaulval +="} ";
            scriptavaulval +="] ";
            scriptavaulval +="} ";
            scriptavaulval +="});";
            scriptavaulval +="\<\/script\>";
            jQuery("head").append(scriptavaulval);
        },
		checkPromoCodeValidity:function(opt){
			try
			{
				showLoadingBar();
				
				var checkin=$("#eZ_chkin").val();
				var dateformat=$("input[id$='CalDtFormat']").val();
				
				var no_nights=1;
				if(opt.ShowNights==false && opt.ShowDepart==true)
				    no_nights=$("input[id$='eZ_Nights']").val();
				else
				    no_nights=$("input#eZ_Nights2").val();
				    
				var checkin_date=new Date(jQuery.datepicker.parseDate(dateformat,checkin));
				checkin_date = jQuery.datepicker.formatDate("yy-mm-dd", checkin_date);
				
				var param='action=PromoCodeValidity&HotelId='+opt.HotelId+'&check_in_date='+checkin_date+'&num_nights='+no_nights+'&pmcd='+jQuery("#FacebookShareRewardPromo").val();
				//console.log(param);
				jQuery.ajax({
					type: "POST", 
					url: serverurl+'service.php',
					data: param, 
					success: function(response) {
						
						//console.log(response);
						
						if (jQuery.trim(response)=='true')
							jQuery('.slider_popup').addClass('slider-right');
						else
						{
							var message = js_stripslashes(langjs[0]["L_FBShareOfferExpire"]); 							
                            swal({
                                title: '',//title_message,
                                text: message,
                                type: 'warning',
                                showCancelButton: false,
                                animation:false																					
                             }); 
						}
						hideLoadingBar();
					},
				});
				
			}
			catch(e)
			{
				console.log("checkPromoCodeValidity - "+e);
			}
		},
		_loadheaderevents:function(opt){ //Header events
		    try
		    {
			//Quick Box
			$('.offer-btn').on('click', function(){
			    $('.vres-specialoffer').toggleClass('vres-offerposition').css({'transition':'all ease .3s'});
			    $('#ofr-btn').toggleClass('offr_bg');
			    $(this).children('img').toggleClass('animate_ofr-img');
			});
			
			// Currency and Language dropdown
			$('.cur-btn').on('click', function(e){
                jQuery("#re_pricecheckiframe").hide();//Sonam - 1.0.52.60 - 18 Aug 17 - Purpose: to hide price widget when we change currency
                methods._checksession(opt,'currency'); 
			    if($(this).parent().hasClass('open')){
				$(this).parent().removeClass('open');    
			    }else{
				$('.vres-cur-label').removeClass('open');
				$(this).parent().addClass('open')
                jQuery("#re_pricecheckiframe").show();//Sonam - 1.0.52.60 - 22 Aug 17 - Purpose: to show price widget when we change currency
			    }
                
                //Chinmay Gandhi - 14th Mar 2017 - START
                //Purpose: show suggested currency
                $("#CHCurrency li").each(function(){
                    var val_countrycode=jQuery("#suggested_curr").val();
                    if (jQuery(this).hasClass(val_countrycode)==true) {
                        //var set_value= jQuery.trim(jQuery(this).html());
                        var set_value= jQuery.trim(jQuery(this).html());
                        jQuery("#CHSuggested").html('<li value='+val_countrycode+'>'+set_value+'</li>');
                    }
                });
                //Chinmay Gandhi - 14th Mar 2017 - END
			});
			
			//close header dropdowns on outside click
			$(document).mouseup(function (e){
			    var vrescontainer = $(".vres-cur-label");
			    //console.log(e.target);
			    if (!vrescontainer.is(e.target)
				&& vrescontainer.has(e.target).length === 0)
			    {
				$('.vres-cur-label').removeClass('open');
			    }
			});

			//Currency combo
			$("#CHCurrency li").click(function(e){
                $('#searchcurrency').val(""); //Sachi Desai 28 sep 2019 Purpose - BE_search_currencyissue
                if($(this).attr('value')!='')
			    {
                    if(typeof(resgrid[0][0])!='undefined')
                    {
                        $('.vres-cur-label').removeClass('open');
                        methods._getCurrencyValue(opt,resgrid[0][0]['MultiplyCurrCode'],$(this).attr('value'),resgrid[0][0].decimal);
                    }
			    }
			});
			
            //Chinmay Gandhi - 14th Mar 2017 - START
            //Purpose: show suggested currency
            $("#CHSuggested").click(function(e){
                var country_code = $("#CHSuggested").find("li").attr('value');
                if(country_code != '')
                {
                    if(typeof(resgrid[0][0])!='undefined')
                    {
                        $('.vres-cur-label').removeClass('open');
                        methods._getCurrencyValue(opt,resgrid[0][0]['MultiplyCurrCode'],country_code,resgrid[0][0].decimal);
                    }
                }
			});
            //Chinmay Gandhi - 14th Mar 2017 - END
            
			//Back to Top
			if ($('.vres-back-top').length) {
			    var scrollTrigger = 100, // px
				backToTop = function () {
				    var scrollTop = $(window).scrollTop();
				    if (scrollTop > scrollTrigger) {
					$('.vres-back-top').addClass('vshow');
				    } else {
					$('.vres-back-top').removeClass('vshow');
				    }
				};
			    backToTop();
			    $(window).on('scroll', function () {
                    backToTop();
			    });
			    $('.vres-back-top').on('click', function (e) {
				e.preventDefault();
				$('html,body').animate({
				    scrollTop: 0
				}, 500);
			    });
			}
		    }
		    catch(e)
		    {
			console.log("_loadheaderevents - "+e);
		    }
		},
        //Chinmay Gandhi - 14th Mar 2017 - START
        //Purpose: Set Currency Setting
        _CountryCurrency:function(opt){
		    try
		    {
                if(opt.ShowMultipleCurrencyOption == 0 && opt.ShowCurrencyCountryWise == 0)
                {
                    if(opt.WebShowSelectedCurrencyList != '' && opt.WebShowSelectedCurrencyList !=null)
                    {
                        var country_code = $("#suggested_curr").val();
                        var SelectCurrVal = $.trim(opt.WebShowSelectedCurrencyList);
                        if(SelectCurrVal.indexOf(country_code) >= 0 && country_code != '')
                        {
                            if(typeof(resgrid[0][0])!='undefined')
                            {
                                $('.vres-cur-label').removeClass('open');
                                methods._getCurrencyValue(opt,resgrid[0][0]['MultiplyCurrCode'],country_code,resgrid[0][0].decimal);
                            }
                        }
                        else
                        {
                            if(opt.WebDefaultCurrency != '' && opt.WebDefaultCurrency != null)
                            {
                                methods._getCurrencyValue(opt,resgrid[0][0]['MultiplyCurrCode'],opt.WebDefaultCurrency,resgrid[0][0].decimal);
                            }
                        }
                    }
                }
		    }
		    catch(e)
		    {
                console.log("_CountryCurrency - "+e);
		    }
		},
        //Chinmay Gandhi - 14th Mar 2017 - END
		_loadbbevents:function(){ //Booking box events
		    
		    try
		    {
                //update in help toggle for american disabled act - Daksh 14 feb 2018 -START
                var helpDtl = $('.help_btn').parents('[data-type="help_section"]').find('.vres-helpdetail');
                var isOver = false;
                $('.help_btn').mouseover(function(){isOver = true;}).mouseleave(function(){isOver = false;});
                $('.help_btn').bind('focusin', function () {
                    $('.tipso-tooltip-help').tipso('show'); //Chandrakant - 1.0.53.61 - 15 February 2018 - ADARequirement
                });  
                $('.help_btn').bind('focusout', function () {
                    if(!isOver){
                        $('.tipso-tooltip-help').tipso('hide'); //Chandrakant - 1.0.53.61 - 15 February 2018 - ADARequirement
                    }
                }); 

                $('.help_btn').on('click', function(e){
                    e.stopPropagation();
                    if ( helpDtl.css('display') == 'block') { 
                        helpDtl.hide();
                        $('.tipso-tooltip-help').tipso('hide'); //Chandrakant - 1.0.53.61 - 15 February 2018 - ADARequirement
                    } else {
                        helpDtl.show();
                        $('.tipso-tooltip-help').tipso('hide'); //Chandrakant - 1.0.53.61 - 15 February 2018 - ADARequirement
                    }
                });
                //update in help toggle for american disabled act - Daksh 14 feb 2018 -END
		    }
		    catch(e)
		    {
			console.log("_loadbbevents - "+e);
		    }
		    
		},
        _pricewidgetreload:function(opt,_toCurrCode) {            
            try
            {
                if (jQuery("#re_pricecheckiframe").length>0 && jQuery("#pricewidgetlink").length>0 && jQuery("#pricewidgetlink").val()!=undefined && jQuery("#pricewidgetlink").val()!="")
                {
                    _toCurrCode=_toCurrCode || ''; 
                    var min=''; 
                    var find_minrtplan='';			    
                    var total_rooms_av=0;
                   var lowest = Array();
                   var hidnaray = Array();
                   jQuery(".pricewid-webprice").each(function()
                                                {
                                                   var webprice=parseFloat(jQuery(this).val());
                                                   var isroomvisible=jQuery(this).parent().is(":visible");
                                                   if ((isroomvisible || (!isroomvisible && jQuery(this).parent().hasClass("read-more-content"))))
                                                   {
                                                       var thisid=jQuery(this).parent().attr("id");
                                                       var findvisiblity=thisid.split("_");
                                                       if(!(typeof(resgrid[0][findvisiblity[1]].hiderateplan)!='undefined' && resgrid[0][findvisiblity[1]].hiderateplan=='1'))
                                                       {
                                                           lowest.push(jQuery(this).val());// Ravi -  1.0.53.61 22 November 2017 - Purpose - To show lowest wholestay value on PriceWidget.
                                                           total_rooms_av++;
                                                       }
                                                   }             
                                                });
                   //Sonam - 1.0.52.60 - 17 Aug 2017 - END
                   
                   // Ravi -  1.0.53.61 22 November 2017 - START
                   // Purpose - To show lowest wholestay value on PriceWidget.
                   //console.log(lowest);
                   var min = Math.min.apply(Math,lowest);
                   min = parseFloat(min);
                   // Ravi -  1.0.53.61 22 November 2017 Temp. code. - END
                    
                    if (total_rooms_av>0)
                    {
                        var currency_symbl=resgrid[0][0]['MultiplyCurrCode'];
                        //console.log("currency_symbl=>"+_toCurrCode);
                        
                        // Ravi -  1.0.53.61 27 November 2017 - START
                        // Purpose - To show lowest wholestay value on PriceWidget.
                        var currencyfactor = resgrid[0][0]['MultiplyFactor'];
                        //console.log("currency factor : " + currencyfactor);
                        min = min*parseFloat(currencyfactor);
                        //console.log("Lowest RatePlan/Package after currency apply : "+min);
                        // Ravi -  1.0.53.61 27 November 2017 Temp. code. - END
                        
                        var no_nights=1;
                        if(opt.ShowNights==false && opt.ShowDepart==true)
                        no_nights=$("input[id$='eZ_Nights']").val();
                        else
                        no_nights=$("input#eZ_Nights2").val();
                        var checkin=$("#eZ_chkin").datepicker('getDate');
                        var checkout=$("#eZ_chkin").datepicker('getDate');
                        
                        if(opt.ShowNights==true)
                            checkout.setDate(checkin.getDate()+parseInt(no_nights));
                        else
                            checkout.setDate(checkin.getDate()+parseInt(no_nights));
                            
                        checkin=$.datepicker.formatDate("yy-mm-dd ", checkin);
                        checkout=$.datepicker.formatDate("yy-mm-dd ", checkout);
                       // console.log("Selected Check-in : "+checkin);
                       // console.log("Selected Check-out : "+checkout);
                        
                        var pricewidgetlink=jQuery("#pricewidgetlink").val();
                        pricewidgetlink = pricewidgetlink.replace(/\{check_in_date}/g,checkin);
                        pricewidgetlink = pricewidgetlink.replace(/\{check_out_date}/g,checkout);
                        pricewidgetlink = pricewidgetlink.replace(/\{directprice}/g,methods._applyRoundOff(min).toFixed(_display_decimalplaces)); //Chandrakant - 1.0.52.57 - 28 April 2017 // Solved Roundoff issue    
                        if(_toCurrCode=='')
                            pricewidgetlink = pricewidgetlink.replace(/\{hotelcurrency}/g,currency_symbl);
                        else
                            pricewidgetlink = pricewidgetlink.replace(/\{hotelcurrency}/g, _toCurrCode);   //Sonam - 1.0.52.60 - 17 Aug 2017 - Purpose: to fetch currency name in price widget on changing currency
                            
                        if (opt.selectedLang!='-zh-CN-Chinese' && opt.selectedLang!='-zh-TW-ChineseTraditional')
                        {
                            var lang=opt.selectedLang.split("-");
                        }
                        else
                        {
                            var lang2=opt.selectedLang.split("-");
                            var lang=Array();
                            lang[2]=lang2[3];
                            lang[1]=lang2[1]+"-"+lang2[2];
                        }
                        
                        if (lang[1]!='' && lang[1]!=undefined)
                        {
                            pricewidgetlink=pricewidgetlink+"&language="+lang[1];
                        }
                        
                        if (opt.device_detect=="MOBILE")
                            pricewidgetlink+="&widget-open=close";
                            
                        if (jQuery("body").attr("class")!="")
                            pricewidgetlink+="&classadd="+jQuery("body").attr("class");
                        
                        if (resgrid[0][0].ShowTaxInclusiveExclusiveSettings!=undefined)
                            pricewidgetlink+="&istaxinclusive="+resgrid[0][0].ShowTaxInclusiveExclusiveSettings;
                            
                        jQuery("#re_pricecheckiframe").attr("src",pricewidgetlink);
                        //console.log(pricewidgetlink);
                        //jQuery("#re_pricecheckiframe").css("top",jQuery("#chk_wrapper").height()+" px");
                    }
                }
            }
            catch(e)
            {
            console.log("_pricewidgetreload - "+e);   
            }
        },
        //Chandrakant - 30 September 2022 - START
        //Purpose : RES-3133
        dayTripper:function() {
            //console.log('called dayTripper');
            //$('.hasDatepicker').click(function () {
            $('.hasDatepicker').on('click focusin', function() {
                //console.log('called dayTripper click');
              setTimeout(function () {
                var today = $('.ui-datepicker-today a')[0];
          
                if (!today) {
                  today = $('.ui-state-active')[0] ||
                          $('.ui-state-default')[0];
                }
          
                // Hide the entire page (except the date picker)
                // from screen readers to prevent document navigation
                // (by headings, etc.) while the popup is open
                $("main").attr('id','dp-container');
                $("#dp-container").attr('aria-hidden','true');
                $("#skipnav").attr('aria-hidden','true');
          
                // Hide the "today" button because it doesn't do what
                // you think it supposed to do
                $(".ui-datepicker-current").hide();
          
                today.focus();
                methods.datePickHandler();
                $(document).on('click', '#ui-datepicker-div .ui-datepicker-close', function () {
                  methods.closeCalendar();
                });
              }, 0);
            });
          },
          datePickHandler:function() {
            var activeDate;
            var container = document.getElementById('ui-datepicker-div');
            
            if (!container) {
              return;
            }
          
           // $(container).find('table').first().attr('role', 'grid');
          
            container.setAttribute('role', 'application');
            container.setAttribute('aria-label', 'Calendar view date-picker');
          
              // the top controls:
            var prev = $('.ui-datepicker-prev', container)[0],
                next = $('.ui-datepicker-next', container)[0];
          
          
          // This is the line that needs to be fixed for use on pages with base URL set in head
            next.href = 'javascript:void(0)';
            prev.href = 'javascript:void(0)';
          
            next.setAttribute('role', 'button');
            next.removeAttribute('title');
            prev.setAttribute('role', 'button');
            prev.removeAttribute('title');
          
            methods.appendOffscreenMonthText(next);
            methods.appendOffscreenMonthText(prev);
          
            // delegation won't work here for whatever reason, so we are
            // forced to attach individual click listeners to the prev /
            // next month buttons each time they are added to the DOM
            $(next).on('click', methods.handleNextClicks);
            $(prev).on('click', methods.handlePrevClicks);
            var container = $('#ui-datepicker-div');
            $(container).off('keydown');
            methods.monthDayYearText();
            $(container).on('keydown', function calendarKeyboardListener(keyVent) {
              var which = keyVent.which;
              var target = keyVent.target;
              var dateCurrent = methods.getCurrentDate(container);
          
              if (!dateCurrent) {
                dateCurrent = $('a.ui-state-default')[0];
                methods.setHighlightState(dateCurrent, container);
              }
          
              if (27 === which) {
                keyVent.stopPropagation();
                return methods.closeCalendar();
              } else if (which === 9 && keyVent.shiftKey) { // SHIFT + TAB
                keyVent.preventDefault();
                if ($(target).hasClass('ui-datepicker-close')) { // close button
                  $('.ui-datepicker-prev')[0].focus();
                } else if ($(target).hasClass('ui-state-default')) { // a date link
                  $('.ui-datepicker-close')[0].focus();
                } else if ($(target).hasClass('ui-datepicker-prev')) { // the prev link
                  $('.ui-datepicker-next')[0].focus();
                } else if ($(target).hasClass('ui-datepicker-next')) { // the next link
                  activeDate = $('.ui-state-highlight') ||
                              $('.ui-state-active')[0];
                  if (activeDate) {
                    activeDate.focus();
                  }
                }
              } else if (which === 9) { // TAB
                keyVent.preventDefault();
                if ($(target).hasClass('ui-datepicker-close')) { // close button
                  activeDate = $('.ui-state-highlight') ||
                              $('.ui-state-active')[0];
                  if (activeDate) {
                    activeDate.focus();
                  }
                } else if ($(target).hasClass('ui-state-default')) {
                  $('.ui-datepicker-next')[0].focus();
                } else if ($(target).hasClass('ui-datepicker-next')) {
                  $('.ui-datepicker-prev')[0].focus();
                } else if ($(target).hasClass('ui-datepicker-prev')) {
                    activeDate = $('.ui-state-highlight') ||
                    $('.ui-state-active')[0];
                    if (activeDate) {
                    activeDate.focus();
                    }
                }
              } else if (which === 37) { // LEFT arrow key
                // if we're on a date link...
                if (!$(target).hasClass('ui-datepicker-close') && $(target).hasClass('ui-state-default')) {
                  keyVent.preventDefault();
                  methods.previousDay(target);
                }
              } else if (which === 39) { // RIGHT arrow key
                // if we're on a date link...
                if (!$(target).hasClass('ui-datepicker-close') && $(target).hasClass('ui-state-default')) {
                  keyVent.preventDefault();
                  methods.nextDay(target);
                }
              } else if (which === 38) { // UP arrow key
                if (!$(target).hasClass('ui-datepicker-close') && $(target).hasClass('ui-state-default')) {
                  keyVent.preventDefault();
                  methods.upHandler(target, container, prev);
                }
              } else if (which === 40) { // DOWN arrow key
                if (!$(target).hasClass('ui-datepicker-close') && $(target).hasClass('ui-state-default')) {
                  keyVent.preventDefault();
                  methods.downHandler(target, container, next);
                }
              } else if (which === 13) { // ENTER
                if ($(target).hasClass('ui-state-default')) {
                  setTimeout(function () {
                    //methods.closeCalendar();
                  }, 100);
                } else if ($(target).hasClass('ui-datepicker-prev')) {
                  methods.handlePrevClicks();
                } else if ($(target).hasClass('ui-datepicker-next')) {
                  methods.handleNextClicks();
                }
              } else if (32 === which) {
                if ($(target).hasClass('ui-datepicker-prev') || $(target).hasClass('ui-datepicker-next')) {
                  target.click();
                }
              } else if (33 === which) { // PAGE UP
                methods.moveOneMonth(target, 'prev');
              } else if (34 === which) { // PAGE DOWN
                methods.moveOneMonth(target, 'next');
              } else if (36 === which) { // HOME
                var firstOfMonth = $(target).closest('tbody').find('.ui-state-default')[0];
                if (firstOfMonth) {
                  firstOfMonth.focus();
                  methods.setHighlightState(firstOfMonth, $('#ui-datepicker-div')[0]);
                }
              } else if (35 === which) { // END
                var $daysOfMonth = $(target).closest('tbody').find('.ui-state-default');
                var lastDay = $daysOfMonth[$daysOfMonth.length - 1];
                if (lastDay) {
                  lastDay.focus();
                  methods.setHighlightState(lastDay, $('#ui-datepicker-div')[0]);
                }
              }
              $(".ui-datepicker-current").hide();
            });
          },
          closeCalendar:function() {
            var container = $('#ui-datepicker-div');
            $(container).off('keydown');
            var input = $('.hasDatepicker')[0];
            $(input).datepicker('hide');
          
           // input.focus();
          },
          removeAria:function() {
            // make the rest of the page accessible again:
            $("#dp-container").removeAttr('aria-hidden');
            $("#skipnav").removeAttr('aria-hidden');
          },
          isOdd:function(num) {
            return num % 2;
          },
          moveOneMonth:function(currentDate, dir) {
            var button = (dir === 'next')
                        ? $('.ui-datepicker-next')[0]
                        : $('.ui-datepicker-prev')[0];
          
            if (!button) {
              return;
            }
          
            var ENABLED_SELECTOR = '#ui-datepicker-div tbody td:not(.ui-state-disabled)';
            var $currentCells = $(ENABLED_SELECTOR);
            var currentIdx = $.inArray(currentDate.parentNode, $currentCells);
          
            button.click();
            setTimeout(function () {
              methods.updateHeaderElements();
          
              var $newCells = $(ENABLED_SELECTOR);
              var newTd = $newCells[currentIdx];
              var newAnchor = newTd && $(newTd).find('a')[0];
          
              while (!newAnchor) {
                currentIdx--;
                newTd = $newCells[currentIdx];
                newAnchor = newTd && $(newTd).find('a')[0];
              }
          
              methods.setHighlightState(newAnchor, $('#ui-datepicker-div')[0]);
              newAnchor.focus();
          
            }, 0);
          
          },
          handleNextClicks:function() {
            setTimeout(function () {
              methods.updateHeaderElements();
              methods.prepHighlightState();
              $('.ui-datepicker-next').focus();
              $(".ui-datepicker-current").hide();
            }, 0);
          },
          handlePrevClicks:function() {
            setTimeout(function () {
              methods.updateHeaderElements();
              methods.prepHighlightState();
              $('.ui-datepicker-prev').focus();
              $(".ui-datepicker-current").hide();
            }, 0);
          },
          previousDay:function(dateLink) {
            var container = document.getElementById('ui-datepicker-div');
            if (!dateLink) {
              return;
            }
            var td = $(dateLink).closest('td');
            if (!td) {
              return;
            }
          
            var prevTd = $(td).prev(),
                prevDateLink = $('a.ui-state-default', prevTd)[0];
          
            if (prevTd && prevDateLink) {
              methods.setHighlightState(prevDateLink, container);
              prevDateLink.focus();
            } else {
              methods.handlePrevious(dateLink);
            }
          },
          handlePrevious:function(target) {
            var container = document.getElementById('ui-datepicker-div');
            if (!target) {
              return;
            }
            var currentRow = $(target).closest('tr');
            if (!currentRow) {
              return;
            }
            var previousRow = $(currentRow).prev();
          
            if (!previousRow || previousRow.length === 0) {
              // there is not previous row, so we go to previous month...
              methods.previousMonth();
            } else {
              var prevRowDates = $('td a.ui-state-default', previousRow);
              var prevRowDate = prevRowDates[prevRowDates.length - 1];
          
              if (prevRowDate) {
                setTimeout(function () {
                  methods.setHighlightState(prevRowDate, container);
                  prevRowDate.focus();
                }, 0);
              }
            }
          },
          previousMonth:function() {
            var prevLink = $('.ui-datepicker-prev')[0];
            var container = document.getElementById('ui-datepicker-div');
            prevLink.click();
            // focus last day of new month
            setTimeout(function () {
              var trs = $('tr', container),
                  lastRowTdLinks = $('td a.ui-state-default', trs[trs.length - 1]),
                  lastDate = lastRowTdLinks[lastRowTdLinks.length - 1];
          
              // updating the cached header elements
              methods.updateHeaderElements();
          
              methods.setHighlightState(lastDate, container);
              lastDate.focus();
          
            }, 0);
          },
          nextDay:function(dateLink) {
            var container = document.getElementById('ui-datepicker-div');
            if (!dateLink) {
              return;
            }
            var td = $(dateLink).closest('td');
            if (!td) {
              return;
            }
            var nextTd = $(td).next(),
                nextDateLink = $('a.ui-state-default', nextTd)[0];
          
            if (nextTd && nextDateLink) {
              methods.setHighlightState(nextDateLink, container);
              nextDateLink.focus(); // the next day (same row)
            } else {
              methods.handleNext(dateLink);
            }
          },
          handleNext:function(target) {
            var container = document.getElementById('ui-datepicker-div');
            if (!target) {
              return;
            }
            var currentRow = $(target).closest('tr'),
                nextRow = $(currentRow).next();
          
            if (!nextRow || nextRow.length === 0) {
              methods.nextMonth();
            } else {
              var nextRowFirstDate = $('a.ui-state-default', nextRow)[0];
              if (nextRowFirstDate) {
                methods.setHighlightState(nextRowFirstDate, container);
                nextRowFirstDate.focus();
              }
            }
          },
          nextMonth:function() {
            nextMon = $('.ui-datepicker-next')[0];
            var container = document.getElementById('ui-datepicker-div');
            nextMon.click();
            // focus the first day of the new month
            setTimeout(function () {
              // updating the cached header elements
              methods.updateHeaderElements();
          
              var firstDate = $('a.ui-state-default', container)[0];
              methods.setHighlightState(firstDate, container);
              firstDate.focus();
            }, 0);
          },
          upHandler:function(target, cont, prevLink) {
            prevLink = $('.ui-datepicker-prev')[0];
            var rowContext = $(target).closest('tr');
            if (!rowContext) {
              return;
            }
            var rowTds = $('td', rowContext),
                rowLinks = $('a.ui-state-default', rowContext),
                targetIndex = $.inArray(target, rowLinks),
                prevRow = $(rowContext).prev(),
                prevRowTds = $('td', prevRow),
                parallel = prevRowTds[targetIndex],
                linkCheck = $('a.ui-state-default', parallel)[0];
          
            if (prevRow && parallel && linkCheck) {
              // there is a previous row, a td at the same index
              // of the target AND theres a link in that td
              methods.setHighlightState(linkCheck, cont);
              linkCheck.focus();
            } else {
              // we're either on the first row of a month, or we're on the
              // second and there is not a date link directly above the target
              prevLink.click();
              setTimeout(function () {
                // updating the cached header elements
                methods.updateHeaderElements();
                var newRows = $('tr', cont),
                    lastRow = newRows[newRows.length - 1],
                    lastRowTds = $('td', lastRow),
                    tdParallelIndex = $.inArray(target.parentNode, rowTds),
                    newParallel = lastRowTds[tdParallelIndex],
                    newCheck = $('a.ui-state-default', newParallel)[0];
          
                if (lastRow && newParallel && newCheck) {
                  methods.setHighlightState(newCheck, cont);
                  newCheck.focus();
                } else {
                  // theres no date link on the last week (row) of the new month
                  // meaning its an empty cell, so we'll try the 2nd to last week
                  var secondLastRow = newRows[newRows.length - 2],
                      secondTds = $('td', secondLastRow),
                      targetTd = secondTds[tdParallelIndex],
                      linkCheck = $('a.ui-state-default', targetTd)[0];
          
                  if (linkCheck) {
                   // methods._CountryCurrencysetHighlightState(linkCheck, cont);
                    linkCheck.focus();
                  }
          
                }
              }, 0);
            }
          },
          downHandler:function(target, cont, nextLink) {
            nextLink = $('.ui-datepicker-next')[0];
            var targetRow = $(target).closest('tr');
            if (!targetRow) {
              return;
            }
            var targetCells = $('td', targetRow),
                cellIndex = $.inArray(target.parentNode, targetCells), // the td (parent of target) index
                nextRow = $(targetRow).next(),
                nextRowCells = $('td', nextRow),
                nextWeekTd = nextRowCells[cellIndex],
                nextWeekCheck = $('a.ui-state-default', nextWeekTd)[0];
          
            if (nextRow && nextWeekTd && nextWeekCheck) {
              // theres a next row, a TD at the same index of `target`,
              // and theres an anchor within that td
              methods.setHighlightState(nextWeekCheck, cont);
              nextWeekCheck.focus();
            } else {
              nextLink.click();
          
              setTimeout(function () {
                // updating the cached header elements
                methods.updateHeaderElements();
          
                var nextMonthTrs = $('tbody tr', cont),
                    firstTds = $('td', nextMonthTrs[0]),
                    firstParallel = firstTds[cellIndex],
                    firstCheck = $('a.ui-state-default', firstParallel)[0];
          
                if (firstParallel && firstCheck) {
                  methods.setHighlightState(firstCheck, cont);
                  firstCheck.focus();
                } else {
                  // lets try the second row b/c we didnt find a
                  // date link in the first row at the target's index
                  var secondRow = nextMonthTrs[1],
                      secondTds = $('td', secondRow),
                      secondRowTd = secondTds[cellIndex],
                      secondCheck = $('a.ui-state-default', secondRowTd)[0];
          
                  if (secondRow && secondCheck) {
                    methods.setHighlightState(secondCheck, cont);
                    secondCheck.focus();
                  }
                }
              }, 0);
            }
          },
          onCalendarHide:function() {
            methods.closeCalendar();
          },
          monthDayYearText:function() {
            var cleanUps = $('.amaze-date');
          
            $(cleanUps).each(function (clean) {
            // each(cleanUps, function (clean) {
              clean.parentNode.removeChild(clean);
            });
          
            var datePickDiv = document.getElementById('ui-datepicker-div');
            // in case we find no datepick div
            if (!datePickDiv) {
              return;
            }
          
            var dates = $('a.ui-state-default', datePickDiv);
            $(dates).attr('role', 'button').on('keydown', function (e) {
              if (e.which === 32) {
                e.preventDefault();
                e.target.click();
                setTimeout(function () {
                  methods.closeCalendar();
                }, 100);
              }
            });
            $(dates).each(function (index, date) {
              var currentRow = $(date).closest('tr'),
                  currentTds = $('td', currentRow),
                  currentIndex = $.inArray(date.parentNode, currentTds),
                  headThs = $('thead tr th', datePickDiv),
                  dayIndex = headThs[currentIndex],
                  daySpan = $('span', dayIndex)[0],
                  monthName = $('.ui-datepicker-month', datePickDiv)[0].innerHTML,
                  year = $('.ui-datepicker-year', datePickDiv)[0].innerHTML,
                  number = date.innerHTML;
          
              if (!daySpan || !monthName || !number || !year) {
                return;
              }
          
              // AT Reads: {month} {date} {year} {day}
              // "December 18 2014 Thursday"
              var dateText = date.innerHTML + ' ' + monthName + ' ' + year + ' ' + daySpan.title;
              // AT Reads: {date(number)} {name of day} {name of month} {year(number)}
              // var dateText = date.innerHTML + ' ' + daySpan.title + ' ' + monthName + ' ' + year;
              // add an aria-label to the date link reading out the currently focused date
              date.setAttribute('aria-label', dateText);
            });
          },
          updateHeaderElements:function() {
            var context = document.getElementById('ui-datepicker-div');
            if (!context) {
              return;
            }
          
          //  $(context).find('table').first().attr('role', 'grid');
          
            prev = $('.ui-datepicker-prev', context)[0];
            next = $('.ui-datepicker-next', context)[0];
          
            //make them click/focus - able
            next.href = 'javascript:void(0)';
            prev.href = 'javascript:void(0)';
          
            next.setAttribute('role', 'button');
            prev.setAttribute('role', 'button');
            methods.appendOffscreenMonthText(next);
            methods.appendOffscreenMonthText(prev);
          
            $(next).on('click', methods.handleNextClicks);
            $(prev).on('click', methods.handlePrevClicks);
          
            // add month day year text
            methods.monthDayYearText();
          },
          prepHighlightState:function() {
            var highlight;
            var cage = document.getElementById('ui-datepicker-div');
            highlight = $('.ui-state-highlight', cage)[0] ||
                        $('.ui-state-default', cage)[0];
            if (highlight && cage) {
              methods.setHighlightState(highlight, cage);
            }
          },
          setHighlightState:function(newHighlight, container) {
            var prevHighlight = methods.getCurrentDate(container);
            // remove the highlight state from previously
            // highlighted date and add it to our newly active date
            $(prevHighlight).removeClass('ui-state-highlight');
            $(newHighlight).addClass('ui-state-highlight');
          },
          getCurrentDate:function(container) {
            var currentDate = $('.ui-state-highlight', container)[0];
            return currentDate;
          },
          appendOffscreenMonthText:function(button) {
            var buttonText;
            var isNext = $(button).hasClass('ui-datepicker-next');
            var months = [
              'january', 'february',
              'march', 'april',
              'may', 'june', 'july',
              'august', 'september',
              'october',
              'november', 'december'
            ];
          
            var currentMonth = $('.ui-datepicker-title .ui-datepicker-month').text().toLowerCase();
            var monthIndex = $.inArray(currentMonth.toLowerCase(), months);
            var currentYear = $('.ui-datepicker-title .ui-datepicker-year').text().toLowerCase();
            var adjacentIndex = (isNext) ? monthIndex + 1 : monthIndex - 1;
            
            if (isNext && currentMonth === 'december') {
              currentYear = parseInt(currentYear, 10) + 1;
              adjacentIndex = 0;
            } else if (!isNext && currentMonth === 'january') {
              currentYear = parseInt(currentYear, 10) - 1;
              adjacentIndex = months.length - 1;
            }
          
            buttonText = (isNext)
                          ? 'Next Month, ' + methods.firstToCap(months[adjacentIndex]) + ' ' + currentYear
                          : 'Previous Month, ' + methods.firstToCap(months[adjacentIndex]) + ' ' + currentYear;
          
            $(button).find('.ui-icon').html(buttonText);
          
          },
          firstToCap:function(s) {
            return s.charAt(0).toUpperCase() + s.slice(1);
          }
          //Chandrakant - 30 September 2022 - END
     }
     
     var defaultOptions = {
        btnContainer: "eZee_BookingContainer",
        HotelId:1,
        LayoutTheme:"0",
        LayoutView:"0",
        
         //Header Settings
        HeaderBgColor1:"#eeeeee",
        HeaderFontColor:"#000",
        HeaderBgColor2:"#eeeeee",
        HeaderFontColor2:"#000",
        HeaderBorderColor:"#a6a6a6",
        
        //background settings
        BodyBgColor:"#EDEDED",		
        BodyBgTheme:"0",        
        BodyBgPosition1:"repeat",
        BodyBgPosition2:"left",
        BodyBgPosition3:"top",
        BodyBgPosition4:"",
        BodyFontFamily:"'SourceSansProRegular', sans-serif",
        //calender settings      
        Calinit:true,
        CalShowOn:"both",
        CalDefaultDt: "+0w",
        CalChangeMonth: true,
        CalMaxDate:"",        
        siteroot:true,
        selectedLang:null,
        CalMinDate:0,
        CalDtFormat:"dd-mm-yy",
        //CalBackColor: "#cccccc",
        CalHeaderColor: "#cccccc",
        CalCellActiveColor: "#eeeeee",
        CalCellInActiveColor: "#F4f4f4",        
        CalHeaderFontColor:"#000000",
        CalLanguage:"en",
               
        ShowPromotion:true,			
        
        ResUIAvailabilityTextColor:"#ffffff",
		ResUIAvailabilityBackgroundColor:"#46d46d",
		
		ResUIAddRoomTextColor:"#fcfcfc",
		ResUIAddRoomBackgroundColor:"#5c1111",
		ResUIAddRoomBackgroundHoverColor:"#806666",
		
		NeedHelpBackgroundColor:"#e00d0d",		
		NeedHelpTextColor:"#d3cbd4",
				
		ResUIHelpBoxBackColor:"#d610e0",
		ResUIHelpBoxTextColor:"#ffffff",
		ResUIHelpBoxBorderColor:"#ffffff",
        
		ResUIRoomNameTextColor:"#5c1111",
		ResUIRateTextColor:"#10b063",
		ResUIRoomInfoEnquireTextColor:"#fc3f3f",
                
        init:true,
        loadvisitor:true,
        loadgrid:false,
		
        serverurl:'https://live.ipms247.com/booking/',
		serverhost:'live.ipms247.com',
		
        decimalplaces:2,
        modifysearch:false,
        arrivaldate:null,
        chkindate:null,
        chkoutdate:null,
        
        //modify case
        chkoutdate_modify:null,
        nights_billing:null,
        
        isLogin:'lf',
        ShowNights:false,
        Nonights:50,
        ShowSelectedNights:false,
        DefaultSelectedNights:0	,
        SelectedNights:0,//For Reservation Box
        
        showchild:true,
        acr: true,
		showchild_bb:"-1",//show/hide child from booking box
		defaultadult:5,//combo fill
        defaultchild:5,//combo fill
		SelectedAdults:1,//For Reservation Box
        SelectedChild:0,//For Reservation Box
		defaultselectedadult:0,//Default adult selection
		defaultselectedchild:'',//Default child selection
        
		WebAvailabilityCutOffDay:1,//dept date
		SelectedPromo:null,//For Reservation Box
        
	 	NeedHelpBackgroundColor:"#000000",
		NeedHelpTextColor:"#FFFFFF",
        
		ShowOnlyAvailableRatePlanOrPackage:0,
        ShowMinNightsMatchedRatePlan:false,
		ShowDepart:false,//dept date
		roomtypeunkid:'',
        isShowRecentBooking:'1',
		isShowBEViewers:'1',
		isShowTotalGuestCountryWise:'1',        
        ResUIshowfilter:true,
        ResUIMinimumPrice:0,
		ResUIMaximumPrice:500,
		ResUIshowfilterPlan:true,
		ResUIshowfilterPrice:true,
		ResUIshowfilterPreference:true,
        selectedviewoptions:0,
        AllowMultipleRoomBooking:true,
        WebDefaultRatesAvgNightORWholeStay:1,
        WebShowRatesAvgNightORWholeStay:2,
        RunOnMaximumOccupanySettings:0,
        device_detect:'DESKTOP',
        ResUIshowGuestRating:0,
        ResUILimitRatePlanPackage:1,
		ResUINoOfRatePlanPackage:2,
        ResUIFilterNonRefundable:0,
        ResUIFilterNonRefundableValue:0,
        ResUIFilterExcludeNotAvailable:0,
        ResUIFilterSearchMore:false,
        ResUIFilterRoomType:false,
        ShowClosestAvailability:0,
        specialunkid:"", //Pinal - 1.0.50.55 - 1 August 2016 - Purpose - Display only package when package is selected from website
        opt_roomrateunkid:"", //Flora - 1.0.52.57
        sflroomrateunkid:'', //Chandrakant - 1.0.50.55 - 20 Sep 2016 - Purpose - Save for later
		sflspecialunkid:'', //Chandrakant - 1.0.50.55 - 20 Sep 2016 - Purpose - Save for later
        LocalFolderSessionChk:'',
        HideResetFilter:false,//Chinmay Gandhi - Hide filter for online hostel booking
        //Chinmay Gandhi - 1.0.53.61 - Set currency setting - Start
        WebShowSelectedCurrencyList:'',
        WebDefaultCurrency:'',
		ShowMultipleCurrencyOption:1,
		ShowCurrencyCountryWise:1,
        //Chinmay Gandhi - 1.0.53.61 - End
        TripteasehotelKey:"",//kishan Tailor 4 september 2018 purpose:Triptease Price widget
		TripteasehotelAPIkey:"",//kishan Tailor 4 september 2018 purpose:Triptease Price widget
        ResUIPaxOnMaximumOccupancy:0, //Chandrakant - 2.0.12 - 19 November 2018, Purpose - RES-1774
        BookingFrom:"", //Priyanka Maurya - 04 Nov 2018 - FBEnhancement
        ADABECountry:"", //Chandrakant - 30 September 2022, Purpose - RES-3133
    };
})(jQuery);